import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        ITEM_COMMON_DATA: {
            id: null,
            gear_step: 3.175, // @Explanation: Constant value for flexo print
            amount_gear_teeth: null,
        },

        formData: [],

        loadingGetList: false,
        loadingSaveList: false,
        loadingDeleteItem: false,
    },

    actions: {
        GET_LIST: async ({ state, rootGetters, getters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/equipments/${id}/rapports`);

                commit('SET_FORM_DATA', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        SAVE_LIST: async ({ state, rootGetters, commit, dispatch }, { id, formData, without_fetch }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingSaveList',
                    status: true,
                });

                let resp = await $axios.post(
                    `/api/typographies/${rootGetters.currentTypography.id}/equipments/${id}/rapports`,
                    formData,
                );

                if (!without_fetch) dispatch('GET_LIST', id);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingSaveList',
                    status: false,
                });
            }
        },

        DELETE_ITEM: async ({ commit, rootGetters, dispatch }, { equipmentId, id, without_fetch }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let path = `/api/typographies/${rootGetters.currentTypography.id}/equipments/rapports/${id}`;

                let resp = await $axios.delete(path);

                if (!without_fetch) dispatch('GET_LIST', equipmentId);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },
    },

    mutations: {
        SET_FORM_DATA(state, formData) {
            Vue.set(
                state,
                'formData',
                [...formData].sort((prev, next) => prev.amount_gear_teeth - next.amount_gear_teeth),
            );
        },

        SET_FORM_DATA_ITEM(state) {
            Vue.set(state.formData, state.formData.length, {
                ...state.ITEM_COMMON_DATA,
            });
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        REMOVE_FORM_DATA_ITEM(state, index) {
            Vue.delete(state.formData, index);
        },

        RESET_FORM_DATA(state) {
            state.formData = [];
        },
    },
};
