<template>
    <span class="imgPrev">
        <span class="imgPrev__title">{{ title }}</span>

        <span class="imgPrev__main" :class="`has-${side}`">
            <!-- <img class="imgPrev__img" :src="img" v-if="img"> -->
            <span class="imgPrev__preview">
                <img
                    class="imgPrev__img"
                    :src="img || require('@img/roll/letter.png')"
                    :class="`imgPrev__img--${rotation}`"
                    @error="$fn.fixImageError" />
            </span>
        </span>
    </span>
</template>

<script>
    export default {
        name: 'img-preview',

        props: {
            title: String,
            img: String,
            rotation: [Number, String],
            side: String,
        },
    };
</script>

<style lang="scss" scoped>
    .imgPrev {
        position: relative;
        &:hover {
            .imgPrev__main {
                opacity: 1;
            }
        }
        &__title {
            cursor: pointer;
        }
        &__main {
            pointer-events: none;
            position: absolute;
            width: 100px;
            height: 100px;
            opacity: 0;
            z-index: 3;
            border-radius: 12px;
            transition: all 0.15s ease-in-out;
            &.has-left {
                top: 50%;
                right: calc(100% + 8px);
                transform: translateY(-50%);
            }
            &.has-right {
                top: 50%;
                left: calc(100% + 8px);
                transform: translateY(-50%);
            }
            &.has-right-bottom {
                bottom: 100%;
                left: 100%;
                border-bottom-left-radius: 0px;
                transform: translateY(0%);
            }
        }
        &__preview {
            display: flex;
            width: 100%;
            height: 100%;
            background-color: var(--druk-tint-surface-container-brighter);
            border-radius: 12px;
            box-shadow: var(--druk-elevation-2);
        }
        &__img {
            margin: auto;
            padding: 5px;
            width: 80%;
            height: 80%;
            filter: brightness(2);
            &--1,
            &--5 {
                transform: rotate(0deg);
            }
            &--2,
            &--6 {
                transform: rotate(180deg);
            }
            &--3,
            &--7 {
                transform: rotate(90deg);
            }
            &--4,
            &--8 {
                transform: rotate(270deg);
            }
            &--5,
            &--6,
            &--7,
            &--8 {
                opacity: 0.38;
            }
        }
    }
</style>
