import Vue from 'vue';

import axios from 'axios';

import modules from './modules';
import domains from './domains';
import employees from './modules/employees';

const langParser = (data) => {
    let list = [...data],
        mainLang = list.splice(list.indexOf(list.find((item) => item.code === 'uk')), 1),
        trashLang = list.splice(list.indexOf(list.find((item) => item.code === 'ru')), 1);

    return mainLang.concat(list).concat(trashLang);
};

const CancelToken = axios.CancelToken;

let cancel;

export default {
    namespaced: true,

    modules: {
        modules,
        domains,
        employees,
    },

    state: {
        list: [],
        item: {},
        listPagination: {},
        listFilter: {
            page: null,
            limit: 30,
            title: null,
            domain: null,
            sort: 'new',
            direction: 'desc',
        },

        loadingGetList: false,
        loadingGetItem: false,
        loadingSaveItem: false,
        loadingCopyItem: false,
        loadingDeleteItem: false,
        loadingGetInvoice: false,
    },

    getters: {
        clientPickedLanguages(state, getters, rootState, rootGetters) {
            let list = rootGetters.hasERPAccess
                ? rootState.languages.systemList
                : state.item.domains?.map((domain) => domain.languages).flat();
            return langParser(list);
        },

        alphabetValidationParams(state) {
            return state.item.client_account_languages?.map((language) => language.alias).join(',');
        },

        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },

        isFilterNotEmpty(state, getters, rootState) {
            return !!Object.keys(getters.listActiveFilter).filter(
                (key) => !rootState.constant.INACTIVE_FILTERING_KEYS.concat(state.INACTIVE_FILTERING_KEYS).includes(key),
            ).length;
        },

        novaPoshtaSettings(state, getters, rootState) {
            return (
                state.item.modules.find((module) => module.alias === rootState.constant.TYPOGRAPHY_MODULE_NOVA_POSHTA)?.settings ||
                null
            );
        },

        DPDBalticSettings(state, getters, rootState) {
            return (
                state.item.modules.find((module) => module.alias === rootState.constant.TYPOGRAPHY_MODULE_DPD_BALTIC)?.settings || null
            );
        },

        hasBitrix24Module(state, getters, rootState) {
            return $fn.findIndex(state.item.modules, ['alias', rootState.constant.TYPOGRAPHY_MODULE_BITRIX24]) !== -1;
        },

        hasGoogleAnalyticsModule(state, getters, rootState) {
            return $fn.findIndex(state.item.modules, ['alias', rootState.constant.TYPOGRAPHY_MODULE_GOOGLE_ANALYTICS]) !== -1;
        },

        hasLiqpayModule(state, getters, rootState) {
            return $fn.findIndex(state.item.modules, ['alias', rootState.constant.TYPOGRAPHY_MODULE_LIQPAY]) !== -1;
        },

        hasInstagramFeedModule(state, getters, rootState) {
            return $fn.findIndex(state.item.modules, ['alias', rootState.constant.TYPOGRAPHY_MODULE_INSTAGRAM]) !== -1;
        },

        hasCheckboxModule(state, getters, rootState) {
            return $fn.findIndex(state.item.modules, ['alias', rootState.constant.TYPOGRAPHY_MODULE_CHECKBOX]) !== -1;
        },

        hasGTMModule(state, getters, rootState) {
            return $fn.findIndex(state.item.modules, ['alias', rootState.constant.TYPOGRAPHY_MODULE_GTM]) !== -1;
        },

        hasStripeModule(state, getters, rootState) {
            return $fn.findIndex(state.item.modules, ['alias', rootState.constant.TYPOGRAPHY_MODULE_STRIPE]) !== -1;
        },

        hasNovaPoshtaModule(state, getters, rootState) {
            return $fn.findIndex(state.item.modules, ['alias', rootState.constant.TYPOGRAPHY_MODULE_NOVA_POSHTA]) !== -1;
        },

        hasDPDBalticModule(state, getters, rootState) {
            return $fn.findIndex(state.item.modules, ['alias', rootState.constant.TYPOGRAPHY_MODULE_DPD_BALTIC]) !== -1;
        },

        hasThirdPartyAuthModule(state, getters, rootState) {
            return $fn.findIndex(state.item.modules, ['alias', rootState.constant.TYPOGRAPHY_MODULE_THIRD_PARTY_AUTH]) !== -1;
        },

        hasFacebookPixelModule(state, getters, rootState) {
            return $fn.findIndex(state.item.modules, ['alias', rootState.constant.TYPOGRAPHY_MODULE_FACEBOOK_PIXEL]) !== -1;
        },

        hasMultipleDomainsModule(state, getters, rootState) {
            return $fn.findIndex(state.item.modules, ['alias', rootState.constant.TYPOGRAPHY_MODULE_MULTIPLE_DOMAINS]) !== -1;
        },

        hasRollPrintModule(state, getters, rootState) {
            return $fn.findIndex(state.item.modules, ['alias', rootState.constant.TYPOGRAPHY_MODULE_ROLL_PRINT]) !== -1;
        },

        hasFlexoPrintModule(state, getters, rootState) {
            return $fn.findIndex(state.item.modules, ['alias', rootState.constant.TYPOGRAPHY_MODULE_FLEXO_PRINT]) !== -1;
        },

        hasHotjarModule(state, getters, rootState) {
            return $fn.findIndex(state.item.modules, ['alias', rootState.constant.TYPOGRAPHY_MODULE_HOTJAR]) !== -1;
        },

        hasInvoiceGeneration(state) {
            return !!state.item.generation_invoices;
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_ITEM(state, item) {
            state.item = item;
        },

        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        UPDATE_LIST_FILTER(state, newFilterObj) {
            $fn.forIn(newFilterObj, (val, key) => {
                Vue.set(state.listFilter, key, val);
            });

            Vue.set(state.listFilter, 'page', newFilterObj.page || 1);
        },

        RESET_ITEM(state) {
            state.item = {};
        },

        RESET_STORE(state) {
            state.listFilter = {
                page: null,
                limit: null,
                title: null,
                domain: null,
            };
        },
    },

    actions: {
        GET_LIST: async ({ state, getters, commit }, filters = {}) => {
            try {
                if (cancel !== undefined) cancel();

                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.get('/api/typographies', {
                    cancelToken: new CancelToken(function executor(c) {
                        cancel = c;
                    }),

                    params: Object.keys(filters).length ? filters : getters.listActiveFilter,
                });

                commit('SET_LIST', resp?.list || []);
                commit('SET_PAGINATION', resp?.pagination || {});

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_ITEM: async ({ state, getters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                let resp = await $axios.get(`/api/typographies/${id}`);

                commit('SET_ITEM', resp.single);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        SAVE_ITEM: async ({ state, rootState, rootGetters, commit, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let resp = await $axios.post('/api/typographies', formData);

                if (!rootGetters.hasTypographyAccess) {
                    dispatch('GET_LIST');
                }

                if (formData.id && Object.keys(state.item).length) dispatch('GET_ITEM', formData.id);

                if (rootState.auth.user.typography && formData.id == rootState.auth.user.typography.id) {
                    dispatch('USER_FETCH', null, { root: true });
                }
                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        COPY_ITEM: async ({ commit, dispatch }, { formData, id }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingCopyItem',
                    status: true,
                });

                let resp = await $axios.post(`/api/typographies/copy/${id}`, formData);
                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingCopyItem',
                    status: false,
                });
            }
        },

        DELETE_ITEM: async ({ commit, dispatch }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let resp = await $axios.delete(`/api/typographies/${id}`);
                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },

        PREVIEW_INVOICE: async ({ commit, dispatch }, id) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetInvoice',
                    status: true,
                });

                let resp = await $axios.get(`/api/typographies/${id}/orders/invoice-preview`);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetInvoice',
                    status: false,
                });
            }
        },
    },
};
