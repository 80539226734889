import VueI18n from 'vue-i18n';
import Vue from './vue_base';
import store from '@/store';
import axios from '@/utils/axios/index';
import translates from '@/translates/index.js';

Vue.use(VueI18n);

let locale = localStorage.getItem('_language_druk') || store.state.DEFAULT_LANG;
store.commit('SET_SELECTED_LANG', locale);

const i18n = new VueI18n({
    locale: locale,
    fallbackLocale: 'uk',
});

i18n.setLang = (locale_key) => {
    return axios
        .get(`/api/static-translations`, {
            params: {
                scopes: ['common', 'admin', 'system', 'marketplace'],
            },
            headers: {
                'Accept-Language': locale_key,
            },
        })
        .then((res) => {
            i18n.locale = locale_key;
            i18n.setLocaleMessage(locale_key, $fn.assign(translates, res.list));

            localStorage.setItem('_language_druk', locale_key);

            axios.defaults.headers.common['Accept-Language'] = locale_key;
            document.querySelector('html').setAttribute('lang', locale_key);
            store.commit('SET_SELECTED_LANG', locale_key);
        });
};

export default i18n;
