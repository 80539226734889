import logs from './logs';

export default {
    namespaced: true,

    modules: {
        logs,
    },

    state: {
        PRODUCTION_TYPE_PREPRESS: 'prepress',
        PRODUCTION_TYPE_MANUFACTURING: 'manufacturing',
        PRODUCTION_TYPE_ASSEMBLY: 'assembly',
        PRODUCTION_TYPE_LOGISTIC: 'logistic',

        list: [],
        operations: [],

        workArea: {},

        loadingGetList: false,
        loadingGetWorkArea: false,
    },

    getters: {
        sortedProductionList(state) {
            let map = {};

            state.list.forEach((el) => {
                if (Array.isArray(map[el.sort])) {
                    map[el.sort].push(el);
                } else {
                    map[el.sort] = [el];
                }
            });

            return Object.values(map);
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_WORKAREA(state, data) {
            state.workArea = data;
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        RESET_WORKAREA(state) {
            state.workArea = {};
        },
    },

    actions: {
        GET_LIST: async ({ state, rootGetters, getters, commit }, { orderId, productId }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let path = `/api/typographies/${rootGetters.currentTypography.id}/orders/${orderId}/products/${productId}/productions`;

                let resp = await $axios.get(path);

                commit('SET_LIST', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_WORKAREA: async ({ state, rootGetters, getters, commit }, { orderId, productId }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetWorkArea',
                    status: true,
                });

                let path = `/api/typographies/${rootGetters.currentTypography.id}/orders/${orderId}/products/${productId}/productions/equipment-area`;

                let resp = await $axios.get(path);

                commit('SET_WORKAREA', resp.data);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetWorkArea',
                    status: false,
                });
            }
        },
    },
};
