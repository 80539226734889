<template>
    <div class="section">
        <div class="container">
            <div class="contact__wrap">
                <div class="section__cnt">
                    <div v-if="!isModal" class="section__title">
                        <div class="druk-l-title" :class="{ 'druk-has-button': isOwner }">
                            <div class="druk-l-title__text">
                                {{ isOwner ? $t('owner.tilte') : $t('empl.item.title') }}: {{ this.item ? this.item.full_name : '' }}
                            </div>

                            <div v-if="item.deleted" class="druk-l-title__addl">
                                <div class="label label--disactive">
                                    {{ $t('employees.employee_is_deleted') }}
                                </div>
                            </div>

                            <div v-if="isOwner" class="druk-l-title__content">
                                <druk-dialog-option
                                    :isInline="true"
                                    :isUnavailable="loadingDopplerEffect"
                                    @success="onDopplerEffect()">
                                    <druk-icon-button
                                        :type="'tonal'"
                                        :icon="'key-skeleton'"
                                        :label="$t('common.delete')"
                                        :isWaiting="loadingDopplerEffect" />
                                </druk-dialog-option>
                            </div>

                            <div class="druk-l-title__brd-crmbs">
                                <bread-crumbs :breadCrumbs="breadCrumbs" />
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="hasLoadedGeneralData"
                        :class="{
                            'section__card druk-l-surface-tint-pale druk-u-margin-bottom-40': !isModal,
                        }">
                        <div class="form">
                            <form-save
                                v-if="isEditMode"
                                :item="item"
                                :isEditMode="isEditMode"
                                :isPageLayout="true"
                                :isOwner="isOwner"
                                @onChangeRole="onChangeRole" />

                            <div v-else class="grid">
                                <div
                                    class="grid__col grid__col--2 grid__col--lg-4 grid__col--md-12 _display--md-flex _justify-center _mb--md-25">
                                    <div class="avatar is-form">
                                        <img
                                            :src="item.avatar ? item.avatar : require('@img/placeholder.svg')"
                                            @error="$fn.fixImageError" />
                                    </div>
                                </div>

                                <div class="grid__col grid__col--10 grid__col--lg-8 grid__col--md-12">
                                    <div class="druk-l-info-list">
                                        <div class="druk-l-info-list__item">
                                            <div class="druk-l-info-list__prop">{{ $t('empl.table.name') }}:</div>
                                            <div class="druk-l-info-list__value">{{ item.full_name || $t('common.no_name') }}</div>
                                        </div>

                                        <div class="druk-l-info-list__item">
                                            <div class="druk-l-info-list__prop">{{ $t('table.email') }}:</div>
                                            <div class="druk-l-info-list__value">{{ item.email || '–' }}</div>
                                        </div>

                                        <div class="druk-l-info-list__item">
                                            <div class="druk-l-info-list__prop">{{ $t('table.phone') }}:</div>
                                            <div class="druk-l-info-list__value">{{ item.phone || '–' }}</div>
                                        </div>

                                        <div v-if="item.birthday" class="druk-l-info-list__item">
                                            <div class="druk-l-info-list__prop">{{ $t('form.birthday') }}:</div>
                                            <div class="druk-l-info-list__value">
                                                {{ item.birthday.day }}.{{ item.birthday.month }}.{{ item.birthday.year }}
                                            </div>
                                        </div>

                                        <div v-if="hasRole" class="druk-l-info-list__item">
                                            <div class="druk-l-info-list__prop">{{ $t('empl.change.role') }}:</div>
                                            <div class="druk-l-info-list__value">{{ currentRole.title }}</div>
                                        </div>

                                        <div v-if="item.is_auto_assigned && !item.deleted" class="druk-l-info-list__suppport">
                                            <div class="label label--active">
                                                {{ $t('employees.item.is_auto_assigned') }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <template v-if="$fn.hasAccess(PERM_MODULE_EMPLOYEES, PERM_ACTION_EDIT) && !isModal">
                                <div class="form__divider"></div>

                                <form-actions
                                    :item="item"
                                    :base="'form'"
                                    :isEditMode="isEditMode"
                                    @onCancel="onCancel"
                                    @onStartSaving="onStartSaving" />
                            </template>
                        </div>
                    </div>

                    <div v-else class="section__loader _mb-20">
                        <druk-loader :size="'m'" />
                        <span>{{ $t('common.module_loading') }}</span>
                    </div>

                    <role-permissions
                        v-if="hasRole && hasLoadedData && !isModal"
                        :list="permissions"
                        :role="currentRole"
                        :isEditMode="isEditMode && !hasPickedRoleTemplate"
                        :isCurrentUserRole="isCurrentUserRole"
                        :loadingGetRole="loadingGetRole || loadingGetRoleTemplate" />

                    <form-actions
                        v-if="$fn.hasAccess(PERM_MODULE_EMPLOYEES, PERM_ACTION_EDIT) && !isModal && hasLoadedData && hasRole"
                        class="druk-l-surface-tint-pale"
                        :item="item"
                        :base="'section'"
                        :isEditMode="isEditMode"
                        @onCancel="onCancel"
                        @onStartSaving="onStartSaving" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    import mixinPermissions from '@mixins/mixinPermissions';

    import ModalRoleSave from './components/ModalRoleSave';

    import FormSave from './components/FormSave';
    import FormActions from './components/FormActions';
    import RolePermissions from '@views/technicalData/roles/components/RolePermissions';

    export default {
        name: 'employees-item',

        mixins: [mixinPermissions],

        components: {
            FormSave,
            FormActions,
            RolePermissions,
        },

        props: {
            id: [Number, String],
            isModal: Boolean,
            isOwner: Boolean,
        },

        data() {
            return {
                isEditMode: false,
                hasPickedRoleTemplate: false,

                hasLoadedGeneralData: false,
                hasLoadedSoftData: false,
            };
        },

        created() {
            this.initGeneralData();
            this.initSoftData();
        },

        computed: {
            ...mapState({
                PERM_MODULE_EMPLOYEES: (state) => state.permissions.PERM_MODULE_EMPLOYEES,
                PERM_ACTION_EDIT: (state) => state.permissions.PERM_ACTION_EDIT,
                PERM_ACTION_DELETE: (state) => state.permissions.PERM_ACTION_DELETE,

                user: (state) => state.auth.user,
                item: (state) => state.employees.item,
                formData: (state) => state.employees.formData,
                role: (state) => state.roles.item,
                roleTemplate: (state) => state.roles.templates.item,
                permissionsData: (state) => state.permissions.formData,

                loadingDopplerEffect: (state) => state.auth.loadingDopplerEffect,
                loadingGetItem: (state) => state.employees.loadingGetItem,
                loadingGetRole: (state) => state.roles.loadingGetItem,
                loadingGetRoleTemplate: (state) => state.roles.templates.loadingGetItem,
                loadingDeleteItem: (state) => state.employees.loadingDeleteItem,
            }),

            ...mapGetters({
                currentTypography: 'currentTypography',
                ERPPermissions: 'permissions/ERPList',
                typographyPermissions: 'permissions/typographyList',

                hasERPAccess: 'hasERPAccess',
                hasTypographyAccess: 'hasTypographyAccess',
            }),

            breadCrumbs() {
                return [
                    {
                        title: this.isOwner ? this.$t('owners.title') : this.$t('empl.table.emploeers'),
                        path: this.isOwner ? '/owners' : '/employees',
                    },
                    {
                        title: `${this.item ? this.item.full_name || this.$t('common.no_name') : ''}`,
                        path: '',
                    },
                ];
            },

            permissions() {
                return (this.hasTypographyPermissions ? this.typographyPermissions : this.ERPPermissions).filter(
                    (group) => !!group.modules.length,
                );
            },

            currentRole() {
                return this.isOwner || this.formData.role?.is_template ? this.roleTemplate : this.role;
            },

            hasRole() {
                return !!this.formData.role?.id;
            },

            hasLoadedData() {
                return this.hasLoadedGeneralData && this.hasLoadedSoftData;
            },

            isCurrentUserRole() {
                return (this.user.typography_role || this.user.role)?.id === this.currentRole.id;
            },

            hasTypographyPermissions() {
                return !!(this.hasTypographyAccess || this.isOwner);
            },
        },

        methods: {
            ...mapActions({
                AUTH_DOPPLER_LOGIN: 'AUTH_DOPPLER_LOGIN',

                GET_ITEM: 'employees/GET_ITEM',
                GET_ROLES: 'roles/GET_LIST',
                GET_ROLE: 'roles/GET_ITEM',
                GET_ROLE_TEMPLATES: 'roles/templates/GET_LIST',
                GET_ROLE_TEMPLATE: 'roles/templates/GET_ITEM',
                GET_PERMISSIONS: 'permissions/GET_LIST',
                SAVE_ITEM: 'employees/SAVE_ITEM',
            }),

            ...mapMutations({
                SET_FORM_DATA: 'employees/SET_FORM_DATA',
                RESET_FORM_DATA: 'employees/RESET_FORM_DATA',
            }),

            async initGeneralData() {
                this.resetData();

                await this.GET_ITEM(this.id);
                await this.getRole(this.item.typography_role || this.item.role);

                this.setFormData({ role: this.currentRole });
                this.hasLoadedGeneralData = true;
            },

            async initSoftData() {
                let promises = [this.GET_PERMISSIONS(), this.getRoles()];

                if (this.hasTypographyAccess) promises.push(this.GET_ROLE_TEMPLATES({ page: null, limit: null }));

                await Promise.all(promises);

                this.hasLoadedSoftData = true;
            },

            resetData() {
                this.RESET_FORM_DATA();
            },

            getRole(role) {
                if (!role) return;
                return this.isOwner || role.is_template ? this.GET_ROLE_TEMPLATE(role.role_template_id) : this.GET_ROLE(role.id);
            },

            getRoles() {
                return this.isOwner
                    ? this.GET_ROLE_TEMPLATES({ page: null, limit: null })
                    : this.GET_ROLES({ page: null, limit: null });
            },

            onCancel() {
                this.$bus.$emit('buildPermissionData');
                this.setFormData();

                this.isEditMode = false;
            },

            async onStartSaving(e) {
                let result = await this.$validator.validate();

                if (result) {
                    this.isEditMode = !this.isEditMode;
                    window.scrollTo(0, 0, 'smooth');

                    if (!this.isEditMode) {
                        let newPermissions = this.buildPermissions(),
                            hasChangedRole =
                                this.getParsedMap(this.currentRole.permissions) !== this.getParsedMap(newPermissions) &&
                                this.currentRole.id === this.formData.role.id &&
                                !this.hasPickedRoleTemplate;

                        hasChangedRole ? this.onSaveRole(newPermissions) : this.onSave();
                    } else this.setFormData();
                } else {
                    $fn.showFormError(e.target.closest('.druk-c-btn'), this.$validator.errors.items);
                }
            },

            getParsedMap(object) {
                return Object.keys(object)
                    .sort()
                    .map((key) => [key, object[key].sort()])
                    .toString();
            },

            onSaveRole(permissions) {
                this.$modal.show(
                    ModalRoleSave,
                    {
                        permissions,
                        isOwner: this.isOwner,
                        fnWait: (role) => {
                            this.$set(this.formData, 'role', role);
                            this.onSave();
                        },
                        fnCancel: () => this.onCancel(),
                    },
                    $MODAL_OPTIONS.normal,
                );
            },

            async onSave() {
                try {
                    let formData = {
                        ...this.formData,
                        id: this.item?.id || null,
                        typography_id: this.hasTypographyAccess
                            ? this.currentTypography.id
                            : this.isOwner
                              ? this.formData.typography.id
                              : null,
                        birthday: this.formData.birthday?.from || null,
                    };

                    if (this.hasERPAccess) {
                        this.$set(formData, 'role_id', this.formData.role.id);
                    } else
                        this.$set(
                            formData,
                            `${this.formData.role.is_template ? 'role_template_id' : 'typography_role_id'}`,
                            this.formData.role.id,
                        );

                    this.SAVE_ITEM({ formData: formData });
                    this.getRole(this.formData.role);

                    this.hasPickedRoleTemplate = false;
                    window.scrollTo(0, 0, 'smooth');
                } catch (e) {
                    $fn.setValidateErrors(e, this.errors);
                }
            },

            setFormData(data = {}) {
                this.SET_FORM_DATA(
                    Object.assign(
                        { ...this.item },
                        {
                            role: this.item.typography_role || this.item.role,
                            birthday: {
                                from: this.item.birthday ? Object.values(this.item.birthday).join('-') : null,
                                to: this.item.birthday ? Object.values(this.item.birthday).join('-') : null,
                            },
                            deleted: data.deleted !== null ? data.deleted : this.item.deleted,
                        },
                    ),
                );
            },

            async onActivate(e) {
                let result = await this.$validator.validate();

                if (result) {
                    this.setFormData({ deleted: 0 });
                    this.onSave();
                } else {
                    $fn.showFormError(e.target.closest('.druk-c-btn'), this.$validator.errors.items);
                }
            },

            async onDopplerEffect() {
                await this.AUTH_DOPPLER_LOGIN(this.item.id);

                this.$router.push({
                    name: 'dashboard',
                    query: { with_doppler_reload: true },
                });
            },

            async onChangeRole(role) {
                await this.getRole(role);
                this.hasPickedRoleTemplate = role.is_template;

                this.$bus.$emit('buildPermissionData');
            },
        },
    };
</script>

<style lang="scss" scoped></style>
