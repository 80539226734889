import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        COMMON_ITEM: {
            max_clicks: null,
            max_price: null,
            min_price: null,

            x1: 40,
            y1: 240,
            x2: 460,
            y2: 10,
        },

        item: null,
        itemStash: null,

        resetData: null,
    },

    actions: {
        DELETE_ITEM: ({ state, getters, commit }) => {
            commit('RESET_ITEM');
            commit('STASH_ITEM');
        },
    },

    mutations: {
        SET_ITEM(state, item) {
            Vue.set(state, 'item', item ? { ...item } : null);
            Vue.set(state, 'itemStash', item ? { ...item } : null);
            Vue.set(state, 'resetData', item ? { ...item } : null);
        },

        SET_ITEM_PROP(state, { key, value }) {
            Vue.set(state.item, key, value);
        },

        UPDATE_ITEM(state, formData = {}) {
            Object.assign(state.item, formData);
        },

        STASH_ITEM(state) {
            state.itemStash = state.item;
        },

        RESTORE_ITEM(state) {
            Vue.set(state, 'item', state.itemStash ? { ...state.itemStash } : null);
            Vue.set(state, 'resetData', state.itemStash ? { ...state.itemStash } : null);
        },

        RESET_ITEM(state) {
            if (state.item) Vue.set(state, 'itemStash', { ...state.item });

            Vue.set(state, 'item', null);
            Vue.set(state, 'resetData', null);
        },
    },
};
