export default function handleInteraction({ canvas, curve, borderGap }) {
    if (!curve) return {};

    let lpts = [...curve.points].slice(1, 3);

    const gap = borderGap;

    let moving = false,
        mx = 0,
        my = 0,
        ox = 0,
        oy = 0,
        cx = 0,
        cy = 0,
        mp = false;

    let handler = {
        onUpdate: function () {},
    };

    let hasMouseInGap = (y) => {
        return y < gap || y > curve.points[3].y;
    };

    curve.mouse = false;

    canvas.addEventListener('mousedown', function (evt) {
        mx = evt.offsetX;
        my = evt.offsetY;

        lpts.forEach(function (p) {
            if (Math.abs(mx - p.x) < 10 && Math.abs(my - p.y) < 10) {
                moving = true;
                mp = p;
                cx = p.x;
                cy = p.y;
            }
        });
    });

    canvas.addEventListener('mousemove', function (evt) {
        var found = false;

        if (!lpts || hasMouseInGap(evt.offsetY)) {
            moving = false;
            return handler.onUpdate(evt);
        }

        lpts.forEach(function (p) {
            var mx = evt.offsetX;
            var my = evt.offsetY;

            if (Math.abs(mx - p.x) < 10 && Math.abs(my - p.y) < 10) {
                found = found || true;
            }
        });

        canvas.style.cursor = found ? 'pointer' : 'default';

        if (!moving) return handler.onUpdate(evt);

        ox = evt.offsetX - mx;
        oy = evt.offsetY - my;

        mp.x = cx + ox;
        mp.y = cy + oy;

        curve.update();
        handler.onUpdate();
    });

    canvas.addEventListener('mouseup', function (evt) {
        if (!moving) return;

        moving = false;
        mp = false;

        handler.onUpdate(evt);
    });

    canvas.addEventListener('click', function (evt) {
        var mx = evt.offsetX;
        var my = evt.offsetY;
    });

    return handler;
}
