export default {
    namespaced: true,

    state: {
        loadingActualizeItem: false,
    },

    getters: {},

    mutations: {
        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },
    },

    actions: {
        ACTUALIZE_ITEM_TR: async ({ state, rootGetters, getters, commit }, formData) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingActualizeItem', status: true });

                let resp = await $axios.post(
                    `/api/typographies/${rootGetters.currentTypography.id}/store/internals/actualize`,
                    formData,
                );

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingActualizeItem', status: false });
            }
        },
    },
};
