import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        list: [],
        listPagination: {},

        logTypes: [],

        listFilter: {
            page: null,
            limit: 30,
            operation_id: null,
            sort: 'new',
            direction: 'desc',
            'created_at[from]': null,
            'created_at[to]': null,
        },

        loadingGetList: false,
        loadingSaveItem: false,
        loadingGetLogTypes: false,
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },

        isFilterNotEmpty(state, getters, rootState) {
            return !!Object.keys(getters.listActiveFilter).filter(
                (key) => !rootState.constant.INACTIVE_FILTERING_KEYS.concat(state.INACTIVE_FILTERING_KEYS).includes(key),
            ).length;
        },

        formattedList(state) {
            let newMap = [];

            state.list.forEach((el) => {
                let operation = $fn.assign(el.operation, {
                    operation_type: el.operation_type,
                    material_type: !el.operation?.items && el.operation ? materialTypeGeneration(el.operation.material_table) : null,
                });

                function materialTypeGeneration(table) {
                    return table.format
                        ? `${table.format.title || table.format.original_title} ${table.options.map((option) => option.text).join(' ')}`
                        : table.options.map((option) => option.text).join(' ');
                }

                let items = $fn.map(operation.items, (material, index) => {
                    return {
                        index: index,
                        material: material.material,
                        dimension: material.material_table.dimension,
                        material_table: material.material_table,
                        material_type: materialTypeGeneration(material.material_table),
                        amount: material.amount,
                        amount_in_pack: material.amount_in_pack,
                        unit_price: material.unit_price,
                        price: material.price,
                    };
                });

                if (el.operation?.items?.length > 1) {
                    newMap.push($fn.assign(operation));
                    items.forEach((item) => {
                        newMap.push($fn.assign(item, { parent_id: operation.id }));
                    });
                } else {
                    newMap.push($fn.assign(operation, $fn.first($fn.cloneDeep(items))));
                }
            });

            return newMap;
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },
        SET_LOG_TYPES(state, types) {
            state.logTypes = types;
        },
        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },
        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },
        UPDATE_LIST_FILTER(state, newFilterObj) {
            $fn.forIn(newFilterObj, (val, key) => {
                Vue.set(state.listFilter, key, val);
            });

            Vue.set(state.listFilter, 'page', newFilterObj.page || 1);
        },
        RESET_STORE(state) {
            state.listFilter = {
                page: null,
                limit: 30,
                operation_id: null,
                sort: 'new',
                direction: 'desc',
            };
        },
    },

    actions: {
        GET_LIST: async ({ state, rootGetters, getters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/store/operations`, {
                    params: getters.listActiveFilter,
                });

                commit('SET_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_LOG_TYPES: async ({ state, rootGetters, getters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetLogTypes',
                    status: true,
                });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/store/operations/types`, {
                    params: getters.listActiveFilter,
                });

                commit('SET_LOG_TYPES', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetLogTypes',
                    status: false,
                });
            }
        },

        EXPORT_LIST: async ({ state, commit, getters, rootGetters, dispatch }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios
                    .get(`/api/typographies/${rootGetters.currentTypography.id}/store/operations/export`, {
                        params: getters.listActiveFilter,
                    })
                    .then((resp) => {
                        const url = resp.link;
                        const link = document.createElement('a');
                        link.href = url;
                        link.click();

                        URL.revokeObjectURL(link.href);
                    });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },
    },
};
