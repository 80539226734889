<template>
    <div
        class="formula__prompt form"
        :class="{ [`druk-l-surface-${surface}`]: surface, 'has-active': hasShownPrompt, 'has-alt': hasAltStyles }">
        <druk-support class="druk-u-margin-bottom-16">{{ $t('calcModes.formula.prompt') }}</druk-support>

        <div class="form__body-head">
            <div class="from__body-head-text">{{ $t('calcModes.formula.interactions') }}</div>
        </div>

        <div class="formula__interactions">
            <div class="formula__interaction">
                <druk-support :hasNoIcon="true">
                    <div class="formula__variable has-icon">
                        <font-awesome-icon icon="fa-regular fa-arrow-left" />
                    </div>
                    <span>— {{ $t('calcModes.formula.interactions_arrow_left') }}</span>
                </druk-support>
            </div>

            <div class="formula__interaction">
                <druk-support :hasNoIcon="true">
                    <div class="formula__variable has-icon">
                        <font-awesome-icon icon="fa-regular fa-arrow-right" />
                    </div>
                    <span>— {{ $t('calcModes.formula.interactions_arrow_right') }}</span>
                </druk-support>
            </div>

            <div class="formula__interaction">
                <druk-support :hasNoIcon="true">
                    <div class="formula__variable">
                        {{ isMacOS ? 'Delete' : 'BackSpace' }}
                    </div>
                    <span>— {{ $t('calcModes.formula.interactions_backspace') }}</span>
                </druk-support>
            </div>

            <div class="formula__interaction">
                <druk-support :hasNoIcon="true">
                    <template v-if="isMacOS">
                        <div class="formula__variable has-icon">
                            <font-awesome-icon icon="fa-regular fa-command" />
                        </div>
                        <font-awesome-icon class="_mr-4" icon="fa-regular fa-plus" />
                    </template>

                    <div class="formula__variable">Delete</div>
                    <span>— {{ $t('calcModes.formula.interactions_delete') }}</span>
                </druk-support>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'calc-formula-prompt',

        props: {
            surface: {
                type: String,
                default: 'default',
            },

            hasShownPrompt: Boolean,
            hasAltStyles: Boolean,
        },

        computed: {
            isMacOS() {
                return this.$device.isMacOS;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .formula {
        &__prompt {
            padding: 0px 12px 0px 24px;
            max-height: 0px;
            border-radius: 8px;
            opacity: 0;
            transition: all 0.3s ease-in-out;
            &.has-active {
                margin-bottom: 20px;
                max-height: 200px;
                opacity: 1;
                padding: 24px 12px 24px 24px;
            }
        }
        &__interactions {
            display: flex;
            flex-wrap: wrap;
            margin: 0px -10px -5px;
        }
        &__interaction {
            flex-basis: 50%;
            padding: 0px 10px;
            margin-bottom: 5px;
            font-size: 12px;
        }
        &__variable {
            cursor: initial;
            font-size: 12px;
            line-height: 22px;
            margin: 0px 4px 0px 0px;
            &:hover {
                box-shadow: initial;
                transform: initial;
            }
        }
    }
</style>
