import Vue from 'vue';

const COMMON_FORM_DATA = {
    api_key: null,
    delivery_city: null,
    delivery_warehouse: null,
};

const listParser = (list) => {
    return Object.entries(list)
        .map(([id, title]) => ({ id, title }))
        .sort((prev, next) => prev.title.localeCompare(next.title));
};

export default {
    namespaced: true,

    state: {
        formData: { ...COMMON_FORM_DATA },

        novaPoshtaCities: [],
        novaPoshtaWarehouses: [],

        loadingGetNovaPoshtaCities: false,
        loadingGetNovaPoshtaWarehouses: false,
    },

    actions: {
        GET_NOVA_POSHTA_CITIES: async ({ state, rootState, rootGetters, getters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetNovaPoshtaCities', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `api/typographies/${rootGetters.currentTypography.id}/delivery/nova-poshta/cities`
                    : `api/typographies/${rootState.typographies.item.id}/delivery/nova-poshta/cities`;

                let resp = await $axios.get(path);

                commit('SET_NOVA_POSHTA_CITIES', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetNovaPoshtaCities', status: false });
            }
        },

        GET_NOVA_POSHTA_WAREHOUSES: async ({ state, rootState, rootGetters, getters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetNovaPoshtaWarehouses', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `api/typographies/${rootGetters.currentTypography.id}/delivery/nova-poshta/warehouses/${id}`
                    : `api/typographies/${rootState.typographies.item.id}/delivery/nova-poshta/warehouses/${id}`;

                let resp = await $axios.get(path);

                commit('SET_NOVA_POSHTA_WAREHOUSES', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetNovaPoshtaWarehouses', status: false });
            }
        },
    },

    mutations: {
        SET_FORM_DATA(state, formData) {
            Vue.set(state, 'formData', { ...formData });
        },

        SET_NOVA_POSHTA_CITIES(state, list) {
            state.novaPoshtaCities = listParser(list);
        },

        SET_NOVA_POSHTA_WAREHOUSES(state, list) {
            state.novaPoshtaWarehouses = listParser(list);
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        RESET_NOVA_POSHTA_CITIES(state) {
            state.novaPoshtaCities = [];
        },

        RESET_NOVA_POSHTA_WAREHOUSES(state) {
            state.novaPoshtaWarehouses = [];
        },

        RESET_FORM_DATA(state) {
            Vue.set(state, 'formData', { ...COMMON_FORM_DATA });
        },
    },
};
