var render = function render(){var _vm=this,_c=_vm._self._c;return _c(!!_vm.path ? 'router-link' : 'button',{tag:"component",staticClass:"druk-c-icon-btn",class:{ 'druk-is-waitnig': _vm.isWaiting, 'druk-is-disabled': _vm.isDisabled },attrs:{"to":_vm.path},on:{"click":function($event){return _vm.$emit('click', $event)}}},[_c('div',{staticClass:"druk-c-icon-btn__wrapper",class:{
            [`druk-u-${_vm.type}`]: !!_vm.type,
            'druk-is-inverse': _vm.isInverse,
            'druk-is-highlighted': _vm.isHighlighted,
            'druk-is-disabled': _vm.isDisabled,
        },style:({
            '--druk-icon-btn-palette-color': _vm.palette ? _vm.$material[_vm.palette.color] : null,
            '--druk-icon-btn-palette-background-color': _vm.palette ? _vm.$material[_vm.palette.bg] : null,
            '--druk-icon-btn-palette-border-color': _vm.palette ? _vm.$material[_vm.palette.outline] : null,
        })},[_c('div',{staticClass:"druk-c-icon-btn__icon",class:{ 'druk-is-shown': !_vm.isWaiting, 'druk-is-disabled': _vm.isDisabled }},[_c('druk-icon',{attrs:{"color":_vm.isDisabled ? _vm.DISABLED_COLOR : _vm.iconColor,"name":_vm.icon}})],1),(_vm.isWaiting && !_vm.isDisabled)?_c('div',{staticClass:"druk-c-icon-btn__loader"},[_c('druk-circular-indicator',{attrs:{"size":'s',"color":_vm.iconColor}})],1):_vm._e(),(_vm.badge)?_c('div',{staticClass:"druk-c-icon-btn__badge",class:{ 'druk-is-small': _vm.hasSmallBadge }},[_c('druk-badge',{attrs:{"label":_vm.hasSmallBadge ? '' : _vm.badge}})],1):_vm._e(),_c('div',{staticClass:"druk-c-icon-btn__state",class:{ [`druk-u-${_vm.type}`]: !!_vm.type, 'druk-is-highlighted': _vm.isHighlighted, 'druk-is-inverse': _vm.isInverse }})])])
}
var staticRenderFns = []

export { render, staticRenderFns }