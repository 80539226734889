<template>
    <div
        class="ranges__drop rDrop"
        :class="{ 'has-active': hasActive, 'has-inaccessible': hasInaccessible }"
        ref="rDrop"
        :id="rDropId">
        <div class="rDrop__main" v-on="hasContextMode ? { contextmenu: () => onToggle() } : { click: () => onToggle() }">
            <slot></slot>
        </div>

        <div class="rDrop__wrapper" :class="{ [`druk-l-surface-${surface}`]: surface, [side]: side, 'has-from-center': !side }">
            <div class="form">
                <div class="form__item" :class="{ 'has-prompt': hasLockedValues }">
                    <form-input
                        v-model="count"
                        :class="{ '_mr-5': hasLockedValues, 'druk-is-disabled': hasFirstRange }"
                        :label="startLabel"
                        :errorLabel="$t('time.range.start')"
                        :rules="validationRules"
                        :surface="surface"
                        name="count" />

                    <druk-hint
                        v-if="hasLockedValues"
                        :tooltip="`${$t('common.locked_values')}: ${lockedValues.join(', ')}`"
                        :icon="{ name: 'triangle-exclamation', color: 'warning' }" />
                </div>

                <div class="form__item">
                    <form-input
                        v-model="price"
                        :label="`${$t('equep.range.price')} (${selectedCurrencyCode})`"
                        :rules="'required|float'"
                        :surface="surface"
                        name="price" />
                </div>

                <div class="form__footer form__footer--alt has-sm">
                    <druk-button
                        v-if="isEditMode && !hasFirstRange"
                        class="druk-u-margin-right-8"
                        :type="'outlined'"
                        :icon="'trash'"
                        :label="$t('common.delete')"
                        :isHighlighted="true"
                        @click="onRemove" />

                    <druk-button :icon="'floppy-disk'" :label="isEditMode ? $t('btn.save') : $t('btn.create')" @click="onSave" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    export default {
        name: 'calc-modes-range-save',

        props: {
            index: [Number, String],
            lockedValues: {
                type: Array,
                default: () => {
                    return [];
                },
            },

            surface: {
                type: String,
                default: 'tint-brighter',
            },

            side: String, // @Explanation: has-from-right

            isEditMode: Boolean,
            hasContextMode: Boolean,
            hasInaccessible: Boolean,
        },

        data() {
            return {
                hasActive: false,
            };
        },

        mounted() {
            window.addEventListener('click', (e) => {
                this.onDetectClick(e);
            });
            window.addEventListener('contextmenu', (e) => {
                this.onDetectClick(e);
            });
        },

        destroyed() {
            window.removeEventListener('click', (e) => {
                this.onDetectClick(e);
            });
            window.removeEventListener('contextmenu', (e) => {
                this.onDetectClick(e);
            });
        },

        computed: {
            ...mapState({
                rangesCast: (state) => state.calcModes.ranges.listCast,

                selectedCurrencyCode: (state) => state.selectedCurrencyCode,
            }),

            rDropId() {
                return `drop-${$fn.generateHash()}`;
            },

            rDropIds() {
                return [this.rDropId];
            },

            range() {
                let currentRange = this.rangesCast.find((range) => range.index === this.index);

                return {
                    ...currentRange,
                    min:
                        this.rangesCast[currentRange?.index - 1] && this.isEditMode
                            ? +this.rangesCast[currentRange.index - 1].count + 1
                            : 2,
                    max: this.rangesCast[currentRange?.index + 1] ? +this.rangesCast[currentRange.index + 1].count - 1 : null,
                };
            },

            startLabel() {
                return !this.hasFirstRange
                    ? `${this.$t('time.range.start')} (${this.$t('common.min')}: ${this.range.min}, ${this.$t('common.max')}: ${this.range.max || '...'})`
                    : this.$t('time.range.start');
            },

            validationRules() {
                return {
                    int: [`min_value:${!this.hasFirstRange ? this.range.min : 1}`, `max_value:${this.range.max || false}`],
                    required: true,
                    not_in_array: this.lockedValues,
                };
            },

            count: {
                get() {
                    return this.range.count;
                },
                set(value) {
                    this.SET_RANGE_DATA({ index: this.index, key: 'count', value });
                },
            },

            price: {
                get() {
                    return this.range.price;
                },
                set(value) {
                    this.SET_RANGE_DATA({ index: this.index, key: 'price', value });
                },
            },

            hasFirstRange() {
                return this.range.count === 1 && this.isEditMode;
            },

            hasLockedValues() {
                return this.lockedValues.length;
            },
        },

        methods: {
            ...mapMutations({
                SET_RANGE_DATA: 'calcModes/ranges/SET_ITEM_DATA',
                UPDATE_RANGES_CAST: 'calcModes/ranges/UPDATE_LIST_CAST',
                DELETE_RANGE: 'calcModes/ranges/DELETE_ITEM',
                RESET_RANGES_CAST: 'calcModes/ranges/RESET_LIST_CAST',
                RESET_RANGES_CAST_ITEM: 'calcModes/ranges/RESET_LIST_CAST_ITEM',
            }),

            onDetectClick(e) {
                if (!this.rDropIds.includes(e.target.closest('.rDrop')?.getAttribute('id')) && this.hasActive) this.onClose();
                if (e?.type === 'contextmenu') e.preventDefault();
            },

            onClose() {
                if (!this.isEditMode && !this.count && !this.price) {
                    this.onClear();
                    return;
                }

                this.RESET_RANGES_CAST_ITEM(this.index);
                this.onToggle();
            },

            onClear() {
                this.DELETE_RANGE(this.index);
                this.RESET_RANGES_CAST();

                this.$emit('onUpdate', {});

                this.onToggle();
            },

            onToggle() {
                this.hasActive = !this.hasActive;
                this.hasActive ? this.$validator.reset() : this.$emit('onClose', this.isEditMode ? this.index - 1 : null);
            },

            onRemove() {
                this.DELETE_RANGE(this.index);
                this.$emit('onUpdate');

                this.onClose();
            },

            async onSave(e) {
                let result = await this.$validator.validate();

                if (result) {
                    try {
                        if (!this.isEditMode) this.UPDATE_RANGES_CAST();

                        this.$emit('onUpdate');
                        this.onToggle();
                    } catch (e) {
                        $fn.setValidateErrors(e, this.errors);
                    }
                } else {
                    $fn.showFormError(e.target.closest('.druk-c-btn'), this.$validator.errors.items);
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .rDrop {
        position: relative;
        z-index: 11;
        &.has-active {
            .rDrop__main {
                pointer-events: none;
            }
            .rDrop__wrapper {
                pointer-events: all;
                visibility: visible;
                top: calc(100% + 20px);
                opacity: 1;
            }
        }
        &__wrapper {
            pointer-events: none;
            visibility: hidden;
            position: absolute;
            top: calc(100% + 30px);
            padding: 20px 16px;
            width: 286px;
            border-radius: 8px;
            box-shadow: var(--druk-elevation-2);
            opacity: 0;
            z-index: 11;
            transition:
                opacity 0.2s ease-in-out,
                top 0.2s ease-in-out;
            &.has-from-center {
                left: 50%;
                transform: translateX(-50%);
            }
            &.has-from-right {
                right: 0%;
            }
        }
    }
</style>
