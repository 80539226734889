import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        list: [],
        listPagination: {},
        listFilter: {
            page: null,
            limit: 30,
            title: null,
            is_published: null,
            sort: 'new',
            direction: 'desc',
        },

        item: {},

        loadingGetList: false,
        loadingGetItem: false,
        loadingSaveItem: false,
        loadingDeleteItem: false,
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_ITEM(state, item) {
            state.item = item;
        },

        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },

        SET_FILTER_LIST(state, { key, val }) {
            state.listFilter[key] = val;
        },

        MERGE_FILTER_LIST(state, val) {
            state.listFilter = Object.assign(state.listFilter, val);
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },
    },

    actions: {
        SAVE_ITEM: async ({ state, rootGetters, commit, dispatch }, { productId, kindId, detailId, id }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let path = `/api/typographies/${rootGetters.currentTypography.id}/products/${productId}/kinds/${kindId}/details/${detailId}/algorithms/${id}`;

                let resp = await $axios.post(path);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        DELETE_ITEM: async ({ commit, rootGetters, dispatch }, { productId, kindId, detailId, id, without_noty }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let path = `/api/typographies/${rootGetters.currentTypography.id}/products/${productId}/kinds/${kindId}/details/${detailId}/algorithms/${id}`;

                let resp = await $axios.delete(path, {
                    headers: {
                        'Without-Noty': without_noty,
                    },
                });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },
    },
};
