import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        list: [],
        item: {},

        formData: {},
        selectedLang: null,

        loadingGetList: false,
        loadingGetItem: false,
        loadingSaveItem: false,
        loadingDeleteItem: false,
    },

    getters: {},

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_ITEM(state, item) {
            state.item = item;
        },

        RESET_ITEM(state) {
            state.item = {};
        },

        SET_FORM_DATA(state, formData) {
            Vue.set(state, 'formData', formData);
        },

        RESET_FORM_DATA(state) {
            state.formData = {};
        },

        SET_SELECTED_LANG(state, lang) {
            state.selectedLang = lang;
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },
    },

    actions: {
        GET_LIST: async ({ commit, rootState, rootGetters, state, getters }, { base, category }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.get(`/api/knowledge/${base}/categories/${category}/articles`);

                commit('SET_LIST', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_ITEM: async ({ commit, rootState, rootGetters, state, getters }, { base, category, article, filters }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                let resp = await $axios.get(`/api/knowledge/${base}/categories/${category}/articles/${article}`, {
                    params: filters,
                });

                commit('SET_ITEM', resp.single);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        SAVE_ITEM: async ({ commit, dispatch, rootState, rootGetters, state, getters }, { base, category, formData }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let resp = await $axios.post(`/api/knowledge/${base}/categories/${category}/articles`, formData);

                dispatch('GET_ITEM', { base, category, article: resp.single.alias });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        DELETE_ITEM: async ({ commit, dispatch, rootState, rootGetters, state, getters }, { base, category, article }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let resp = await $axios.delete(`/api/knowledge/${base}/categories/${category}/articles/${article}`);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },
    },
};
