import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        list: [],
        listPagination: {},

        formDataMaterials: [],

        listFilter: {
            page: null,
            limit: 30,
            sort: 'new',
            supplier_id: null,
            status_ids: null,
            direction: 'desc',
            'created_at[from]': null,
            'created_at[to]': null,
        },

        loadingGetList: false,
        loadingSaveItem: false,
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },

        isFilterNotEmpty(state, getters, rootState) {
            return !!Object.keys(getters.listActiveFilter).filter(
                (key) => !rootState.constant.INACTIVE_FILTERING_KEYS.concat(state.INACTIVE_FILTERING_KEYS).includes(key),
            ).length;
        },

        formattedList(state) {
            let newMap = [];

            state.list.forEach((el) => {
                let receipt = {
                    id: el.id,
                    comment: el.comment,
                    date_at: el.date_at,
                    paid_sum: el.paid_sum,
                    total_sum: el.total_sum,
                    status: el.status,
                    supplier: el.supplier,
                    employee: el.employee,
                };

                function materialTypeGeneration(table) {
                    return table.format
                        ? `${table.format.title || table.format.original_title} ${table.options.map((option) => option.text).join(' ')}`
                        : table.options.map((option) => option.text).join(' ');
                }

                let materials = $fn.map(el.items, (material, index) => {
                    return {
                        index: index,
                        material: material.material,
                        dimension: material.material_table.dimension,
                        material_table: material.material_table,
                        material_type: materialTypeGeneration(material.material_table),
                        amount: material.amount,
                        amount_in_pack: material.amount_in_pack,
                        unit_price: material.unit_price,
                        price: material.price,
                    };
                });

                if (el.items?.length > 1) {
                    newMap.push(
                        $fn.assign(receipt, {
                            materials: $fn.first($fn.cloneDeep(materials)),
                        }),
                    );
                    materials.forEach((material) => {
                        newMap.push($fn.assign(material, { parent_id: receipt.id }));
                    });
                } else {
                    newMap.push($fn.assign(receipt, $fn.first($fn.cloneDeep(materials))));
                }
            });

            return newMap;
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },
        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },
        ADD_MATERIAL(state, stockMaterialId) {
            state.formDataMaterials.push({
                stockMaterialId: stockMaterialId,
                material_table_id: null,
                material: null,
                materialType: null,

                amount: null,
                dimension: null,
                amount_in_pack: null,
                price: null,
                unit_price: null,
            });
        },
        RESET_FORM_DATA_MATERIAL(state) {
            state.formDataMaterials = [];
        },
        UPDATE_MATERIAL(state, formData) {
            let index = $fn.findIndex(state.formDataMaterials, ['stockMaterialId', formData.stockMaterialId]);
            Vue.set(state.formDataMaterials, index, formData);
        },
        DELETE_MATERIAL(state, stockMaterialId) {
            let index = $fn.findIndex(state.formDataMaterials, ['stockMaterialId', stockMaterialId]);
            Vue.delete(state.formDataMaterials, index);
        },
        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },
        UPDATE_LIST_FILTER(state, newFilterObj) {
            $fn.forIn(newFilterObj, (val, key) => {
                Vue.set(state.listFilter, key, val);
            });

            Vue.set(state.listFilter, 'page', newFilterObj.page || 1);
        },
        RESET_STORE(state) {
            state.listFilter = {
                page: null,
                limit: 30,
                sort: 'new',
                supplier_id: null,
                status_ids: null,
                direction: 'desc',
            };
        },
    },

    actions: {
        GET_LIST: async ({ state, rootGetters, getters, commit }, filter) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/store/receipts`, {
                    params: filter || getters.listActiveFilter,
                });

                commit('SET_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        ADD_MOVEMENT: async ({ state, rootState, rootGetters, commit, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let resp = await $axios.post(`/api/typographies/${rootGetters.currentTypography.id}/store/receipts`, formData);

                dispatch('GET_LIST');
                dispatch('stock/availability/GET_LIST', null, { root: true });
                dispatch('stock/logs/GET_LIST', null, { root: true });

                if (formData.movementId) {
                    dispatch('stock/movement/GET_LIST', { id: formData.movementId }, { root: true });
                }

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        DELETE_MOVEMENT: async ({ state, rootState, rootGetters, commit, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let resp = await $axios.post(`/api/typographies/${rootGetters.currentTypography.id}/store/writeoffs`, formData);

                dispatch('GET_LIST');
                dispatch('stock/availability/GET_LIST', null, { root: true });
                dispatch('stock/logs/GET_LIST', null, { root: true });

                if (formData.movementId) {
                    dispatch('stock/movement/GET_LIST', { id: formData.movementId }, { root: true });
                }

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        PAY_SUPPLY: async ({ state, rootState, rootGetters, commit, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let resp = await $axios.post(`/api/typographies/${rootGetters.currentTypography.id}/store/receipts/pay`, formData);

                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        EXPORT_LIST: async ({ state, commit, getters, rootGetters, dispatch }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios
                    .get(`/api/typographies/${rootGetters.currentTypography.id}/store/receipts/export`, {
                        params: getters.listActiveFilter,
                    })
                    .then((resp) => {
                        const url = resp.link;
                        const link = document.createElement('a');
                        link.href = url;
                        link.click();

                        URL.revokeObjectURL(link.href);
                    });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },
    },
};
