<template>
    <div class="form__tabs">
        <druk-tabs
            :tabs="list"
            :tabId="'code'"
            :tabLabel="'code'"
            :startFrom="startFrom"
            :isSecondary="true"
            :hasNoScroll="true"
            :hasEnlargedLabels="true"
            @change="$emit('change', $event)" />
    </div>
</template>

<script>
    export default {
        name: 'form-language-tabs',

        props: {
            languages: Array,
            formData: Object,
            requiredFields: [String, Array],
        },

        data() {
            return {
                startFrom: null,
            };
        },

        created() {
            this.startFrom =
                this.languages.find((language) => language.code === this.$store.state.selectedLang)?.code ||
                [...this.languages].shift().code;
        },

        computed: {
            list() {
                return this.languages.map((language) => ({
                    ...language,
                    hasBadge: !!this.requiredFields ? this.checkrequiredFields(language) : false,
                }));
            },
        },

        methods: {
            checkrequiredFields(language) {
                return Array.isArray(this.requiredFields)
                    ? this.requiredFields.filter((field) => !this.formData[field][language.code]).length
                    : !this.formData[this.requiredFields][language.code];
            },
        },
    };
</script>
