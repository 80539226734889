import {
    isMobile,
    isMobileOnly,
    isTablet,
    isBrowser,
    isSmartTV,
    isWearable,
    isConsole,
    isAndroid,
    isWinPhone,
    isIOS,
    isChrome,
    isSafari,
    isFirefox,
    isOpera,
    isIE,
    isEdge,
    isChromium,
    isMobileSafari,
    osVersion,
    osName,
    fullBrowserVersion,
    browserVersion,
    browserName,
    mobileVendor,
    mobileModel,
    engineName,
    engineVersion,
    getUA,
    deviceType,
    isIOS13,
    isIPhone13,
    isIPad13,
    isIPod13,
    isElectron,
    isEdgeChromium,
    isLegacyEdge,
    isWindows,
    isMacOs,
    deviceDetect,
} from 'mobile-device-detect'; //Read more options for this library - https://npm.io/package/mobile-device-detect

let device = {};

device.isMobile = isMobile;
device.isMobileOnly = isMobileOnly;
device.isTablet = isTablet;
device.isBrowser = isBrowser;
device.isSmartTV = isSmartTV;
device.isWearable = isWearable;
device.isConsole = isConsole;
device.isAndroid = isAndroid;
device.isWinPhone = isWinPhone;
device.isIOS = isIOS;
device.isChrome = isChrome;
device.isSafari = isSafari;
device.isFirefox = isFirefox;
device.isOpera = isOpera;
device.isIE = isIE;
device.isEdge = isEdge;
device.isChromium = isChromium;
device.isMobileSafari = isMobileSafari;
device.osVersion = osVersion;
device.osName = osName;
device.fullBrowserVersion = fullBrowserVersion;
device.browserVersion = browserVersion;
device.browserName = browserName;
device.mobileVendor = mobileVendor;
device.mobileModel = mobileModel;
device.engineName = engineName;
device.engineVersion = engineVersion;
device.getUA = getUA;
device.deviceType = deviceType;
device.isIOS13 = isIOS13;
device.isIPhone13 = isIPhone13;
device.isIPad13 = isIPad13;
device.isIPod13 = isIPod13;
device.isElectron = isElectron;
device.isEdgeChromium = isEdgeChromium;
device.isLegacyEdge = isLegacyEdge;
device.isWindows = isWindows;
device.isMacOs = isMacOs;
device.deviceDetect = deviceDetect;

window.$device = device;
