import Vue from 'vue';

import fieldsMap from './fieldsMap';

export default {
    namespaced: true,

    modules: {
        fieldsMap,
    },

    state: {
        list: [],
        item: {},
        listPagination: {},
        listFilter: {
            page: null,
            limit: 30,
        },

        loadingGetList: false,
        loadingGetItem: false,
        loadingSaveItem: false,
        loadingDeleteItem: false,
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_ITEM(state, item) {
            state.item = item;
        },

        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        UPDATE_LIST_FILTER(state, newFilterObj) {
            $fn.forIn(newFilterObj, (val, key) => {
                Vue.set(state.listFilter, key, val);
            });

            Vue.set(state.listFilter, 'page', newFilterObj.page || 1);
        },

        RESET_STORE(state) {
            state.listFilter = {
                page: null,
                limit: null,
            };
        },
    },

    actions: {
        GET_LIST: async ({ rootState, rootGetters, state, getters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let pathApi = '/api/partner-pages';

                let resp = await $axios.get(pathApi, {
                    params: getters.listActiveFilter,
                });

                commit('SET_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_ITEM: async ({ rootState, rootGetters, state, getters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                let resp = await $axios.get(`/api/partner-pages/${id}`);

                commit('SET_ITEM', resp.single);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        SAVE_ITEM: async ({ rootState, rootGetters, state, getters, dispatch, commit }, formData) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let pathApi = '/api/partner-pages';

                let resp = await $axios.post(pathApi, formData);

                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        DELETE_ITEM: async ({ rootState, rootGetters, state, getters, dispatch, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let resp = await $axios.delete(`/api/partner-pages/${id}`);

                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },
    },
};
