export default {
    'create.orders': 'Заказы',
    'create.orders.add': 'Создать заказ',
    'create.elements': 'Элементов в детали (шт)',
    'create.msg.success': 'Техкарта успешно загрузилась.',
    'create.param.edit': 'Редактировать параметры',
    'create.to.pay': 'К оплате за тираж',
    'create.product': 'Товар',
    'create.calc': 'Расчеты',
    'create.basket': 'Корзина',
    'create.basket.date': 'Срок изготовления заказа',
    'create.basket.to.pay': 'К оплате',
    'create.btn.order': 'Оформить заказ',
    'create.basket.msg.delete': 'Товар удален',
    'create.basket.msg.save': 'Товар сохранен в корзину',
    'create.basket.calc.del': 'Расчет удален',
    'create.product.title': 'Выбор товара',
    'create.product.params': 'Параметры товара',
    'create.product.dop': 'Дополнительные параметры',
    'create.result': 'Результат',
    'create.save.calc': 'Сохранить расчёт',
    'create.basket.add': 'Добавить в корзину',
    'create.list.size': 'Розмір наліпки',
    'create.list.forma': 'Форма наліпки',
    'create.list.radius': 'Радіус скруглення наліпки',
    'create.list.format': 'Формат аркуша',
    'create.list.time': 'Срок',
    'create.draft.price.one': 'Цена за единицу',
    'create.table.weight': 'Вес',
    'create.logs': 'Логи расчётов',
    'create.layaut.count': 'Количество наклеек',
    'create.form.width': 'Ширина наклейки (мм)',
    'create.form.height': 'Высота наклейки (мм)',
    'create.form.bleeds': 'Расстояние к краю листа (мм)',
    'create.form.padding': 'Расстояние между наклейками (мм)',
    'create.form.radius': 'Радиус скругления угла (мм)',
    'create.form.catalog': 'Категория',
    'create.calc.refresh': 'Данные обновляются',
    'create.msg.calc': 'Выберите параметры продукта для начала расчёта.',
    'create.params.type': 'Вид',
    'create.form.principle': 'Способ изготовления',
    'create.btn.rotation': 'Повернуть',
    'create.shape.title': 'Форма наклейки',
    'create.order.use.hot': 'Отметить как срочный',
    'create.order.in.hot': 'Содержит срочные товары',
    'create.order.hot': 'Срочный',
    'create.format.use': 'Использовать формат продукта',
    'create.form.placing': 'Корректировка суммы заказа (UAH)',
    'create.form.placing.info':
        'Вы можете скорректировать итоговую цену заказа, введя сумму в поле. Возможен ввод отрицательных значений. Для этого поставьте символ “-” в начале поля и введите сумму, которую надо отнять.',
    'create.total.price': 'Итоговая цена',
    'create.total.price.discount': '>С учётом скидки клиента',
    'create.total.price.info': 'Итоговая цена заказа не может быть ниже 1.00 UAH. Минимальная корректировочная сумма составляет',
    'create.total.name':
        'Пожалуйста, введите в поле корректировочной суммы заказа целое число или десятичную дробь (Например: "63", "-22.37" и т.д.)',
    'create.table.rate': 'Наценка, %',
    'create.table.rate.sum': 'Корректировка суммы заказа (UAH)',
    'create.client': 'Клиент',
    'create.basket.price': 'Цена за товар',
    'create.basket.maket': 'Цена за проверку макетов',
    'create.basket.maket.loaded': 'Макети були завантажені',
    'create.basket.maket.loaded.not': 'Макети не були завантажені',
    'create.delevery.title': 'Создать новый адрес доставки',
    'create.delevery.form.city': 'Город',
    'create.delevery.form.warehouses': 'Отделения',
    'create.delevery.form.address': 'Адрес доставки',
    'create.delevery.form.address.self': 'Адрес cамовывоза',
    'create.palcing.title': 'Оформление',
    'create.palcing.delivery': 'Доставка',
    'create.palcing.product.add': 'Добавить еще товар',
    'create.palcing.save.order': 'Сохранить заявку',
    'create.palcing.order': 'Оформить заказ',
    'create.delivery.title': 'Данные получателя',
    'create.delivery.form.phone': 'Телефон получателя',
    'create.delivery.empty':
        'Нет адрессов для выбранного способа доставки. Пожалуйста, выберите другой способ или добавьте клиенту новый адресс',
    'create.finish.form.manager': 'Ответственный менеджер',
    'create.finish.manager.true': 'За этим клиентом уже закреплен менеджер',
    'create.finish.manager.false':
        'За этим клиентом не закреплен менеджер. При желании вы можете выбрать менеджера. Он закрепится за клиентом автоматически. Обратите внимание, что менеджер - это пользователь, имеющий доступ к модулям создания заказа и клиентам',
    'create.finish.manager.info':
        'Обратите внимание, что менеджер - это пользователь, имеющий доступ к модулям создания заказа и клиентам',
    'create.finish.form.order': 'Название заказа',
    'create.finish.form.payment': 'Способ оплаты',
    'create.msg.order': 'Заказ создан!',
    'create.product.format': 'Формат товара',
    'create.in.basket': 'В корзину',

    //ORDER--------------------------/
    'order.btn.repeat': 'Повторить заказ',
    'order.title.single': 'Заказ',
    'order.title.multi': 'Заказы',
    'order.finance': 'Фінансові операції',
    'order.cancel': 'Відмінити замовлення',
    'order.status.info': 'Хотите перевести в статус',
    'order.btn.add': 'Назначить',
    'order.btn.change': 'Изменить',
    'order.table.manager': 'Менеджер',
    'order.table.action': 'Действия',
    'order.table.price.one': 'Цена за товар',
    'order.manager.alt': 'менеджера',
    'order.product.multi': 'Товары',
    'order.data.order': 'Данные заказа',
    'order.form.address': 'Адресс доставки',
    'order.tech.list': 'На листі',
    'order.tech.roll.param': 'Параметры рулонной печати',
    'order.tech.rotation': 'Поворот',
    'order.tech.generate': 'Сгенерировать документ на печать',
    'order.alg.not.material': 'Не указан вес материала',
    'order.detail.single': 'Деталь',
    '': '',
    '': '',

    // WT-825:
    'create_order.basket_item.client_discount': 'Знижка клієнта',
    'create_order.basket_item.fixed_price': 'Було встановлено фіксовану ціну',
    'create_order.price_edit.title': 'Редагування ціни',
    'create_order.price_edit.changing_way': 'Спосіб редагування',
    'create_order.price_edit.discount': 'Знижка',
    'create_order.price_edit.markup': 'Націнка',
    'create_order.price_edit.fixed_price': 'Фіксована ціна',
    'create_order.price_edit.client_discount': 'Наразі для товару встановлено знижку клієнта, яка складає',

    // WT-430:
    'create_order.product.optimal_count': 'Оптимальний тираж',
    'create_order.product.real_material': 'Кількість матеріалу',
};
