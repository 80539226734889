<template>
    <div class="druk-c-crcl-ind">
        <div class="druk-c-crcl-ind__wrapper" :class="{ [`druk-u-size-${size}`]: size }">
            <svg class="druk-c-crcl-ind__progress" :style="{ stroke: $material[color] }">
                <circle
                    class="druk-c-crcl-ind__path"
                    :cx="variables[size].cx"
                    :cy="variables[size].cy"
                    :r="variables[size].r"
                    fill="none"
                    :stroke-width="variables[size].strokeWidth"
                    stroke-miterlimit="10"></circle>
            </svg>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'druk-circular-indicator',

        props: {
            size: {
                type: String,
                default: 'lg', // @Explanation: Possible values - s, m, lg
            },
            color: {
                type: String,
                default: 'on-primary-container', // @Explanation: Possible values - on-primary-container, on-secondary-container, any system colors used on surfaces, does not matter
            },
        },

        computed: {
            variables() {
                return {
                    s: {
                        cx: 12,
                        cy: 12,
                        r: 10,
                        strokeWidth: 2,
                    },
                    m: {
                        cx: 20,
                        cy: 20,
                        r: 16,
                        strokeWidth: 4,
                    },
                    lg: {
                        cx: 24,
                        cy: 24,
                        r: 20,
                        strokeWidth: 4,
                    },
                };
            },
        },
    };
</script>

<style lang="scss" scoped>
    .druk-c-crcl-ind {
        display: inline-block;
        &__wrapper {
            &.druk-u-size-s {
                width: 24px;
                height: 24px;
            }
            &.druk-u-size-m {
                width: 40px;
                height: 40px;
            }
            &.druk-u-size-lg {
                width: 48px;
                height: 48px;
            }
        }
        &__progress {
            display: flex;
            position: relative;
            width: 100%;
            height: 100%;
            animation: rotate 2s linear infinite;
        }
        &__path {
            stroke-dasharray: 1, 96;
            stroke-dashoffset: 0;
            stroke-linecap: round;
            animation: dash 1.2s ease-in-out infinite;
        }

        @-webkit-keyframes rotate {
            100% {
                transform: rotate(360deg);
            }
        }

        @-moz-keyframes rotate {
            100% {
                transform: rotate(360deg);
            }
        }

        @keyframes rotate {
            100% {
                transform: rotate(360deg);
            }
        }

        @-webkit-keyframes dash {
            0% {
                stroke-dasharray: 1, 100;
                stroke-dashoffset: 0;
            }
            50% {
                stroke-dasharray: 69, 100;
                stroke-dashoffset: -25;
            }
            100% {
                stroke-dasharray: 69, 100;
                stroke-dashoffset: -84;
            }
        }

        @-moz-keyframes dash {
            0% {
                stroke-dasharray: 1, 100;
                stroke-dashoffset: 0;
            }
            50% {
                stroke-dasharray: 69, 100;
                stroke-dashoffset: -25;
            }
            100% {
                stroke-dasharray: 69, 100;
                stroke-dashoffset: -84;
            }
        }

        @keyframes dash {
            0% {
                stroke-dasharray: 1, 100;
                stroke-dashoffset: 0;
            }
            50% {
                stroke-dasharray: 33, 100;
                stroke-dashoffset: -18;
            }
            100% {
                stroke-dasharray: 33, 100;
                stroke-dashoffset: -62;
            }
        }
    }
</style>
