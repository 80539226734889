<template>
    <div
        :id="index"
        class="formula__item"
        :class="{
            'has-cursor': hasCursorToTheRight || hasCursorToTheLeft,
            'has-cursor-to-the-right': hasCursorToTheRight,
            'has-cursor-to-the-left': hasCursorToTheLeft,
            'has-empty': isEmpty,
            'has-shown-empty': isEmpty && !hasHiddenEmptyCursor,
        }"
        @mouseenter="onHideEmptyCursor"
        @mousemove="onStartInteraction"
        @mouseleave="onStopInteraction">
        <div
            v-if="!isEmpty"
            class="formula__cursor formula__cursor--left"
            :class="{ 'has-active': isActive }"
            @click="onSetCursor"></div>

        <div
            v-if="!isEmpty"
            :id="isMaskItem ? 'layout' : ''"
            :ref="!isMaskItem ? 'layout' : ''"
            class="formula__layout"
            @contextmenu="onToggleContext">
            <calc-formula-number
                v-if="item.value.is_number"
                :item="item"
                @setCursor="onSetCursor"
                @resetCursor="onResetActiveCursor" />

            <calc-formula-variable
                v-else
                :item="item"
                :bracketColor="bracketColor"
                :isBracket="isBracket"
                :isOpenBracket="isBracket && !isFullBracketPair" />
        </div>

        <calc-formula-context v-if="!isEmpty && hasShownContext" :item="item" :index="item.index" :surface="surface" />

        <div
            class="formula__cursor formula__cursor--right"
            :class="{
                'has-active': isActive,
                'has-accessible': isEmpty && !hasHiddenEmptyCursor,
            }"
            @click="onSetCursor"></div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

    import CalcFormulaVariable from './CalcFormulaVariable';
    import CalcFormulaNumber from './CalcFormulaNumber';
    import CalcFormulaContext from './CalcFormulaContext';

    export default {
        name: 'calc-formula-item',

        inject: ['$validator'],

        components: {
            CalcFormulaVariable,
            CalcFormulaNumber,
            CalcFormulaContext,
        },

        props: {
            item: Object,
            index: [Number, String],
            areaLayout: Object,

            surface: {
                type: String,
                default: 'tint-brighter',
            },

            hasHiddenEmptyCursor: Boolean,

            isMaskItem: Boolean,
        },

        data() {
            return {
                hasCursorToTheRight: false,
                hasCursorToTheLeft: false,
                hasShownContext: false,
            };
        },

        mounted() {
            this.$bus.$on(`on-reset-${this.index}`, () => this.resetCursor());
        },

        destroyed() {
            this.$bus.$off(`on-reset-${this.index}`);
        },

        watch: {
            isActive() {
                this.onCheckContextAccessible();
            },
        },

        computed: {
            ...mapState({
                BRACKETS_COLOR_MAP: (state) => state.calcModes.formula.BRACKETS_COLOR_MAP,

                cursor: (state) => state.calcModes.formula.cursor,
            }),
            ...mapGetters({
                bracketsPairs: 'calcModes/formula/bracketsPairs',

                hasActiveCursor: 'calcModes/formula/hasActiveCursor',
            }),

            isActive() {
                return this.cursor.index === this.item.index;
            },

            isEmpty() {
                return !this.item.value;
            },

            bracketPairIndex() {
                return this.isBracket ? this.bracketsPairs.findIndex((pair) => pair.includes(this.item.index)) : null;
            },

            bracketColor() {
                if (!this.isFullBracketPair) return 'error';

                let index = this.bracketPairIndex;
                while (!this.BRACKETS_COLOR_MAP[index]) index -= this.BRACKETS_COLOR_MAP.length;

                return this.BRACKETS_COLOR_MAP[index];
            },

            isBracket() {
                return this.item.value.is_opening_bracket || this.item.value.is_closing_bracket;
            },

            isFullBracketPair() {
                return this.isBracket
                    ? this.bracketsPairs[this.bracketPairIndex].filter((bracket) => bracket !== null).length === 2
                    : false;
            },
        },

        methods: {
            ...mapMutations({
                SET_CURSOR: 'calcModes/formula/SET_CURSOR',
                RESET_CURSOR: 'calcModes/formula/RESET_CURSOR',
            }),

            // @Interactions:
            onHideEmptyCursor() {
                if (!this.isEmpty && !this.hasHiddenEmptyCursor) this.$bus.$emit('on-hide-empty-cursor');
            },

            onStartInteraction(e) {
                if (this.hasActiveCursor || this.isEmpty) return;

                let targetWidth = this.$refs.layout.offsetWidth,
                    mouseX = e.clientX - this.$refs.layout.getBoundingClientRect().left;

                mouseX >= targetWidth / 2 ? this.onSetRightCursorStyles() : this.onSetLeftCursorStyles();
            },

            onSetRightCursorStyles() {
                if (this.hasCursorToTheLeft) this.hasCursorToTheLeft = false;
                if (!this.hasCursorToTheRight) this.hasCursorToTheRight = true;
            },

            onSetLeftCursorStyles() {
                if (this.hasCursorToTheRight) this.hasCursorToTheRight = false;
                if (!this.hasCursorToTheLeft) this.hasCursorToTheLeft = true;
            },

            onStopInteraction() {
                if (!this.hasActiveCursor) {
                    this.onResetActiveCursor();
                    if (!this.isEmpty) this.$bus.$emit('on-show-empty-cursor');
                }
            },

            onResetActiveCursor() {
                this.hasCursorToTheRight = false;
                this.hasCursorToTheLeft = false;
            },

            // @Main logic
            onSetCursor() {
                if (this.isEmpty) return;

                !this.isActive
                    ? this.SET_CURSOR({
                          index: this.item.index,
                          is_right: this.hasCursorToTheRight,
                      })
                    : this.RESET_CURSOR();

                if (this.isActive) this.$bus.$emit('on-hide-empty-cursor');
            },

            resetCursor() {
                this.hasShownContext = false;

                this.RESET_CURSOR();
                this.onStopInteraction();
            },

            onCheckContextAccessible() {
                if (!this.isActive && (this.hasShownContext || this.hasActiveCursor)) {
                    this.hasShownContext = false;
                    this.onResetActiveCursor();
                }
            },

            onToggleContext() {
                this.hasShownContext = !this.hasShownContext;

                if (this.hasShownContext) {
                    this.hasCursorToTheRight = true;
                    this.hasCursorToTheLeft = false;

                    this.onSetCursor();
                } else this.resetCursor();
            },
        },
    };
</script>

<style lang="scss" scoped>
    .formula {
        &__item {
            position: relative;
            display: inline-flex;
            margin-bottom: 4px;
            height: 24px;
            min-width: 24px;
            z-index: 2;
            transition: all 0.3s ease-in-out;
            &.has-cursor {
                z-index: 3;
            }
            &.has-cursor-to-the-right {
                padding-right: 28px;
                .formula__cursor--right {
                    opacity: 1;
                }
            }
            &.has-cursor-to-the-left {
                padding-left: 28px;
                .formula__cursor--left {
                    opacity: 1;
                }
            }
            &.has-empty {
                pointer-events: none;
                min-width: initial;
            }
            &.has-shown-empty {
                margin-right: 4px;
                min-width: 24px;
                .formula__cursor--right {
                    right: 0px;
                    width: 24px;
                    opacity: 1;
                    -webkit-animation: blink var(--druk-duration-extra-long-4) linear infinite;
                    animation: blink var(--druk-duration-extra-long-4) linear infinite;
                }
                @keyframes blink {
                    0% {
                        background-color: var(--druk-state-layers-primary-0-08);
                    }
                    50% {
                        background-color: var(--druk-state-layers-primary-0-16);
                    }
                    100% {
                        background-color: var(--druk-state-layers-primary-0-08);
                    }
                }
                @-webkit-keyframes blink {
                    0% {
                        background-color: var(--druk-state-layers-primary-0-08);
                    }
                    50% {
                        background-color: var(--druk-state-layers-primary-0-16);
                    }
                    100% {
                        background-color: var(--druk-state-layers-primary-0-08);
                    }
                }
            }
        }
        &__layout {
            position: relative;
            display: inline-flex;
            margin: 0px 4px 0px 0px;
            z-index: 2;
        }
        &__variable {
            display: inline-block;
            margin: 0px;
            height: 100%;
            min-height: 24px;
            transition:
                outline var(--druk-duration-medium-2) var(--druk-easing-emphasized-decelerate),
                border var(--druk-duration-medium-2) var(--druk-easing-emphasized-decelerate),
                color var(--druk-duration-medium-2) var(--druk-easing-emphasized-decelerate),
                background-color var(--druk-duration-medium-2) var(--druk-easing-emphasized-decelerate);
            &:hover {
                transform: initial;
            }
            &.is-input {
                outline: 1px solid var(--druk-formulas-default-outline-variant);
                border: solid 2px transparent;
                color: var(--druk-formulas-on-default-container);
                background-color: var(--druk-formulas-default-container);
                &:hover {
                    outline: 1px solid var(--druk-formulas-default-outline);
                }
            }
            &.has-caret {
                outline: 1px solid var(--druk-primary);
                border: solid 2px var(--druk-primary);
                color: var(--druk-formulas-on-default-container);
            }
            &.has-invalid {
                -webkit-animation: invalid var(--druk-duration-extra-long-4) linear;
                animation: invalid var(--druk-duration-extra-long-4) linear;
                @keyframes invalid {
                    0% {
                        outline: 1px solid var(--druk-formulas-default-outline-variant);
                        background-color: var(--druk-formulas-default-container);
                    }
                    50% {
                        outline: 1px solid var(--druk-error);
                        background-color: var(--druk-error-container);
                    }
                    100% {
                        outline: 1px solid var(--druk-formulas-default-outline-variant);
                        background-color: var(--druk-formulas-default-container);
                    }
                }
                @-webkit-keyframes invalid {
                    0% {
                        border: 1px solid var(--druk-on-error-container);
                        background-color: var(--druk-error-container);
                    }
                    50% {
                        border: 1px solid var(--druk-error);
                        background-color: var(--druk-error-container);
                    }
                    100% {
                        border: 1px solid var(--druk-on-error-container);
                        background-color: var(--druk-error-container);
                    }
                }
            }
            &.has-error {
                outline: 1px solid var(--druk-error);
                color: var(--druk-error);
                background-color: var(--druk-error-container);
                &:hover {
                    outline: 1px solid var(--druk-on-error-container);
                    color: var(--druk-on-error-container);
                }
                &::v-deep .formula__char.has-caret {
                    -webkit-animation: error-caret var(--druk-duration-extra-long-1) linear infinite;
                    animation: error-caret var(--druk-duration-extra-long-1) linear infinite;
                }
            }
            &.has-error.has-caret {
                border: solid 2px var(--druk-error);
            }
            &::v-deep {
                .formula__number {
                    display: flex;
                    align-items: center;
                    height: 100%;
                }
                .formula__input {
                    position: absolute;
                    width: 0px;
                    height: 0px;
                    opacity: 0;
                    border: initial;
                }
                .formula__char {
                    content: '';
                    display: inline-block;
                    pointer-events: none;
                    vertical-align: middle;
                    line-height: 14px;
                    height: 14px;
                    &.has-caret {
                        -webkit-animation: caret var(--druk-duration-extra-long-1) linear infinite;
                        animation: caret var(--druk-duration-extra-long-1) linear infinite;
                    }
                    @keyframes caret {
                        0% {
                            border-right: 1px solid transparent;
                        }
                        50% {
                            border-right: 1px solid var(--druk-primary);
                        }
                        100% {
                            border-right: 1px solid transparent;
                        }
                    }
                    @-webkit-keyframes caret {
                        0% {
                            border-right: 1px solid transparent;
                        }
                        50% {
                            border-right: 1px solid var(--druk-primary);
                        }
                        100% {
                            border-right: 1px solid transparent;
                        }
                    }
                    @keyframes error-caret {
                        0% {
                            border-right: 1px solid transparent;
                        }
                        50% {
                            border-right: 1px solid var(--druk-error);
                        }
                        100% {
                            border-right: 1px solid transparent;
                        }
                    }
                    @-webkit-keyframes error-caret {
                        0% {
                            border-right: 1px solid transparent;
                        }
                        50% {
                            border-right: 1px solid var(--druk-error);
                        }
                        100% {
                            border-right: 1px solid transparent;
                        }
                    }
                }
            }
        }
        &__cursor {
            cursor: pointer;
            position: absolute;
            top: 0px;
            width: 24px;
            height: 24px;
            border-radius: 4px;
            background-color: var(--druk-state-layers-primary-0-12);
            opacity: 0;
            transition:
                background-color var(--druk-duration-short-4) var(--druk-easing-emphasized-decelerate),
                opacity var(--druk-duration-short-4) var(--druk-easing-emphasized-decelerate);
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 4px;
                background-color: transparent;
                transition: background-color var(--druk-duration-short-4) var(--druk-easing-emphasized-decelerate);
            }
            &:hover::before {
                background-color: var(--druk-state-layers-primary-0-08);
            }
            &:active::before {
                background-color: var(--druk-state-layers-primary-0-12);
            }
            &--left {
                left: 0px;
            }
            &--right {
                right: 4px;
            }
            &.has-active {
                background-color: var(--druk-state-layers-primary-0-16);
            }
        }
    }
</style>
