import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

export default {
    computed: {
        ...mapState({
            PERM_DEFAULT_PAYLOAD: (state) => state.permissions.PERM_DEFAULT_PAYLOAD,
        }),
    },

    methods: {
        buildPermissions() {
            let permissions = { ...this.PERM_DEFAULT_PAYLOAD };

            this.permissions.forEach((group) => {
                group.modules.forEach((module) => {
                    let actions = Object.entries(this.permissionsData[group.key][module.key]).reduce((acc, curr) => {
                        if (!!curr[1]) {
                            let payload = module.actions.find((moduleAction) => moduleAction.key === curr[0])?.permissionsPayload;
                            if (!!payload) Object.assign(permissions, this.insertPermissionsPayload(permissions, payload));

                            return acc.concat(curr[0]);
                        } else return acc;
                    }, []);

                    actions.forEach((action) => {
                        if (!permissions[module.key]?.includes(action))
                            permissions[module.key] = (permissions[module.key] || []).concat(actions);
                    });
                });
            });

            return permissions;
        },

        insertPermissionsPayload(permissions, payload) {
            let modules = Object.create(permissions);

            Object.entries(payload).forEach(([key, actions]) => {
                actions.forEach((value) => {
                    if (!modules[key]?.includes(value)) modules[key] = (modules[key] || []).concat(value);
                });
            });

            return modules;
        },
    },
};
