<!-- @TODO: It will be need in the future -->
<template>
    <div ref="dialog" class="druk-c-dialog" :class="{ 'druk-is-shown': isShown }" @click="onToggle">
        <div class="druk-c-dialog__wrapper">
            <div class="druk-c-dialog__card">
                <div class="druk-c-dialog__header text-headline-s"></div>

                <div class="druk-c-dialog__body">
                    <div v-if="support" class="druk-c-dialog__sup-text text-body-m">{{ support }}</div>

                    <slot :name="'druk-dialog-content'"></slot>
                </div>

                <div class="druk-c-dialog__footer">
                    <template v-if="!hasAltFooter">
                        <div class="druk-c-dialog__action">
                            <druk-button :type="'outlined'" :icon="'xmark'" :label="$t('alert.no')" />
                        </div>

                        <div class="druk-c-dialog__action">
                            <druk-button :icon="'check'" :label="$t('alert.yes')" />
                        </div>
                    </template>

                    <slot :name="'druk-dialog-actions'"></slot>
                </div>
            </div>

            <div class="druk-c-dialog__scrim"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'druk-dialog',

        props: {
            icon: String,

            headline: {
                type: String,
                default: () => {
                    return this.$t('alert.question');
                },
            },
            support: {
                type: String,
            },

            hasAltFooter: Boolean,
        },

        data() {
            return {
                isShown: false,
            };
        },

        methods: {
            onToggle(e) {
                this.isShown = !this.isShown;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .druk-c-dialog {
        color: var(--druk-on-surface-variant);
        &.druk-is-shown {
            opacity: 1;
            pointer-events: all;
            width: 100vw;
            height: 100vh;
        }
        &__sup-text {
            color: var(--druk-on-surface);
        }
    }
</style>
