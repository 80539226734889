<template>
    <div
        class="statusBox"
        :class="{
            'has-active': hasOpen,
            'has-blocked': hasBlockedStatus || hasEmptyList,
            'has-button': isButtonType,
        }">
        <multiselect
            v-if="!hasBlockedStatus && !hasEmptyList"
            v-model="status"
            :style="{ [`--druk-content-background`]: contentBackground }"
            class="multiselect--form-status-picker statusBox__current"
            :class="{ 'multiselect--active': hasOpen, 'has-button': isButtonType, 'is-from-right': isFromRight }"
            :options="parsedList"
            :label="option_label || 'title'"
            :tagPlaceholder="''"
            :selectLabel="''"
            :deselectLabel="''"
            :placeholder="''"
            :track-by="option_label || 'title'"
            :hideSelected="notEmpty || false"
            :multiple="multiple || false"
            :searchable="false"
            @select="onChangeStatus"
            @open="hasOpen = true"
            @close="hasOpen = false">
            <template v-slot:caret>
                <div class="multiselect__caret">
                    <font-awesome-icon :icon="isButtonType ? 'fa-regular fa-angle-down' : 'fa-regular fa-caret-down'" />
                </div>
            </template>

            <template slot="option" slot-scope="props">
                <span :class="{ 'has-divided': props.option.is_divided }">{{ props.option[option_label || 'title'] }}</span>
            </template>
        </multiselect>

        <span v-else class="label" :class="'label--' + status.alias">{{ status.title }}</span>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect';
    import ModalAlert from '../components/druk/ModalAlert';

    export default {
        name: 'form-status-picker',

        components: {
            Multiselect,
        },

        props: {
            value: Object,
            list: Array,
            name: String,
            currentId: Number,
            item: Object,
            option_label: String,
            multiple: Boolean,
            notEmpty: Boolean,
            importantVariables: Array,
            alertText: String,
            alertOption: String,
            hasBlockedStatus: Boolean,
            isButtonType: Boolean,
            isFromRight: Boolean,

            surfaceVariant: {
                type: String,
                default: 'tint-bright',
            },
        },

        data() {
            return {
                currentStatus: this.value,

                hasOpen: false,
            };
        },

        computed: {
            status: {
                get() {
                    return this.value;
                },
                set(val) {
                    this.$emit('input', val);
                },
            },

            hasEmptyList() {
                return !this.list.length;
            },

            parsedList() {
                return $fn
                    .filter(this.list, (item) => {
                        return !item.is_divided && item.id !== this.currentId;
                    })
                    .concat(
                        $fn.filter(this.list, (item) => {
                            return item.is_divided;
                        }),
                    );
            },

            contentBackground() {
                let base = this.surfaceVariant.split('-');
                return `var(--druk-${[...base].shift()}-surface-container-${[...base].pop()})`;
            },
        },

        methods: {
            onChangeStatus() {
                setTimeout(() => {
                    this.changeStatus();
                }, 100);
            },

            changeStatus() {
                if (this.importantVariables?.includes(this.status.id)) {
                    this.$modal.show(
                        ModalAlert,

                        {
                            text: this.alertText + ' "' + this.status[this.alertOption] + '"?',
                            fnWait: () => this.onChange(),
                            fnCancel: () => this.onCancel(),
                        },

                        $MODAL_OPTIONS.alert,
                    );
                } else {
                    this.onChange();
                }
            },

            onCancel() {
                this.$emit('onCancel', this.currentStatus);
            },

            onChange() {
                this.currentStatus = this.status;
                this.$emit('onChange', this.status);
            },
        },
    };
</script>

<style lang="scss">
    .multiselect {
        &--form-status-picker {
            position: relative;
            min-height: initial;
            border-radius: 8px !important;
            &.is-from-right {
                .multiselect__content-wrapper {
                    right: 0;
                }
            }
            svg {
                position: absolute;
                right: 8px;
                top: 50%;
                transform: translateY(-50%) !important;
                transform-origin: center;
                transition: transform var(--druk-duration-short-2) var(--druk-easing-emphasized-accelerate);
            }
            .multiselect__single {
                padding: 5px 24px 5px 12px;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.4px;
                font-weight: 600;
                border-radius: 8px;
            }
            .multiselect__option {
                padding: 10px 12px;
                min-height: 32px;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.5px;
                font-weight: 500;
                span {
                    display: block;
                    width: 100%;
                    &.has-divided {
                        position: relative;
                        &::before {
                            content: '';
                            position: absolute;
                            top: -11px;
                            left: -12px;
                            width: calc(100% + 24px);
                            height: 1px;
                            background-color: var(--druk-outline);
                        }
                    }
                }
            }
            .multiselect__content-wrapper {
                bottom: auto;
                margin: 1px 0 0 -1px;
                min-width: calc(100% + 2px);
                width: auto;
            }
            .multiselect__tags {
                min-height: initial;
            }
        }
        &--active {
            .multiselect__tags {
                border-top-left-radius: 8px !important;
                border-top-right-radius: 8px !important;
            }
        }
        &.has-button {
            display: inline-block;
            position: relative;
            padding: 0px 12px;
            min-height: initial;
            width: auto;
            height: 34px;
            font-size: 14px;
            font-weight: 500;
            border-radius: 4px !important;
            &.multiselect--active {
                svg {
                    transform: rotate(180deg) !important;
                }
            }
            .multiselect__tags {
                display: inline-block;
                padding: 0;
                width: auto;
                height: 100%;
                max-height: initial;
            }
            .multiselect__single {
                padding: 7px 8px 7px 0px;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.1px;
                font-weight: 500;
            }
            .multiselect__content-wrapper {
                margin: 1px 0 0 -1px;
                left: 0px;
                z-index: 5;
            }
            .multiselect__caret {
                pointer-events: none;
                display: flex;
                align-items: center;
                justify-content: center;
                float: right;
                height: 34px;
            }
            .multiselect__content {
                padding: 0;
            }
            .multiselect__option {
                padding: 12px 12px;
                min-height: 40px;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.1px;
                font-weight: 500;
                span {
                    border-radius: 4px;
                    &.has-divided {
                        position: relative;
                        &::before {
                            content: '';
                            position: absolute;
                            top: -13px;
                            left: -12px;
                            width: calc(100% + 24px);
                            height: 1px;
                            background-color: var(--druk-outline);
                        }
                    }
                }
            }
            svg {
                display: inline;
                position: initial;
                transform: rotate(0deg) !important;
            }
        }
    }

    .statusBox {
        cursor: pointer;
        position: relative;
        display: inline-block;
        font-size: 12px;
        border-radius: 8px;
        .statusBox__current {
            border: 1px solid var(--druk-chips-azul);
            color: var(--druk-chips-on-azul-container);
            background-color: var(--druk-chips-azul-container);
            transition: box-shadow 0.2s ease-in-out;
            &::before {
                content: '';
                pointer-events: none;
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                border-radius: 8px;
                background-color: transparent;
                transition: background-color 0.2s ease-in-out;
            }
            &.has-button {
                &::before {
                    border-radius: 4px;
                }
            }
            .multiselect__single {
                color: var(--druk-secondary);
            }
        }
        &:hover {
            .statusBox__current {
                box-shadow: var(--druk-elevation-1);
            }
            .statusBox__current::before {
                background-color: var(--druk-state-layers-on-azul-container-0-08);
            }
        }
        &:active,
        &:focus {
            .statusBox__current::before {
                background-color: var(--druk-state-layers-on-water-blue-container-0-12);
            }
        }
        &--waiting {
            &:hover {
                .statusBox__current::before {
                    background-color: var(--druk-state-layers-on-water-blue-container-0-08);
                }
            }
            &:active,
            &:focus {
                .statusBox__current::before {
                    background-color: var(--druk-state-layers-on-water-blue-container-0-12);
                }
            }
            .statusBox__current {
                color: var(--druk-chips-on-water-blue-container);
                background-color: var(--druk-chips-water-blue-container);
                border: 1px solid var(--druk-chips-water-blue);
                svg {
                    color: var(--druk-chips-water-blue);
                }
                .multiselect__single {
                    color: var(--druk-chips-water-blue);
                }
            }
        }
        &--new {
            &:hover {
                .statusBox__current::before {
                    background-color: var(--druk-state-layers-on-topaz-container-0-08);
                }
            }
            &:active,
            &:focus {
                .statusBox__current::before {
                    background-color: var(--druk-state-layers-on-topaz-container-0-12);
                }
            }
            .statusBox__current {
                color: var(--druk-chips-on-topaz-container);
                background-color: var(--druk-chips-topaz-container);
                border: 1px solid var(--druk-chips-topaz);
                .multiselect__single {
                    color: var(--druk-chips-topaz);
                }
            }
        }
        &--in_progress,
        &--in_progress_prepress,
        &--in_progress_print,
        &--in_progress_postpress,
        &--in_progress_logistic {
            &:hover {
                .statusBox__current::before {
                    background-color: var(--druk-state-layers-on-sunglow-container-0-08);
                }
            }
            &:active,
            &:focus {
                .statusBox__current::before {
                    background-color: var(--druk-state-layers-on-sunglow-container-0-12);
                }
            }
            .statusBox__current {
                color: var(--druk-chips-on-sunglow-container);
                background-color: var(--druk-chips-sunglow-container);
                border: 1px solid var(--druk-chips-sunglow);
                .multiselect__single {
                    color: var(--druk-chips-sunglow);
                }
            }
        }
        &--ready_to_delivery,
        &--stopped {
            &:hover {
                .statusBox__current::before {
                    background-color: var(--druk-state-layers-on-gamboge-container-0-08);
                }
            }
            &:active,
            &:focus {
                .statusBox__current::before {
                    background-color: var(--druk-state-layers-on-gamboge-container-0-12);
                }
            }
            .statusBox__current {
                color: var(--druk-chips-on-gamboge-container);
                background-color: var(--druk-chips-gamboge-container);
                border: 1px solid var(--druk-chips-gamboge);
                .multiselect__single {
                    color: var(--druk-chips-gamboge);
                }
            }
        }
        &--partly_delivered {
            &:hover {
                .statusBox__current::before {
                    background-color: var(--druk-state-layers-on-shamrock-green-container-0-08);
                }
            }
            &:active,
            &:focus {
                .statusBox__current::before {
                    background-color: var(--druk-state-layers-on-shamrock-green-container-0-12);
                }
            }
            .statusBox__current {
                color: var(--druk-chips-on-shamrock-green-container);
                background-color: var(--druk-chips-shamrock-green-container);
                border: 1px solid var(--druk-chips-shamrock-green);
                .multiselect__single {
                    color: var(--druk-chips-shamrock-green);
                }
            }
        }
        &--completed,
        &--done {
            &:hover {
                .statusBox__current::before {
                    background-color: var(--druk-state-layers-on-dark-aqua-container-0-08);
                }
            }
            &:active,
            &:focus {
                .statusBox__current::before {
                    background-color: var(--druk-state-layers-on-dark-aqua-container-0-12);
                }
            }
            .statusBox__current {
                color: var(--druk-chips-on-dark-aqua-container);
                background-color: var(--druk-chips-dark-aqua-container);
                border: 1px solid var(--druk-chips-dark-aqua);
                .multiselect__single {
                    color: var(--druk-chips-dark-aqua);
                }
            }
        }
        &--canceled,
        &--calculation {
            &:hover {
                .statusBox__current::before {
                    background-color: var(--druk-state-layers-on-surface-0-08);
                }
            }
            &:active,
            &:focus {
                .statusBox__current::before {
                    background-color: var(--druk-state-layers-on-surface-0-12);
                }
            }
            .statusBox__current {
                color: var(--druk-chips-on-neutral-container);
                background-color: var(--druk-chips-neutral-container);
                border: 1px solid var(--druk-chips-neutral);
                .multiselect__single {
                    color: var(--druk-chips-neutral);
                }
            }
        }
        &--accepted,
        &--accepted_by_manager,
        &--new_prepress,
        &--new_print,
        &--new_postpress,
        &--new_logistic {
            &:hover {
                .statusBox__current::before {
                    background-color: var(--druk-state-layers-on-iris-container-0-08);
                }
            }
            &:active,
            &:focus {
                .statusBox__current::before {
                    background-color: var(--druk-state-layers-on-iris-container-0-12);
                }
            }
            .statusBox__current {
                color: var(--druk-chips-on-iris-container);
                background-color: var(--druk-chips-iris-container);
                border: 1px solid var(--druk-chips-iris);
                .multiselect__single {
                    color: var(--druk-chips-iris);
                }
            }
        }
        &.has-active {
            svg {
                transform: translateY(-50%) rotate(180deg) !important;
                transition: transform var(--druk-duration-short-2) var(--druk-easing-emphasized-decelerate);
            }
        }
        &.has-blocked {
            cursor: initial;
            padding-right: 0px;
            max-width: initial;
        }
        &.has-button {
            padding: 0px;
        }
        &__current {
            position: relative;
            padding: 0;
            font-weight: 600;
            border-radius: 8px;
            .multiselect__tags,
            .multiselect__single {
                border-radius: 8px;
            }
        }
    }
</style>
