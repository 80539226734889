import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        TYPE_KEY_ASSIGNEE: 'assignee',
        TYPE_KEY_STATUS: 'status',
        TYPE_KEY_COMMENT: 'comment',
        TYPE_KEY_CONSUMPTION: 'consumption',
        TYPE_KEY_CUSTOM: 'custom',

        RELATION_KEY_OPERATION: 'operation',
        RELATION_KEY_PRODUCT: 'orderProduct',
        RELATION_KEY_ORDER: 'order',

        COMMENT_LOG_TYPE_ADD: 'add',
        COMMENT_LOG_TYPE_EDIT: 'edit',
        COMMENT_LOG_TYPE_DELETE: 'delete',

        list: [],
        listPagination: {},
        listFilter: {
            page: null,
            limit: null,
            sort: 'new',
            direction: 'asc',
        },

        loadingGetList: false,
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },

        isFilterNotEmpty(state, getters, rootState) {
            return !!Object.keys(getters.listActiveFilter).filter(
                (key) => !rootState.constant.INACTIVE_FILTERING_KEYS.concat(state.INACTIVE_FILTERING_KEYS).includes(key),
            ).length;
        },
    },

    actions: {
        GET_LIST: async ({ state, rootState, rootGetters, getters, commit }, { id, filters = {} }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.get(
                    `/api/typographies/${rootGetters.currentTypography.id}/orders/productions/operations/${id}/logs`,
                    {
                        params: Object.keys(filters).length ? filters : getters.listActiveFilter,
                    },
                );

                commit('SET_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            Vue.set(state, value_key, status);
        },

        RESET_STORE(state) {
            state.listFilter = {
                page: null,
                limit: null,
                sort: 'new',
                direction: 'desc',
            };
        },
    },
};
