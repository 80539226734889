<template>
    <div class="druk-c-divider" :class="{ [`druk-c-divider--${type}`]: type, [dividerClass]: dividerClass }">
        <div class="druk-c-divider__content"></div>
    </div>
</template>

<script>
    export default {
        name: 'druk-divider',

        props: {
            type: {
                type: String,
                default: 'full', // @Explanation: Additional values: start, middle, end
            },

            indents: {
                type: [String, Number, Object],
                default: 0, // @Explanation: You should use values from 'druk-u-margin' css selector
            },
        },

        computed: {
            dividerClass() {
                return typeof this.indents === 'object' && this.indents !== null
                    ? `druk-u-margin-top-${this.indents.top} druk-u-margin-bottom-${this.indents.bottom}`
                    : `druk-u-margin-vertical-${this.indents}`;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .druk-c-divider {
        width: 100%;
        &--start {
            padding-right: 16px;
        }
        &--middle {
            padding: 0 16px;
        }
        &--end {
            padding-left: 16px;
        }
        &__content {
            content: '';
            height: 1px;
            width: 100%;
            background-color: var(--druk-outline-variant);
        }
    }
</style>
