import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        list: [],
        listPagination: {},

        item: {},

        listFilter: {
            'created_at[from]': null,
            'created_at[to]': null,
            page: null,
            limit: 30,
            sort: 'new',
            direction: 'desc',
        },

        loadingGetList: false,
        loadingExportList: false,
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },

        isFilterNotEmpty(state, getters, rootState) {
            return !!Object.keys(getters.listActiveFilter).filter(
                (key) => !rootState.constant.INACTIVE_FILTERING_KEYS.concat(state.INACTIVE_FILTERING_KEYS).includes(key),
            ).length;
        },
    },

    actions: {
        GET_LIST: async ({ state, rootGetters, getters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { key: 'loadingGetList', value: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/ready-products/${id}/movement`, {
                    params: getters.listActiveFilter,
                });

                commit('SET_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { key: 'loadingGetList', value: false });
            }
        },

        EXPORT_LIST: async ({ state, commit, getters, rootGetters }, id) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingExportList',
                    status: true,
                });

                let path = `/api/typographies/${rootGetters[`currentTypography`].id}/ready-products/${id}/movement/export`;

                let resp = await $axios.get(path, {
                    params: getters.listActiveFilter,
                });

                const url = resp.link;
                const link = document.createElement('a');
                link.href = url;
                link.click();

                URL.revokeObjectURL(link.href);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingExportList',
                    status: false,
                });
            }
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },

        SET_ITEM(state, item) {
            state.item = item;
        },

        SET_LOADING_STATUS(state, { key, value }) {
            Vue.set(state, key, value);
        },

        UPDATE_LIST_FILTER(state, newFilterlist) {
            Object.entries(newFilterlist).forEach(([key, value]) => Vue.set(state.listFilter, key, value));

            Vue.set(state.listFilter, 'page', newFilterlist.page || 1);
        },

        RESET_LIST(state) {
            state.list = [];
        },

        RESET_FORM_DATA(state) {
            state.formData = {};
        },

        RESET_STORE(state) {
            state.listFilter = {
                'created_at[from]': null,
                'created_at[to]': null,
                page: null,
                limit: 30,
                sort: 'new',
                direction: 'desc',
            };
        },
    },
};
