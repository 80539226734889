import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        tree: [],

        list: [],
        listPagination: {},
        listFilter: {
            page: null,
            limit: 30,
            title: null,
        },

        item: {},

        loadingGetTree: false,
        loadingGetList: false,
        loadingGetItem: false,
        loadingSaveItem: false,
        loadingDeleteItem: false,
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },
    },

    actions: {
        GET_TREE: async ({ state, rootGetters, getters, commit }, ids) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetTree', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/equipments/params/tree`, {
                    params: {
                        ids: ids,
                    },
                });

                commit('equipments/params/SET_PARAM_OPTIONS_LIST', resp.list, { root: true });
                commit('SET_TREE');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetTree', status: false });
            }
        },

        GET_LIST: async ({ state, getters, rootGetters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/equipments/params/${id}/options`, {
                    params: { ...getters.listActiveFilter },
                });

                commit('SET_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_ITEM: async ({ state, rootGetters, getters, commit }, { paramId, id }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                let resp = await $axios.get(
                    `/api/typographies/${rootGetters.currentTypography.id}/equipments/params/${paramId}/options/${id}`,
                );

                commit('SET_ITEM', resp.single);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        SAVE_ITEM: async ({ state, rootGetters, rootState, commit, dispatch }, { formData, paramsIds, id }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let resp = await $axios.post(
                    `/api/typographies/${rootGetters.currentTypography.id}/equipments/params/${id}/options`,
                    formData,
                );

                if (paramsIds) dispatch('GET_TREE', paramsIds);
                dispatch('GET_LIST', id);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        DELETE_ITEM: async ({ commit, rootGetters, dispatch }, { paramId, id }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let resp = await $axios.delete(
                    `/api/typographies/${rootGetters.currentTypography.id}/equipments/params/${paramId}/options/${id}`,
                );

                dispatch('GET_LIST', paramId);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },
    },

    mutations: {
        SET_TREE(state, tree) {
            state.tree = tree;
        },

        SET_LIST(state, list) {
            state.list = list;
        },

        SET_ITEM(state, item) {
            state.item = item;
        },

        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },

        SET_FILTER_LIST(state, { key, val }) {
            state.listFilter[key] = val;
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        UPDATE_LIST_FILTER(state, newFilterlist) {
            Object.entries(newFilterlist).forEach(([key, value]) => Vue.set(state.listFilter, key, value));
            Vue.set(state.listFilter, 'page', newFilterlist.page || 1);
        },

        RESET_STORE(state) {
            state.list = [];
        },
    },
};
