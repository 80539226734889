<template>
    <div class="vmodal">
        <div class="vmodal__container">
            <div class="vmodal__head">
                <div class="vmodal__title">
                    <div class="vmodal__title-text">{{ $t('login.locked_modal.title') }}</div>
                </div>

                <druk-icon-button :type="'standard'" :icon="'xmark'" @click="$emit('close')" />
            </div>

            <div class="vmodal__main">
                <div class="form grid">
                    <div class="grid__col grid__col--4">
                        <img src="https://druk.s3.amazonaws.com/static/maintenance.gif" alt="" @error="$fn.fixImageError" />
                    </div>

                    <div class="grid__col grid__col--8 _flex _align_center">
                        <div class="card__title">
                            {{ $t('login.locked_modal.message') }}
                        </div>
                    </div>

                    <div class="grid__col grid__col--12 form__footer">
                        <druk-button :icon="'check'" :label="$t('btn.agree')" @click="$emit('close')" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'modal-locked-user',
    };
</script>
