<template>
    <div class="card">
        <div
            class="card__wrapper"
            :class="{
                _inner: innerCard,
                _sub: isSubCard,
                [`druk-l-surface-${surface}`]: surface,
                [`druk-l-shape-${shape}`]: shape,
            }">
            <div class="card__header" :class="{ _solo: hasOnlyOneSection }">
                <div
                    class="card__header-wrapper"
                    :class="{
                        grid: headerImg || sliderItems || hasProductLinks,
                        '_mb-10': flexibleBodyTitle,
                        card__cell: cardHoldersCard,
                    }">
                    <div
                        v-if="headerImg || sliderItems || hasProductLinks"
                        class="card__img grid__col grid__col--4"
                        :class="{
                            'grid__col--def-12 _mb-10': !cardHoldersCard && !optimizeFromDuo,
                            'grid__col--duo-12 _mb-10':
                                ((headerImg || sliderItems || hasProductLinks) && cardHoldersCard) || optimizeFromDuo,
                        }">
                        <card-file-controll
                            v-if="headerImg"
                            :icon="mockupIcon"
                            :label="mockupLabel"
                            :hasFileLoader="hasFileLoader"
                            :hasMockupAccess="hasMockupAccess"
                            :hasBlockedMockups="hasBlockedMockups"
                            @onFiles="$emit('onFiles')">
                            <template v-slot:body>
                                <img :src="headerImg" @error="$fn.fixImageError" alt="placeholder" />
                            </template>
                        </card-file-controll>

                        <div class="swipper" v-else>
                            <div class="swipper__body _mx-auto">
                                <div class="swipper__items">
                                    <div class="swipper__item">
                                        <div
                                            v-for="(mockup, index) in sliderItems"
                                            v-show="index === activeSwipperItem"
                                            :key="index"
                                            class="swipper__itemCnt"
                                            :class="{ 'has-active': index === activeSwipperItem }">
                                            <card-file-controll
                                                :icon="mockupIcon"
                                                :label="mockupLabel"
                                                :hasFileLoader="hasFileLoader"
                                                :hasMockupAccess="hasMockupAccess"
                                                :hasBlockedMockups="hasBlockedMockups"
                                                @onFiles="$emit('onFiles')">
                                                <template v-slot:body>
                                                    <font-awesome-icon icon="fa-regular fa-link" v-if="mockup.destination_custom" />

                                                    <font-awesome-icon
                                                        icon="fa-regular fa-empty-set"
                                                        v-else-if="!mockup.preview_original && !mockup.destination_original" />

                                                    <img
                                                        :src="mockup.preview_original || mockup.destination_original"
                                                        @error="$fn.fixImageError"
                                                        v-else />
                                                </template>
                                            </card-file-controll>
                                        </div>

                                        <div class="swipper__itemCnt has-active" v-if="hasProductLinks && !sliderItems">
                                            <card-file-controll
                                                :icon="mockupIcon"
                                                :label="mockupLabel"
                                                :hasFileLoader="hasFileLoader"
                                                :hasProductLinks="hasProductLinks"
                                                :hasMockupAccess="hasMockupAccess"
                                                :hasBlockedMockups="hasBlockedMockups"
                                                @onFiles="$emit('onFiles')">
                                                <template v-slot:body>
                                                    <font-awesome-icon icon="fa-regular fa-link" />
                                                </template>
                                            </card-file-controll>
                                        </div>
                                    </div>
                                </div>

                                <div class="swipper__dots" v-if="sliderItems && sliderItems.length > 1">
                                    <div class="swipper__dotsWrap">
                                        <div
                                            class="swipper__dot"
                                            v-for="(dot, index) in sliderItems"
                                            :key="index"
                                            :class="{ 'has-active': index === activeSwipperItem }"
                                            @click="activeSwipperItem = index" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="card__header-cnt"
                        :class="{
                            'grid__col grid__col--8': headerImg || sliderItems || hasProductLinks,
                            'grid__col--def-12': (headerImg || sliderItems || hasProductLinks) && !cardHoldersCard && !optimizeFromDuo,
                            'grid__col--duo-12': (headerImg || sliderItems || hasProductLinks) && !cardHoldersCard && optimizeFromDuo,
                        }">
                        <slot name="v-card-header"></slot>
                    </div>
                </div>

                <div class="card__picker" v-if="flexibleBodyTitle" :class="{ 'has-hidden_md': cardHoldersCard }">
                    <span class="card__picker-title _mr-5">{{ flexibleBodyTitle }}</span>

                    <druk-link
                        :path="link"
                        :label="showBody ? $t('common.close') : $t('common.open')"
                        :icon="'circle-caret-down'"
                        :isActive="true"
                        @click="showBody = !showBody" />
                </div>
            </div>

            <div
                class="card__body"
                :class="{
                    'has-always': !flexibleBodyTitle,
                    'has-show': showBody && flexibleBodyTitle,
                    'has-show_md': cardHoldersCard,
                }">
                <slot name="v-card-body"></slot>
            </div>

            <div class="card__footer">
                <div class="card__footer-wrapper">
                    <slot name="v-card-footer"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

    import CardFileControll from '@common/filesControll/CardFileControll';

    export default {
        name: 'v-card',

        inject: ['$validator'],

        components: {
            CardFileControll,
        },

        props: {
            headerImg: String,
            flexibleBodyTitle: String,
            mockupIcon: String,
            mockupLabel: String,
            sliderItems: Array,
            moveImage: Object,
            cardHoldersCard: Boolean,
            innerCard: Boolean,
            isSubCard: Boolean,
            optimizeFromDuo: Boolean,
            hasOnlyOneSection: Boolean,
            hasFileLoader: Boolean,
            hasProductLinks: Boolean,
            hasBlockedMockups: Boolean,

            surface: {
                type: String,
                default: 'tint-pale',
            },

            shape: {
                type: String,
                default: 'm',
            },
        },
        data() {
            return {
                activeSwipperItem: 0,

                showBody: false,
            };
        },

        computed: {
            ...mapState({
                PERM_MODULE_ORDER_MOCKUP: (state) => state.permissions.PERM_MODULE_ORDER_MOCKUP,
                PERM_ACTION_VIEW: (state) => state.permissions.PERM_ACTION_VIEW,
                PERM_ACTION_EDIT: (state) => state.permissions.PERM_ACTION_EDIT,
            }),

            sectionsCount() {
                return this.$el.querySelectorAll('.card__cell').length;
            },

            hasMockupAccess() {
                return $fn.hasAccess(this.PERM_MODULE_ORDER_MOCKUP, this.PERM_ACTION_VIEW);
            },
        },

        mounted() {
            if (this.cardHoldersCard && window.screen.width > 768) this.cardRestruct();
        },

        methods: {
            cardRestruct() {
                let cardRowCells = this.$el.querySelectorAll('.card__cell'),
                    cardContentWrapper = this.$el.querySelector('.card__wrapper');

                while (cardContentWrapper.firstChild) cardContentWrapper.firstChild.remove();
                cardRowCells.forEach((cell) => cardContentWrapper.appendChild(cell));
            },
        },
    };
</script>

<style lang="scss">
    .card {
        margin-bottom: 20px;
        font-size: 14px;
        color: var(--druk-on-surface);
        &:last-child {
            margin-bottom: 0px;
        }
        &__wrapper {
            padding: 20px;
            &._inner {
                padding: 0px;
            }
            &._sub {
                height: 100%;
            }
        }
        &__header {
            padding-bottom: 16px;
            margin-bottom: 16px;
            &._solo {
                margin-bottom: 0px;
                padding-bottom: 0px;
            }
        }
        &__section {
            padding-bottom: 20px;
            margin-bottom: 20px;
        }
        &__footer-wrapper {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
        &__body {
            display: none;
            &.has-always {
                display: block;
            }
            &.has-show {
                display: block;
            }
            &.has-show_md {
                @media (min-width: $druk-breakpoints-md) {
                    display: block;
                }
            }
        }
        &__header-title {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.5px;
            font-weight: 400;
            &.has-payload {
                align-items: center;
            }
        }
        &__options {
            display: flex;
            align-items: center;
            svg {
                margin: 0px;
            }
        }
        &__picker {
            display: flex;
            &.has-hidden_md {
                @media (min-width: $druk-breakpoints-md) {
                    display: none;
                }
            }
        }
        &__picker-title {
            margin-right: 4px;
            font-weight: 600;
        }
        &__option {
            cursor: pointer;
        }
        &__item {
            &--only-mobile {
                @media (min-width: $druk-breakpoints-md) {
                    display: none !important;
                }
            }
            &--only-desktop {
                display: none;
                @media (min-width: $druk-breakpoints-md) {
                    display: block;
                }
            }
        }
    }
</style>
