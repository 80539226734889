<template>
    <div class="checkbox control" :class="{ 'is-parent': isGroupParent, 'is-disabled': isDisabled }">
        <label :for="name" class="checkbox__button control__button" :class="{ 'is-active': isChecked || isIndeterminate }">
            <span class="checkbox__hover control__hover"></span>

            <span class="checkbox__icon control__icon">
                <font-awesome-icon v-if="isChecked" icon="fa-solid fa-check" />
                <font-awesome-icon v-if="isIndeterminate" icon="fa-solid fa-minus" />
            </span>
        </label>

        <input
            :ref="'checkbox'"
            :id="name"
            type="checkbox"
            :checked="checked"
            class="checkbox__input control__input"
            :name="name"
            @change="onChange(+$event.target.checked)" />

        <div class="checkbox__text control__text">
            <label :for="name" class="checkbox__label control__label">{{ label }}</label>

            <div v-if="prompt" class="checkbox__support control__support">
                <druk-support :hasNoIcon="true">{{ prompt }}</druk-support>
            </div>
        </div>

        <div v-if="tooltip" class="checkbox__tooltip control__tooltip">
            <druk-hint
                :tooltip="{ text: $t(tooltip.text) || tooltip, from: tooltip.from || 'bottom', maxWidth: tooltip.maxWidth || '320px' }"
                :icon="tooltip.icon" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'form-checkbox',

        inject: ['$validator'],

        model: {
            prop: 'checked',
            event: 'change',
        },

        props: {
            checked: [Boolean, Number],
            label: String,
            prompt: String,
            name: String,

            tooltip: {
                type: [Object, String], // @Explanation: You can pass object same to the default value or just a string value with text
                // default: () => {
                // 	return {
                // 		text: 'DRUK!',
                // 		icon: 'circle-info',
                // 		from: 'bottom',
                // 	};
                // },
            },

            isIndeterminate: Boolean,
            isGroupParent: Boolean,
            isDisabled: [Boolean, Number],
        },

        mounted() {
            if (this.indeterminate) this.onDetermine();
        },

        watch: {
            emptyTrigger() {
                this.onDetermine();
            },
        },

        computed: {
            emptyTrigger() {
                return this.isChecked || this.isIndeterminate;
            },

            isChecked() {
                return !!this.checked;
            },
        },

        methods: {
            onDetermine() {
                this.$refs.checkbox.indeterminate = this.indeterminate;
            },

            onChange(value) {
                this.$emit('change', value);
                this.onDetermine();
            },
        },
    };
</script>

<style lang="scss" scoped></style>
