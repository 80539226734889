<template>
    <div class="wrapper" :class="{ 'has-elusive-thief': hasElusiveThief }">
        <elusive-thief v-if="hasElusiveThief" />

        <main class="wrapper__main">
            <router-view></router-view>
        </main>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    import ElusiveThief from '@common/doppler/ElusiveThief';

    export default {
        name: 'error-layout',

        components: {
            ElusiveThief,
        },

        computed: {
            ...mapGetters({
                hasElusiveThief: 'hasElusiveThief',
            }),
        },
    };
</script>

<style scoped>
    .wrapper {
        min-height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
