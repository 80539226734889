// @Explanation: This is a mixin that provides basic logic for filtering of data in tables
//                  You can integrate this code to the module and replace provided methods when you need it

export default {
    data() {
        return {
            COMMON_PARAMS_SORT_KEY: 'params',

            sortKey: 'created',

            hasOpenedFilter: false,
        };
    },

    created() {
        this.getFilterDataFromUrl();
    },

    computed: {
        activeSort() {
            return this.currentFilter?.sort && this.currentFilter?.direction
                ? `${this.currentFilter.sort}_${this.currentFilter.direction}`
                : null;
        },

        currentFilter() {
            return !this.isInnerModule ? this.listActiveFilter : this.moduleActiveFilter;
        },

        hasFilterLoader() {
            return !!(!this.hasLoadedFilterData || !this.hasLoadedSensitiveData);
        },
    },

    methods: {
        getFilterDataFromUrl() {
            this.updateListFilter(this.$route.query);
            if (this.isFilterNotEmpty) this.hasOpenedFilter = true;
        },

        updateListFilter(filters) {
            !this.isInnerModule ? this.UPDATE_LIST_FILTER(filters) : this.fnModuleFilterUpdate(filters);
        },

        setFilterDataToUrl(filterObj) {
            return new Promise((resolve) => {
                this.$router
                    .replace({
                        ...this.$route,
                        query: filterObj || this.currentFilter,
                    })
                    .catch(() => {});
                resolve();
            });
        },

        applyFilters(filterObj) {
            this.updateListFilter(filterObj);
            this.initGeneralData();
        },

        onPaginationChangePage(page) {
            this.updateListFilter({ page });
            this.getList();
        },

        onPaginationChangeLimit(limit) {
            this.updateListFilter({ limit });
            this.getList();
        },

        onSort(value) {
            if (!value) {
                this.sortKey = null;
                return;
            }

            if (value.sort && (value.sort.includes('param') || value.sort.includes('variable'))) {
                Object.assign(value, {
                    param_id: value.sort.includes('param') ? value.sort.replace(/\D/g, '') : null,
                    variable_id: value.sort.includes('variable') ? value.sort.replace(/\D/g, '') : null,
                    sort: this.COMMON_PARAMS_SORT_KEY,
                });
            }

            this.updateListFilter(value);
            this.getList();

            let sortList = $fn.cloneDeep(value);
            let payloadKey = null;

            delete sortList.sort;
            delete sortList.direction;

            Object.keys(sortList).forEach((el) => {
                if (sortList[el]) payloadKey = el.slice(0, -2) + sortList[el];
            });

            this.sortKey = payloadKey ? payloadKey : value.sort;
        },
    },
};
