<template>
    <div class="radio">
        <div v-if="label" class="radio__header druk-u-text-label-lg">{{ label }}:</div>

        <div class="radio__body control" :class="{ 'is-column': isColumn }">
            <div v-for="(item, index) in options" :key="index" class="radio__item" :class="{ 'druk-is-disabled': item.$isDisabled }">
                <label
                    :for="`${name}_${item.value}`"
                    class="radio__button control__button"
                    :class="{ 'is-active': item.value === value, 'druk-is-disabled': item.$isDisabled }">
                    <span class="radio__hover control__hover"></span>

                    <span class="radio__icon control__icon" :class="{ 'druk-is-disabled': item.$isDisabled }">
                        <font-awesome-icon v-if="item.value === value" icon="fa-solid fa-circle-small" />
                    </span>
                </label>

                <input
                    type="radio"
                    :id="`${name}_${item.value}`"
                    :value="item.value"
                    v-model="radio"
                    class="radio__input control__input"
                    :name="name"
                    @change="$emit('change', $event.target.value)" />

                <div class="control__text radio__text">
                    <label :for="item.value" class="control__label radio__label" :class="{ 'druk-is-disabled': item.$isDisabled }">{{
                        item.title
                    }}</label>

                    <div v-if="item.support || prompt" class="control__support radio__support">
                        <druk-support :hasNoIcon="true">{{ $t(item.support) || prompt }}</druk-support>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'form-radio-buttons-group',

        inject: ['$validator'],

        model: {
            prop: 'value',
            event: 'change',
        },

        props: {
            value: String,
            label: String,
            options: Array,
            name: String,
            prompt: String,
            rules: [String, Object],

            isColumn: Boolean,
        },

        data() {
            return {
                option: null,
            };
        },

        computed: {
            radio: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.option = value;
                },
            },
        },
    };
</script>

<style lang="scss" scoped></style>
