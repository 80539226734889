var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"druk-c-form-txt-fld"},[_c('div',{staticClass:"selector selector--select selector--select-of-add",class:{
            'has-focus': _vm.isActive,
            'has-full': _vm.isFull,
            'has-error': _vm.errors.has(_vm.name),
            'has-loading': _vm.isLoading || _vm.hasListLoading,
            'druk-is-disabled': (_vm.options.length <= 1 && _vm.isFull) || _vm.isDisabled,
            'selector--multiple': _vm.multiple,
        }},[_c('div',{staticClass:"selector__label",class:{ [`druk-l-surface-${_vm.surface}`]: _vm.surface },on:{"click":_vm.onToggle}},[_c('span',[_c('label',[_vm._v(_vm._s(_vm.label))]),(_vm.isRequired)?_c('i',[_vm._v("*")]):_vm._e()]),(_vm.hint)?_c('druk-hint',{attrs:{"tooltip":{ text: _vm.hint.text || _vm.hint, from: 'top', maxWidth: _vm.hint.maxWidth, isNotCollapse: _vm.hint.isNotCollapse },"icon":{ name: 'circle-info', size: 'xs', color: _vm.isActive ? 'primary' : 'outline' }}}):_vm._e()],1),_c('div',{staticClass:"selector__main",style:({ 'background-color': _vm.surface })},[_c('multiselect',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.rules),expression:"rules"}],ref:"multiselect",staticClass:"selector__area",style:({ [`--druk-content-background`]: _vm.contentBackground }),attrs:{"value":_vm.value,"data-vv-name":_vm.name,"data-vv-as":_vm.label,"options":_vm.list,"track-by":_vm.optionId,"label":_vm.optionLabel,"placeholder":_vm.placeholder,"tagPlaceholder":_vm.$t('select.paceholder.create'),"selectLabel":'',"selectedLabel":'',"selectGroupLabel":'',"deselectGroupLabel":'',"deselectLabel":'',"taggable":_vm.taggable},on:{"search-change":_vm.onSearch,"select":function($event){return _vm.onSelect($event)},"tag":function($event){return _vm.onTag($event)},"open":function($event){_vm.isActive = true},"close":_vm.onClose}},[_c('template',{slot:"noResult"},[_c('span',[_vm._v(_vm._s(_vm.$t('common.multiple_async.no_result')))])])],2),_c('div',{staticClass:"selector__select",class:{ 'has-active': _vm.isFull || _vm.isActive },on:{"click":_vm.onToggle}},[_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-angle-down"}})],1)],1)]),_c('div',{staticClass:"druk-c-form-txt-fld__hint"},[_c('druk-hint',{attrs:{"tooltip":_vm.searchHint,"icon":_vm.searchHint.icon}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }