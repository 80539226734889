var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"druk-c-icon",class:{
        [`druk-c-icon--${_vm.size}`]: _vm.size,
        'druk-is-disabled': _vm.isDisabled,
        'druk-is-inline': _vm.isInline,
    },on:{"click":function($event){return _vm.$emit('click')}}},[_c('font-awesome-icon',{style:({
            '--icon-color': _vm.iconColor,
            '--fa-secondary-opacity': 0.38,
            '--fa-animation-duration': _vm.animation.duration,
        }),attrs:{"icon":`fa-${_vm.variant} fa-${_vm.name}`,"spin":_vm.hasSpin ? true : false}}),(_vm.hasBadge)?_c('div',{staticClass:"druk-c-icon__badge druk-u-text-label-m"},[_vm._v(" "+_vm._s(_vm.badge)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }