import { Chart as ChartJS } from 'chart.js';
import { Title, Tooltip, Legend, BarElement, ArcElement, CategoryScale, LinearScale, RadialLinearScale } from 'chart.js';

import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartJSCustomPlugins from './custom-plugins';

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    ArcElement,
    CategoryScale,
    LinearScale,
    RadialLinearScale,
    ChartDataLabels,
    ChartJSCustomPlugins.DoughutLabel,
);

export default ChartJSCustomPlugins;
window.$ChartJSCustomPlugins = ChartJSCustomPlugins;
