import Vue from 'vue';

const langParser = (data) => {
    let list = [...data],
        mainLang = list.splice(list.indexOf(list.find((item) => item.code === 'uk')), 1),
        trashLang = list.splice(list.indexOf(list.find((item) => item.code === 'ru')), 1);

    return mainLang.concat(list).concat(trashLang);
};

export default {
    namespaced: true,

    state: {
        MAIN_LANGUAGE: 'uk',
        DRUKWEB_LANGUAGE: 'pl',
        NONHUMAN_LANGUAGE: 'ru',

        list: [],

        systemList: [],
        clientList: [],

        systemPickedList: [],

        systemFormData: {},

        loadingGetList: false,
        loadingGetSystemList: false,
        loadingGetClientList: false,
        loadingSaveSystemList: false,
        loadingSaveClientList: false,
    },

    getters: {
        editableSystemLanguages(state, getters, rootState, rootGetters) {
            return rootGetters.hasERPAccess ? state.systemList : state.systemPickedList;
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = langParser(list);
        },

        SET_SYSTEM_LIST(state, list) {
            state.systemList = langParser(list);
        },

        SET_CLIENT_LIST(state, list) {
            state.clientList = langParser(list);
        },

        SET_SYSTEM_PICKED_LIST(state, list) {
            state.systemPickedList = langParser(list);
        },

        SET_SYSTEM_FORM_DATA(state, formData) {
            Vue.set(state, 'systemFormData', formData);
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },
    },

    actions: {
        GET_LIST: async ({ state, getters, rootGetters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.get('/api/langs');

                commit('SET_SYSTEM_LIST', resp.system);
                commit('SET_CLIENT_LIST', resp.client);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_SYSTEM_LIST: async ({ state, getters, rootGetters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetSystemList',
                    status: true,
                });

                let resp = await $axios.get(
                    `/api/typographies/${rootGetters.hasTypographyAccess ? rootGetters.currentTypography.id : id}/languages/engine`,
                );

                commit('SET_SYSTEM_LIST', resp.languages);
                commit('SET_SYSTEM_PICKED_LIST', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetSystemList',
                    status: false,
                });
            }
        },

        SAVE_SYSTEM_LIST: async ({ state, getters, rootGetters, commit, dispatch }, { formData, id, without_noty }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingSaveSystemList',
                    status: true,
                });

                let resp = await $axios.post(`/api/typographies/${id}/languages/engine`, formData, {
                    headers: {
                        'Without-Noty': without_noty,
                    },
                });

                dispatch('GET_SYSTEM_LIST', id);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingSaveSystemList',
                    status: false,
                });
            }
        },
    },
};
