import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        SETTINGS_TYPES: [
            {
                key: 'common.settings_standard',
                value: 'standard',
                title: null,
            },
            {
                key: 'common.settings_detail',
                value: 'detail',
                title: null,
            },
            {
                key: 'product.title.singl',
                value: 'product',
                title: null,
            },
        ],

        STANDARD_SETTINGS_KEY: 'standard',
        DETAIL_SETTINGS_KEY: 'detail',
        PRODUCT_SETTINGS_KEY: 'product',

        INTEGER_TYPE_ITEMS: ['default_angle_corner_radius', 'min_angle_corner_radius', 'max_angle_corner_radius'],

        list: [],
        listPagination: {},

        detailTypeList: [],
        productTypeList: [],

        item: {},

        listFilter: {
            page: 1,
            limit: 30,
            type: 'standard',
        },

        loadingGetList: false,
        loadingGetDetailTypeList: false,
        loadingGetProductTypeList: false,
        loadingGetItem: false,
        loadingSaveItem: false,
        loadingDeleteItem: false,
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },

        formattedList(state) {
            return Object.fromEntries(state.list.map((item) => [item.alias, item.value]));
        },

        formattedDetailSettings(state) {
            return Object.fromEntries(state.detailTypeList.map((item) => [item.alias, item.value]));
        },

        formattedProductSettings(state) {
            let parameters = $fn.paramsParser(state.productTypeList);

            let {
                product_default_height,
                product_default_width,
                product_max_height,
                product_max_width,
                product_min_height,
                product_min_width,
                ...resp
            } = parameters;

            return {
                label: resp,
                format: {
                    default_height: product_default_height,
                    default_width: product_default_width,
                    max_height: product_max_height,
                    max_width: product_max_width,
                    min_height: product_min_height,
                    min_width: product_min_width,
                },
            };
        },

        productLabelSettings(state, getters) {
            return getters.formattedProductSettings.label;
        },

        productFormatSettings(state, getters) {
            return getters.formattedProductSettings.format;
        },

        isFilterNotEmpty(state, getters, rootState) {
            return !!Object.keys(getters.listActiveFilter).filter(
                (key) => !rootState.constant.INACTIVE_FILTERING_KEYS.concat(state.INACTIVE_FILTERING_KEYS).includes(key),
            ).length;
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },

        SET_DETAIL_TYPE_LIST(state, list) {
            state.detailTypeList = list;
        },

        SET_PRODUCT_TYPE_LIST(state, list) {
            state.productTypeList = list;
        },

        SET_ITEM(state, item) {
            state.item = item;
        },

        UPDATE_LIST_FILTER(state, newFilterObj) {
            $fn.forIn(newFilterObj, (val, key) => {
                Vue.set(state.listFilter, key, val);
            });

            Vue.set(state.listFilter, 'page', newFilterObj.page || 1);
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        RESET_STORE(state) {
            state.listFilter = {
                page: null,
                limit: null,
                type: 'standard',
            };
        },
    },

    actions: {
        GET_LIST: async ({ state, rootGetters, getters, commit }, filters = {}) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters.currentTypography.id}/settings`
                    : `/api/settings`;

                let params = !!Object.keys(filters).length ? filters : getters.listActiveFilter;

                let resp = await $axios.get(path, { params });

                commit('SET_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_DETAIL_TYPE_LIST: async ({ state, rootGetters, getters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetDetailTypeList', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters.currentTypography.id}/settings`
                    : `/api/settings`;

                let params = { type: state.DETAIL_SETTINGS_KEY };

                let resp = await $axios.get(path, { params });

                commit('SET_DETAIL_TYPE_LIST', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetDetailTypeList', status: false });
            }
        },

        GET_PRODUCT_TYPE_LIST: async ({ state, rootGetters, getters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetProductTypeList', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters.currentTypography.id}/settings`
                    : `/api/settings`;

                let params = { type: state.PRODUCT_SETTINGS_KEY };

                let resp = await $axios.get(path, { params });

                commit('SET_PRODUCT_TYPE_LIST', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetProductTypeList', status: false });
            }
        },

        GET_ITEM: async ({ state, rootGetters, getters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters.currentTypography.id}/settings/${id}`
                    : `/api/settings${id}`;

                let resp = await $axios.get(path);

                commit('SET_ITEM', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        SAVE_ITEM: async ({ commit, rootGetters, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters.currentTypography.id}/settings`
                    : `/api/settings`;

                let resp = await $axios.post(path, formData);
                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        DELETE_ITEM: async ({ commit, rootGetters, dispatch }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters.currentTypography.id}/settings/${id}`
                    : `/api/settings${id}`;

                let resp = await $axios.delete(path);
                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },
    },
};
