<template>
    <div class="vmodal">
        <div class="vmodal__container">
            <div class="vmodal__head">
                <div class="vmodal__title">
                    <div class="vmodal__title-text">
                        {{ `${$t('employees.deactivation_confirmation_title')} ${item.full_name || $t('common.no_name')}` }}
                    </div>
                </div>

                <druk-icon-button :type="'standard'" :icon="'xmark'" @click="onClose" />
            </div>

            <div class="vmodal__main">
                <div class="form">
                    <div class="form__body-support">
                        <druk-support><span v-html="deactivationPrompt"></span></druk-support>
                    </div>

                    <div class="form__footer form__footer--alt">
                        <druk-button :type="'outlined'" :icon="'xmark'" :label="$t('admin.btn.cancel')" @click="onClose" />

                        <druk-button
                            :icon="'user-slash'"
                            :label="$t('employees.deactivation_confirmation_action')"
                            :isHighlighted="true"
                            @click="onDeactivate" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'employees-deactivation',

        props: {
            item: Object,
            dependencies: Object,
            fnWait: Function,
        },

        computed: {
            deactivationPrompt() {
                let prompt = $fn.tShift(this.$t('employees.deactivation_confirmation_prompt'), {
                    employee: `<b>${this.item.full_name || this.$t('common.no_name')}</b>`,
                });

                return `${prompt} ${this.promptPayload}.`;
            },

            promptPayload() {
                return [this.promptClients, this.promptOrders, this.promptOperations].filter((item) => !!item).join(', ');
            },

            promptClients() {
                return !!this.dependencies.clients
                    ? `<b>${this.dependencies.clients}</b> ${this.$t('employees.deactivation_confirmation_prompt.clients')}`
                    : null;
            },

            promptOrders() {
                return !!this.dependencies.orders
                    ? `<b>${this.dependencies.orders}</b> ${this.$t('employees.deactivation_confirmation_prompt.orders')}`
                    : null;
            },

            promptOperations() {
                return !!this.dependencies.operations
                    ? `<b>${this.dependencies.operations}</b> ${this.$t('employees.deactivation_confirmation_prompt.operations')}`
                    : null;
            },
        },

        methods: {
            onClose() {
                this.$emit('close');
            },

            onDeactivate() {
                this.fnWait();
                this.onClose();
            },
        },
    };
</script>

<style lang="scss" scoped></style>
