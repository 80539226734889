<template>
    <div class="cFile">
        <div class="cFile__wrapper">
            <slot name="content-wrapper">
                <div
                    class="cFile__body"
                    :class="{
                        'has-hover': isControllAvailable,
                        'has-static': !hasMockupAccess || hasBlockedMockups || !hasFileLoader,
                    }"
                    @mouseover="onHover(true)"
                    @mouseout="onHover(false)"
                    @click="$emit('onFiles')">
                    <slot name="body"></slot>
                    <div class="cFile__ctrl" v-if="hasFileLoader && hasMockupAccess" :class="{ 'has-invisible': hasBlockedMockups }">
                        <div class="cFile__icon">
                            <druk-icon-button :type="'elevated'" :icon="'swatchbook'" />
                        </div>

                        <div class="cFile__prompt druk-u-text-body-m druk-l-shape-m druk-l-elevation-2" v-if="icon">
                            <span>{{ label }}</span>
                        </div>
                    </div>
                </div>
            </slot>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'card-file-controll',
        props: {
            icon: { type: String, default: null },
            label: String,
            hasFileLoader: Boolean,
            hasProductLinks: Boolean,
            hasMockupAccess: Boolean,
            hasBlockedMockups: Boolean,
            hasNoHover: Boolean,
        },
        data() {
            return { hasHovering: false };
        },
        computed: {
            isControllAvailable() {
                return this.hasHovering && this.hasFileLoader && !this.hasBlockedMockups;
            },
        },
        methods: {
            onHover(value) {
                this.hasHovering = this.hasMockupAccess && !this.hasBlockedMockups && !this.hasNoHover ? value : false;
            },
        },
    };
</script>
<style lang="scss" scoped>
    .cFile {
        height: auto;
        img {
            transition: all 0.3s ease-in-out;
        }
        &__body {
            overflow: hidden;
            cursor: pointer;
            position: relative;
            display: flex;
            border: 1px solid var(--druk-outline-variant);
            background-color: var(--druk-tint-surface-container-bright);
            border-radius: 12px;
            &::before {
                content: '';
                position: absolute;
                pointer-events: none;
                width: 100%;
                height: 100%;
                opacity: 0;
            }
            &.has-hover {
                .cFile__icon {
                    transform: translate(-50%, -140%);
                }
                .cFile__prompt {
                    opacity: 1;
                }
                &::before {
                    opacity: 1;
                }
                img {
                    filter: brightness(0.32);
                }
            }
            &.has-static {
                cursor: initial;
                pointer-events: none;
            }
        }
        &__ctrl {
            &.has-invisible {
                display: none;
            }
        }
        &__icon,
        &__prompt {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 50%;
            left: 50%;
            transition: all 0.3s ease-in-out;
        }
        &__prompt {
            flex-direction: column;
        }
        &__icon {
            width: 40px;
            height: 40px;
            transform: translate(-50%, -50%);
            &:hover {
            }
            svg {
                width: 16px;
                height: 16px;
            }
        }
        &__prompt {
            width: auto;
            margin: 4px 0px;
            padding: 4px 8px;
            text-align: center;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.4px;
            font-weight: 400;
            border-radius: 4px;
            color: var(--druk-inverse-on-surface);
            background-color: var(--druk-surface-inverse-surface);
            transform: translate(-50%, -20%);
            opacity: 0;
            svg {
                position: initial;
                width: 16px;
                height: 16px;
                transform: initial;
            }
        }
    }
    .swipper {
        .cFile {
            height: 100%;
        }
        .cFile__wrapper {
            height: 100%;
        }
        .cFile__body {
            height: 100%;
        }
    }
</style>
