export default {
    //CLIENT_MODULES----------------/
    'clmod.title': 'Модули типографий',
    'clmod.title.modal': 'модуль типографии',
    'clmod.table.description': 'Описание модуля',

    //PARAMS-------------------------/
    'params.title': 'Настройка характеристик',
    'params.title.material.param': 'Характеристики материалов',
    'params.title.material.variable': 'Парамеры материалов',
    'params.title.equipment.param': 'Характеристики оборудования',
    'params.title.equipment.variable': 'Парамеры оборудования',
    'params.title.material.param.modal': 'характеристика материала',
    'params.title.equipment.param.modal': 'характеристику оборудования',
    'params.title.equipment.variable.modal': 'параметр оборудования',
    'params.title.material.variable.modal': 'параметр материала',

    //ROLES--------------------------/
    'roles.title': 'Доступы и роли',
    'roles.title.sm': 'роль',
    'roles.title.md': 'Роль',
    'roles.title.multi': 'Роли',
    'roles.form.title': 'Название роли',
    'roles.brn.create': 'Создать новую',
    'roles.bread.create': 'Создание',
    'roles.type.druk': 'Роль для DRUK.UA',
    'roles.type.typography': 'Роль для типографии',
    'roles.msg.use': 'Выберите действия которые может совершать пользователь с этими правами доступа.',

    //SETTINGS------------------------/
    'settings.title': 'Настройка Тех-карты',
    'settings.title.sm': 'переменную',
    'settings.name': 'Название характеристики',

    //TRANSLATE SCOPES----------------/
    'scop.title': 'Модули переводов',
    'scop.title.sm': 'модуль переводов',
};
