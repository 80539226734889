<template>
    <div class="curve">
        <div class="form" v-if="!hasPreviewMode">
            <div class="grid">
                <div class="grid__col grid__col--8">
                    <div class="form__body-head">
                        <div class="form__body-head-text">{{ $t('common.params') }}</div>
                    </div>

                    <div class="_flex _align_center">
                        <div class="grid _full _mr-0">
                            <div class="grid__col grid__col--4">
                                <div class="form__item">
                                    <form-input
                                        v-model="curve.max_price"
                                        :label="`${$t('equep.curve.price.max')} (${selectedCurrencyCode})`"
                                        name="max_price"
                                        :rules="`required|positive_float:decimal:4,min_value:${(($fn.toFloat(curve.min_price) || 0.0001) + 0.0001).toFixed(4)}`"
                                        :surface="surface"
                                        @input="onCurveUpdate" />
                                </div>
                            </div>

                            <div class="grid__col grid__col--4">
                                <div class="form__item">
                                    <form-input
                                        v-model="curve.min_price"
                                        :label="`${$t('equep.curve.price.min')} (${selectedCurrencyCode})`"
                                        name="min_price"
                                        :rules="`required|positive_float:decimal:4,min_value:${0.0001},max_value:${(($fn.toFloat(curve.max_price) || 0.0002) - 0.0001).toFixed(4)}`"
                                        :surface="surface"
                                        @input="onCurveUpdate" />
                                </div>
                            </div>

                            <div class="grid__col grid__col--4">
                                <div class="form__item has-alt">
                                    <form-input
                                        v-model="curve.max_clicks"
                                        class="_mr-5"
                                        :label="$t(`${translatesBase}.curve.price.count`)"
                                        name="max_clicks"
                                        rules="required|int"
                                        :surface="surface"
                                        @input="onCurveUpdate" />

                                    <druk-hint :tooltip="$t('equep.curve.price.count.info')" />
                                </div>
                            </div>
                        </div>

                        <tool-tip
                            class="_mb-25"
                            :class="{ '_mr-10': hasRanges }"
                            :text="$t('librery.algorithms.components.workItem.resetParams')">
                            <druk-icon-button
                                class="druk-u-margin-right-8"
                                :type="'outlined'"
                                :icon="'rotate-left'"
                                @click="onCurveReset" />
                        </tool-tip>
                    </div>
                </div>

                <div class="grid__col grid__col--4">
                    <div class="form__body-head">
                        <div class="form__body-head-text">{{ $t('common.calculation') }}</div>
                    </div>

                    <div class="form__item has-alt">
                        <form-input
                            v-model="formData.clicks"
                            class="_mr-5"
                            :label="`${$t(`${translatesBase}.curve.for.click`)} = ${parseFloat(formData.price).toFixed(4)} (${selectedCurrencyCode})`"
                            name="clicks"
                            rules="int"
                            :surface="surface"
                            @input="onChangeClicks(+$event)" />
                    </div>
                </div>
            </div>
        </div>

        <div v-if="hasLoadedData" :class="{ 'section__card druk-u-margin-bottom-0': hasAltStyles }">
            <calc-curve-canvas
                :translatesBase="translatesBase"
                :surface="surface"
                :hasPreviewMode="hasPreviewMode"
                @onUpdate="onUpdate" />
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    import CalcCurveCanvas from './curve/CalcCurveCanvas';

    export default {
        name: 'calc-modes-curve',

        inject: ['$validator'],

        components: {
            CalcCurveCanvas,
        },

        props: {
            translatesBase: {
                type: String,
                default: 'equep',
            },

            surface: {
                type: String,
                default: 'tint-pale',
            },

            hasAltStyles: Boolean,
            hasPreviewMode: Boolean,
        },

        data() {
            return {
                formData: {
                    clicks: 1,
                    price: 0,
                },

                hasLoadedData: false,
            };
        },

        created() {
            this.setCommonData();
        },

        computed: {
            ...mapState({
                COMMON_CURVE: (state) => state.calcModes.curve.COMMON_ITEM,

                curve: (state) => state.calcModes.curve.item,
                curveRanges: (state) => state.calcModes.ranges.listStash,

                selectedCurrencyCode: (state) => state.selectedCurrencyCode,
            }),

            isEditMode() {
                return !!this.curve;
            },

            hasRanges() {
                return this.curveRanges.length > 1;
            },
        },

        methods: {
            ...mapMutations({
                SET_CURVE: 'calcModes/curve/SET_ITEM',
                UPDATE_CURVE: 'calcModes/curve/UPDATE_ITEM',
            }),

            setCommonData() {
                if (!this.isEditMode) {
                    this.SET_CURVE({
                        ...this.COMMON_CURVE,
                        max_clicks: this.hasRanges ? [...this.curveRanges].pop().count : null,
                        max_price: this.hasRanges ? [...this.curveRanges].shift().price : null,
                        min_price: this.hasRanges ? [...this.curveRanges].pop().price : null,
                    });
                }

                this.formData.price = this.curve.max_price || 0;

                this.hasLoadedData = true;
                this.onCurveUpdate();
            },

            onCurveUpdate() {
                this.$bus.$emit('on-curve-update');
            },

            onCurveReset() {
                this.$bus.$emit('on-curve-reset');
            },

            onChangeClicks(value) {
                this.$bus.$emit('on-change-clicks', value);
            },

            onUpdate(data) {
                Object.assign(this.formData, data);
            },
        },
    };
</script>

<style lang="scss" scoped></style>
