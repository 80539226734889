<!-- @Explanation: Super secret functionality -->
<template>
    <form-switch v-model="isInverse" :label="$t('common.color_theme.contrast_drawer')" name="isInverse" />
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    export default {
        name: 'typographies-inverse-sidebar-picker',

        computed: {
            ...mapState({
                hasInverseDrawer: (state) => state.hasInverseDrawer,
            }),

            isInverse: {
                get() {
                    return this.hasInverseDrawer;
                },
                set(value) {
                    this.SET_PROJECT_INVERSE_DRAWER(value);
                },
            },
        },

        methods: {
            ...mapMutations({
                SET_PROJECT_INVERSE_DRAWER: 'SET_PROJECT_INVERSE_DRAWER',
            }),
        },
    };
</script>

<style lang="scss" scoped></style>
