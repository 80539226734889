import { forIn } from 'lodash';

import groups from './groups';
import tables from './tables';
import imp from './imp';
import operations from './operations';

export default {
    namespaced: true,
    modules: {
        groups,
        tables,
        imp,
        operations,
    },
    state: {
        list: [],
        listPagination: {},

        item: {
            contractor: {},
            list: [],
        },

        listFilter: {
            page: null,
            limit: 30,
        },

        contractorsList: [],

        loadingGetList: false,
        loadingGetItem: false,
        loadingSaveItem: false,
        loadingDeleteItem: false,
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_CONTRACTORS_LIST(state, list) {
            state.contractorsList = list;
        },

        SET_ITEM(state, item) {
            state.item.contractor = item.contractor;
            state.item.list = item.list;
        },

        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },

        SET_FILTER_LIST(state, { key, val }) {
            state.listFilter[key] = val;
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },
    },

    actions: {
        GET_CONTRACTOR_LIST: async ({ state, rootGetters, getters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/contractors`);

                commit('SET_CONTRACTORS_LIST', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_LIST: async ({ state, rootGetters, getters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/contractors/groups/tree`, {
                    params: getters.listActiveFilter,
                });

                commit('SET_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_ITEM: async ({ state, rootGetters, getters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/contractors/${id}/operations`);

                commit('SET_ITEM', {
                    list: resp.list,
                    contractor: resp.contractor,
                });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        SAVE_ITEM: async ({ state, rootGetters, commit, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let resp = await $axios.post(`/api/typographies/${rootGetters.currentTypography.id}/contractors`, formData);

                dispatch('GET_LIST');

                // if (formData.id && Object.keys(state.item).length) dispatch('GET_ITEM', formData.id);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        SAVE_ITEM_TR: async ({ state, rootGetters, commit, dispatch }, { contractorId, formData }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let resp = await $axios.post(
                    `/api/typographies/${rootGetters.currentTypography.id}/contractors/${contractorId}/operations`,
                    formData,
                );

                dispatch('GET_ITEM', contractorId);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        DELETE_ITEM: async ({ commit, rootGetters, dispatch }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let resp = await $axios.delete(`/api/typographies/${rootGetters.currentTypography.id}/contractors/${id}`);
                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },
    },
};
