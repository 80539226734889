export default {
    state: {
        token: localStorage.getItem('_token_druk_crm') || null,
        user: JSON.parse(localStorage.getItem('_user_druk_crm')) || null,
        permissions: JSON.parse(localStorage.getItem('_permissions_druk_crm')) || null,

        loadingLogin: false,
        loadingPermissions: false,
        loadingDopplerEffect: false,
    },

    getters: {
        currentTypography(state) {
            return state.user?.typography || null;
        },

        isLogged(state) {
            return state.token !== null;
        },

        hasUser(state) {
            return !!state.user;
        },

        hasElusiveThief() {
            return !!localStorage.getItem('_doppler_token_druk_crm');
        },

        hasLoadedPermission(state) {
            return !!state.permissionss && window.$permissions;
        },

        hasERPAccess(state) {
            return !state.user?.typography_role;
        },

        hasTypographyAccess(state) {
            return !!state.user?.typography_role;
        },
    },

    mutations: {
        SET_TOKEN(state, token) {
            state.token = token;
        },

        SET_DOPPLER(state) {
            localStorage.setItem('_doppler_token_druk_crm', localStorage.getItem('_token_druk_crm'));
            localStorage.setItem('_doppler_user_druk_crm', localStorage.getItem('_user_druk_crm'));
            localStorage.setItem('_doppler_permissions_druk_crm', localStorage.getItem('_permissions_druk_crm'));
        },

        SET_USER(state, user) {
            state.user = user;
        },

        SET_PERMISSIONS(state, permissions) {
            state.permissionss = permissions;
            //set global permissions
            window.$permissions = permissions;
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        RESET_TOKENS() {
            localStorage.removeItem('_token_druk_crm');
            localStorage.removeItem('_user_druk_crm');
            localStorage.removeItem('_permissions_druk_crm');
        },

        RESET_DOPPLER_TOKENS() {
            localStorage.removeItem('_doppler_token_druk_crm');
            localStorage.removeItem('_doppler_user_druk_crm');
            localStorage.removeItem('_doppler_permissions_druk_crm');
        },

        RESET_DOPPLER() {
            localStorage.setItem('_token_druk_crm', localStorage.getItem('_doppler_token_druk_crm'));
            localStorage.setItem('_user_druk_crm', localStorage.getItem('_doppler_user_druk_crm'));
            localStorage.setItem('_permissions_druk_crm', localStorage.getItem('_doppler_permissions_druk_crm'));
        },

        LOGOUT(state) {
            state.token = null;
            state.user = null;
            state.permissionss = null;
        },
    },

    actions: {
        AUTH_LOGIN: async ({ rootState, rootGetters, state, getters, commit }, formData) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingLogin', status: true });

                let resp = await $axios.post('/api/employees/auth/login', formData);

                let token = 'Bearer ' + resp.token;

                localStorage.setItem('_token_druk_crm', token);
                $axios.defaults.headers.common['Authorization'] = token;
                commit('SET_TOKEN', token);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingLogin', status: false });
            }
        },

        AUTH_DOPPLER_LOGIN: async ({ rootState, rootGetters, state, getters, commit, dispatch }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'hasDopplerEffect', status: true });

                let resp = await $axios.get(`/api/employees/auth/login-as/${id}`);

                commit('SET_DOPPLER');

                let token = 'Bearer ' + resp.token;

                localStorage.setItem('_token_druk_crm', token);

                $axios.defaults.headers.common['Authorization'] = token;
                commit('SET_TOKEN', token);

                dispatch('USER_FETCH');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'hasDopplerEffect', status: false });
            }
        },

        AUTH_LOGOUT({ commit }) {
            return new Promise((resolve, reject) => {
                commit('RESET_TOKENS');
                commit('RESET_DOPPLER_TOKENS');
                commit('LOGOUT');

                delete $axios.defaults.headers.common['Authorization'];

                resolve();
            });
        },

        AUTH_DOPPLER_LOGOUT({ commit, dispatch }) {
            return new Promise(async (resolve, reject) => {
                commit('RESET_DOPPLER');
                commit('RESET_DOPPLER_TOKENS');

                $axios.defaults.headers.common['Authorization'] = localStorage.getItem('_token_druk_crm');

                await dispatch('USER_FETCH');
                resolve();
            });
        },

        USER_FETCH: async ({ rootState, rootGetters, state, getters, commit, dispatch }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingLogin', status: true });
                commit('SET_LOADING_STATUS', { value_key: 'loadingPermissions', status: true });

                let resp = await $axios.get('/api/employees/authorized');

                let user = resp.single;
                let permissions = resp.single.typography_role?.permissions || resp.single.role?.permissions;

                localStorage.setItem('_user_druk_crm', JSON.stringify(user));
                localStorage.setItem('_permissions_druk_crm', JSON.stringify(permissions));

                commit('SET_USER', user);
                commit('SET_PERMISSIONS', permissions);
                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingLogin', status: false });
                commit('SET_LOADING_STATUS', { value_key: 'loadingPermissions', status: false });
            }
        },
    },
};
