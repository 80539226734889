import Vue from '../vue_base';

import axios from 'axios';
import i18n from '../i18n';

// import customNoty from './customNoty';

import { loadProgressBar } from 'axios-progress-bar';
import { forIn } from 'lodash';

import 'axios-progress-bar/dist/nprogress.css';

loadProgressBar();

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API;

axios.interceptors.request.use((config) => {
    let token = localStorage.getItem('_token_druk_crm');

    if (token) {
        config.headers['Authorization'] = token;
    }

    return config;
});

let timer = null;

axios.interceptors.response.use(
    (resp) => {
        let requestMethod = resp.config.method;

        if (
            (Object.keys($customNoty).includes(resp.data.message) && !$customNoty[resp.data.message]) ||
            resp.config.headers['Without-Noty']
        )
            return resp.data;

        switch (requestMethod) {
            case 'post':
                $VUE_APP.$noty.success(i18n.t('common.noty_post_success'));
                break;

            case 'delete':
                $VUE_APP.$noty.success(i18n.t('common.noty_delete_success'));
                break;
        }

        if (resp.data.message) {
            $VUE_APP.$noty.success(resp.data.message);
        }

        return resp.data;
    },
    function (err) {
        let status = err.response?.status;

        if (status === 403) {
            if (!timer) {
                $VUE_APP.$bus.$emit('permission');

                timer = true;
                setTimeout(() => {
                    timer = null;
                }, 1000);
            }
        } else {
            if (err.response?.headers['Without-Noty']) return;

            if (err.response?.data?.errors) {
                forIn(err.response.data.errors, (item, key) => {
                    if (!Object.keys($customNoty).includes(key)) {
                        $VUE_APP.$noty.error(item);
                    } else if (Object.keys($customNoty).includes(key) && $customNoty[key]) {
                        $VUE_APP.$noty.error(i18n.t($customNoty[key]));
                    }
                });
            } else if (err.response?.data?.message || err.response?.message) {
                $VUE_APP.$noty.error(err.response?.data?.message || err.response?.message);
            } else {
                return;
            }

            return Promise.reject(err);
        }
    },
);

Vue.prototype.$axios = axios;
window.$axios = axios;
export default axios;
