<template>
    <div class="chart mosaic druk-l-surface-tint-pale druk-l-shape-lg">
        <div class="chart__header mosaic__header druk-u-text-title-m">
            {{ $t('dashboard.orders.base_chart.title') }}
        </div>

        <div class="chart__body mosaic__body">
            <div class="grid">
                <div class="grid__col grid__col--3 grid__col--def-6 grid__col--sm-12 druk-u-margin-bottom-def-16">
                    <div class="mosaic__item druk-l-surface-tint-brighter druk-l-shape-lg">
                        <div class="mosaic__label druk-u-text-label-lg">
                            {{ $t('dashboard.orders.base_chart.total') }}
                        </div>
                        <div class="mosaic__value druk-u-text-headline-m druk-is-proniment">
                            {{ filteredOrdersData.total }}
                        </div>
                    </div>
                </div>

                <div class="grid__col grid__col--3 grid__col--def-6 grid__col--sm-12 druk-u-margin-bottom-def-16">
                    <div class="mosaic__item druk-l-surface-tint-brighter druk-l-shape-lg">
                        <div class="mosaic__label druk-u-text-label-lg">
                            {{ $t('dashboard.orders.base_chart.total_price') }} ({{ selectedCurrencyCode }})
                        </div>
                        <div class="mosaic__value druk-u-text-headline-m druk-is-proniment">
                            {{ filteredOrdersData.total_price }}
                        </div>
                    </div>
                </div>

                <div class="grid__col grid__col--3 grid__col--def-6 grid__col--sm-12 druk-u-margin-bottom-sm-16">
                    <div class="mosaic__item druk-l-surface-tint-brighter druk-l-shape-lg">
                        <div class="mosaic__label druk-u-text-label-lg">
                            {{ $t('dashboard.orders.base_chart.avg_price') }} ({{ selectedCurrencyCode }})
                        </div>
                        <div class="mosaic__value druk-u-text-headline-m druk-is-proniment">
                            {{ filteredOrdersData.avg_price }}
                        </div>
                    </div>
                </div>

                <div class="grid__col grid__col--3 grid__col--def-6 grid__col--sm-12">
                    <div class="mosaic__item druk-l-surface-tint-brighter druk-l-shape-lg druk-is-in-development">
                        <div class="mosaic__label druk-u-text-label-lg">
                            {{ $t('dashboard.orders.base_chart.total_manufacturing_costs') }}
                        </div>
                        <div class="mosaic__value druk-u-text-headline-m druk-is-proniment">
                            {{ filteredOrdersData.total_manufacturing_costs }}%
                        </div>

                        <div class="chart__development-label druk-u-text-label-m">
                            {{ $t('dashaboard.prompt.in_development') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="chart__footer"></div>

        <div v-if="loadingGetData" class="chart__loader">
            <div class="druk-l-card druk-l-card-m druk-l-surface druk-l-shape-lg druk-has-inverse-surface">
                <druk-loader :color="'inverse-on-surface'" :size="'lg'" />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    export default {
        name: 'orders-base-mosaic-chart',

        computed: {
            ...mapState({
                selectedCurrencyCode: (state) => state.selectedCurrencyCode,
                filteredOrdersData: (state) => state.dashboard.filteredOrdersData,

                loadingGetData: (state) => state.dashboard.loadingGetData,
            }),
        },
    };
</script>

<style lang="scss" scoped></style>
