import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        list: [],
    },

    getters: {},

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        ADD_TABLE(state) {
            state.list.push({
                count: null,
                price: null,
            });
        },

        DELETE_TABLE(state, index) {
            Vue.delete(state.list, index);
        },

        SET_TABLE_DATA(state, { index, key, value }) {
            state.list[index][key] = value;
        },

        RESET_STORE(store) {
            store.list = [];
        },
    },

    actions: {},
};
