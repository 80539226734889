<template>
    <div class="imgCheck">
        <div
            class="imgCheck__item"
            :class="{ 'has-active': item.checked }"
            v-for="item in items"
            :key="item.index"
            @click="onChange(item)">
            <img :src="item.img" @error="$fn.fixImageError" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'form-img-check',
        props: {
            items: {
                type: Array,
                required: true,
            },
        },

        computed: {
            list() {
                return this.items;
            },
        },

        methods: {
            onChange(item) {
                this.list.forEach((el, index) => {
                    this.list[index].checked = index === item.index ? true : false;
                });

                this.$emit('onChange', this.list);
            },
        },
    };
</script>

<style scoped lang="scss">
    .imgCheck {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px -10px;
        &__item {
            cursor: pointer;
            position: relative;
            background-color: var(--druk-background);
            margin: 0px 10px;
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                height: 100%;
                border: 1px solid var(--druk-outline-variant);
                transform: translate(-50%, -50%);
                transition: border var(--druk-duration-short-2) var(--druk-easing-emphasized-accelerate);
            }
            &.has-active {
                &::before {
                    border: solid 2px var(--druk-primary-container);
                    transition: border var(--druk-duration-short-2) var(--druk-easing-emphasized-decelerate);
                }
            }
        }
    }
</style>
