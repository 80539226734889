<template>
    <div
        class="selector selector--select"
        :class="{
            'has-focus': hasActive,
            'has-full': hasFull,
            'has-error': errors.has(name),
            'has-loading': loader,
            'druk-is-disabled': options.length <= 1 && notEmpty,
            'selector--multiple': multiple,
        }">
        <div class="selector__label" :class="{ [`druk-l-surface-${surface}`]: surface }" @click="onToggle">
            <span>
                <label>{{ label }}</label>
                <i v-if="hasRequired">*</i>
            </span>

            <druk-hint
                v-if="hint"
                :tooltip="{ text: hint.text || hint, from: 'top', maxWidth: hint.maxWidth, isNotCollapse: hint.isNotCollapse }"
                :icon="{ name: 'circle-info', size: 'xs', color: hasActive ? 'primary' : 'outline' }" />
        </div>

        <div class="selector__main" :style="{ 'background-color': surface }">
            <multiselect
                ref="multiselect"
                :style="{ [`--druk-content-background`]: contentBackground }"
                class="selector__area"
                v-model="selectedValues"
                :data-vv-name="name"
                :data-vv-as="label"
                v-validate="rules || ''"
                :options="splittedOptions"
                :multiple="multiple || false"
                :close-on-select="multiple ? false : true"
                :label="
                    value && value[`parsed_${option_label || 'title'}`] ? `parsed_${option_label || 'title'}` : option_label || 'title'
                "
                :placeholder="placeholder || ''"
                :groupLabel="isActiveSeparate || multiple ? 'title' : groupLabel || null"
                :groupValues="isActiveSeparate || multiple ? 'options' : groupValues || null"
                :groupSelect="multiple && !isActiveSeparate"
                :max="limit"
                :tagPlaceholder="''"
                :selectLabel="''"
                :selectedLabel="''"
                :selectGroupLabel="''"
                :deselectGroupLabel="''"
                :deselectLabel="''"
                :hideSelected="notEmpty"
                :searchable="!notEmpty"
                :track-by="option_id"
                :showNoOptions="!limit || (limit && selectedValues.length < limit)"
                @select="onSelect($event)"
                @open="hasActive = true"
                @close="onClose">
                <template slot="option" slot-scope="props">
                    <div
                        class="multiselect__option-body"
                        :class="{
                            'druk-is-select-all': props.option.$isLabel,
                            'druk-is-multiple': multiple && !props.option.$isLabel && !isActiveSeparate,
                            'druk-is-column': props.option.prompt || props.option.has_divider,
                        }">
                        <template v-if="multiple && !isActiveSeparate">
                            <div
                                v-if="isSomeOptionsSelected && props.option.$isLabel"
                                class="multiselect__option-icon multiselect__option-icon--empty">
                                <druk-icon :name="'square-minus'" :variant="'solid'" :isInline="true" />
                            </div>

                            <div v-else class="multiselect__option-icon multiselect__option-icon--empty">
                                <druk-icon :name="'square'" :color="'surface-variant'" :isInline="true" />
                            </div>

                            <div class="multiselect__option-icon multiselect__option-icon--selected">
                                <druk-icon :name="'square-check'" :variant="'solid'" :isInline="true" />
                            </div>
                        </template>

                        <span v-if="props.option.$isLabel">{{ props.option.$groupLabel }}</span>

                        <template v-else>
                            <span>{{
                                props.option[`parsed_${[option_label || 'title']}`] ||
                                props.option[option_label || 'title'] ||
                                `(${$t('common.without_name')})`
                            }}</span>

                            <span v-if="props.option.prompt" class="multiselect__prompt">
                                {{ `${$t(props.option.promptPayload)}: ${props.option[props.option.prompt].title}` }}
                            </span>

                            <span v-if="props.option.has_divider" class="multiselect__divider"></span>
                        </template>
                    </div>
                </template>

                <template v-if="!multiple" slot="singleLabel" slot-scope="props">
                    {{
                        props.option[`parsed_${[option_label || 'title']}`] ||
                        props.option[option_label || 'title'] ||
                        `(${$t('common.without_name')})`
                    }}
                </template>

                <template slot="noResult">
                    <span>{{ $t('common.multiple_async.no_result') }}</span>
                </template>

                <template slot="maxElements">
                    <span>{{ $fn.tShift($t('common.multiselect.prompt.limit_reached'), { limit }) }}</span>
                </template>
            </multiselect>

            <div class="multiselect__single" v-if="multiple && selectedValues">
                {{ customMultipleLabel || `${$t('filter.values')}: ${selectedValues.length}` }}
            </div>

            <div
                v-if="!hasFull || !isFilterType || (notEmpty && isFilterType) || isSorting"
                class="selector__select"
                :class="{ 'has-active': hasFull || hasActive }"
                @click="onToggle">
                <font-awesome-icon icon="fa-regular fa-angle-down" />
            </div>

            <div class="selector__clear" v-if="isFilterType && hasFull && !notEmpty" @click="onClear">
                <font-awesome-icon icon="fa-regular fa-xmark" />
            </div>
        </div>

        <div v-if="errors.has(name)" class="selector__error">
            <span :class="{ [`druk-l-surface-${surface}`]: surface }">{{ errorText || errors.first(name) }}</span>
        </div>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect';

    export default {
        name: 'form-select',

        inject: ['$validator'],

        components: {
            Multiselect,
        },

        props: {
            value: {
                required: true,
            },
            options: {
                type: Array,
                required: true,
            },
            hasLoadOptions: {
                default: true,
                type: Boolean,
            },
            name: {
                type: String,
                required: true,
            },
            hint: [String, Object],
            option_label: String,
            option_id: {
                type: String,
                default: 'id',
            },
            type: String,
            label: String,
            rules: String,
            groupLabel: String,
            groupValues: String,
            errorText: String,
            customMultipleLabel: String,
            notEmpty: Boolean,
            isGroup: Boolean,
            isSorting: Boolean,
            multiple: Boolean,
            isActiveSeparate: Boolean,
            isFilterType: Boolean,
            placeholder: String,
            loader: Boolean,
            prompt: Object,
            tShift: Object,
            limit: [Number, String],

            //
            surface: {
                type: String,
                default: 'tint-pale',
            },

            surfaceVariant: {
                type: String,
                default: 'tint-bright',
            },
        },

        data() {
            return {
                hasActive: false,
            };
        },

        computed: {
            selectedValues: {
                get() {
                    return this.value;
                },
                set(val) {
                    this.$emit('input', val);
                    this.$emit('extraInput', { old: this.value, new: val });
                },
            },

            parsedOptions() {
                let options =
                    this.multiple && !this.isActiveSeparate
                        ? [{ title: this.$t('common.select_all'), options: this.options }]
                        : this.options;

                return (
                    options
                        ?.filter((item) => !!item)
                        .map((item) => ({
                            ...item,
                            [`parsed_${[this.option_label || 'title']}`]: this.onOptionTShiht(item?.[this.option_label || 'title']),
                        })) || []
                );
            },

            splittedOptions() {
                return this.isActiveSeparate
                    ? [
                          {
                              title: this.$t('filter.active_options'),
                              options: this.parsedOptions.filter((option) => option.active),
                          },
                          {
                              title: this.$t('filter.inactive_options'),
                              options: this.parsedOptions.filter((option) => !option.active),
                          },
                      ]
                    : this.parsedOptions;
            },

            contentBackground() {
                let base = this.surfaceVariant.split('-');
                return `var(--druk-${[...base].shift()}-surface-container-${[...base].pop()})`;
            },

            hasFull() {
                return !!this.value;
            },

            hasRequired() {
                if (this.rules && this.rules.indexOf('required') != -1) return true;
                return false;
            },

            isAllOptionsSelected() {
                return this.selectedValues?.length > 1 && this.selectedValues?.length === this.options.length;
            },

            isSomeOptionsSelected() {
                return !!this.selectedValues?.length && this.selectedValues.length < this.options.length;
            },

            hasNoSelectedOptions() {
                return !this.selectedValues?.length;
            },
        },

        methods: {
            onClear() {
                this.selectedValues = null;
            },

            onToggle() {
                this.hasActive ? this.$refs.multiselect.deactivate() : this.$refs.multiselect.activate();
            },

            onSelect(e) {
                if (!this.multiple) this.onToggle();
                this.$emit('select', e);
            },

            onClose() {
                this.hasActive = false;
                this.$emit('close');
            },

            onOptionTShiht(text) {
                return $fn.tShift(text, this.tShift);
            },
        },
    };
</script>

<style lang="scss"></style>
