import { render, staticRenderFns } from "./VTable.vue?vue&type=template&id=883d5946&scoped=true"
import script from "./VTable.vue?vue&type=script&lang=js"
export * from "./VTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "883d5946",
  null
  
)

export default component.exports