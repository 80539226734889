<template>
    <div class="vmodal">
        <div class="vmodal__container">
            <div class="vmodal__head">
                <div class="vmodal__title">
                    <div class="vmodal__title-text">
                        {{ isOwner ? $t('employees.role_save.template_title') : $t('empl.role.title.save') }}
                    </div>

                    <div class="vmodal__title-support">
                        <druk-support>
                            <span
                                >{{ isOwner ? $t('employees.role_save.template_prompt') : $t('empl.role.info') }}:
                                {{ currentRole.title }}</span
                            >
                        </druk-support>
                    </div>
                </div>

                <druk-icon-button :type="'standard'" :icon="'xmark'" @click="onClose" />
            </div>

            <div class="vmodal__main">
                <div class="form">
                    <div class="form__item druk-u-margin-bottom-16">
                        <form-radio-buttons-group v-model="roleChange" :options="roleChanges" :isColumn="true" />
                    </div>

                    <div v-if="hasCreateChange" class="form__block">
                        <form-language-tabs
                            :languages="languages"
                            :formData="formData"
                            :requiredFields="'title'"
                            @change="selectedLang = $event" />

                        <div class="form__item">
                            <form-input
                                v-for="language in languages"
                                :key="language.code"
                                v-show="language.code === selectedLang"
                                v-model="formData.title[language.code]"
                                :label="$t('empl.form.key')"
                                :placeholder="$t('empl.form.key.info')"
                                rules="required"
                                :surface="'tint-brighter'"
                                name="tite" />
                        </div>
                    </div>

                    <div class="form__footer form__footer--alt">
                        <div class="form__footerItem">
                            <druk-button :type="'outlined'" :icon="'xmark'" :label="$t('admin.btn.cancel')" @click="onClose" />
                        </div>

                        <div class="form__footerItem">
                            <druk-dialog-option @success="onSave">
                                <druk-button :icon="'floppy-disk'" :label="$t('btn.save')" />
                            </druk-dialog-option>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

    export default {
        name: 'employees-role-save',

        props: {
            permissions: Object,
            isOwner: Boolean,
            fnWait: Function,
            fnCancel: Function,
        },

        data() {
            return {
                SAVE_ROLE_CHANGE: 'save',
                CREATE_ROLE_CHANGE: 'create',

                selectedLang: this.$store.state.selectedLang,

                formData: {
                    title: {},
                },

                roleChange: null,
            };
        },

        created() {
            this.setFormData();
        },

        computed: {
            ...mapState({
                item: (state) => state.employees.item,
                role: (state) => state.roles.item,
                roleTemplate: (state) => state.roles.templates.item,
            }),
            ...mapGetters({
                languages: 'languages/editableSystemLanguages',
            }),

            roleChanges() {
                return [
                    {
                        title: this.isOwner ? this.$t('employees.role_save.template.change_current') : this.$t('empl.role.picked'),
                        value: 'save',
                    },
                    {
                        title: this.isOwner ? this.$t('employees.role_save.template.create_new') : this.$t('empl.role.pecked.create'),
                        value: 'create',
                    },
                ];
            },

            currentRole() {
                return this.isOwner ? this.roleTemplate : this.role;
            },

            hasCreateChange() {
                return this.roleChange === this.CREATE_ROLE_CHANGE;
            },
        },

        methods: {
            ...mapActions({
                SAVE_ROLE: 'roles/SAVE_ITEM',
                SAVE_ROLE_TEMPLATE: 'roles/templates/SAVE_ITEM',
            }),

            setFormData() {
                this.languages.forEach((lang) =>
                    this.$set(this.formData.title, lang.code, this.currentRole.title_translations?.[lang.code] || null),
                );

                this.roleChange = this.SAVE_ROLE_CHANGE;
            },

            onClose() {
                this.fnCancel();
                this.$emit('close');
            },

            async onSave(e) {
                let result = await this.$validator.validate();

                if (result) {
                    try {
                        let formData = {
                            id: this.hasCreateChange ? null : this.currentRole.id,
                            alias: this.hasCreateChange ? $fn.generateHash() : this.currentRole.alias,
                            title: this.hasCreateChange ? this.formData.title : this.currentRole.title_translations,
                            permissions: this.permissions,
                        };

                        let role = this.isOwner
                            ? (await this.saveRoleTemplate(formData)).single
                            : (await this.saveRole(formData)).single;

                        this.fnWait(role);

                        this.$emit('close');
                    } catch (e) {
                        $fn.setValidateErrors(e, this.errors);
                    }
                } else {
                    $fn.showFormError(e.target.closest('.druk-c-btn'), this.$validator.errors.items);
                }
            },

            saveRole(formData) {
                return this.SAVE_ROLE(formData);
            },

            saveRoleTemplate(formData) {
                return this.SAVE_ROLE_TEMPLATE(formData);
            },
        },
    };
</script>

<style lang="scss" scoped></style>
