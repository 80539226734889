import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        DEFAULT_ORDER_CURRENCY: 'UAH',
        DEFAULT_CURRENCY: 'EUR',

        list: [],
        typographyList: [],

        typographyFormData: {},

        loadingGetList: false,
        loadingGetTypographyList: false,
    },

    getters: {
        selectedCurrencyRate(state, getters, rootState) {
            let selectedCurrency = $fn.find(state.list, ['code', rootState.selectedCurrency]);
            return selectedCurrency ? selectedCurrency.rate : 1;
        },

        selectedCurrencyObj(state, getters, rootState) {
            return $fn.find(state.typographyList, ['code', rootState.selectedCurrency]);
        },

        mutatedTypographyList(state) {
            return [...state.list].map((item) =>
                Object.assign(item, {
                    title: `${item.title} (${item.code})`,
                }),
            );
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_TYPOGRAPHY_LIST(state, list) {
            state.typographyList = list;
        },

        SET_TYPOGRAPHY_FORM_DATA(state, formData) {
            Vue.set(state, 'typographyFormData', formData);
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },
    },

    actions: {
        GET_LIST: async ({ state, getters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.get('/api/currencies');

                resp.list.forEach((obj, index) => {
                    obj.rate = parseInt(resp.list[index].rate * 100) / 100;
                });

                commit('SET_LIST', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_TYPOGRAPHY_LIST: async ({ state, getters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetTypographyList',
                    status: true,
                });

                let resp = await $axios.get(`/api/typographies/${id}/active-currencies`);

                resp.list.forEach((obj, index) => {
                    obj.rate = parseInt(resp.list[index].rate * 100) / 100;
                });

                commit('SET_LIST', resp.list);
                commit('SET_TYPOGRAPHY_LIST', resp.currencies);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetTypographyList',
                    status: false,
                });
            }
        },

        GET_BANK_CURRENCY: async ({ state, getters, commit }, currency) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                await $axios.post('/api/currencies/exchange', { code: currency.code });
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        SAVE_CURRENCY: async ({ state, getters, commit }, { currency, without_noty }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                await $axios.post('/api/currencies', currency, {
                    headers: {
                        'Without-Noty': without_noty,
                    },
                });
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        SAVE_ACTIVE_CURRENCIES: async ({ state, getters, commit, dispatch }, { formData, typography_id, without_noty }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let path = `/api/typographies/${typography_id}/active-currencies`;

                await $axios.post(path, formData, {
                    headers: {
                        'Without-Noty': without_noty,
                    },
                });

                dispatch('GET_TYPOGRAPHY_LIST', typography_id);
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },
    },
};
