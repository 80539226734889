(function (t) {
    const e = (t['ru'] = t['ru'] || {});
    e.dictionary = Object.assign(e.dictionary || {}, {
        '(may require <kbd>Fn</kbd>)': '(может требовать <kbd>Fn</kbd>)',
        '%0 of %1': '%0 из %1',
        Accept: 'Принять',
        Accessibility: 'Доступность',
        'Accessibility help': 'Помощь по специальным возможностям',
        'Advanced options': 'Дополнительные параметры',
        'Align cell text to the bottom': 'Выровнять текст ячейки по нижнему краю',
        'Align cell text to the center': 'Выровнять текст по центру',
        'Align cell text to the left': 'Выровнять текст по левому краю',
        'Align cell text to the middle': 'Выровнять текст ячейки по центру',
        'Align cell text to the right': 'Выровнять текст по правому краю',
        'Align cell text to the top': 'Выровнять текст ячейки по верхнему краю',
        'Align center': 'Выравнивание по центру',
        'Align left': 'Выравнивание по левому краю',
        'Align right': 'Выравнивание по правому краю',
        'Align table to the left': 'Выровнять таблицу по левому краю',
        'Align table to the right': 'Выровнять таблицу по правому краю',
        Alignment: 'Выравнивание',
        All: 'Все',
        'Almost equal to': 'Почти равный',
        Angle: 'Угол',
        'Approximately equal to': 'Конгруэнтность (геометрическое равенство)',
        Aquamarine: 'Аквамариновый',
        Arrows: 'Стрелки',
        'Asterisk operator': 'Оператор звездочка',
        'Austral sign': 'Символ аргентинского аустраля',
        'back with leftwards arrow above': 'Стрелка влево над словом BACK (назад)',
        Background: 'Фон',
        'Below, you can find a list of keyboard shortcuts that can be used in the editor.':
            'Ниже вы можете найти список сочетаний клавиш, которые можно использовать в редакторе.',
        Big: 'Крупный',
        'Bitcoin sign': 'Символ биткоина',
        Black: 'Чёрный',
        'Block quote': 'Цитата',
        'Block styles': 'Блочные стили',
        Blue: 'Синий',
        'Blue marker': 'Выделение синим маркером',
        Bold: 'Жирный',
        'Bold text': 'Жирный текст',
        Border: 'Граница',
        'Break text': 'Разрывать текст',
        'Bulleted List': 'Маркированный список',
        'Bulleted list styles toolbar': 'Стили маркированного списка',
        Cancel: 'Отмена',
        'Caption for image: %0': 'Подпись к изображению: %0',
        'Caption for the image': 'Подпись к изображению',
        'Cedi sign': 'Символ ганского седи',
        'Cell properties': 'Свойства ячейки',
        'Cent sign': 'Символ цента',
        'Center table': 'Выровнять таблицу по центру',
        'Centered image': 'Выравнивание по центру',
        'Change image text alternative': 'Редактировать альтернативный текст',
        'Character categories': 'Категории',
        'Characters: %0': 'Символов: %0',
        'Choose heading': 'Выбор стиля',
        Circle: 'Окружность',
        Clear: 'Очистить',
        'Click to edit block': 'Нажмите, чтобы редактировать блок',
        Close: 'Закрыть',
        'Close contextual balloons, dropdowns, and dialogs':
            'Закрыть контекстные всплывающие окна, раскрывающиеся списки и диалоговые окна.',
        Code: 'Исходный код',
        'Code block': 'Блок кодов',
        'Colon sign': 'Символ двоеточия',
        Color: 'Цвет',
        'Color picker': 'Выбор цвета',
        Column: 'Столбец',
        'Contains as member': 'Содержит как член',
        'Content editing keystrokes': 'Клавиши для редактирования контента',
        'Copy selected content': 'Копировать выбранное содержание',
        'Copyright sign': 'Знак авторского права',
        'Create link': 'Создать ссылку',
        'Cruzeiro sign': 'Символ бразильского крузейро',
        Currency: 'Валюта',
        'Currency sign': 'Символ валюты',
        Custom: 'Другое',
        'Custom image size': 'Пользовательский размер изображения',
        Dashed: 'Пунктирная',
        Decimal: 'Десятичный',
        'Decimal with leading zero': 'Десятичный с ведущим нулем',
        'Decrease indent': 'Уменьшить отступ',
        'Decrease list item indent': 'Уменьшить отступ элемента списка',
        Default: 'По умолчанию',
        'Degree sign': 'Знак градуса',
        'Delete column': 'Удалить столбец',
        'Delete row': 'Удалить строку',
        'Dim grey': 'Тёмно-серый',
        Dimensions: 'Размеры',
        'Disable editing': 'Отключить редактирование',
        Disc: 'Диск',
        'Division sign': 'Знак деления',
        'Document colors': 'Цвет страницы',
        'Dollar sign': 'Символ доллара',
        'Dong sign': 'Символ донга',
        Dotted: 'Точечная',
        Double: 'Двойная',
        'Double dagger': 'Двойной крестик',
        'Double exclamation mark': 'Двойной восклицательный знак',
        'Double low-9 quotation mark': 'Нижняя двойная открывающая кавычка',
        'Double question mark': 'Двойной вопросительный знак',
        Downloadable: 'Загружаемые',
        'downwards arrow to bar': 'Стрелка вниз, упирающаяся в планку',
        'downwards dashed arrow': 'Пунктирная стрелка вниз',
        'downwards double arrow': 'Двойная стрелка вниз',
        'downwards simple arrow': 'простая стрелка вниз',
        'Drachma sign': 'Символ драхмы',
        'Drag to move': 'Перетащить',
        'Dropdown toolbar': 'Выпадающая панель инструментов',
        'Edit block': 'Редактировать блок',
        'Edit link': 'Редактировать ссылку',
        'Editor block content toolbar': 'Панель инструментов редактора',
        'Editor contextual toolbar': 'Контекстуальная панель инструментов редактора',
        'Editor dialog': 'Диалоговое окно редактора',
        'Editor editing area: %0': 'Область редактирования редактора: %0',
        'Editor menu bar': 'Панель меню редактора',
        'Editor toolbar': 'Панель инструментов редактора',
        'Element of': 'Принадлежит',
        'Em dash': 'Длинное тире',
        'Empty set': 'Пустое множество',
        'En dash': 'Среднее тире',
        'Enable editing': 'Разрешить редактирование',
        'end with leftwards arrow above': 'Стрелка влево над словом END (конец)',
        'Enter image caption': 'Подпись к изображению',
        'Enter table caption': 'Подпись таблицы',
        'Entering %0 code snippet': 'Ввод фрагмента кода %0',
        'Entering a to-do list': 'Ввод списка текущих дел',
        'Entering code snippet': 'Ввод фрагмента кода',
        'Error during image upload': 'Ошибка при загрузке изображения',
        'Euro sign': 'Символ евро',
        'Euro-currency sign': 'Символ евровалюты',
        'Exclamation question mark': 'Восклицательный вопросительный знак',
        'Execute the currently focused button. Executing buttons that interact with the editor content moves the focus back to the content.':
            'Активировать кнопку, находящуюся в фокусе. Активирование кнопок, которые взаимодействуют с содержимым редактора, перемещает фокус обратно на содержимое.',
        Find: 'Найти',
        'Find and replace': 'Найти и заменить',
        'Find in text…': 'Найти в тексте',
        'Find in the document': 'Найти в документе',
        'Font Background Color': 'Цвет фона',
        'Font Color': 'Цвет шрифта',
        'Font Family': 'Семейство шрифтов',
        'Font Size': 'Размер шрифта',
        'For all': 'Для всех',
        'Fraction slash': 'Дробная наклонная черта',
        'French franc sign': 'Символ французского франка',
        'Full size image': 'Оригинальный размер изображения',
        'German penny sign': 'Символ немецкого пенни',
        'Greater-than or equal to': 'Больше либо равно',
        'Greater-than sign': 'Знак больше',
        Green: 'Зелёный',
        'Green marker': 'Выделение зелёным маркером',
        'Green pen': 'Зеленый цвет текста',
        Grey: 'Серый',
        Groove: 'Желобчатая',
        'Guarani sign': 'Символ гуарани',
        'Header column': 'Столбец заголовков',
        'Header row': 'Строка заголовков',
        Heading: 'Стиль',
        'Heading 1': 'Заголовок 1',
        'Heading 2': 'Заголовок 2',
        'Heading 3': 'Заголовок 3',
        'Heading 4': 'Заголовок 4',
        'Heading 5': 'Заголовок 5',
        'Heading 6': 'Заголовок 6',
        Height: 'Высота',
        'Help Contents. To close this dialog press ESC.': 'Содержание справки. Чтобы закрыть это диалоговое окно, нажмите ESC.',
        HEX: 'HEX',
        Highlight: 'Выделить',
        'Horizontal ellipsis': 'Многоточие',
        'Horizontal line': 'Горизонтальная линия',
        'Horizontal text alignment toolbar': 'Панель инструментов горизонтального выравнивания текста',
        'Hryvnia sign': 'Символ гривны',
        'HTML object': 'HTML объект',
        Huge: 'Очень крупный',
        'Identical to': 'Тождественно равно',
        'Image from computer': 'Изображение с компьютера',
        'Image resize list': 'Список размеров',
        'Image toolbar': 'Панель инструментов изображения',
        'Image upload complete': 'Загрузка изображения завершена',
        'image widget': 'Виджет изображений',
        'In line': 'В тексте',
        'Increase indent': 'Увеличить отступ',
        'Increase list item indent': 'Увеличить отступ элемента списка',
        'Indian rupee sign': 'Символ индийской рупии',
        Infinity: 'Бесконечность',
        Insert: 'Вставить',
        'Insert a hard break (a new paragraph)': 'Вставить жесткий разрыв (новый абзац)',
        'Insert a new paragraph directly after a widget': 'Вставить новый абзац непосредственно после виджета.',
        'Insert a new paragraph directly before a widget': 'Вставить новый абзац непосредственно перед виджетом.',
        'Insert a new table row (when in the last cell of a table)': 'Вставить новую строку таблицы (в последней ячейке таблицы)',
        'Insert a soft break (a <code>&lt;br&gt;</code> element)': 'Вставить мягкий разрыв (элемент <code>&lt;br&gt;</code>)',
        'Insert code block': 'Вставить код',
        'Insert column left': 'Вставить столбец слева',
        'Insert column right': 'Вставить столбец справа',
        'Insert image': 'Вставить изображение',
        'Insert image via URL': 'Вставить изображение по URL',
        'Insert media': 'Вставить медиа',
        'Insert paragraph after block': 'Вставить параграф после блока',
        'Insert paragraph before block': 'Вставить параграф перед блоком',
        'Insert row above': 'Вставить строку выше',
        'Insert row below': 'Вставить строку ниже',
        'Insert table': 'Вставить таблицу',
        Inset: 'Вдавленная',
        Integral: 'Интеграл',
        Intersection: 'Пересечение',
        'Invalid start index value.': 'Недопустимое значение начального индекса.',
        'Inverted exclamation mark': 'Перевернутый восклицательный знак',
        'Inverted question mark': 'Перевернутый вопросительный знак',
        Italic: 'Курсив',
        'Italic text': 'Текст курсивом',
        Justify: 'Выравнивание по ширине',
        'Justify cell text': 'Выровнять текст по ширине',
        'Keystrokes that can be used in a list': 'Нажатия клавиш, которые можно использовать в списке',
        'Keystrokes that can be used in a table cell': 'Нажатия клавиш, которые можно использовать в ячейке таблицы',
        'Keystrokes that can be used when a widget is selected (for example: image, table, etc.)':
            'Нажатия клавиш, которые можно использовать при выборе виджета (например: изображение, таблица и т. д.)',
        'Kip sign': 'Символ кипа',
        Latin: 'Латинские',
        'Latin capital letter a with breve': 'Латинская заглавная буква «A» с бревисом',
        'Latin capital letter a with macron': 'Латинская заглавная буква «A» с макроном',
        'Latin capital letter a with ogonek': 'Латинская заглавная буква «A» с огонеком',
        'Latin capital letter c with acute': 'Латинская заглавная буква «C» с акутом',
        'Latin capital letter c with caron': 'Латинская заглавная буква «C» с гачеком',
        'Latin capital letter c with circumflex': 'Латинская заглавная буква «C» с циркумфлексом',
        'Latin capital letter c with dot above': 'Латинская заглавная буква «C» с точкой сверху',
        'Latin capital letter d with caron': 'Латинская заглавная буква «D» с гачеком',
        'Latin capital letter d with stroke': 'Латинская заглавная буква «D» со штрихом',
        'Latin capital letter e with breve': 'Латинская заглавная буква «E» с бревисом',
        'Latin capital letter e with caron': 'Латинская заглавная буква «E» с гачеком',
        'Latin capital letter e with dot above': 'Латинская заглавная буква «E» с точкой сверху',
        'Latin capital letter e with macron': 'Латинская заглавная буква «E» с макроном',
        'Latin capital letter e with ogonek': 'Латинская заглавная буква «E» с огонеком',
        'Latin capital letter eng': 'Латинская заглавная буква энг',
        'Latin capital letter g with breve': 'Латинская заглавная буква «G» с бревисом',
        'Latin capital letter g with cedilla': 'Латинская заглавная буква «G» с седилью',
        'Latin capital letter g with circumflex': 'Латинская заглавная буква «G» с циркумфлексом',
        'Latin capital letter g with dot above': 'Латинская заглавная буква «G» с точкой сверху',
        'Latin capital letter h with circumflex': 'Латинская заглавная буква «H» с циркумфлексом',
        'Latin capital letter h with stroke': 'Латинская заглавная буква «H» со штрихом',
        'Latin capital letter i with breve': 'Латинская заглавная буква «I» с бревисом',
        'Latin capital letter i with dot above': 'Латинская заглавная буква «I» с точкой сверху',
        'Latin capital letter i with macron': 'Латинская заглавная буква «I» с макроном',
        'Latin capital letter i with ogonek': 'Латинская заглавная буква «I» с огонеком',
        'Latin capital letter i with tilde': 'Латинская заглавная буква «I» с тильдой',
        'Latin capital letter j with circumflex': 'Латинская заглавная буква «J» с циркумфлексом',
        'Latin capital letter k with cedilla': 'Латинская заглавная буква «K» с седилью',
        'Latin capital letter l with acute': 'Латинская заглавная буква «L» с акутом',
        'Latin capital letter l with caron': 'Латинская заглавная буква «L» с гачеком',
        'Latin capital letter l with cedilla': 'Латинская заглавная буква «L» с седилью',
        'Latin capital letter l with middle dot': 'Латинская заглавная буква «L» с внутристрочной точкой',
        'Latin capital letter l with stroke': 'Латинская заглавная буква «L» со штрихом',
        'Latin capital letter n with acute': 'Латинская заглавная буква «N» с акутом',
        'Latin capital letter n with caron': 'Латинская заглавная буква «N» с гачеком',
        'Latin capital letter n with cedilla': 'Латинская заглавная буква «N» с седилью',
        'Latin capital letter o with breve': 'Латинская заглавная буква «O» с бревисом',
        'Latin capital letter o with double acute': 'Латинская заглавная буква «O» с двойным акутом',
        'Latin capital letter o with macron': 'Латинская заглавная буква «O» с макроном',
        'Latin capital letter r with acute': 'Латинская заглавная буква «R» с акутом',
        'Latin capital letter r with caron': 'Латинская заглавная буква «R» с гачеком',
        'Latin capital letter r with cedilla': 'Латинская заглавная буква «R» с седилью',
        'Latin capital letter s with acute': 'Латинская заглавная буква «S» с акутом',
        'Latin capital letter s with caron': 'Латинская заглавная буква «S» с гачеком',
        'Latin capital letter s with cedilla': 'Латинская заглавная буква «S» с седилью',
        'Latin capital letter s with circumflex': 'Латинская заглавная буква «S» с циркумфлексом',
        'Latin capital letter t with caron': 'Латинская заглавная буква «T» с гачеком',
        'Latin capital letter t with cedilla': 'Латинская заглавная буква «T» с седилью',
        'Latin capital letter t with stroke': 'Латинская заглавная буква «T» со штрихом',
        'Latin capital letter u with breve': 'Латинская заглавная буква «U» с бревисом',
        'Latin capital letter u with double acute': 'Латинская заглавная буква «U» с двойным акутом',
        'Latin capital letter u with macron': 'Латинская заглавная буква «U» с макроном',
        'Latin capital letter u with ogonek': 'Латинская заглавная буква «U» с огонеком',
        'Latin capital letter u with ring above': 'Латинская заглавная буква «U» с кружком сверху',
        'Latin capital letter u with tilde': 'Латинская заглавная буква «U» с тильдой',
        'Latin capital letter w with circumflex': 'Латинская заглавная буква «W» с циркумфлексом',
        'Latin capital letter y with circumflex': 'Латинская заглавная буква «Y» с циркумфлексом',
        'Latin capital letter y with diaeresis': 'Латинская заглавная буква «Y» с диэрезисом',
        'Latin capital letter z with acute': 'Латинская заглавная буква «Z» с акутом',
        'Latin capital letter z with caron': 'Латинская заглавная буква «Z» с гачеком',
        'Latin capital letter z with dot above': 'Латинская заглавная буква «Z» с точкой сверху',
        'Latin capital ligature ij': 'Латинская заглавная лигатура «IJ»',
        'Latin capital ligature oe': 'Латинская заглавная лигатура OE',
        'Latin small letter a with breve': 'Латинская строчная буква «a» с бревисом',
        'Latin small letter a with macron': 'Латинская строчная буква «a» с макроном',
        'Latin small letter a with ogonek': 'Латинская строчная буква «a» с огонеком',
        'Latin small letter c with acute': 'Латинская строчная буква «c» с акутом',
        'Latin small letter c with caron': 'Латинская строчная буква «c» с гачеком',
        'Latin small letter c with circumflex': 'Латинская строчная буква «c» с циркумфлексом',
        'Latin small letter c with dot above': 'Латинская строчная буква «c» с точкой сверху',
        'Latin small letter d with caron': 'Латинская строчная буква «d» с гачеком',
        'Latin small letter d with stroke': 'Латинская строчная буква «d» со штрихом',
        'Latin small letter dotless i': 'Латинская строчная буква «i» без точки',
        'Latin small letter e with breve': 'Латинская строчная буква «e» с бревисом',
        'Latin small letter e with caron': 'Латинская строчная буква «e» с гачеком',
        'Latin small letter e with dot above': 'Латинская строчная буква «e» с точкой сверху',
        'Latin small letter e with macron': 'Латинская строчная буква «e» с макроном',
        'Latin small letter e with ogonek': 'Латинская строчная буква «e» с огонеком',
        'Latin small letter eng': 'Латинская строчная буква энг',
        'Latin small letter f with hook': 'Латинская строчная буква «f» с хвостиком',
        'Latin small letter g with breve': 'Латинская строчная буква «g» с бревисом',
        'Latin small letter g with cedilla': 'Латинская строчная буква «g» с седилью',
        'Latin small letter g with circumflex': 'Латинская строчная буква «g» с циркумфлексом',
        'Latin small letter g with dot above': 'Латинская строчная буква «g» с точкой сверху',
        'Latin small letter h with circumflex': 'Латинская строчная буква «h» с циркумфлексом',
        'Latin small letter h with stroke': 'Латинская строчная буква «h» со штрихом',
        'Latin small letter i with breve': 'Латинская строчная буква «i» с бревисом',
        'Latin small letter i with macron': 'Латинская строчная буква «i» с макроном',
        'Latin small letter i with ogonek': 'Латинская строчная буква «i» с огонеком',
        'Latin small letter i with tilde': 'Латинская строчная буква «i» с тильдой',
        'Latin small letter j with circumflex': 'Латинская строчная буква «j» с циркумфлексом',
        'Latin small letter k with cedilla': 'Латинская строчная буква «k» с седилью',
        'Latin small letter kra': 'Латинская строчная буква кра',
        'Latin small letter l with acute': 'Латинская строчная буква «l» с акутом',
        'Latin small letter l with caron': 'Латинская строчная буква «l» с гачеком',
        'Latin small letter l with cedilla': 'Латинская строчная буква «l» с седилью',
        'Latin small letter l with middle dot': 'Латинская строчная буква «l» с внутристрочной точкой',
        'Latin small letter l with stroke': 'Латинская строчная буква «l» со штрихом',
        'Latin small letter long s': 'Латинская строчная буква длинная «s»',
        'Latin small letter n preceded by apostrophe': 'Латинская строчная буква «n» с предшествующим апострофом',
        'Latin small letter n with acute': 'Латинская строчная буква «n» с акутом',
        'Latin small letter n with caron': 'Латинская строчная буква «n» с гачеком',
        'Latin small letter n with cedilla': 'Латинская строчная буква «n» с седилью',
        'Latin small letter o with breve': 'Латинская строчная буква «o» с бревисом',
        'Latin small letter o with double acute': 'Латинская строчная буква «o» с двойным акутом',
        'Latin small letter o with macron': 'Латинская строчная буква «o» с макроном',
        'Latin small letter r with acute': 'Латинская строчная буква «r» с акутом',
        'Latin small letter r with caron': 'Латинская строчная буква «r» с гачеком',
        'Latin small letter r with cedilla': 'Латинская строчная буква «r» с седилью',
        'Latin small letter s with acute': 'Латинская строчная буква «s» с акутом',
        'Latin small letter s with caron': 'Латинская строчная буква «s» с гачеком',
        'Latin small letter s with cedilla': 'Латинская строчная буква «s» с седилью',
        'Latin small letter s with circumflex': 'Латинская строчная буква «s» с циркумфлексом',
        'Latin small letter t with caron': 'Латинская строчная буква «t» с гачеком',
        'Latin small letter t with cedilla': 'Латинская строчная буква «t» с седилью',
        'Latin small letter t with stroke': 'Латинская строчная буква «t» со штрихом',
        'Latin small letter u with breve': 'Латинская строчная буква «u» с бревисом',
        'Latin small letter u with double acute': 'Латинская строчная буква «u» с двойным акутом',
        'Latin small letter u with macron': 'Латинская строчная буква «u» с макроном',
        'Latin small letter u with ogonek': 'Латинская строчная буква «u» с огонеком',
        'Latin small letter u with ring above': 'Латинская строчная буква «u» с кружком сверху',
        'Latin small letter u with tilde': 'Латинская строчная буква «u» с тильдой',
        'Latin small letter w with circumflex': 'Латинская строчная буква «w» с циркумфлексом',
        'Latin small letter y with circumflex': 'Латинская строчная буква «y» с циркумфлексом',
        'Latin small letter z with acute': 'Латинская строчная буква «z» с акутом',
        'Latin small letter z with caron': 'Латинская строчная буква «z» с гачеком',
        'Latin small letter z with dot above': 'Латинская строчная буква «z» с точкой сверху',
        'Latin small ligature ij': 'Латинская строчная лигатура «ij»',
        'Latin small ligature oe': 'Латинская строчная лигатура oe',
        'Leaving %0 code snippet': 'Оставляем фрагмент кода %0',
        'Leaving a to-do list': 'Оставляем список текущих дел',
        'Leaving code snippet': 'Оставляем фрагмент кода',
        'Left aligned image': 'Выравнивание по левому краю',
        'Left double quotation mark': 'Открывающая двойная кавычка',
        'Left single quotation mark': 'Открывающая одинарная кавычка',
        'Left-pointing double angle quotation mark': 'Открывающая левая кавычка «ёлочка»',
        'leftwards arrow to bar': 'Стрелка влево, упирающаяся в планку',
        'leftwards dashed arrow': 'Пунктирная стрелка влево',
        'leftwards double arrow': 'Двойная стрелка влево',
        'leftwards simple arrow': 'простая стрелка влево',
        'Less-than or equal to': 'Меньше либо равно',
        'Less-than sign': 'Знак меньше',
        'Light blue': 'Голубой',
        'Light green': 'Салатовый',
        'Light grey': 'Светло-серый',
        Link: 'Ссылка',
        'Link image': 'Ссылка на изображение',
        'Link URL': 'Ссылка URL',
        'Link URL must not be empty.': 'URL-адрес ссылки не должен быть пустым.',
        'Lira sign': 'Символ лиры',
        'List properties': 'Свойства списка',
        'Livre tournois sign': 'Символ турского ливра',
        'Logical and': 'Логическое И',
        'Logical or': 'Логическое ИЛИ',
        'Lower-latin': 'Малые латинские',
        'Lower–roman': 'Малые римские',
        Macron: 'Макрон',
        'Manat sign': 'Символ маната',
        'Match case': 'С учетом регистра',
        Mathematical: 'Математические',
        'Media toolbar': 'Панель инструментов медиа',
        'Media URL': 'URL медиа',
        'media widget': 'медиа-виджет',
        MENU_BAR_MENU_EDIT: 'Редактировать',
        MENU_BAR_MENU_FILE: 'Файл',
        MENU_BAR_MENU_FONT: 'Шрифт',
        MENU_BAR_MENU_FORMAT: 'Формат',
        MENU_BAR_MENU_HELP: 'Помощь',
        MENU_BAR_MENU_INSERT: 'Вставить',
        MENU_BAR_MENU_TEXT: 'Текст',
        MENU_BAR_MENU_TOOLS: 'Инструменты',
        MENU_BAR_MENU_VIEW: 'Посмотреть',
        'Merge cell down': 'Объединить с ячейкой снизу',
        'Merge cell left': 'Объединить с ячейкой слева',
        'Merge cell right': 'Объединить с ячейкой справа',
        'Merge cell up': 'Объединить с ячейкой сверху',
        'Merge cells': 'Объединить ячейки',
        'Mill sign': 'Символ милль',
        'Minus sign': 'Знак минус',
        'Move focus between form fields (inputs, buttons, etc.)': 'Переместить фокус между полями формы (вводы, кнопки и т. д.)',
        'Move focus in and out of an active dialog window': 'Переместить фокус в активное диалоговое окно и обратно.',
        'Move focus to the menu bar, navigate between menu bars': 'Переместить фокус на панель меню, перемещаться между панелями меню',
        'Move focus to the toolbar, navigate between toolbars':
            'Переместить фокус на панель инструментов, перемещаться между панелями инструментов',
        'Move out of a link': 'Выйти из ссылки',
        'Move out of an inline code style': 'Выйти из встроенного стиля кода',
        'Move the caret to allow typing directly after a widget':
            'Переместить курсор, чтобы можно было вводить текст сразу после виджета.',
        'Move the caret to allow typing directly before a widget':
            'Переместите курсор, чтобы можно было вводить текст непосредственно перед виджетом.',
        'Move the selection to the next cell': 'Переместить выделение в следующую ячейку',
        'Move the selection to the previous cell': 'Переместить выделение на предыдущую ячейку',
        'Multiple styles': 'Несколько стилей',
        'Multiplication sign': 'Знак умножения',
        'N-ary product': 'N-арное произведение',
        'N-ary summation': 'N-арная сумма',
        Nabla: 'Набла',
        'Naira sign': 'Символ найры',
        'Navigate editable regions': 'Навигация по редактируемым регионам',
        'Navigate through the table': 'Навигация по таблице',
        'Navigate through the toolbar or menu bar': 'Перемещение по панели инструментов или панели меню',
        'New sheqel sign': 'Символ нового шекеля',
        Next: 'Следующий',
        'Next editable region': 'Следующий редактируемый регион',
        'Next result': 'Следующий результат',
        'No results found': 'Результаты не найдены',
        'No searchable items': 'Нет элементов для поиска',
        None: 'Нет',
        'Nordic mark sign': 'Символ скандинавской марки',
        'Not an element of': 'Не принадлежит',
        'Not equal to': 'Не равно',
        'Not sign': 'Знак отрицания',
        'Numbered List': 'Нумерованный список',
        'Numbered list styles toolbar': 'Стили нумерованного списка',
        'on with exclamation mark with left right arrow above': 'Стрелка влево и вправо над словом ON! (включить)',
        'Open in a new tab': 'Открыть в новой вкладке',
        'Open link in new tab': 'Открыть ссылку в новой вкладке',
        'Open media in new tab': 'Откройте медиа в новой вкладке',
        'Open the accessibility help dialog': 'Открыть диалоговое окно справки по специальным возможностям',
        Orange: 'Оранжевый',
        Original: 'Оригинальный',
        Outset: 'Выпуклая',
        Overline: 'Надчёркивание',
        Padding: 'Отступ',
        'Page break': 'Разрыв страницы',
        Paragraph: 'Параграф',
        'Paragraph sign': 'Знак абзаца',
        'Partial differential': 'Частичный дифференциал',
        'Paste content': 'Вставить содержание',
        'Paste content as plain text': 'Вставить содержанрие в виде обычного текста',
        'Paste the media URL in the input.': 'Вставьте URL медиа в поле ввода.',
        'Per mille sign': 'Знак промилле',
        'Per ten thousand sign': 'Знак на десять тысяч',
        'Peseta sign': 'Символ песеты',
        'Peso sign': 'Символ песо',
        'Pink marker': 'Выделение розовым маркером',
        'Plain text': 'Простой текст',
        'Please enter a valid color (e.g. "ff0000").': 'Введите действительный цвет (например, "ff0000").',
        'Plus-minus sign': 'Знак плюс-минус',
        'Pound sign': 'Символ фунта стерлингов',
        'Press %0 for help.': 'Нажмите %0 для получения помощи.',
        'Press Enter to type after or press Shift + Enter to type before the widget':
            'Нажмите Enter для ввода текста после виджета или нажмите Shift + Enter для ввода текста перед виджетом',
        Previous: 'Предыдущий',
        'Previous editable region': 'Предыдущий редактируемый регион',
        'Previous result': 'Предыдущий результат',
        'Proportional to': 'Пропорционально',
        Purple: 'Фиолетовый',
        'Question exclamation mark': 'Вопросительный восклицательный знак',
        Red: 'Красный',
        'Red pen': 'Красный цвет текста',
        Redo: 'Повторить',
        'Registered sign': 'Зарегистрированный товарный знак',
        'Remove color': 'Убрать цвет',
        'Remove Format': 'Убрать форматирование',
        'Remove highlight': 'Убрать выделение',
        Replace: 'Заменить',
        'Replace all': 'Заменить всё',
        'Replace from computer': 'Заменить с компьютера',
        'Replace image': 'Заменить изображение',
        'Replace image from computer': 'Заменить изображение с компьютера',
        'Replace with…': 'Заменить на...',
        'Resize image': 'Изменить размер изображения',
        'Resize image (in %0)': 'Изменить размер изображения (в %0)',
        'Resize image to %0': 'Изменить размер изображения до %0',
        'Resize image to the original size': 'Вернуть размер изображения к оригинальному',
        'Restore default': 'По умолчанию',
        'Reversed order': 'Обратный порядок',
        'Reversed paragraph sign': 'Обратный знак абзаца',
        'Revert autoformatting action': 'Отменить действие автоформатирования',
        'Rich Text Editor': 'Редактор',
        Ridge: 'Ребристая',
        'Right aligned image': 'Выравнивание по правому краю',
        'Right double quotation mark': 'Закрывающая двойная кавычка',
        'Right single quotation mark': 'Закрывающая одинарная кавычка',
        'Right-pointing double angle quotation mark': 'Закрывающая правая кавычка «ёлочка»',
        'rightwards arrow to bar': 'Стрелка вправо, упирающаяся в планку',
        'rightwards dashed arrow': 'Пунктирная стрелка вправо',
        'rightwards double arrow': 'Двойная стрелка вправо',
        'rightwards simple arrow': 'простая стрелка вправо',
        Row: 'Строка',
        'Ruble sign': 'Символ рубля',
        'Rupee sign': 'Символ рупии',
        Save: 'Сохранить',
        'Section sign': 'Параграф',
        'Select all': 'Выбрать все',
        'Select column': 'Выбрать столбец',
        'Select row': 'Выбрать строку',
        'Show blocks': 'Показать блоки',
        'Show more items': 'Другие инструменты',
        'Show source': 'Показать источник',
        'Side image': 'Боковое изображение',
        'Single left-pointing angle quotation mark': 'Одинарная открывающая (левая) французская угловая кавычка',
        'Single low-9 quotation mark': 'Нижняя одинарная открывающая кавычка',
        'Single right-pointing angle quotation mark': 'Одинарная закрывающая (правая) французская угловая кавычка',
        Small: 'Мелкий',
        Solid: 'Сплошная',
        'soon with rightwards arrow above': 'Стрелка вправо над словом SOON (скоро)',
        Source: 'HTML редактор',
        'Special characters': 'Спецсимволы',
        'Spesmilo sign': 'Символ спесмило',
        'Split cell horizontally': 'Разделить ячейку горизонтально',
        'Split cell vertically': 'Разделить ячейку вертикально',
        Square: 'Квадрат',
        'Square root': 'Квадратный корень',
        'Start at': 'Начать с',
        'Start index must be greater than 0.': 'Начальный индекс должен быть больше 0.',
        Strikethrough: 'Зачеркнутый',
        'Strikethrough text': 'Зачеркнутый текст',
        Style: 'Стиль',
        Styles: 'Стили',
        Subscript: 'Подстрочный',
        Superscript: 'Надстрочный',
        Table: 'Таблица',
        'Table alignment toolbar': 'Панель инструментов выравнивания таблицы',
        'Table cell text alignment': 'Выравнивание текста в ячейке таблицы',
        'Table properties': 'Свойства таблицы',
        'Table toolbar': 'Панель инструментов таблицы',
        'Tenge sign': 'Символ тенге',
        Text: 'Текст',
        'Text alignment': 'Выравнивание текста',
        'Text alignment toolbar': 'Выравнивание',
        'Text alternative': 'Альтернативный текст',
        'Text highlight toolbar': 'Панель инструментов выделения текста',
        'Text styles': 'Стиль текста',
        'Text to find must not be empty.': 'Искомый текст не должен быть пустым.',
        'The color is invalid. Try "#FF0000" or "rgb(255,0,0)" or "red".':
            'Неверный цвет. Попробуйте "#FF0000" или "rgb(255,0,0)" или "red".',
        'The URL must not be empty.': 'URL не должен быть пустым.',
        'The value is invalid. Try "10px" or "2em" or simply "2".': 'Неверное значение. Попробуйте "10px" или "2em" или просто "2".',
        'The value must not be empty.': 'Значение не должно быть пустым.',
        'The value should be a plain number.': 'Значение должно быть простым числом.',
        'There exists': 'Существует',
        'These keyboard shortcuts allow for quick access to content editing features.':
            'Эти сочетания клавиш обеспечивают быстрый доступ к функциям редактирования контента.',
        'This link has no URL': 'Для этой ссылки не установлен адрес URL',
        'This media URL is not supported.': 'Этот URL медиа не поддерживается.',
        'Tilde operator': 'Оператор тильда',
        Tiny: 'Очень мелкий',
        'Tip: Find some text first in order to replace it.': 'Совет: сначала найдите текст, чтобы заменить его.',
        'Tip: Paste the URL into the content to embed faster.': 'Подсказка: Вставьте URL в контент для быстрого включения.',
        'To-do List': 'Список задач',
        'Toggle caption off': 'Выключить описание',
        'Toggle caption on': 'Включить описание',
        'Toggle the circle list style': 'Переключить на стиль списка с маркерами в виде незакрашенного кружка',
        'Toggle the decimal list style': 'Переключить на стиль списка с цифровыми маркерами',
        'Toggle the decimal with leading zero list style': 'Переключить на стиль списка с маркерами в виде цифр с нулем в начале',
        'Toggle the disc list style': 'Переключить на стиль списка с маркерами в виде закрашенного кружка',
        'Toggle the lower–latin list style': 'Переключить на стиль списка с маркерами в виде строчных латинских букв',
        'Toggle the lower–roman list style': 'Переключить на стиль списка с маркерами в виде строчных римских цифр',
        'Toggle the square list style': 'Переключить на стиль списка с квадратными маркерами',
        'Toggle the upper–latin list style': 'Переключить на стиль списка с маркерами в виде заглавных латинских букв',
        'Toggle the upper–roman list style': 'Переключить на стиль списка с маркерами в виде заглавных римских цифр',
        'top with upwards arrow above': 'Стрелка вверх над словом TOP (верх)',
        'Trade mark sign': 'Знак торговой марки',
        'Tugrik sign': 'Символ тугрика',
        'Turkish lira sign': 'Символ турецкой лиры',
        Turquoise: 'Бирюзовый',
        'Two dot leader': 'Двухточечный пунктир',
        'Type or paste your content here.': 'Введите или вставьте сюда ваш текст',
        'Type your title': 'Введите заголовок',
        Underline: 'Подчеркнутый',
        'Underline text': 'Подчеркнутый текст',
        Undo: 'Отменить',
        Union: 'Объединение',
        Unlink: 'Убрать ссылку',
        'up down arrow with base': 'Стрелка вверх и вниз от планки внизу',
        Update: 'Изменить',
        'Update image URL': 'Изменить URL изображения',
        'Upload failed': 'Загрузка не выполнена',
        'Upload from computer': 'Загрузить с компьютера',
        'Upload image from computer': 'Загрузить изображение с компьютера',
        'Upload in progress': 'Идёт загрузка',
        'Uploading image': 'Загрузка изображения',
        'Upper-latin': 'Большие латинские',
        'Upper-roman': 'Большие римские',
        'upwards arrow to bar': 'Стрелка вверх, упирающаяся в планку',
        'upwards dashed arrow': 'Пунктирная стрелка вверх',
        'upwards double arrow': 'Двойная стрелка вверх',
        'upwards simple arrow': 'простая стрелка вверх',
        'Use the following keystrokes for more efficient navigation in the CKEditor 5 user interface.':
            'Использовать следующие сочетания клавиш для более эффективной навигации в пользовательском интерфейсе CKEditor 5.',
        'User interface and content navigation keystrokes': 'Пользовательский интерфейс и клавиши навигации по контенту',
        'Vertical text alignment toolbar': 'Панель инструментов вертикального выравнивания текста',
        'Vulgar fraction one half': 'Дробь – одна вторая',
        'Vulgar fraction one quarter': 'Дробь – одна четверть',
        'Vulgar fraction three quarters': 'Дробь – три четверти',
        White: 'Белый',
        'Whole words only': 'Только слова целиком',
        'Widget toolbar': 'Панель инструментов виджета',
        Width: 'Ширина',
        'Won sign': 'Символ воны',
        'Words: %0': 'Слов: %0',
        'Wrap text': 'Обтекать текст',
        Yellow: 'Жёлтый',
        'Yellow marker': 'Выделение жёлтым маркером',
        'Yen sign': 'Символ иены',
    });
    e.getPluralForm = function (t) {
        return t % 10 == 1 && t % 100 != 11
            ? 0
            : t % 10 >= 2 && t % 10 <= 4 && (t % 100 < 12 || t % 100 > 14)
              ? 1
              : t % 10 == 0 || (t % 10 >= 5 && t % 10 <= 9) || (t % 100 >= 11 && t % 100 <= 14)
                ? 2
                : 3;
    };
})(window.CKEDITOR_TRANSLATIONS || (window.CKEDITOR_TRANSLATIONS = {}));
