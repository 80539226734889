import DrukSnackbarContainer from '@common/components/druk/DrukSnackbarContainer';

const snackbar = {
    install(Vue, args = {}) {
        this.bus = new Vue();

        Vue.component('druk-snackbar-container', DrukSnackbarContainer);

        Vue.prototype.$snackbar = {
            show(args) {
                snackbar.bus.$emit('show', args);
            },

            close() {
                snackbar.bus.$emit('close');
            },
        };
    },
};

export default snackbar;
