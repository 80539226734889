(function (a) {
    const e = (a['pl'] = a['pl'] || {});
    e.dictionary = Object.assign(e.dictionary || {}, {
        '(may require <kbd>Fn</kbd>)': '(może wymagać użycia klawisza <kbd>Fn</kbd>)',
        '%0 of %1': '%0 z %1',
        Accept: 'Zaakceptuj',
        Accessibility: 'Dostępność',
        'Accessibility help': 'Pomoc dotycząca dostępności',
        'Advanced options': 'Opcje zaawansowane',
        'Align cell text to the bottom': 'Wyrównaj tekst w komórce do dołu',
        'Align cell text to the center': 'Wyrównaj tekst w komórce do środka',
        'Align cell text to the left': 'Wyrównaj tekst w komórce do lewej',
        'Align cell text to the middle': 'Wyrównaj tekst w komórce do środka',
        'Align cell text to the right': 'Wyrównaj tekst w komórce do prawej',
        'Align cell text to the top': 'Wyrównaj tekst w komórce do góry',
        'Align center': 'Wyrównaj do środka',
        'Align left': 'Wyrównaj do lewej',
        'Align right': 'Wyrównaj do prawej',
        'Align table to the left': 'Wyrównaj tabelę do lewej',
        'Align table to the right': 'Wyrównaj tabelę do prawej',
        Alignment: 'Wyrównanie',
        All: 'Wszystko',
        'Almost equal to': 'Prawie równe',
        Angle: 'Kąt',
        'Approximately equal to': 'W przybliżeniu równe',
        Aquamarine: 'Akwamaryna',
        Arrows: 'Strzałki',
        'Asterisk operator': 'Operator asterysk',
        'Austral sign': 'Znak australa',
        'back with leftwards arrow above': 'do tyłu ze strzałką w lewo powyżej',
        Background: 'Tło',
        'Below, you can find a list of keyboard shortcuts that can be used in the editor.':
            'Poniżej znajdziesz listę skrótów klawiszowych, których można używać w edytorze.',
        Big: 'Duży',
        'Bitcoin sign': 'Znak bitcoina',
        Black: 'Czarny',
        'Block quote': 'Cytat blokowy',
        'Block styles': 'Style tekstu blokowego',
        Blue: 'Niebieski',
        'Blue marker': 'Niebieski marker',
        Bold: 'Pogrubienie',
        'Bold text': 'Pogrubia tekst',
        Border: 'Obramowanie',
        'Break text': 'Rozbijaj tekst',
        'Bulleted List': 'Lista wypunktowana',
        'Bulleted list styles toolbar': 'Pasek z narzędziami: style listy z punktorami',
        Cancel: 'Anuluj',
        'Caption for image: %0': 'Nagłówek do obrazu: %0',
        'Caption for the image': 'Nagłówek do obrazu',
        'Cedi sign': 'Znak cedi',
        'Cell properties': 'Właściwości komórki',
        'Cent sign': 'Znak centa',
        'Center table': 'Wyrównaj tabelę do środka',
        'Centered image': 'Obraz wyrównany do środka',
        'Change image text alternative': 'Zmień tekst zastępczy obrazka',
        'Character categories': 'Kategorie znaków',
        'Characters: %0': 'Znaki: %0',
        'Choose heading': 'Wybierz nagłówek',
        Circle: 'Kółko',
        Clear: 'Wyczyść',
        'Click to edit block': 'Kliknij, aby edytować blok',
        Close: 'Zamknij',
        'Close contextual balloons, dropdowns, and dialogs': 'Zamyka podpowiedzi kontekstowe, menu rozwijane i okna dialogowe',
        Code: 'Kod',
        'Code block': 'Blok kodu',
        'Colon sign': 'Znak colona',
        Color: 'Kolor',
        'Color picker': 'Wybór koloru',
        Column: 'Kolumna',
        'Contains as member': 'Zawiera element',
        'Content editing keystrokes': 'Klawisze edycji zawartości',
        'Copy selected content': 'Kopiuje zaznaczoną zawartość',
        'Copyright sign': 'Znak praw autorskich',
        'Create link': 'Tworzy link',
        'Cruzeiro sign': 'Znak cruzeiro',
        Currency: 'Waluta',
        'Currency sign': 'Znak waluty',
        Custom: 'Niestandardowy',
        'Custom image size': 'Niestandardowy rozmiar obrazu',
        Dashed: 'Kreskowane',
        Decimal: 'Dziesiętne',
        'Decimal with leading zero': 'Dziesiętne z zerem wiodącym',
        'Decrease indent': 'Zmniejsz wcięcie',
        'Decrease list item indent': 'Zmniejsza wcięcie elementu listy',
        Default: 'Domyślny',
        'Degree sign': 'Znak stopnia',
        'Delete column': 'Usuń kolumnę',
        'Delete row': 'Usuń wiersz',
        'Dim grey': 'Ciemnoszary',
        Dimensions: 'Wymiary',
        'Disable editing': 'Zablokuj edycję',
        Disc: 'Dysk',
        'Division sign': 'Znak dzielenia',
        'Document colors': 'Kolory dokumentu',
        'Dollar sign': 'Znak dolara',
        'Dong sign': 'Znak donga',
        Dotted: 'Kropkowane',
        Double: 'Podwójne',
        'Double dagger': 'Podwójny sztylet',
        'Double exclamation mark': 'Podwójny wykrzyknik',
        'Double low-9 quotation mark': 'Podwójny dolny cudzysłów',
        'Double question mark': 'Podwójny pytajnik',
        Downloadable: 'Do pobrania',
        'downwards arrow to bar': 'strzałka w dół do belki',
        'downwards dashed arrow': 'przerywana strzałka w dół',
        'downwards double arrow': 'podwójna strzałka w dół',
        'downwards simple arrow': 'prosta strzałka w dół',
        'Drachma sign': 'Znak drachmy',
        'Drag to move': 'Przeciągnij, aby przenieść',
        'Dropdown toolbar': 'Rozwijany pasek narzędzi',
        'Edit block': 'Edytuj blok',
        'Edit link': 'Edytuj odnośnik',
        'Editor block content toolbar': 'Pasek zadań treści blokowej edytora',
        'Editor contextual toolbar': 'Kontekstowy pasek zadań edytora',
        'Editor dialog': 'Okno edytora',
        'Editor editing area: %0': 'Obszar edycji edytora: %0',
        'Editor menu bar': 'Pasek menu edytora',
        'Editor toolbar': 'Pasek narzędzi edytora',
        'Element of': 'Należy do',
        'Em dash': 'Pauza',
        'Empty set': 'Zbiór pusty',
        'En dash': 'Półpauza',
        'Enable editing': 'Odblokuj edycję',
        'end with leftwards arrow above': 'koniec ze strzałką w lewo powyżej',
        'Enter image caption': 'Wstaw tytuł obrazka',
        'Enter table caption': 'Wprowadź podpis tabeli',
        'Entering %0 code snippet': 'Wchodzenie we fragment kodu %0',
        'Entering a to-do list': 'Wchodzenie na listę zadań do wykonania',
        'Entering code snippet': 'Wchodzenie we fragment kodu',
        'Error during image upload': 'Błąd podczas przesyłania obrazu',
        'Euro sign': 'Znak euro',
        'Euro-currency sign': 'Znak euro',
        'Exclamation question mark': 'Wykrzyknik z pytajnikiem',
        'Execute the currently focused button. Executing buttons that interact with the editor content moves the focus back to the content.':
            'Uruchom aktualnie aktywny przycisk. Uruchomienie przycisków wchodzących w interakcję z zawartością edytora przywraca ukierunkowanie na zawartość.',
        Find: 'Znajdź',
        'Find and replace': 'Znajdź i zamień',
        'Find in text…': 'Znajdź…',
        'Find in the document': 'Otwiera interfejs Znajdź w dokumencie',
        'Font Background Color': 'Kolor tła czcionki',
        'Font Color': 'Kolor czcionki',
        'Font Family': 'Czcionka',
        'Font Size': 'Rozmiar czcionki',
        'For all': 'Kwantyfikator ogólny',
        'Fraction slash': 'Kreska ułamkowa',
        'French franc sign': 'Znak franka francuskiego',
        'Full size image': 'Obraz w pełnym rozmiarze',
        'German penny sign': 'Znak feniga',
        'Greater-than or equal to': 'Znak większe niż lub równe',
        'Greater-than sign': 'Znak większości',
        Green: 'Zielony',
        'Green marker': 'Zielony marker',
        'Green pen': 'Zielony długopis',
        Grey: 'Szary',
        Groove: 'Wklęsłe',
        'Guarani sign': 'Znak guarani',
        'Header column': 'Kolumna nagłówka',
        'Header row': 'Wiersz nagłówka',
        Heading: 'Nagłówek',
        'Heading 1': 'Nagłówek 1',
        'Heading 2': 'Nagłówek 2',
        'Heading 3': 'Nagłówek 3',
        'Heading 4': 'Nagłówek 4',
        'Heading 5': 'Nagłówek 5',
        'Heading 6': 'Nagłówek 6',
        Height: 'Wysokość',
        'Help Contents. To close this dialog press ESC.': 'Zawartość pomocy. Aby zamknąć to okno dialogowe, naciśnij klawisz ESC.',
        HEX: 'SZESNASTKOWY',
        Highlight: 'Podświetlenie',
        'Horizontal ellipsis': 'Wielokropek poziomy',
        'Horizontal line': 'Linia pozioma',
        'Horizontal text alignment toolbar': 'Pasek narzędzi wyrównania tekstu w poziomie',
        'Hryvnia sign': 'Znak hrywny',
        'HTML object': 'Obiekt HTML',
        Huge: 'Bardzo duży',
        'Identical to': 'Identyczne',
        'Image from computer': 'Obraz z komputera',
        'Image resize list': 'Lista: zmiana wielkości obrazu',
        'Image toolbar': 'Pasek narzędzi obrazka',
        'Image upload complete': 'Przesyłanie obrazu zakończone',
        'image widget': 'Obraz',
        'In line': 'W linii',
        'Increase indent': 'Zwiększ wcięcie',
        'Increase list item indent': 'Zwiększa wcięcie elementu listy',
        'Indian rupee sign': 'Znak rupii indyjskiej',
        Infinity: 'Nieskończoność',
        Insert: 'Wstaw',
        'Insert a hard break (a new paragraph)': 'Wstawia twardą spację (nowy akapit)',
        'Insert a new paragraph directly after a widget': 'Wstawia nowy akapit bezpośrednio po widżecie',
        'Insert a new paragraph directly before a widget': 'Wstawia nowy akapit bezpośrednio przed widżetem',
        'Insert a new table row (when in the last cell of a table)':
            'Wstawia nowy wiersz tabeli (w przypadku ostatniej komórki tabeli)',
        'Insert a soft break (a <code>&lt;br&gt;</code> element)': 'Wstawia miękki enter (element <code>&lt;br&gt;</code>',
        'Insert code block': 'Wstaw blok kodu',
        'Insert column left': 'Wstaw kolumnę z lewej',
        'Insert column right': 'Wstaw kolumnę z prawej',
        'Insert image': 'Wstaw obraz',
        'Insert image via URL': 'Wstaw obraz z adresu URL',
        'Insert media': 'Wstaw media',
        'Insert paragraph after block': 'Wstaw akapit po bloku',
        'Insert paragraph before block': 'Wstaw akapit przed blokiem',
        'Insert row above': 'Wstaw wiersz ponad',
        'Insert row below': 'Wstaw wiersz poniżej',
        'Insert table': 'Wstaw tabelę',
        Inset: 'Zapadnięte',
        Integral: 'Całka',
        Intersection: 'Część wspólna',
        'Invalid start index value.': 'Nieprawidłowa wartość indeksu początkowego.',
        'Inverted exclamation mark': 'Odwrócony wykrzyknik',
        'Inverted question mark': 'Odwrócony pytajnik',
        Italic: 'Kursywa',
        'Italic text': 'Zmienia tekst na kursywę',
        Justify: 'Wyrównaj obustronnie',
        'Justify cell text': 'Wyjustuj tekst komórki',
        'Keystrokes that can be used in a list': 'Klawisze, których można używać w odniesieniu do listy',
        'Keystrokes that can be used in a table cell': 'Klawisze, których można używać w komórce tabeli',
        'Keystrokes that can be used when a widget is selected (for example: image, table, etc.)':
            'Klawisze, których można używać po wybraniu widżetu (na przykład: obraz, tabela itd.)',
        'Kip sign': 'Znak kipa',
        Latin: 'Łaciński',
        'Latin capital letter a with breve': 'Łacińska wielka litera a z łuczkiem',
        'Latin capital letter a with macron': 'Łacińska wielka litera a z makronem',
        'Latin capital letter a with ogonek': 'Łacińska wielka litera a z ogonkiem',
        'Latin capital letter c with acute': 'Łacińska wielka litera c z kreską',
        'Latin capital letter c with caron': 'Łacińska wielka litera c z ptaszkiem',
        'Latin capital letter c with circumflex': 'Łacińska wielka litera c z daszkiem',
        'Latin capital letter c with dot above': 'Łacińska wielka litera c z kropką powyżej',
        'Latin capital letter d with caron': 'Łacińska wielka litera d z ptaszkiem',
        'Latin capital letter d with stroke': 'Łacińska wielka litera d z przekreśleniem',
        'Latin capital letter e with breve': 'Łacińska wielka litera e z łuczkiem',
        'Latin capital letter e with caron': 'Łacińska wielka litera e z ptaszkiem',
        'Latin capital letter e with dot above': 'Łacińska wielka litera e z kropką powyżej',
        'Latin capital letter e with macron': 'Łacińska wielka litera e z makronem',
        'Latin capital letter e with ogonek': 'Łacińska wielka litera e z ogonkiem',
        'Latin capital letter eng': 'Łacińska wielka litera eng',
        'Latin capital letter g with breve': 'Łacińska wielka litera g z łuczkiem',
        'Latin capital letter g with cedilla': 'Łacińska wielka litera g z haczykiem',
        'Latin capital letter g with circumflex': 'Łacińska wielka litera g z daszkiem',
        'Latin capital letter g with dot above': 'Łacińska wielka litera g z kropką powyżej',
        'Latin capital letter h with circumflex': 'Łacińska wielka litera h z daszkiem',
        'Latin capital letter h with stroke': 'Łacińska wielka litera h z przekreśleniem',
        'Latin capital letter i with breve': 'Łacińska wielka litera i z łuczkiem',
        'Latin capital letter i with dot above': 'Łacińska wielka litera i z kropką powyżej',
        'Latin capital letter i with macron': 'Łacińska wielka litera i z makronem',
        'Latin capital letter i with ogonek': 'Łacińska wielka litera i z ogonkiem',
        'Latin capital letter i with tilde': ' Łacińska wielka litera i z tyldą',
        'Latin capital letter j with circumflex': 'Łacińska wielka litera j z daszkiem',
        'Latin capital letter k with cedilla': 'Łacińska wielka litera k z haczykiem',
        'Latin capital letter l with acute': 'Łacińska wielka litera l z kreską',
        'Latin capital letter l with caron': 'Łacińska wielka litera l z ptaszkiem',
        'Latin capital letter l with cedilla': 'Łacińska wielka litera l z haczykiem',
        'Latin capital letter l with middle dot': 'Łacińska wielka litera l z kropką pośrodku',
        'Latin capital letter l with stroke': 'Łacińska wielka litera l z przekreśleniem',
        'Latin capital letter n with acute': 'Łacińska wielka litera n z kreską',
        'Latin capital letter n with caron': 'Łacińska wielka litera n z ptaszkiem',
        'Latin capital letter n with cedilla': 'Łacińska wielka litera n z haczykiem',
        'Latin capital letter o with breve': 'Łacińska wielka litera o z łuczkiem',
        'Latin capital letter o with double acute': 'Łacińska wielka litera o z dwiema kreskami',
        'Latin capital letter o with macron': 'Łacińska wielka litera o z makronem',
        'Latin capital letter r with acute': 'Łacińska wielka litera r z kreską',
        'Latin capital letter r with caron': 'Łacińska wielka litera r z ptaszkiem',
        'Latin capital letter r with cedilla': 'Łacińska wielka litera r z haczykiem',
        'Latin capital letter s with acute': 'Łacińska wielka litera s z kreską',
        'Latin capital letter s with caron': 'Łacińska wielka litera s z ptaszkiem',
        'Latin capital letter s with cedilla': 'Łacińska wielka litera s z haczykiem',
        'Latin capital letter s with circumflex': 'Łacińska wielka litera s z daszkiem',
        'Latin capital letter t with caron': 'Łacińska wielka litera t z ptaszkiem',
        'Latin capital letter t with cedilla': 'Łacińska wielka litera t z haczykiem',
        'Latin capital letter t with stroke': 'Łacińska wielka litera t z przekreśleniem',
        'Latin capital letter u with breve': 'Łacińska wielka litera u z łuczkiem',
        'Latin capital letter u with double acute': 'Łacińska wielka litera u z dwiema kreskami',
        'Latin capital letter u with macron': 'Łacińska wielka litera u z makronem',
        'Latin capital letter u with ogonek': 'Łacińska wielka litera u z ogonkiem',
        'Latin capital letter u with ring above': 'Łacińska wielka litera u z kółkiem powyżej',
        'Latin capital letter u with tilde': 'Łacińska wielka litera u z tyldą',
        'Latin capital letter w with circumflex': 'Łacińska wielka litera w z daszkiem',
        'Latin capital letter y with circumflex': 'Łacińska wielka litera y z daszkiem',
        'Latin capital letter y with diaeresis': 'Łacińska wielka litera y z dwiema kropkami',
        'Latin capital letter z with acute': 'Łacińska wielka litera z z kreską',
        'Latin capital letter z with caron': 'Łacińska wielka litera z z ptaszkiem',
        'Latin capital letter z with dot above': 'Łacińska wielka litera z z kropką powyżej',
        'Latin capital ligature ij': 'Łacińska wielka ligatura ij',
        'Latin capital ligature oe': 'Łacińska wielka ligatura oe',
        'Latin small letter a with breve': 'Łacińska mała litera a z łuczkiem',
        'Latin small letter a with macron': 'Łacińska mała litera a z makronem',
        'Latin small letter a with ogonek': 'Łacińska mała litera a z ogonkiem',
        'Latin small letter c with acute': 'Łacińska mała litera c z kreską',
        'Latin small letter c with caron': 'Łacińska mała litera c z ptaszkiem',
        'Latin small letter c with circumflex': 'Łacińska mała litera c z daszkiem',
        'Latin small letter c with dot above': 'Łacińska mała litera c z kropką powyżej',
        'Latin small letter d with caron': 'Łacińska mała litera d z ptaszkiem',
        'Latin small letter d with stroke': 'Łacińska mała litera d z przekreśleniem',
        'Latin small letter dotless i': 'Łacińska mała litera i bez kropki',
        'Latin small letter e with breve': 'Łacińska mała litera e z łuczkiem',
        'Latin small letter e with caron': 'Łacińska mała litera e z ptaszkiem',
        'Latin small letter e with dot above': 'Łacińska mała litera e z kropką powyżej',
        'Latin small letter e with macron': 'Łacińska mała litera e z makronem',
        'Latin small letter e with ogonek': 'Łacińska mała litera e z ogonkiem',
        'Latin small letter eng': 'Łacińska mała litera eng',
        'Latin small letter f with hook': 'Łacińska mała litera f z zawijasem',
        'Latin small letter g with breve': 'Łacińska mała litera g z łuczkiem',
        'Latin small letter g with cedilla': 'Łacińska mała litera g z haczykiem',
        'Latin small letter g with circumflex': 'Łacińska mała litera g z daszkiem',
        'Latin small letter g with dot above': 'Łacińska mała litera g z kropką powyżej',
        'Latin small letter h with circumflex': 'Łacińska mała litera h z daszkiem',
        'Latin small letter h with stroke': 'Łacińska mała litera h z przekreśleniem',
        'Latin small letter i with breve': 'Łacińska mała litera i z łuczkiem',
        'Latin small letter i with macron': 'Łacińska mała litera i z makronem',
        'Latin small letter i with ogonek': 'Łacińska mała litera i z ogonkiem',
        'Latin small letter i with tilde': 'Łacińska mała litera i z tyldą',
        'Latin small letter j with circumflex': 'Łacińska mała litera j z daszkiem',
        'Latin small letter k with cedilla': 'Łacińska mała litera k z haczykiem',
        'Latin small letter kra': 'Łacińska mała litera kra',
        'Latin small letter l with acute': 'Łacińska mała litera l z kreską',
        'Latin small letter l with caron': 'Łacińska mała litera l z ptaszkiem',
        'Latin small letter l with cedilla': 'Łacińska mała litera l z haczykiem',
        'Latin small letter l with middle dot': 'Łacińska mała litera l z kropką pośrodku',
        'Latin small letter l with stroke': 'Łacińska mała litera l z przekreśleniem',
        'Latin small letter long s': 'Łacińska litera długie s',
        'Latin small letter n preceded by apostrophe': 'Łacińska mała litera n poprzedzona apostrofem',
        'Latin small letter n with acute': 'Łacińska mała litera n z kreską',
        'Latin small letter n with caron': 'Łacińska mała litera n z ptaszkiem',
        'Latin small letter n with cedilla': 'Łacińska mała litera n z haczykiem',
        'Latin small letter o with breve': 'Łacińska mała litera o z łuczkiem',
        'Latin small letter o with double acute': 'Łacińska mała litera o z dwiema kreskami',
        'Latin small letter o with macron': 'Łacińska mała litera o z makronem',
        'Latin small letter r with acute': 'Łacińska mała litera r z kreską',
        'Latin small letter r with caron': 'Łacińska mała litera r z ptaszkiem',
        'Latin small letter r with cedilla': 'Łacińska mała litera r z haczykiem',
        'Latin small letter s with acute': 'Łacińska mała litera s z kreską',
        'Latin small letter s with caron': 'Łacińska mała litera s z ptaszkiem',
        'Latin small letter s with cedilla': 'Łacińska wielka litera s z haczykiem',
        'Latin small letter s with circumflex': 'Łacińska mała litera s z daszkiem',
        'Latin small letter t with caron': 'Łacińska mała litera t z ptaszkiem',
        'Latin small letter t with cedilla': 'Łacińska mała litera t z haczykiem',
        'Latin small letter t with stroke': 'Łacińska mała litera t z przekreśleniem',
        'Latin small letter u with breve': 'Łacińska mała litera u z łuczkiem',
        'Latin small letter u with double acute': 'Łacińska mała litera u z dwiema kreskami',
        'Latin small letter u with macron': 'Łacińska mała litera u z makronem',
        'Latin small letter u with ogonek': 'Łacińska mała litera u z ogonkiem',
        'Latin small letter u with ring above': 'Łacińska mała litera u z kółkiem powyżej',
        'Latin small letter u with tilde': 'Łacińska mała litera u z tyldą',
        'Latin small letter w with circumflex': 'Łacińska mała litera w z daszkiem',
        'Latin small letter y with circumflex': 'Łacińska mała litera y z daszkiem',
        'Latin small letter z with acute': 'Łacińska mała litera z z kreską',
        'Latin small letter z with caron': 'Łacińska mała litera z z ptaszkiem',
        'Latin small letter z with dot above': 'Łacińska mała litera z z kropką powyżej',
        'Latin small ligature ij': 'Łacińska mała ligatura ij',
        'Latin small ligature oe': 'Łacińska mała ligatura oe',
        'Leaving %0 code snippet': 'Opuszczenie fragmentu kodu %0',
        'Leaving a to-do list': 'Opuszczenie listy zadań do wykonania',
        'Leaving code snippet': 'Opuszczanie fragmentu kodu',
        'Left aligned image': 'Obraz wyrównany do lewej',
        'Left double quotation mark': 'Podwójny lewy cudzysłów',
        'Left single quotation mark': 'Pojedynczy lewy cudzysłów',
        'Left-pointing double angle quotation mark': 'Podwójny lewy cudzysłów kątowy',
        'leftwards arrow to bar': 'strzałka w lewo do belki',
        'leftwards dashed arrow': 'przerywana strzałka w lewo',
        'leftwards double arrow': 'podwójna strzałka w lewo',
        'leftwards simple arrow': 'prosta strzałka w lewo',
        'Less-than or equal to': 'Znak mniejsze niż lub równe',
        'Less-than sign': 'Znak mniejszości',
        'Light blue': 'Jasnoniebieski',
        'Light green': 'Jasnozielony',
        'Light grey': 'Jasnoszary',
        Link: 'Wstaw odnośnik',
        'Link image': 'Wstaw odnośnik do obrazka',
        'Link URL': 'Adres URL',
        'Link URL must not be empty.': 'Adres URL linku nie może być pusty',
        'Lira sign': 'Znak liry',
        'List properties': 'Właściwości listy',
        'Livre tournois sign': 'Symbol liwra turońskiego',
        'Logical and': 'Koniunkcja logiczna',
        'Logical or': 'Alternatywa logiczna',
        'Lower-latin': 'Alfabet łaciński – małe litery',
        'Lower–roman': 'Małe cyfry rzymskie',
        Macron: 'Makron',
        'Manat sign': 'Znak manata',
        'Match case': 'Uwzględnij wielkość liter',
        Mathematical: 'Matematyczne',
        'Media toolbar': 'Pasek narzędzi mediów',
        'Media URL': 'Adres URL',
        'media widget': 'widget osadzenia mediów',
        MENU_BAR_MENU_EDIT: 'Zmiana',
        MENU_BAR_MENU_FILE: 'Plik',
        MENU_BAR_MENU_FONT: 'Czcionka',
        MENU_BAR_MENU_FORMAT: 'Format',
        MENU_BAR_MENU_HELP: 'Pomoc',
        MENU_BAR_MENU_INSERT: 'Wstaw',
        MENU_BAR_MENU_TEXT: 'Tekst',
        MENU_BAR_MENU_TOOLS: 'Narzędzia',
        MENU_BAR_MENU_VIEW: 'Zobacz',
        'Merge cell down': 'Scal komórkę w dół',
        'Merge cell left': 'Scal komórkę w lewo',
        'Merge cell right': 'Scal komórkę w prawo',
        'Merge cell up': 'Scal komórkę w górę',
        'Merge cells': 'Scal komórki',
        'Mill sign': 'Symbol mila',
        'Minus sign': 'Znak minus',
        'Move focus between form fields (inputs, buttons, etc.)':
            'Przenosi fokus pomiędzy polami formularza (polami wprowadzania, przyciskami itd.)',
        'Move focus in and out of an active dialog window': 'Przenosi fokus do i z aktywnego okna dialogowego',
        'Move focus to the menu bar, navigate between menu bars': 'Zmień ukierunkowanie na pasek menu, nawiguj między paskami menu',
        'Move focus to the toolbar, navigate between toolbars':
            'Przenosi fokus na pasek narzędzi, umożliwia poruszanie się pomiędzy paskami narzędzi',
        'Move out of a link': 'Umożliwia wyjście z linku',
        'Move out of an inline code style': 'Przenosi zaznaczenie poza styl kodu inline',
        'Move the caret to allow typing directly after a widget': 'Przenosi kursor, aby umożliwić pisanie bezpośrednio za widżetem',
        'Move the caret to allow typing directly before a widget':
            'Przenosi kursor, aby umożliwić pisanie bezpośrednio przed widżetem',
        'Move the selection to the next cell': 'Przenosi zaznaczenie do następnej komórki',
        'Move the selection to the previous cell': 'Przenosi zaznaczenie do poprzedniej komórki',
        'Multiple styles': 'Wiele stylów',
        'Multiplication sign': 'Znak mnożenia',
        'N-ary product': 'Iloczyn n-argumentowy',
        'N-ary summation': 'Suma n-argumentowa',
        Nabla: 'Operator nabla',
        'Naira sign': 'Znak nairy',
        'Navigate editable regions': 'Przejdź między polami do edycji',
        'Navigate through the table': 'Umożliwia poruszanie się po tabeli',
        'Navigate through the toolbar or menu bar': 'Nawiguj za pomocą paska narzędzi lub paska menu',
        'New sheqel sign': 'Znak nowego szekla',
        Next: 'Następny',
        'Next editable region': 'Następne pole do edycji',
        'Next result': 'Następny',
        'No results found': 'Nie znaleziono wyników',
        'No searchable items': 'Brak elementów do wyszukania',
        None: 'Brak',
        'Nordic mark sign': 'Znak marki nordyckiej',
        'Not an element of': 'Nie należy do',
        'Not equal to': 'Różne',
        'Not sign': 'Znak negacji',
        'Numbered List': 'Lista numerowana',
        'Numbered list styles toolbar': 'Pasek z narzędziami: style listy numerowanej',
        'on with exclamation mark with left right arrow above': 'na z wykrzyknikiem i strzałką w lewo i prawo powyżej',
        'Open in a new tab': 'Otwórz w nowej zakładce',
        'Open link in new tab': 'Otwórz odnośnik w nowej zakładce',
        'Open media in new tab': 'Otwórz media w nowej zakładce',
        'Open the accessibility help dialog': 'Otwiera okno pomocy dotyczącej dostępności',
        Orange: 'Pomarańczowy',
        Original: 'Oryginalny',
        Outset: 'Wysunięte',
        Overline: 'Nadkreślenie',
        Padding: 'Dopełnienie',
        'Page break': 'Podział strony',
        Paragraph: 'Akapit',
        'Paragraph sign': 'Znak akapitu',
        'Partial differential': 'Pochodna cząstkowa',
        'Paste content': 'Wkleja zawartość',
        'Paste content as plain text': 'Wkleja zawartość jako zwykły tekst',
        'Paste the media URL in the input.': 'Wklej adres URL mediów do pola.',
        'Per mille sign': 'Znak promila',
        'Per ten thousand sign': 'Punkt bazowy',
        'Peseta sign': 'Znak pesety',
        'Peso sign': 'Znak peso',
        'Pink marker': 'Różowy marker',
        'Plain text': 'Zwykły tekst',
        'Please enter a valid color (e.g. "ff0000").': 'Wprowadź prawidłowy kolor (np. „ff0000”).',
        'Plus-minus sign': 'Znak plus-minus',
        'Pound sign': 'Znak funta',
        'Press %0 for help.': 'Naciśnij %0, aby uzyskać pomoc.',
        'Press Enter to type after or press Shift + Enter to type before the widget':
            'Naciśnij Enter, aby pisać po widżecie, lub Shift + Enter, aby pisać przed widżetem',
        Previous: 'Poprzedni',
        'Previous editable region': 'Poprzednie pole do edycji',
        'Previous result': 'Poprzedni',
        'Proportional to': 'Proporcjonalność',
        Purple: 'Purpurowy',
        'Question exclamation mark': 'Pytajnik z wykrzyknikiem',
        Red: 'Czerwony',
        'Red pen': 'Czerwony długopis',
        Redo: 'Ponów',
        'Registered sign': 'Zastrzeżony znak towarowy',
        'Remove color': 'Usuń kolor',
        'Remove Format': 'Usuń formatowanie',
        'Remove highlight': 'Usuń podświetlenie',
        Replace: 'Zamień',
        'Replace all': 'Zamień wszystko',
        'Replace from computer': 'Zastąp z komputera',
        'Replace image': 'Zastąp obraz',
        'Replace image from computer': 'Zastąp obraz z komputera',
        'Replace with…': 'Zamień na…',
        'Resize image': 'Zmień rozmiar obrazka',
        'Resize image (in %0)': 'Zmień rozmiar obrazu (w %0)',
        'Resize image to %0': 'Zmień rozmiar do %0',
        'Resize image to the original size': 'Przywróć oryginalny rozmiar obrazu',
        'Restore default': 'Przywróć domyślne',
        'Reversed order': 'Odwrócona kolejność',
        'Reversed paragraph sign': 'Odwrócony znak akapitu',
        'Revert autoformatting action': 'Cofa automatyczne formatowanie',
        'Rich Text Editor': 'Edytor tekstu sformatowanego',
        Ridge: 'Wypukłe',
        'Right aligned image': 'Obraz wyrównany do prawej',
        'Right double quotation mark': 'Podwójny prawy cudzysłów',
        'Right single quotation mark': 'Pojedynczy prawy cudzysłów',
        'Right-pointing double angle quotation mark': 'Podwójny prawy cudzysłów kątowy',
        'rightwards arrow to bar': 'strzałka w prawo do belki',
        'rightwards dashed arrow': 'przerywana strzałka w prawo',
        'rightwards double arrow': 'podwójna strzałka w prawo',
        'rightwards simple arrow': 'prosta strzałka w prawo',
        Row: 'Wiersz',
        'Ruble sign': 'Znak rubla',
        'Rupee sign': 'Znak rupii',
        Save: 'Zapisz',
        'Section sign': 'Znak sekcji',
        'Select all': 'Zaznacz wszystko',
        'Select column': 'Zaznacz kolumnę',
        'Select row': 'Zaznacz wiersz',
        'Show blocks': 'Wyświetl bloki',
        'Show more items': 'Pokaż więcej',
        'Show source': 'Pokaż źródło',
        'Side image': 'Obraz dosunięty do brzegu, oblewany tekstem',
        'Single left-pointing angle quotation mark': 'Pojedynczy lewy cudzysłów kątowy',
        'Single low-9 quotation mark': 'Pojedynczy dolny cudzysłów',
        'Single right-pointing angle quotation mark': 'Pojedynczy prawy cudzysłów kątowy',
        Small: 'Mały',
        Solid: 'Ciągłe',
        'soon with rightwards arrow above': 'wkrótce ze strzałką w prawo powyżej',
        Source: 'Źródło',
        'Special characters': 'Znaki specjalne',
        'Spesmilo sign': 'Symbol spesmilo',
        'Split cell horizontally': 'Podziel komórkę poziomo',
        'Split cell vertically': 'Podziel komórkę pionowo',
        Square: 'Kwadrat',
        'Square root': 'Pierwiastek kwadratowy',
        'Start at': 'Zacznij od',
        'Start index must be greater than 0.': 'Wartość początkowa musi być większa niż 0.',
        Strikethrough: 'Przekreślenie',
        'Strikethrough text': 'Przekreśla tekst',
        Style: 'Styl',
        Styles: 'Style',
        Subscript: 'Indeks dolny',
        Superscript: 'Indeks górny',
        Table: 'Tabela',
        'Table alignment toolbar': 'Pasek narzędzi wyrównania tabeli',
        'Table cell text alignment': 'Wyrównanie tekstu komórki tabeli',
        'Table properties': 'Właściwości tabeli',
        'Table toolbar': 'Pasek narzędzi tabel',
        'Tenge sign': 'Znak tenge',
        Text: 'Tekst',
        'Text alignment': 'Wyrównanie tekstu',
        'Text alignment toolbar': 'Pasek narzędzi wyrównania tekstu',
        'Text alternative': 'Tekst zastępczy obrazka',
        'Text highlight toolbar': 'Pasek narzędzi podświetleń',
        'Text styles': 'Style tekstu',
        'Text to find must not be empty.': 'Szukany tekst nie może być pusty.',
        'The color is invalid. Try "#FF0000" or "rgb(255,0,0)" or "red".':
            'Kolor jest niepoprawny. Spróbuj wpisać "#FF0000", "rgb(255,0,0)" lub "red".',
        'The URL must not be empty.': 'Adres URL nie może być pusty.',
        'The value is invalid. Try "10px" or "2em" or simply "2".':
            'Wartość jest niepoprawna. Spróbuj  wpisać "10px", "2em" lub po prostu "2".',
        'The value must not be empty.': 'Wartość nie może być pusta.',
        'The value should be a plain number.': 'Wartość powinna zawierać tylko liczbę.',
        'There exists': 'Kwantyfikator szczegółowy',
        'These keyboard shortcuts allow for quick access to content editing features.':
            'Te skróty klawiszowe umożliwiają szybki dostęp do funkcji edycji zawartości.',
        'This link has no URL': 'Nie podano adresu URL odnośnika',
        'This media URL is not supported.': 'Ten rodzaj adresu URL nie jest obsługiwany.',
        'Tilde operator': 'Operator tylda',
        Tiny: 'Bardzo mały',
        'Tip: Find some text first in order to replace it.': 'Podpowiedź: Znajdź jakiś tekst, aby go zamienić.',
        'Tip: Paste the URL into the content to embed faster.': 'Wskazówka: Wklej URL do treści edytora, by łatwiej osadzić media.',
        'To-do List': 'Lista rzeczy do zrobienia',
        'Toggle caption off': 'Ukryj podpis tabeli',
        'Toggle caption on': 'Pokaż podpis tabeli',
        'Toggle the circle list style': 'Włącz/wyłącz listę w stylu „kółko”',
        'Toggle the decimal list style': 'Włącz/wyłącz listę w stylu „dziesiętne”',
        'Toggle the decimal with leading zero list style': 'Włącz/wyłącz listę w stylu „dziesiętne z zerem wiodącym”',
        'Toggle the disc list style': 'Włącz/wyłącz listę w stylu „dysk”',
        'Toggle the lower–latin list style': 'Włącz/wyłącz listę w stylu „alfabet łaciński – małe litery”',
        'Toggle the lower–roman list style': 'Włącz/wyłącz listę w stylu „małe cyfry rzymskie”',
        'Toggle the square list style': 'Włącz/wyłącz listę w stylu „kwadrat”',
        'Toggle the upper–latin list style': 'Włącz/wyłącz listę w stylu „alfabet łaciński – wielkie litery”',
        'Toggle the upper–roman list style': 'Włącz/wyłącz listę w stylu „wielkie cyfry rzymskie”',
        'top with upwards arrow above': 'do góry ze strzałką w górę powyżej',
        'Trade mark sign': 'Symbol znaku towarowego',
        'Tugrik sign': 'Znak tugrika',
        'Turkish lira sign': 'Znak liry tureckiej',
        Turquoise: 'Turkusowy',
        'Two dot leader': 'Dwie kropki wiodące',
        'Type or paste your content here.': 'Wpisz lub wklej tutaj treść dokumentu.',
        'Type your title': 'Podaj tytuł',
        Underline: 'Podkreślenie',
        'Underline text': 'Podkreśla tekst',
        Undo: 'Cofnij',
        Union: 'Suma zbiorów',
        Unlink: 'Usuń odnośnik',
        'up down arrow with base': 'strzałka w górę i w dół z podstawą',
        Update: 'Zaktualizuj',
        'Update image URL': 'Uaktualnij obraz z adresu URL',
        'Upload failed': 'Przesyłanie obrazu nie powiodło się',
        'Upload from computer': 'Prześlij z komputera',
        'Upload image from computer': 'Prześlij obraz z komputera',
        'Upload in progress': 'Trwa przesyłanie',
        'Uploading image': 'Trwa przesyłanie obrazu',
        'Upper-latin': 'Alfabet łaciński – wielkie litery',
        'Upper-roman': 'Wielkie cyfry rzymskie',
        'upwards arrow to bar': 'strzałka w górę do belki',
        'upwards dashed arrow': 'przerywana strzałka w górę',
        'upwards double arrow': 'podwójna strzałka w górę',
        'upwards simple arrow': 'prosta strzałka w górę',
        'Use the following keystrokes for more efficient navigation in the CKEditor 5 user interface.':
            'Aby łatwiej poruszać się po interfejsie użytkownika CKEditor 5, użyj następujących skrótów klawiszowych.',
        'User interface and content navigation keystrokes':
            'Klawisze umożliwiające poruszanie się po interfejsie użytkownika i zawartości',
        'Vertical text alignment toolbar': 'Pasek narzędzi wyrównania tekstu w pionie',
        'Vulgar fraction one half': 'Ułamek zwykły jedna druga',
        'Vulgar fraction one quarter': 'Ułamek zwykły jedna czwarta',
        'Vulgar fraction three quarters': 'Ułamek zwykły trzy czwarte',
        White: 'Biały',
        'Whole words only': 'Znajdź tylko całe wyrazy',
        'Widget toolbar': 'Pasek widgetów',
        Width: 'Szerokość',
        'Won sign': 'Znak wona',
        'Words: %0': 'Słowa: %0',
        'Wrap text': 'Zawijaj tekst',
        Yellow: 'Żółty',
        'Yellow marker': 'Żółty marker',
        'Yen sign': 'Znak jena',
    });
    e.getPluralForm = function (a) {
        return a == 1
            ? 0
            : a % 10 >= 2 && a % 10 <= 4 && (a % 100 < 12 || a % 100 > 14)
              ? 1
              : (a != 1 && a % 10 >= 0 && a % 10 <= 1) || (a % 10 >= 5 && a % 10 <= 9) || (a % 100 >= 12 && a % 100 <= 14)
                ? 2
                : 3;
    };
})(window.CKEDITOR_TRANSLATIONS || (window.CKEDITOR_TRANSLATIONS = {}));
