var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"formula__item",class:{
        'has-cursor': _vm.hasCursorToTheRight || _vm.hasCursorToTheLeft,
        'has-cursor-to-the-right': _vm.hasCursorToTheRight,
        'has-cursor-to-the-left': _vm.hasCursorToTheLeft,
        'has-empty': _vm.isEmpty,
        'has-shown-empty': _vm.isEmpty && !_vm.hasHiddenEmptyCursor,
    },attrs:{"id":_vm.index},on:{"mouseenter":_vm.onHideEmptyCursor,"mousemove":_vm.onStartInteraction,"mouseleave":_vm.onStopInteraction}},[(!_vm.isEmpty)?_c('div',{staticClass:"formula__cursor formula__cursor--left",class:{ 'has-active': _vm.isActive },on:{"click":_vm.onSetCursor}}):_vm._e(),(!_vm.isEmpty)?_c('div',{ref:!_vm.isMaskItem ? 'layout' : '',staticClass:"formula__layout",attrs:{"id":_vm.isMaskItem ? 'layout' : ''},on:{"contextmenu":_vm.onToggleContext}},[(_vm.item.value.is_number)?_c('calc-formula-number',{attrs:{"item":_vm.item},on:{"setCursor":_vm.onSetCursor,"resetCursor":_vm.onResetActiveCursor}}):_c('calc-formula-variable',{attrs:{"item":_vm.item,"bracketColor":_vm.bracketColor,"isBracket":_vm.isBracket,"isOpenBracket":_vm.isBracket && !_vm.isFullBracketPair}})],1):_vm._e(),(!_vm.isEmpty && _vm.hasShownContext)?_c('calc-formula-context',{attrs:{"item":_vm.item,"index":_vm.item.index,"surface":_vm.surface}}):_vm._e(),_c('div',{staticClass:"formula__cursor formula__cursor--right",class:{
            'has-active': _vm.isActive,
            'has-accessible': _vm.isEmpty && !_vm.hasHiddenEmptyCursor,
        },on:{"click":_vm.onSetCursor}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }