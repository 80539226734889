<template>
    <div class="pModule druk-l-surface-tint-pale druk-l-shape-m">
        <div class="pModule__wrapper">
            <div v-if="!isEditMode" class="pModule__title druk-u-text-title-m">
                <span>{{ $t(module.translation_alias_title) }}</span>

                <div class="pModule__title-support">
                    <druk-support v-if="module.translation_alias_info" :hasNoIcon="true">{{
                        $t(module.translation_alias_info)
                    }}</druk-support>
                </div>
            </div>

            <div class="pModule__actions">
                <form-checkbox-group
                    v-if="isEditMode"
                    v-model="formData[groupKey][module.key]"
                    :label="$t(module.translation_alias_title)"
                    :options="getFormattedGroupOptions(module.actions)"
                    :prompt="$t(module.translation_alias_info)"
                    :name="module.key"
                    :isDisabled="isCurrentUserRole" />

                <div v-else v-for="(action, index) in module.actions" :key="index" class="pModule__action">
                    <div
                        class="chip"
                        :class="{
                            'chip--disactive': !formData[groupKey][module.key][action.key],
                        }">
                        {{ $t(action.translation_alias_title) }}
                    </div>

                    <druk-hint
                        v-if="action.translation_alias_info"
                        class="pModule__action-hint"
                        :tooltip="{ from: 'top', text: $t(action.translation_alias_info), maxWidth: '260px' }" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

    export default {
        name: 'role-permissions-module',

        props: {
            groupKey: String,
            module: Object,
            isEditMode: Boolean,
            isCurrentUserRole: Boolean,
        },

        computed: {
            ...mapState({
                formData: (state) => state.permissions.formData,
            }),
        },

        methods: {
            getFormattedGroupOptions(actions) {
                return actions.map((action) =>
                    !action.info_translation_alias
                        ? action
                        : { ...action, tooltip: { text: action.info_translation_alias, from: 'top' } },
                );
            },
        },
    };
</script>

<style lang="scss" scoped>
    .pModule {
        margin-bottom: 32px;
        padding: 12px 16px;
        height: calc(100% - 20px);
        &__title {
            margin-bottom: 8px;
            &-support {
                margin-top: 4px;
            }
        }
        &__action {
            display: flex;
            align-items: center;
            margin: 0px 0px 8px 0px;
            font-size: 14px;
            &:last-child {
                margin: 0px;
            }
            &-hint {
                margin: 0 0 0 4px;
            }
        }
    }
</style>
