import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        FLEXIBLE_SECTION_BREAKPOINTS: {
            1: 12,
            2: 6,
            3: 4,
        },

        partnerPagesFieldsMap: {
            index: {
                promo: {
                    title: 'promo.section',
                    body: {
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                button: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                    },
                },

                advantages: {
                    title: 'marketplace.druk.advantages_title',
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                'menu-option-1': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.menu_option',
                                },
                                'menu-option-2': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.menu_option',
                                },
                                'menu-option-3': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                        },
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                    },
                },

                possibilities: {
                    title: 'marketplace.druk.possibilities_subtitle',
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        'block-1': {
                            type: 'section',
                            colspan: 4,
                            name: 'section.block_content',
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.block_img',
                                },
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_title',
                                },
                                text: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_text',
                                },
                            },
                        },
                        'block-2': {
                            type: 'section',
                            colspan: 4,
                            name: 'section.block_content',
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.block_img',
                                },
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_title',
                                },
                                text: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_text',
                                },
                            },
                        },
                        'block-3': {
                            type: 'section',
                            colspan: 4,
                            name: 'section.block_content',
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.block_img',
                                },
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_title',
                                },
                                text: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_text',
                                },
                            },
                        },
                        'block-4': {
                            type: 'section',
                            colspan: 4,
                            name: 'section.block_content',
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.block_img',
                                },
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_title',
                                },
                                text: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_text',
                                },
                            },
                        },
                        'block-5': {
                            type: 'section',
                            colspan: 4,
                            name: 'section.block_content',
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.block_img',
                                },
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_title',
                                },
                                text: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_text',
                                },
                            },
                        },
                        'block-6': {
                            type: 'section',
                            colspan: 4,
                            name: 'section.block_content',
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.block_img',
                                },
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_title',
                                },
                                text: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_text',
                                },
                            },
                        },
                        'block-7': {
                            type: 'section',
                            colspan: 4,
                            name: 'section.block_content',
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.block_img',
                                },
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_title',
                                },
                                text: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_text',
                                },
                            },
                        },
                        'block-8': {
                            type: 'section',
                            colspan: 4,
                            name: 'section.block_content',
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.block_img',
                                },
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_title',
                                },
                                text: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_text',
                                },
                            },
                        },
                        'block-9': {
                            type: 'section',
                            colspan: 4,
                            name: 'section.block_content',
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.block_img',
                                },
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_title',
                                },
                                text: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_text',
                                },
                            },
                        },
                    },
                },

                lead: {
                    title: 'partnerPages.lead_title',
                    body: {
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                left_text: {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.left_text',
                                },
                                right_text: {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.right_text',
                                },
                                prompt: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.prompt',
                                },
                            },
                        },
                    },
                },

                features: {
                    title: 'marketplace.druk.features_title',
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                'menu-option-1': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.menu_option',
                                },
                                'menu-option-2': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.menu_option',
                                },
                                'menu-option-3': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.menu_option',
                                },
                                'menu-option-4': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.menu_option',
                                },
                                'menu-option-5': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.menu_option',
                                },
                                'menu-option-6': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.menu_option',
                                },
                                'menu-option-7': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                        },
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                        button: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.button',
                        },
                    },
                },

                simple: {
                    title: 'marketplace.druk.simple_title',
                    body: {
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                text: {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.block_text',
                                },
                            },
                        },
                    },
                },

                troubles: {
                    title: 'marketplace.druk.troubles_title',
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        cards: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                'card-title-1': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-1': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-2': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-2': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-3': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-3': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-4': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-4': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-5': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-5': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-6': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-6': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-7': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-7': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-8': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-8': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_text',
                                },
                            },
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.img',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                text: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_text',
                                },
                            },
                        },
                    },
                },

                footer: {
                    title: 'marketplace.druk.footer_title',
                    body: {
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                text: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_text',
                                },
                                button: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                    },
                },
            },

            features: {
                promo: {
                    title: 'promo.section',
                    body: {
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                button: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                    },
                },

                modules: {
                    title: 'marketplace.features.modules_title',
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        'block-1': {
                            type: 'section',
                            colspan: 4,
                            name: 'section.block_content',
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.block_img',
                                },
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_title',
                                },
                                text: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_text',
                                },
                            },
                        },
                        'block-2': {
                            type: 'section',
                            colspan: 4,
                            name: 'section.block_content',
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.block_img',
                                },
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_title',
                                },
                                text: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_text',
                                },
                            },
                        },
                        'block-3': {
                            type: 'section',
                            colspan: 4,
                            name: 'section.block_content',
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.block_img',
                                },
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_title',
                                },
                                text: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_text',
                                },
                            },
                        },
                        'block-4': {
                            type: 'section',
                            colspan: 4,
                            name: 'section.block_content',
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.block_img',
                                },
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_title',
                                },
                                text: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_text',
                                },
                            },
                        },
                        'block-5': {
                            type: 'section',
                            colspan: 4,
                            name: 'section.block_content',
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.block_img',
                                },
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_title',
                                },
                                text: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_text',
                                },
                            },
                        },
                        'block-6': {
                            type: 'section',
                            colspan: 4,
                            name: 'section.block_content',
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.block_img',
                                },
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_title',
                                },
                                text: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_text',
                                },
                            },
                        },
                        'block-7': {
                            type: 'section',
                            colspan: 4,
                            name: 'section.block_content',
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.block_img',
                                },
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_title',
                                },
                                text: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_text',
                                },
                            },
                        },
                        'block-8': {
                            type: 'section',
                            colspan: 4,
                            name: 'section.block_content',
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.block_img',
                                },
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_title',
                                },
                                text: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_text',
                                },
                            },
                        },
                        'block-9': {
                            type: 'section',
                            colspan: 4,
                            name: 'section.block_content',
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.block_img',
                                },
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_title',
                                },
                                text: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_text',
                                },
                            },
                        },
                        'block-10': {
                            type: 'section',
                            colspan: 4,
                            name: 'section.block_content',
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.block_img',
                                },
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_title',
                                },
                                text: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_text',
                                },
                            },
                        },
                        'block-11': {
                            type: 'section',
                            colspan: 4,
                            name: 'section.block_content',
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.block_img',
                                },
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_title',
                                },
                                text: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_text',
                                },
                            },
                        },
                        'block-12': {
                            type: 'section',
                            colspan: 4,
                            name: 'section.block_content',
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.block_img',
                                },
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_title',
                                },
                                text: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_text',
                                },
                            },
                        },
                    },
                },

                footer: {
                    title: 'marketplace.druk.footer_title',
                    body: {
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                text: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_text',
                                },
                                button: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                    },
                },
            },

            contact: {
                form: {
                    title: 'partner.contact_title',
                    body: {
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            name: 'section.block_content',
                            has_disabled: true,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                'form-field-1': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.field_title_name_alt',
                                },
                                'form-field-2': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.field_title_mail',
                                },
                                'form-field-3': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.field_title_msg',
                                },
                                button: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                    },
                },
            },

            about: {
                main: {
                    title: 'marketplace.about.page_title',
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        text: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.text',
                        },
                    },
                },
            },

            'marketplace-about': {
                promo: {
                    title: 'promo.section',
                    body: {
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                'button-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.partner_button',
                                },
                                'button-2': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.redirect_button',
                                },
                            },
                        },
                    },
                },

                features: {
                    title: 'marketplace.marketplace_about.features_title',
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        'block-1': {
                            type: 'section',
                            colspan: 4,
                            name: 'section.block_content',
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.block_img',
                                },
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_title',
                                },
                                text: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_text',
                                },
                            },
                        },
                        'block-2': {
                            type: 'section',
                            colspan: 4,
                            name: 'section.block_content',
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.block_img',
                                },
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_title',
                                },
                                text: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_text',
                                },
                            },
                        },
                        'block-3': {
                            type: 'section',
                            colspan: 4,
                            name: 'section.block_content',
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.block_img',
                                },
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_title',
                                },
                                text: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_text',
                                },
                            },
                        },
                        'block-4': {
                            type: 'section',
                            colspan: 4,
                            name: 'section.block_content',
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.block_img',
                                },
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_title',
                                },
                                text: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_text',
                                },
                            },
                        },
                        'block-5': {
                            type: 'section',
                            colspan: 4,
                            name: 'section.block_content',
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.block_img',
                                },
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_title',
                                },
                                text: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_text',
                                },
                            },
                        },
                        'block-6': {
                            type: 'section',
                            colspan: 4,
                            name: 'section.block_content',
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.block_img',
                                },
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_title',
                                },
                                text: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_text',
                                },
                            },
                        },
                    },
                },

                about: {
                    title: 'marketplace.marketplace_about.about_title',
                    body: {
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                text: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.text',
                                },
                                button: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                    },
                },

                lead: {
                    title: 'partnerPages.lead_title',
                    body: {
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                left_text: {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.left_text',
                                },
                                right_text: {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.right_text',
                                },
                                prompt: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.prompt',
                                },
                            },
                        },
                    },
                },

                win: {
                    title: 'marketplace.marketplace_about.solves_title',
                    body: {
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.menu_title',
                                },
                                'menu-option-1': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.menu_option',
                                },
                                'menu-option-2': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.menu_option',
                                },
                                'menu-option-3': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.menu_option',
                                },
                                'menu-option-4': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.menu_option',
                                },
                                'menu-option-5': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.menu_option',
                                },
                                'menu-option-6': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                        },
                    },
                },

                troubles: {
                    title: 'marketplace.marketplace_about.troubles_title',
                    body: {
                        cards: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                'card-title-1': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-1': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-2': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-2': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-3': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-3': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-4': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-4': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-5': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-5': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_text',
                                },
                            },
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.img',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                text: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_text',
                                },
                            },
                        },
                    },
                },

                footer: {
                    title: 'marketplace.druk.footer_title_alt',
                    body: {
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                text: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_text',
                                },
                                button: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                    },
                },
            },

            partners: {
                promo: {
                    title: 'promo.section',
                    body: {
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                button: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                    },
                },

                form: {
                    title: 'marketplace.partners_form',
                    body: {
                        has_disabled: true,
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.form_title',
                        },
                        'form-1': {
                            type: 'section',
                            name: 'form.body',
                            body: {
                                'field-title-1': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.field_title_ty',
                                },
                                'field-title-2': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.field_title_name',
                                },
                                'field-title-3': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.field_title_phone',
                                },
                                'field-title-4': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.field_title_mail',
                                },
                                'field-title-5': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.field_title_city',
                                },
                                'field-title-6': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.field_title_site',
                                },
                                'field-title-7': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.field_title_code',
                                },
                                'field-title-8': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.field_title_empl',
                                },
                            },
                        },
                        products: {
                            type: 'section',
                            name: 'partner.range',
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.form_title',
                                },
                                'field-title-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'common.partners_products.sheet_offset_products',
                                },
                                'field-title-2': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'common.partners_products.pad_printing',
                                },
                                'field-title-3': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'common.partners_products.sheet_fed_digital_printing',
                                },
                                'field-title-4': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'common.partners_products.souvenir_products',
                                },
                                'field-title-5': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'common.partners_products.roll_flexo',
                                },
                                'field-title-6': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'common.partners_products.label_and_packing',
                                },
                                'field-title-7': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'common.partners_products.roll_digital_printing',
                                },
                                'field-title-8': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'common.partners_products.books_and_magazines',
                                },
                                'field-title-9': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'common.partners_products.large_format_products',
                                },
                                'field-title-10': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'common.partners_products.fabric_printing',
                                },
                                'field-title-11': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'common.partners_products.silkscreen',
                                },
                                'field-title-12': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'common.partners_products.other',
                                },
                            },
                        },
                        modules: {
                            type: 'section',
                            name: 'partner.modules',
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.block_title',
                                },
                                'field-title-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'common.partners_modules.bitrix',
                                },
                                'field-title-2': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'common.partners_modules.roll_print',
                                },
                                'field-title-3': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'common.partners_modules.personal_server',
                                },
                                'field-title-4': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'common.partners_modules.alternative_design',
                                },
                                'field-title-5': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'common.partners_modules.personal_site',
                                },
                                'field-title-6': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'common.partners_modules.large_format_print',
                                },
                                'field-title-7': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'common.partners_modules.offset_print',
                                },
                                'field-title-8': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'common.partners_modules.list_labels',
                                },
                                'field-title-9': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'common.partners_modules.prepress_auto',
                                },
                                'field-title-10': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'common.partners_modules.contactors',
                                },
                                'field-title-11': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'common.partners_modules.stock',
                                },
                                'field-title-12': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'common.partners_modules.team_management',
                                },
                            },
                        },
                        'form-2': {
                            type: 'section',
                            name: 'form.body',
                            body: {
                                'field-title-9': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.field_title_src',
                                },
                                'field-title-10': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.field_title_cmt',
                                },
                            },
                        },
                        prompt: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.prompt',
                        },
                        'check-label': {
                            type: 'form-textarea',
                            colspan: 6,
                            value: null,
                            name: 'form.check_label',
                        },
                        'check-link': {
                            type: 'form-textarea',
                            colspan: 6,
                            value: null,
                            name: 'form.check_link',
                        },
                        button: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.button',
                        },
                    },
                },
            },

            'privacy-policy': {
                main: {
                    title: 'marketplace.policy.page_title',
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'form-editor',
                            value: null,
                            name: 'form.content',
                        },
                    },
                },
            },

            terms: {
                main: {
                    title: 'marketplace.terms.page_title',
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'form-editor',
                            value: null,
                            name: 'form.content',
                        },
                    },
                },
            },

            'task-manager': {
                is_module: true,
                promo: {
                    title: 'promo.section',
                    body: {
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            payload: {
                                editable: true,
                                controll: 'partnerPages.item.button.add_menu_option',
                                target: 'option',
                                position: 2,
                                object: {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                'option-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                                'button-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                                'button-2': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                    },
                },

                reasons: {
                    title: 'marketplace.druk.reasons_title',
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                controll: 'partnerPages.item.button.add_list_option',
                                target: 'menu-option',
                                position: 0,
                                object: {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                            body: {
                                'menu-option-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                        },
                    },
                },

                description: {
                    title: 'marketplace.druk.description_title',
                    has_visible: 1,
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                type: 'section',
                                controll: 'partnerPages.item.button.add_section',
                                deleting: 'partnerPages.item.button.remove_section',
                                position: 0,
                                columns: 2,
                                objects: {
                                    title: {
                                        type: 'form-textarea',
                                        value: null,
                                        col: 1,
                                        name: 'form.form_title',
                                    },
                                    text: {
                                        type: 'form-editor',
                                        value: '',
                                        col: 1,
                                        name: 'form.block_text',
                                    },
                                    img: {
                                        type: 'form-img',
                                        value: null,
                                        col: 2,
                                        name: 'form.img',
                                    },
                                },
                            },
                            body: {
                                'object-1': {
                                    has_multiple: true,
                                    'title-1': {
                                        type: 'form-textarea',
                                        value: null,
                                        col: 1,
                                        name: 'form.form_title',
                                    },
                                    'text-1': {
                                        type: 'form-editor',
                                        value: '',
                                        col: 1,
                                        name: 'form.block_text',
                                    },
                                    'img-1': {
                                        type: 'form-img',
                                        value: null,
                                        col: 2,
                                        name: 'form.img',
                                    },
                                },
                            },
                        },
                    },
                },

                benefits: {
                    title: 'marketplace.druk.benefits_title',
                    body: {
                        cards: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                'card-title-1': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-1': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-2': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-2': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-3': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-3': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-4': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-4': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-5': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-5': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                            },
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.img',
                                },
                                'button-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                                'button-2': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                    },
                },

                recommendations: {
                    title: 'marketplace.druk.recommendations_title',
                    has_visible: 1,
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                type: 'section',
                                controll: 'partnerPages.item.button.add_section',
                                deleting: 'partnerPages.item.button.remove_section',
                                position: 0,
                                objects: {
                                    'module-img': {
                                        type: 'form-img',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.img',
                                    },
                                    'module-title': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.form_title',
                                    },
                                    'module-button': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.check_link',
                                    },
                                    'module-link': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'common.link',
                                        prompt: 'partnerPages.item.link_prompt',
                                    },
                                },
                            },
                            body: {
                                'object-1': {
                                    has_multiple: true,
                                    'module-img-1': {
                                        type: 'form-img',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.img',
                                    },
                                    'module-title-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.form_title',
                                    },
                                    'module-button-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.check_link',
                                    },
                                    'module-link-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'common.link',
                                        prompt: 'partnerPages.item.link_prompt',
                                    },
                                },
                            },
                        },
                    },
                },

                footer: {
                    title: 'marketplace.druk.demo_title',
                    body: {
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                button: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                    },
                },
            },

            'end-user-license-agreement': {
                main: {
                    title: 'marketplace.end_user_license.page_title',
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'form-editor',
                            value: null,
                            name: 'form.content',
                        },
                    },
                },
            },

            'print-house-crm-clients': {
                is_module: true,
                promo: {
                    title: 'promo.section',
                    body: {
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            payload: {
                                editable: true,
                                controll: 'partnerPages.item.button.add_menu_option',
                                target: 'option',
                                position: 2,
                                object: {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                'option-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                                'button-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                                'button-2': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                    },
                },

                reasons: {
                    title: 'marketplace.druk.reasons_title',
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                controll: 'partnerPages.item.button.add_list_option',
                                target: 'menu-option',
                                position: 0,
                                object: {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                            body: {
                                'menu-option-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                        },
                    },
                },

                description: {
                    title: 'marketplace.druk.description_title',
                    has_visible: 1,
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                type: 'section',
                                controll: 'partnerPages.item.button.add_section',
                                deleting: 'partnerPages.item.button.remove_section',
                                position: 0,
                                columns: 2,
                                objects: {
                                    title: {
                                        type: 'form-textarea',
                                        value: null,
                                        col: 1,
                                        name: 'form.form_title',
                                    },
                                    text: {
                                        type: 'form-editor',
                                        value: '',
                                        col: 1,
                                        name: 'form.block_text',
                                    },
                                    img: {
                                        type: 'form-img',
                                        value: null,
                                        col: 2,
                                        name: 'form.img',
                                    },
                                },
                            },
                            body: {
                                'object-1': {
                                    has_multiple: true,
                                    'title-1': {
                                        type: 'form-textarea',
                                        value: null,
                                        col: 1,
                                        name: 'form.form_title',
                                    },
                                    'text-1': {
                                        type: 'form-editor',
                                        value: '',
                                        col: 1,
                                        name: 'form.block_text',
                                    },
                                    'img-1': {
                                        type: 'form-img',
                                        value: null,
                                        col: 2,
                                        name: 'form.img',
                                    },
                                },
                            },
                        },
                    },
                },

                benefits: {
                    title: 'marketplace.druk.benefits_title',
                    body: {
                        cards: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                'card-title-1': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-1': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-2': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-2': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-3': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-3': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-4': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-4': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-5': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-5': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                            },
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.img',
                                },
                                'button-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                                'button-2': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                    },
                },

                recommendations: {
                    title: 'marketplace.druk.recommendations_title',
                    has_visible: 1,
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                type: 'section',
                                controll: 'partnerPages.item.button.add_section',
                                deleting: 'partnerPages.item.button.remove_section',
                                position: 0,
                                objects: {
                                    'module-img': {
                                        type: 'form-img',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.img',
                                    },
                                    'module-title': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.form_title',
                                    },
                                    'module-button': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.check_link',
                                    },
                                    'module-link': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'common.link',
                                        prompt: 'partnerPages.item.link_prompt',
                                    },
                                },
                            },
                            body: {
                                'object-1': {
                                    has_multiple: true,
                                    'module-img-1': {
                                        type: 'form-img',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.img',
                                    },
                                    'module-title-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.form_title',
                                    },
                                    'module-button-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.check_link',
                                    },
                                    'module-link-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'common.link',
                                        prompt: 'partnerPages.item.link_prompt',
                                    },
                                },
                            },
                        },
                    },
                },

                footer: {
                    title: 'marketplace.druk.demo_title',
                    body: {
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                button: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                    },
                },
            },

            'print-house-crm-orders': {
                is_module: true,
                promo: {
                    title: 'promo.section',
                    body: {
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            payload: {
                                editable: true,
                                controll: 'partnerPages.item.button.add_menu_option',
                                target: 'option',
                                position: 2,
                                object: {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                'option-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                                'button-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                                'button-2': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                    },
                },

                reasons: {
                    title: 'marketplace.druk.reasons_title',
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                controll: 'partnerPages.item.button.add_list_option',
                                target: 'menu-option',
                                position: 0,
                                object: {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                            body: {
                                'menu-option-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                        },
                    },
                },

                description: {
                    title: 'marketplace.druk.description_title',
                    has_visible: 1,
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                type: 'section',
                                controll: 'partnerPages.item.button.add_section',
                                deleting: 'partnerPages.item.button.remove_section',
                                position: 0,
                                columns: 2,
                                objects: {
                                    title: {
                                        type: 'form-textarea',
                                        value: null,
                                        col: 1,
                                        name: 'form.form_title',
                                    },
                                    text: {
                                        type: 'form-editor',
                                        value: '',
                                        col: 1,
                                        name: 'form.block_text',
                                    },
                                    img: {
                                        type: 'form-img',
                                        value: null,
                                        col: 2,
                                        name: 'form.img',
                                    },
                                },
                            },
                            body: {
                                'object-1': {
                                    has_multiple: true,
                                    'title-1': {
                                        type: 'form-textarea',
                                        value: null,
                                        col: 1,
                                        name: 'form.form_title',
                                    },
                                    'text-1': {
                                        type: 'form-editor',
                                        value: '',
                                        col: 1,
                                        name: 'form.block_text',
                                    },
                                    'img-1': {
                                        type: 'form-img',
                                        value: null,
                                        col: 2,
                                        name: 'form.img',
                                    },
                                },
                            },
                        },
                    },
                },

                benefits: {
                    title: 'marketplace.druk.benefits_title',
                    body: {
                        cards: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                'card-title-1': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-1': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-2': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-2': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-3': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-3': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-4': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-4': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-5': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-5': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                            },
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.img',
                                },
                                'button-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                                'button-2': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                    },
                },

                recommendations: {
                    title: 'marketplace.druk.recommendations_title',
                    has_visible: 1,
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                type: 'section',
                                controll: 'partnerPages.item.button.add_section',
                                deleting: 'partnerPages.item.button.remove_section',
                                position: 0,
                                objects: {
                                    'module-img': {
                                        type: 'form-img',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.img',
                                    },
                                    'module-title': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.form_title',
                                    },
                                    'module-button': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.check_link',
                                    },
                                    'module-link': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'common.link',
                                        prompt: 'partnerPages.item.link_prompt',
                                    },
                                },
                            },
                            body: {
                                'object-1': {
                                    has_multiple: true,
                                    'module-img-1': {
                                        type: 'form-img',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.img',
                                    },
                                    'module-title-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.form_title',
                                    },
                                    'module-button-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.check_link',
                                    },
                                    'module-link-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'common.link',
                                        prompt: 'partnerPages.item.link_prompt',
                                    },
                                },
                            },
                        },
                    },
                },

                footer: {
                    title: 'marketplace.druk.demo_title',
                    body: {
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                button: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                    },
                },
            },

            'print-house-web2print': {
                is_module: true,
                promo: {
                    title: 'promo.section',
                    body: {
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            payload: {
                                editable: true,
                                controll: 'partnerPages.item.button.add_menu_option',
                                target: 'option',
                                position: 2,
                                object: {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                'option-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                                'button-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                                'button-2': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                    },
                },

                reasons: {
                    title: 'marketplace.druk.reasons_title',
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                controll: 'partnerPages.item.button.add_list_option',
                                target: 'menu-option',
                                position: 0,
                                object: {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                            body: {
                                'menu-option-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                        },
                    },
                },

                description: {
                    title: 'marketplace.druk.description_title',
                    has_visible: 1,
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                type: 'section',
                                controll: 'partnerPages.item.button.add_section',
                                deleting: 'partnerPages.item.button.remove_section',
                                position: 0,
                                columns: 2,
                                objects: {
                                    title: {
                                        type: 'form-textarea',
                                        value: null,
                                        col: 1,
                                        name: 'form.form_title',
                                    },
                                    text: {
                                        type: 'form-editor',
                                        value: '',
                                        col: 1,
                                        name: 'form.block_text',
                                    },
                                    img: {
                                        type: 'form-img',
                                        value: null,
                                        col: 2,
                                        name: 'form.img',
                                    },
                                },
                            },
                            body: {
                                'object-1': {
                                    has_multiple: true,
                                    'title-1': {
                                        type: 'form-textarea',
                                        value: null,
                                        col: 1,
                                        name: 'form.form_title',
                                    },
                                    'text-1': {
                                        type: 'form-editor',
                                        value: '',
                                        col: 1,
                                        name: 'form.block_text',
                                    },
                                    'img-1': {
                                        type: 'form-img',
                                        value: null,
                                        col: 2,
                                        name: 'form.img',
                                    },
                                },
                            },
                        },
                    },
                },

                benefits: {
                    title: 'marketplace.druk.benefits_title',
                    body: {
                        cards: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                'card-title-1': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-1': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-2': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-2': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-3': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-3': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-4': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-4': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-5': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-5': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                            },
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.img',
                                },
                                'button-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                                'button-2': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                    },
                },

                recommendations: {
                    title: 'marketplace.druk.recommendations_title',
                    has_visible: 1,
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                type: 'section',
                                controll: 'partnerPages.item.button.add_section',
                                deleting: 'partnerPages.item.button.remove_section',
                                position: 0,
                                objects: {
                                    'module-img': {
                                        type: 'form-img',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.img',
                                    },
                                    'module-title': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.form_title',
                                    },
                                    'module-button': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.check_link',
                                    },
                                    'module-link': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'common.link',
                                        prompt: 'partnerPages.item.link_prompt',
                                    },
                                },
                            },
                            body: {
                                'object-1': {
                                    has_multiple: true,
                                    'module-img-1': {
                                        type: 'form-img',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.img',
                                    },
                                    'module-title-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.form_title',
                                    },
                                    'module-button-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.check_link',
                                    },
                                    'module-link-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'common.link',
                                        prompt: 'partnerPages.item.link_prompt',
                                    },
                                },
                            },
                        },
                    },
                },

                footer: {
                    title: 'marketplace.druk.demo_title',
                    body: {
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                button: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                    },
                },
            },

            'konica-minolta-application': {
                is_module: true,
                promo: {
                    title: 'promo.section',
                    body: {
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            payload: {
                                editable: true,
                                controll: 'partnerPages.item.button.add_menu_option',
                                target: 'option',
                                position: 2,
                                object: {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                'option-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                                'button-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                                'button-2': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                    },
                },

                reasons: {
                    title: 'marketplace.druk.reasons_title',
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                controll: 'partnerPages.item.button.add_list_option',
                                target: 'menu-option',
                                position: 0,
                                object: {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                            body: {
                                'menu-option-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                        },
                    },
                },

                description: {
                    title: 'marketplace.druk.description_title',
                    has_visible: 1,
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                type: 'section',
                                controll: 'partnerPages.item.button.add_section',
                                deleting: 'partnerPages.item.button.remove_section',
                                position: 0,
                                columns: 2,
                                objects: {
                                    title: {
                                        type: 'form-textarea',
                                        value: null,
                                        col: 1,
                                        name: 'form.form_title',
                                    },
                                    text: {
                                        type: 'form-editor',
                                        value: '',
                                        col: 1,
                                        name: 'form.block_text',
                                    },
                                    img: {
                                        type: 'form-img',
                                        value: null,
                                        col: 2,
                                        name: 'form.img',
                                    },
                                },
                            },
                            body: {
                                'object-1': {
                                    has_multiple: true,
                                    'title-1': {
                                        type: 'form-textarea',
                                        value: null,
                                        col: 1,
                                        name: 'form.form_title',
                                    },
                                    'text-1': {
                                        type: 'form-editor',
                                        value: '',
                                        col: 1,
                                        name: 'form.block_text',
                                    },
                                    'img-1': {
                                        type: 'form-img',
                                        value: null,
                                        col: 2,
                                        name: 'form.img',
                                    },
                                },
                            },
                        },
                    },
                },

                benefits: {
                    title: 'marketplace.druk.benefits_title',
                    body: {
                        cards: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                'card-title-1': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-1': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-2': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-2': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-3': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-3': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-4': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-4': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-5': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-5': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                            },
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.img',
                                },
                                'button-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                                'button-2': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                    },
                },

                recommendations: {
                    title: 'marketplace.druk.recommendations_title',
                    has_visible: 1,
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                type: 'section',
                                controll: 'partnerPages.item.button.add_section',
                                deleting: 'partnerPages.item.button.remove_section',
                                position: 0,
                                objects: {
                                    'module-img': {
                                        type: 'form-img',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.img',
                                    },
                                    'module-title': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.form_title',
                                    },
                                    'module-button': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.check_link',
                                    },
                                    'module-link': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'common.link',
                                        prompt: 'partnerPages.item.link_prompt',
                                    },
                                },
                            },
                            body: {
                                'object-1': {
                                    has_multiple: true,
                                    'module-img-1': {
                                        type: 'form-img',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.img',
                                    },
                                    'module-title-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.form_title',
                                    },
                                    'module-button-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.check_link',
                                    },
                                    'module-link-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'common.link',
                                        prompt: 'partnerPages.item.link_prompt',
                                    },
                                },
                            },
                        },
                    },
                },

                footer: {
                    title: 'marketplace.druk.demo_title',
                    body: {
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                button: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                    },
                },
            },

            'manage-products-and-catalogs': {
                is_module: true,
                promo: {
                    title: 'promo.section',
                    body: {
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            payload: {
                                editable: true,
                                controll: 'partnerPages.item.button.add_menu_option',
                                target: 'option',
                                position: 2,
                                object: {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                'option-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                                'button-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                                'button-2': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                    },
                },

                reasons: {
                    title: 'marketplace.druk.reasons_title',
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                controll: 'partnerPages.item.button.add_list_option',
                                target: 'menu-option',
                                position: 0,
                                object: {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                            body: {
                                'menu-option-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                        },
                    },
                },

                description: {
                    title: 'marketplace.druk.description_title',
                    has_visible: 1,
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                type: 'section',
                                controll: 'partnerPages.item.button.add_section',
                                deleting: 'partnerPages.item.button.remove_section',
                                position: 0,
                                columns: 2,
                                objects: {
                                    title: {
                                        type: 'form-textarea',
                                        value: null,
                                        col: 1,
                                        name: 'form.form_title',
                                    },
                                    text: {
                                        type: 'form-editor',
                                        value: '',
                                        col: 1,
                                        name: 'form.block_text',
                                    },
                                    img: {
                                        type: 'form-img',
                                        value: null,
                                        col: 2,
                                        name: 'form.img',
                                    },
                                },
                            },
                            body: {
                                'object-1': {
                                    has_multiple: true,
                                    'title-1': {
                                        type: 'form-textarea',
                                        value: null,
                                        col: 1,
                                        name: 'form.form_title',
                                    },
                                    'text-1': {
                                        type: 'form-editor',
                                        value: '',
                                        col: 1,
                                        name: 'form.block_text',
                                    },
                                    'img-1': {
                                        type: 'form-img',
                                        value: null,
                                        col: 2,
                                        name: 'form.img',
                                    },
                                },
                            },
                        },
                    },
                },

                benefits: {
                    title: 'marketplace.druk.benefits_title',
                    body: {
                        cards: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                'card-title-1': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-1': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-2': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-2': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-3': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-3': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-4': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-4': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-5': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-5': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                            },
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.img',
                                },
                                'button-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                                'button-2': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                    },
                },

                recommendations: {
                    title: 'marketplace.druk.recommendations_title',
                    has_visible: 1,
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                type: 'section',
                                controll: 'partnerPages.item.button.add_section',
                                deleting: 'partnerPages.item.button.remove_section',
                                position: 0,
                                objects: {
                                    'module-img': {
                                        type: 'form-img',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.img',
                                    },
                                    'module-title': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.form_title',
                                    },
                                    'module-button': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.check_link',
                                    },
                                    'module-link': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'common.link',
                                        prompt: 'partnerPages.item.link_prompt',
                                    },
                                },
                            },
                            body: {
                                'object-1': {
                                    has_multiple: true,
                                    'module-img-1': {
                                        type: 'form-img',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.img',
                                    },
                                    'module-title-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.form_title',
                                    },
                                    'module-button-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.check_link',
                                    },
                                    'module-link-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'common.link',
                                        prompt: 'partnerPages.item.link_prompt',
                                    },
                                },
                            },
                        },
                    },
                },

                footer: {
                    title: 'marketplace.druk.demo_title',
                    body: {
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                button: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                    },
                },
            },

            'personal-manufacturing-settings': {
                is_module: true,
                promo: {
                    title: 'promo.section',
                    body: {
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            payload: {
                                editable: true,
                                controll: 'partnerPages.item.button.add_menu_option',
                                target: 'option',
                                position: 2,
                                object: {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                'option-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                                'button-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                                'button-2': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                    },
                },

                reasons: {
                    title: 'marketplace.druk.reasons_title',
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                controll: 'partnerPages.item.button.add_list_option',
                                target: 'menu-option',
                                position: 0,
                                object: {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                            body: {
                                'menu-option-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                        },
                    },
                },

                description: {
                    title: 'marketplace.druk.description_title',
                    has_visible: 1,
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                type: 'section',
                                controll: 'partnerPages.item.button.add_section',
                                deleting: 'partnerPages.item.button.remove_section',
                                position: 0,
                                columns: 2,
                                objects: {
                                    title: {
                                        type: 'form-textarea',
                                        value: null,
                                        col: 1,
                                        name: 'form.form_title',
                                    },
                                    text: {
                                        type: 'form-editor',
                                        value: '',
                                        col: 1,
                                        name: 'form.block_text',
                                    },
                                    img: {
                                        type: 'form-img',
                                        value: null,
                                        col: 2,
                                        name: 'form.img',
                                    },
                                },
                            },
                            body: {
                                'object-1': {
                                    has_multiple: true,
                                    'title-1': {
                                        type: 'form-textarea',
                                        value: null,
                                        col: 1,
                                        name: 'form.form_title',
                                    },
                                    'text-1': {
                                        type: 'form-editor',
                                        value: '',
                                        col: 1,
                                        name: 'form.block_text',
                                    },
                                    'img-1': {
                                        type: 'form-img',
                                        value: null,
                                        col: 2,
                                        name: 'form.img',
                                    },
                                },
                            },
                        },
                    },
                },

                benefits: {
                    title: 'marketplace.druk.benefits_title',
                    body: {
                        cards: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                'card-title-1': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-1': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-2': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-2': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-3': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-3': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-4': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-4': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-5': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-5': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                            },
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.img',
                                },
                                'button-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                                'button-2': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                    },
                },

                recommendations: {
                    title: 'marketplace.druk.recommendations_title',
                    has_visible: 1,
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                type: 'section',
                                controll: 'partnerPages.item.button.add_section',
                                deleting: 'partnerPages.item.button.remove_section',
                                position: 0,
                                objects: {
                                    'module-img': {
                                        type: 'form-img',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.img',
                                    },
                                    'module-title': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.form_title',
                                    },
                                    'module-button': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.check_link',
                                    },
                                    'module-link': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'common.link',
                                        prompt: 'partnerPages.item.link_prompt',
                                    },
                                },
                            },
                            body: {
                                'object-1': {
                                    has_multiple: true,
                                    'module-img-1': {
                                        type: 'form-img',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.img',
                                    },
                                    'module-title-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.form_title',
                                    },
                                    'module-button-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.check_link',
                                    },
                                    'module-link-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'common.link',
                                        prompt: 'partnerPages.item.link_prompt',
                                    },
                                },
                            },
                        },
                    },
                },

                footer: {
                    title: 'marketplace.druk.demo_title',
                    body: {
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                button: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                    },
                },
            },

            'manufacturing-calculation': {
                is_module: true,
                promo: {
                    title: 'promo.section',
                    body: {
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            payload: {
                                editable: true,
                                controll: 'partnerPages.item.button.add_menu_option',
                                target: 'option',
                                position: 2,
                                object: {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                'option-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                                'button-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                                'button-2': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                    },
                },

                reasons: {
                    title: 'marketplace.druk.reasons_title',
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                controll: 'partnerPages.item.button.add_list_option',
                                target: 'menu-option',
                                position: 0,
                                object: {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                            body: {
                                'menu-option-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                        },
                    },
                },

                description: {
                    title: 'marketplace.druk.description_title',
                    has_visible: 1,
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                type: 'section',
                                controll: 'partnerPages.item.button.add_section',
                                deleting: 'partnerPages.item.button.remove_section',
                                position: 0,
                                columns: 2,
                                objects: {
                                    title: {
                                        type: 'form-textarea',
                                        value: null,
                                        col: 1,
                                        name: 'form.form_title',
                                    },
                                    text: {
                                        type: 'form-editor',
                                        value: '',
                                        col: 1,
                                        name: 'form.block_text',
                                    },
                                    img: {
                                        type: 'form-img',
                                        value: null,
                                        col: 2,
                                        name: 'form.img',
                                    },
                                },
                            },
                            body: {
                                'object-1': {
                                    has_multiple: true,
                                    'title-1': {
                                        type: 'form-textarea',
                                        value: null,
                                        col: 1,
                                        name: 'form.form_title',
                                    },
                                    'text-1': {
                                        type: 'form-editor',
                                        value: '',
                                        col: 1,
                                        name: 'form.block_text',
                                    },
                                    'img-1': {
                                        type: 'form-img',
                                        value: null,
                                        col: 2,
                                        name: 'form.img',
                                    },
                                },
                            },
                        },
                    },
                },

                benefits: {
                    title: 'marketplace.druk.benefits_title',
                    body: {
                        cards: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                'card-title-1': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-1': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-2': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-2': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-3': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-3': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-4': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-4': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-5': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-5': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                            },
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.img',
                                },
                                'button-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                                'button-2': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                    },
                },

                recommendations: {
                    title: 'marketplace.druk.recommendations_title',
                    has_visible: 1,
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                type: 'section',
                                controll: 'partnerPages.item.button.add_section',
                                deleting: 'partnerPages.item.button.remove_section',
                                position: 0,
                                objects: {
                                    'module-img': {
                                        type: 'form-img',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.img',
                                    },
                                    'module-title': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.form_title',
                                    },
                                    'module-button': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.check_link',
                                    },
                                    'module-link': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'common.link',
                                        prompt: 'partnerPages.item.link_prompt',
                                    },
                                },
                            },
                            body: {
                                'object-1': {
                                    has_multiple: true,
                                    'module-img-1': {
                                        type: 'form-img',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.img',
                                    },
                                    'module-title-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.form_title',
                                    },
                                    'module-button-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.check_link',
                                    },
                                    'module-link-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'common.link',
                                        prompt: 'partnerPages.item.link_prompt',
                                    },
                                },
                            },
                        },
                    },
                },

                footer: {
                    title: 'marketplace.druk.demo_title',
                    body: {
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                button: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                    },
                },
            },

            'file-store': {
                is_module: true,
                promo: {
                    title: 'promo.section',
                    body: {
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            payload: {
                                editable: true,
                                controll: 'partnerPages.item.button.add_menu_option',
                                target: 'option',
                                position: 2,
                                object: {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                'option-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                                'button-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                                'button-2': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                    },
                },

                reasons: {
                    title: 'marketplace.druk.reasons_title',
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                controll: 'partnerPages.item.button.add_list_option',
                                target: 'menu-option',
                                position: 0,
                                object: {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                            body: {
                                'menu-option-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                        },
                    },
                },

                description: {
                    title: 'marketplace.druk.description_title',
                    has_visible: 1,
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                type: 'section',
                                controll: 'partnerPages.item.button.add_section',
                                deleting: 'partnerPages.item.button.remove_section',
                                position: 0,
                                columns: 2,
                                objects: {
                                    title: {
                                        type: 'form-textarea',
                                        value: null,
                                        col: 1,
                                        name: 'form.form_title',
                                    },
                                    text: {
                                        type: 'form-editor',
                                        value: '',
                                        col: 1,
                                        name: 'form.block_text',
                                    },
                                    img: {
                                        type: 'form-img',
                                        value: null,
                                        col: 2,
                                        name: 'form.img',
                                    },
                                },
                            },
                            body: {
                                'object-1': {
                                    has_multiple: true,
                                    'title-1': {
                                        type: 'form-textarea',
                                        value: null,
                                        col: 1,
                                        name: 'form.form_title',
                                    },
                                    'text-1': {
                                        type: 'form-editor',
                                        value: '',
                                        col: 1,
                                        name: 'form.block_text',
                                    },
                                    'img-1': {
                                        type: 'form-img',
                                        value: null,
                                        col: 2,
                                        name: 'form.img',
                                    },
                                },
                            },
                        },
                    },
                },

                benefits: {
                    title: 'marketplace.druk.benefits_title',
                    body: {
                        cards: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                'card-title-1': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-1': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-2': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-2': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-3': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-3': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-4': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-4': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-5': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-5': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                            },
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.img',
                                },
                                'button-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                                'button-2': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                    },
                },

                recommendations: {
                    title: 'marketplace.druk.recommendations_title',
                    has_visible: 1,
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                type: 'section',
                                controll: 'partnerPages.item.button.add_section',
                                deleting: 'partnerPages.item.button.remove_section',
                                position: 0,
                                objects: {
                                    'module-img': {
                                        type: 'form-img',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.img',
                                    },
                                    'module-title': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.form_title',
                                    },
                                    'module-button': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.check_link',
                                    },
                                    'module-link': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'common.link',
                                        prompt: 'partnerPages.item.link_prompt',
                                    },
                                },
                            },
                            body: {
                                'object-1': {
                                    has_multiple: true,
                                    'module-img-1': {
                                        type: 'form-img',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.img',
                                    },
                                    'module-title-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.form_title',
                                    },
                                    'module-button-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.check_link',
                                    },
                                    'module-link-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'common.link',
                                        prompt: 'partnerPages.item.link_prompt',
                                    },
                                },
                            },
                        },
                    },
                },

                footer: {
                    title: 'marketplace.druk.demo_title',
                    body: {
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                button: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                    },
                },
            },

            'production-plan': {
                is_module: true,
                promo: {
                    title: 'promo.section',
                    body: {
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            payload: {
                                editable: true,
                                controll: 'partnerPages.item.button.add_menu_option',
                                target: 'option',
                                position: 2,
                                object: {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                'option-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                                'button-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                                'button-2': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                    },
                },

                reasons: {
                    title: 'marketplace.druk.reasons_title',
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                controll: 'partnerPages.item.button.add_list_option',
                                target: 'menu-option',
                                position: 0,
                                object: {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                            body: {
                                'menu-option-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                        },
                    },
                },

                description: {
                    title: 'marketplace.druk.description_title',
                    has_visible: 1,
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                type: 'section',
                                controll: 'partnerPages.item.button.add_section',
                                deleting: 'partnerPages.item.button.remove_section',
                                position: 0,
                                columns: 2,
                                objects: {
                                    title: {
                                        type: 'form-textarea',
                                        value: null,
                                        col: 1,
                                        name: 'form.form_title',
                                    },
                                    text: {
                                        type: 'form-editor',
                                        value: '',
                                        col: 1,
                                        name: 'form.block_text',
                                    },
                                    img: {
                                        type: 'form-img',
                                        value: null,
                                        col: 2,
                                        name: 'form.img',
                                    },
                                },
                            },
                            body: {
                                'object-1': {
                                    has_multiple: true,
                                    'title-1': {
                                        type: 'form-textarea',
                                        value: null,
                                        col: 1,
                                        name: 'form.form_title',
                                    },
                                    'text-1': {
                                        type: 'form-editor',
                                        value: '',
                                        col: 1,
                                        name: 'form.block_text',
                                    },
                                    'img-1': {
                                        type: 'form-img',
                                        value: null,
                                        col: 2,
                                        name: 'form.img',
                                    },
                                },
                            },
                        },
                    },
                },

                benefits: {
                    title: 'marketplace.druk.benefits_title',
                    body: {
                        cards: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                'card-title-1': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-1': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-2': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-2': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-3': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-3': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-4': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-4': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-5': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-5': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                            },
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.img',
                                },
                                'button-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                                'button-2': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                    },
                },

                recommendations: {
                    title: 'marketplace.druk.recommendations_title',
                    has_visible: 1,
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                type: 'section',
                                controll: 'partnerPages.item.button.add_section',
                                deleting: 'partnerPages.item.button.remove_section',
                                position: 0,
                                objects: {
                                    'module-img': {
                                        type: 'form-img',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.img',
                                    },
                                    'module-title': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.form_title',
                                    },
                                    'module-button': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.check_link',
                                    },
                                    'module-link': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'common.link',
                                        prompt: 'partnerPages.item.link_prompt',
                                    },
                                },
                            },
                            body: {
                                'object-1': {
                                    has_multiple: true,
                                    'module-img-1': {
                                        type: 'form-img',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.img',
                                    },
                                    'module-title-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.form_title',
                                    },
                                    'module-button-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.check_link',
                                    },
                                    'module-link-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'common.link',
                                        prompt: 'partnerPages.item.link_prompt',
                                    },
                                },
                            },
                        },
                    },
                },

                footer: {
                    title: 'marketplace.druk.demo_title',
                    body: {
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                button: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                    },
                },
            },

            'print-house-managment': {
                is_module: true,
                promo: {
                    title: 'promo.section',
                    body: {
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            payload: {
                                editable: true,
                                controll: 'partnerPages.item.button.add_menu_option',
                                target: 'option',
                                position: 2,
                                object: {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                'option-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                                'button-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                                'button-2': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                    },
                },

                reasons: {
                    title: 'marketplace.druk.reasons_title',
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                controll: 'partnerPages.item.button.add_list_option',
                                target: 'menu-option',
                                position: 0,
                                object: {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                            body: {
                                'menu-option-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                        },
                    },
                },

                description: {
                    title: 'marketplace.druk.description_title',
                    has_visible: 1,
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                type: 'section',
                                controll: 'partnerPages.item.button.add_section',
                                deleting: 'partnerPages.item.button.remove_section',
                                position: 0,
                                columns: 2,
                                objects: {
                                    title: {
                                        type: 'form-textarea',
                                        value: null,
                                        col: 1,
                                        name: 'form.form_title',
                                    },
                                    text: {
                                        type: 'form-editor',
                                        value: '',
                                        col: 1,
                                        name: 'form.block_text',
                                    },
                                    img: {
                                        type: 'form-img',
                                        value: null,
                                        col: 2,
                                        name: 'form.img',
                                    },
                                },
                            },
                            body: {
                                'object-1': {
                                    has_multiple: true,
                                    'title-1': {
                                        type: 'form-textarea',
                                        value: null,
                                        col: 1,
                                        name: 'form.form_title',
                                    },
                                    'text-1': {
                                        type: 'form-editor',
                                        value: '',
                                        col: 1,
                                        name: 'form.block_text',
                                    },
                                    'img-1': {
                                        type: 'form-img',
                                        value: null,
                                        col: 2,
                                        name: 'form.img',
                                    },
                                },
                            },
                        },
                    },
                },

                benefits: {
                    title: 'marketplace.druk.benefits_title',
                    body: {
                        cards: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                'card-title-1': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-1': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-2': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-2': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-3': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-3': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-4': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-4': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-5': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-5': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                            },
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.img',
                                },
                                'button-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                                'button-2': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                    },
                },

                recommendations: {
                    title: 'marketplace.druk.recommendations_title',
                    has_visible: 1,
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                type: 'section',
                                controll: 'partnerPages.item.button.add_section',
                                deleting: 'partnerPages.item.button.remove_section',
                                position: 0,
                                objects: {
                                    'module-img': {
                                        type: 'form-img',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.img',
                                    },
                                    'module-title': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.form_title',
                                    },
                                    'module-button': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.check_link',
                                    },
                                    'module-link': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'common.link',
                                        prompt: 'partnerPages.item.link_prompt',
                                    },
                                },
                            },
                            body: {
                                'object-1': {
                                    has_multiple: true,
                                    'module-img-1': {
                                        type: 'form-img',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.img',
                                    },
                                    'module-title-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.form_title',
                                    },
                                    'module-button-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.check_link',
                                    },
                                    'module-link-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'common.link',
                                        prompt: 'partnerPages.item.link_prompt',
                                    },
                                },
                            },
                        },
                    },
                },

                footer: {
                    title: 'marketplace.druk.demo_title',
                    body: {
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                button: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                    },
                },
            },

            'seo-settings': {
                is_module: true,
                promo: {
                    title: 'promo.section',
                    body: {
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            payload: {
                                editable: true,
                                controll: 'partnerPages.item.button.add_menu_option',
                                target: 'option',
                                position: 2,
                                object: {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                'option-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                                'button-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                                'button-2': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                    },
                },

                reasons: {
                    title: 'marketplace.druk.reasons_title',
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                controll: 'partnerPages.item.button.add_list_option',
                                target: 'menu-option',
                                position: 0,
                                object: {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                            body: {
                                'menu-option-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                        },
                    },
                },

                description: {
                    title: 'marketplace.druk.description_title',
                    has_visible: 1,
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                type: 'section',
                                controll: 'partnerPages.item.button.add_section',
                                deleting: 'partnerPages.item.button.remove_section',
                                position: 0,
                                columns: 2,
                                objects: {
                                    title: {
                                        type: 'form-textarea',
                                        value: null,
                                        col: 1,
                                        name: 'form.form_title',
                                    },
                                    text: {
                                        type: 'form-editor',
                                        value: '',
                                        col: 1,
                                        name: 'form.block_text',
                                    },
                                    img: {
                                        type: 'form-img',
                                        value: null,
                                        col: 2,
                                        name: 'form.img',
                                    },
                                },
                            },
                            body: {
                                'object-1': {
                                    has_multiple: true,
                                    'title-1': {
                                        type: 'form-textarea',
                                        value: null,
                                        col: 1,
                                        name: 'form.form_title',
                                    },
                                    'text-1': {
                                        type: 'form-editor',
                                        value: '',
                                        col: 1,
                                        name: 'form.block_text',
                                    },
                                    'img-1': {
                                        type: 'form-img',
                                        value: null,
                                        col: 2,
                                        name: 'form.img',
                                    },
                                },
                            },
                        },
                    },
                },

                benefits: {
                    title: 'marketplace.druk.benefits_title',
                    body: {
                        cards: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                'card-title-1': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-1': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-2': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-2': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-3': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-3': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-4': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-4': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-5': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-5': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                            },
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.img',
                                },
                                'button-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                                'button-2': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                    },
                },

                recommendations: {
                    title: 'marketplace.druk.recommendations_title',
                    has_visible: 1,
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                type: 'section',
                                controll: 'partnerPages.item.button.add_section',
                                deleting: 'partnerPages.item.button.remove_section',
                                position: 0,
                                objects: {
                                    'module-img': {
                                        type: 'form-img',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.img',
                                    },
                                    'module-title': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.form_title',
                                    },
                                    'module-button': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.check_link',
                                    },
                                    'module-link': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'common.link',
                                        prompt: 'partnerPages.item.link_prompt',
                                    },
                                },
                            },
                            body: {
                                'object-1': {
                                    has_multiple: true,
                                    'module-img-1': {
                                        type: 'form-img',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.img',
                                    },
                                    'module-title-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.form_title',
                                    },
                                    'module-button-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.check_link',
                                    },
                                    'module-link-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'common.link',
                                        prompt: 'partnerPages.item.link_prompt',
                                    },
                                },
                            },
                        },
                    },
                },

                footer: {
                    title: 'marketplace.druk.demo_title',
                    body: {
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                button: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                    },
                },
            },

            storage: {
                is_module: true,
                promo: {
                    title: 'promo.section',
                    body: {
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            payload: {
                                editable: true,
                                controll: 'partnerPages.item.button.add_menu_option',
                                target: 'option',
                                position: 2,
                                object: {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                'option-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                                'button-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                                'button-2': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                    },
                },

                reasons: {
                    title: 'marketplace.druk.reasons_title',
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                controll: 'partnerPages.item.button.add_list_option',
                                target: 'menu-option',
                                position: 0,
                                object: {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                            body: {
                                'menu-option-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                        },
                    },
                },

                description: {
                    title: 'marketplace.druk.description_title',
                    has_visible: 1,
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                type: 'section',
                                controll: 'partnerPages.item.button.add_section',
                                deleting: 'partnerPages.item.button.remove_section',
                                position: 0,
                                columns: 2,
                                objects: {
                                    title: {
                                        type: 'form-textarea',
                                        value: null,
                                        col: 1,
                                        name: 'form.form_title',
                                    },
                                    text: {
                                        type: 'form-editor',
                                        value: '',
                                        col: 1,
                                        name: 'form.block_text',
                                    },
                                    img: {
                                        type: 'form-img',
                                        value: null,
                                        col: 2,
                                        name: 'form.img',
                                    },
                                },
                            },
                            body: {
                                'object-1': {
                                    has_multiple: true,
                                    'title-1': {
                                        type: 'form-textarea',
                                        value: null,
                                        col: 1,
                                        name: 'form.form_title',
                                    },
                                    'text-1': {
                                        type: 'form-editor',
                                        value: '',
                                        col: 1,
                                        name: 'form.block_text',
                                    },
                                    'img-1': {
                                        type: 'form-img',
                                        value: null,
                                        col: 2,
                                        name: 'form.img',
                                    },
                                },
                            },
                        },
                    },
                },

                benefits: {
                    title: 'marketplace.druk.benefits_title',
                    body: {
                        cards: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                'card-title-1': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-1': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-2': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-2': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-3': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-3': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-4': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-4': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-5': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-5': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                            },
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.img',
                                },
                                'button-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                                'button-2': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                    },
                },

                recommendations: {
                    title: 'marketplace.druk.recommendations_title',
                    has_visible: 1,
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                type: 'section',
                                controll: 'partnerPages.item.button.add_section',
                                deleting: 'partnerPages.item.button.remove_section',
                                position: 0,
                                objects: {
                                    'module-img': {
                                        type: 'form-img',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.img',
                                    },
                                    'module-title': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.form_title',
                                    },
                                    'module-button': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.check_link',
                                    },
                                    'module-link': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'common.link',
                                        prompt: 'partnerPages.item.link_prompt',
                                    },
                                },
                            },
                            body: {
                                'object-1': {
                                    has_multiple: true,
                                    'module-img-1': {
                                        type: 'form-img',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.img',
                                    },
                                    'module-title-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.form_title',
                                    },
                                    'module-button-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.check_link',
                                    },
                                    'module-link-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'common.link',
                                        prompt: 'partnerPages.item.link_prompt',
                                    },
                                },
                            },
                        },
                    },
                },

                footer: {
                    title: 'marketplace.druk.demo_title',
                    body: {
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                button: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                    },
                },
            },

            'website-management': {
                is_module: true,
                promo: {
                    title: 'promo.section',
                    body: {
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            payload: {
                                editable: true,
                                controll: 'partnerPages.item.button.add_menu_option',
                                target: 'option',
                                position: 2,
                                object: {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                'option-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                                'button-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                                'button-2': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                    },
                },

                reasons: {
                    title: 'marketplace.druk.reasons_title',
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                controll: 'partnerPages.item.button.add_list_option',
                                target: 'menu-option',
                                position: 0,
                                object: {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                            body: {
                                'menu-option-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.menu_option',
                                },
                            },
                        },
                    },
                },

                description: {
                    title: 'marketplace.druk.description_title',
                    has_visible: 1,
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                type: 'section',
                                controll: 'partnerPages.item.button.add_section',
                                deleting: 'partnerPages.item.button.remove_section',
                                position: 0,
                                columns: 2,
                                objects: {
                                    title: {
                                        type: 'form-textarea',
                                        value: null,
                                        col: 1,
                                        name: 'form.form_title',
                                    },
                                    text: {
                                        type: 'form-editor',
                                        value: '',
                                        col: 1,
                                        name: 'form.block_text',
                                    },
                                    img: {
                                        type: 'form-img',
                                        value: null,
                                        col: 2,
                                        name: 'form.img',
                                    },
                                },
                            },
                            body: {
                                'object-1': {
                                    has_multiple: true,
                                    'title-1': {
                                        type: 'form-textarea',
                                        value: null,
                                        col: 1,
                                        name: 'form.form_title',
                                    },
                                    'text-1': {
                                        type: 'form-editor',
                                        value: '',
                                        col: 1,
                                        name: 'form.block_text',
                                    },
                                    'img-1': {
                                        type: 'form-img',
                                        value: null,
                                        col: 2,
                                        name: 'form.img',
                                    },
                                },
                            },
                        },
                    },
                },

                benefits: {
                    title: 'marketplace.druk.benefits_title',
                    body: {
                        cards: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                'card-title-1': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-1': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-2': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-2': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-3': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-3': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-4': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-4': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                                'card-title-5': {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.card_title',
                                },
                                'card-text-5': {
                                    type: 'form-editor',
                                    value: null,
                                    name: 'form.card_text',
                                },
                            },
                        },
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                img: {
                                    type: 'form-img',
                                    value: null,
                                    name: 'form.img',
                                },
                                'button-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                                'button-2': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                    },
                },

                recommendations: {
                    title: 'marketplace.druk.recommendations_title',
                    has_visible: 1,
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                type: 'section',
                                controll: 'partnerPages.item.button.add_section',
                                deleting: 'partnerPages.item.button.remove_section',
                                position: 0,
                                objects: {
                                    'module-img': {
                                        type: 'form-img',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.img',
                                    },
                                    'module-title': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.form_title',
                                    },
                                    'module-button': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.check_link',
                                    },
                                    'module-link': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'common.link',
                                        prompt: 'partnerPages.item.link_prompt',
                                    },
                                },
                            },
                            body: {
                                'object-1': {
                                    has_multiple: true,
                                    'module-img-1': {
                                        type: 'form-img',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.img',
                                    },
                                    'module-title-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.form_title',
                                    },
                                    'module-button-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'form.check_link',
                                    },
                                    'module-link-1': {
                                        type: 'form-textarea',
                                        colspan: 4,
                                        value: null,
                                        name: 'common.link',
                                        prompt: 'partnerPages.item.link_prompt',
                                    },
                                },
                            },
                        },
                    },
                },

                footer: {
                    title: 'marketplace.druk.demo_title',
                    body: {
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                button: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                    },
                },
            },

            pricing: {
                header: {
                    title: 'promo.section',
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        subtitle: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.subtitle',
                        },
                        softtitle: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.subtitle',
                        },
                        text: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.text',
                        },
                    },
                },

                grid: {
                    title: 'partnerPages.pricing.plans',
                    body: {
                        picker: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.picker',
                        },
                        plans: {
                            type: 'section',
                            name: 'partnerPages.pricing.plans',
                            payload: {
                                editable: true,
                                type: 'section',
                                controll: 'partnerPages.item.button.add_plan',
                                deleting: 'partnerPages.item.button.remove_plan',
                                addEmit: 'on-add-col',
                                deleteEmit: 'on-delete-col',
                                objects: {
                                    profile: 1,
                                    title: {
                                        type: 'form-textarea',
                                        value: null,
                                        colspan: 4,
                                        name: 'pricing.plan_title',
                                    },
                                    month_price: {
                                        type: 'form-textarea',
                                        value: null,
                                        colspan: 4,
                                        name: 'pricing.plan_month_price',
                                    },
                                    year_price: {
                                        type: 'form-textarea',
                                        value: null,
                                        colspan: 4,
                                        name: 'pricing.plan_year_price',
                                    },
                                    button: {
                                        type: 'form-textarea',
                                        value: null,
                                        colspan: 4,
                                        name: 'form.button',
                                    },
                                },
                            },
                            body: {
                                'object-1': {
                                    has_multiple: true,
                                    'profile-1': 1,
                                    'title-1': {
                                        type: 'form-textarea',
                                        value: null,
                                        colspan: 4,
                                        name: 'pricing.plan_title',
                                    },
                                    'month_price-1': {
                                        type: 'form-textarea',
                                        value: null,
                                        colspan: 4,
                                        name: 'pricing.plan_month_price',
                                    },
                                    'year_price-1': {
                                        type: 'form-textarea',
                                        value: null,
                                        colspan: 4,
                                        name: 'pricing.plan_year_price',
                                    },
                                    'button-1': {
                                        type: 'form-textarea',
                                        value: null,
                                        colspan: 4,
                                        name: 'form.button',
                                    },
                                },
                            },
                        },
                        benefits: {
                            type: 'section',
                            name: 'partnerPages.pricing.plans_benefits',
                            payload: {
                                editable: true,
                                type: 'section',
                                controll: 'partnerPages.item.button.add_benefit',
                                deleting: 'partnerPages.item.button.remove_benefit',
                                addEmit: 'on-add-row',
                                deleteEmit: 'on-delete-row',
                                has_outer_deleteing: true,
                                has_static: true,
                                columns: [9, 3],
                                objects: {
                                    profile: 1,
                                    title: {
                                        type: 'form-textarea',
                                        value: null,
                                        col: 9,
                                        name: 'common.benefit',
                                    },
                                    is_important: {
                                        type: 'form-checkbox',
                                        value: false,
                                        col: 3,
                                        prompt: {
                                            text: 'common.always_show.mobile_prompt',
                                            from: 'top-left',
                                        },
                                        name: 'common.always_show',
                                    },
                                },
                            },
                            body: {
                                'object-1': {
                                    has_multiple: true,
                                    'profile-1': 1,
                                    'title-1': {
                                        type: 'form-textarea',
                                        value: null,
                                        col: 9,
                                        name: 'common.benefit',
                                    },
                                    'is_important-1': {
                                        type: 'form-checkbox',
                                        value: false,
                                        col: 3,
                                        prompt: {
                                            text: 'common.always_show.mobile_prompt',
                                            from: 'top-left',
                                        },
                                        name: 'common.always_show',
                                    },
                                },
                            },
                        },
                        table: {
                            type: 'table',
                            name: 'partnerPages.pricing.plans_table',
                            payload: {
                                cols_target: 'plans',
                                rows_target: 'benefits',
                                type: 'table',
                                options: {
                                    type: {
                                        option_id: 'value',
                                        list: [
                                            {
                                                title: null,
                                                key: 'common.unavailable_f',
                                                value: 'check',
                                            },
                                            {
                                                title: null,
                                                key: 'common.available_f',
                                                value: 'xmark',
                                            },
                                            {
                                                title: null,
                                                key: 'form.text',
                                                value: 'text',
                                            },
                                        ],
                                        flexed: true,
                                        has_space_between: true,
                                        has_event_change: true,
                                    },
                                },
                                objects: {
                                    col: null,
                                    row: null,
                                    type: {
                                        type: 'form-radio-buttons-group',
                                        value: null,
                                        name: 'common.type',
                                    },
                                    text: {
                                        type: 'form-textarea',
                                        value: null,
                                        name: 'form.text',
                                        only_for: { key: 'type', value: 'text' },
                                    },
                                },
                            },
                            body: {
                                'object-1_1': {
                                    col: 'object-1',
                                    row: 'object-1',
                                    type: {
                                        type: 'form-radio-buttons-group',
                                        value: null,
                                        name: 'common.type',
                                    },
                                    text: {
                                        type: 'form-textarea',
                                        value: null,
                                        name: 'form.text',
                                        only_for: { key: 'type', value: 'text' },
                                    },
                                },
                            },
                        },
                        prompt: {
                            type: 'form-editor',
                            value: '',
                            name: 'form.prompt',
                        },
                    },
                },

                server: {
                    title: 'partnerPages.pricing.server_title',
                    body: {
                        content: {
                            type: 'section',
                            colspan: 3,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                button: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                        text: {
                            type: 'form-editor',
                            value: '',
                            colspan: 9,
                            name: 'form.text',
                        },
                    },
                },

                questions: {
                    title: 'partnerPages.pricing.questions_title',
                    body: {
                        title: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.main_title',
                        },
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                type: 'section',
                                controll: 'partnerPages.item.button.add_card',
                                deleting: 'partnerPages.item.button.remove_card',
                                columns: 1,
                                objects: {
                                    title: {
                                        type: 'form-textarea',
                                        value: null,
                                        name: 'form.form_title',
                                    },
                                    text: {
                                        type: 'form-editor',
                                        value: '',
                                        name: 'form.block_text',
                                    },
                                },
                            },
                            body: {
                                'object-1': {
                                    has_multiple: true,
                                    'title-1': {
                                        type: 'form-textarea',
                                        value: null,
                                        name: 'form.card_title',
                                    },
                                    'text-1': {
                                        type: 'form-editor',
                                        value: '',
                                        name: 'form.card_text',
                                    },
                                },
                            },
                        },
                    },
                },

                demo: {
                    title: 'marketplace.druk.demo_title',
                    body: {
                        content: {
                            type: 'section',
                            colspan: 6,
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.subtitle',
                                },
                                button: {
                                    type: 'form-textarea',
                                    value: null,
                                    name: 'form.button',
                                },
                            },
                        },
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                    },
                },

                integration: {
                    title: 'partnerPages.pricing.integration_title',
                    body: {
                        content: {
                            type: 'section',
                            payload: {
                                editable: true,
                                controll: 'partnerPages.item.button.add_list_option',
                                target: 'option',
                                position: 0,
                                object: {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.list_option',
                                },
                            },
                            body: {
                                title: {
                                    type: 'form-textarea',
                                    value: null,
                                    colspan: 12,
                                    name: 'form.main_title',
                                },
                                subtitle: {
                                    type: 'form-textarea',
                                    value: null,
                                    colspan: 12,
                                    name: 'form.subtitle',
                                },
                                'option-1': {
                                    type: 'form-textarea',
                                    colspan: 6,
                                    value: null,
                                    name: 'form.list_option',
                                },
                                prompt: {
                                    type: 'form-textarea',
                                    value: null,
                                    colspan: 12,
                                    name: 'form.prompt',
                                },
                                button: {
                                    type: 'form-textarea',
                                    value: null,
                                    colspan: 12,
                                    name: 'form.button',
                                },
                            },
                        },
                    },
                },
            },

            // @Explanation: I recommend using a small test page for testing of the new features
            test: {
                is_module: true,

                test: {
                    title: 'TestPage',
                    body: {
                        title: {
                            type: 'form-textarea',
                            colspan: 6,
                            value: null,
                            name: 'form.main_title',
                        },
                        img: {
                            type: 'form-img',
                            colspan: 6,
                            value: null,
                            name: 'form.img',
                        },
                    },
                },

                grid: {
                    title: 'partnerPages.pricing.plans',
                    body: {
                        picker: {
                            type: 'form-textarea',
                            value: null,
                            name: 'form.picker',
                        },
                        plans: {
                            type: 'section',
                            name: 'partnerPages.pricing.plans',
                            payload: {
                                editable: true,
                                type: 'section',
                                controll: 'partnerPages.item.button.add_plan',
                                deleting: 'partnerPages.item.button.remove_plan',
                                addEmit: 'on-add-col',
                                deleteEmit: 'on-delete-col',
                                objects: {
                                    profile: 1,
                                    title: {
                                        type: 'form-textarea',
                                        value: null,
                                        colspan: 4,
                                        name: 'pricing.plan_title',
                                    },
                                    month_price: {
                                        type: 'form-textarea',
                                        value: null,
                                        colspan: 4,
                                        name: 'pricing.plan_month_price',
                                    },
                                    year_price: {
                                        type: 'form-textarea',
                                        value: null,
                                        colspan: 4,
                                        name: 'pricing.plan_year_price',
                                    },
                                    button: {
                                        type: 'form-textarea',
                                        value: null,
                                        colspan: 4,
                                        name: 'form.button',
                                    },
                                },
                            },
                            body: {
                                'object-1': {
                                    has_multiple: true,
                                    'profile-1': 1,
                                    'title-1': {
                                        type: 'form-textarea',
                                        value: null,
                                        colspan: 4,
                                        name: 'pricing.plan_title',
                                    },
                                    'month_price-1': {
                                        type: 'form-textarea',
                                        value: null,
                                        colspan: 4,
                                        name: 'pricing.plan_month_price',
                                    },
                                    'year_price-1': {
                                        type: 'form-textarea',
                                        value: null,
                                        colspan: 4,
                                        name: 'pricing.plan_year_price',
                                    },
                                    'button-1': {
                                        type: 'form-textarea',
                                        value: null,
                                        colspan: 4,
                                        name: 'form.button',
                                    },
                                },
                            },
                        },
                        benefits: {
                            type: 'section',
                            name: 'partnerPages.pricing.plans_benefits',
                            payload: {
                                editable: true,
                                type: 'section',
                                controll: 'partnerPages.item.button.add_benefit',
                                deleting: 'partnerPages.item.button.remove_benefit',
                                addEmit: 'on-add-row',
                                deleteEmit: 'on-delete-row',
                                has_outer_deleteing: true,
                                has_static: true,
                                columns: [9, 3],
                                objects: {
                                    profile: 1,
                                    title: {
                                        type: 'form-textarea',
                                        value: null,
                                        col: 9,
                                        name: 'common.benefit',
                                    },
                                    is_important: {
                                        type: 'form-checkbox',
                                        value: false,
                                        col: 3,
                                        prompt: {
                                            text: 'common.always_show.mobile_prompt',
                                            from: 'top-left',
                                        },
                                        name: 'common.always_show',
                                    },
                                },
                            },
                            body: {
                                'object-1': {
                                    has_multiple: true,
                                    'profile-1': 1,
                                    'title-1': {
                                        type: 'form-textarea',
                                        value: null,
                                        col: 9,
                                        name: 'common.benefit',
                                    },
                                    'is_important-1': {
                                        type: 'form-checkbox',
                                        value: false,
                                        col: 3,
                                        prompt: {
                                            text: 'common.always_show.mobile_prompt',
                                            from: 'top-left',
                                        },
                                        name: 'common.always_show',
                                    },
                                },
                            },
                        },
                        table: {
                            type: 'table',
                            name: 'partnerPages.pricing.plans_table',
                            payload: {
                                cols_target: 'plans',
                                rows_target: 'benefits',
                                type: 'table',
                                options: {
                                    type: {
                                        option_id: 'value',
                                        list: [
                                            {
                                                title: null,
                                                key: 'common.unavailable_f',
                                                value: 'check',
                                            },
                                            {
                                                title: null,
                                                key: 'common.available_f',
                                                value: 'xmark',
                                            },
                                            {
                                                title: null,
                                                key: 'form.text',
                                                value: 'text',
                                            },
                                        ],
                                        flexed: true,
                                        has_space_between: true,
                                        has_event_change: true,
                                    },
                                },
                                objects: {
                                    col: null,
                                    row: null,
                                    type: {
                                        type: 'form-radio-buttons-group',
                                        value: null,
                                        name: 'common.type',
                                    },
                                    text: {
                                        type: 'form-textarea',
                                        value: null,
                                        name: 'form.text',
                                        only_for: { key: 'type', value: 'text' },
                                    },
                                },
                            },
                            body: {
                                'object-1_1': {
                                    col: 'object-1',
                                    row: 'object-1',
                                    type: {
                                        type: 'form-radio-buttons-group',
                                        value: null,
                                        name: 'common.type',
                                    },
                                    text: {
                                        type: 'form-textarea',
                                        value: null,
                                        name: 'form.text',
                                        only_for: { key: 'type', value: 'text' },
                                    },
                                },
                            },
                        },
                        prompt: {
                            type: 'form-editor',
                            value: '',
                            name: 'form.prompt',
                        },
                    },
                },
            },
        },
    },

    mutations: {
        ADD_ELEMENT(state, { pageKey, blockKey, sectionKey, item, itemKey }) {
            let section = state.partnerPagesFieldsMap[pageKey][blockKey].body[sectionKey],
                target = section.payload.target,
                filteredBody = $fn.filter(section.body, (el, key) => {
                    return key.includes(target);
                }),
                lastFlexibleItemIndex = Object.values(section.body).indexOf($fn.last(filteredBody)),
                newOptionKey = itemKey || `${target}-${$fn.generateHash()}`,
                newOptionIndex = lastFlexibleItemIndex !== -1 ? lastFlexibleItemIndex + 1 : section.payload.position,
                newBody = {};

            Object.keys(section.body).forEach((key, index) => {
                if (index === newOptionIndex) newBody[newOptionKey] = item;
                newBody[key] = section.body[key];
            });

            if (!newBody[newOptionKey]) newBody[newOptionKey] = item;

            if (section.payload.objects) {
                let sortedBody = {};

                $fn.sortBy(Object.keys(newBody)).forEach((key) => {
                    let legitValues = Object.entries(newBody[key]).filter((legitValue) =>
                        Object.keys(section.payload.objects).some((payloadKey) => legitValue[0].includes(payloadKey)),
                    );

                    legitValues = legitValues.map((legitValue) => {
                        let bindedValue = Object.entries(section.payload.objects).find((entry) => legitValue[0].includes(entry[0]));

                        if (typeof legitValue[1] === 'object' && 'value' in legitValue[1]) {
                            legitValue[1] = { ...bindedValue[1], value: legitValue[1].value };
                        }

                        return legitValue;
                    });

                    sortedBody[key] = Object.fromEntries(legitValues);
                });

                newBody = sortedBody;
            }

            Vue.set(state.partnerPagesFieldsMap[pageKey][blockKey].body[sectionKey], 'body', newBody);
        },

        REMOVE_ELEMENT(state, { pageKey, blockKey, sectionKey, itemKey }) {
            Vue.delete(state.partnerPagesFieldsMap[pageKey][blockKey].body[sectionKey].body, itemKey);
        },
    },
};
