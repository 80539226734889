<template>
    <div class="druk-c-lin-ind">
        <div class="druk-c-lin-ind__bar">
            <div :style="{ width: `${progress}%` }" class="druk-c-lin-ind__prog" :class="{ 'druk-is-inverse': isInverse }"></div>

            <div
                :style="{ width: `${trackWidth}%` }"
                class="druk-c-lin-ind__track"
                :class="{ 'is-exist': isTrackExist, 'druk-is-inverse': isInverse }"></div>

            <div class="druk-c-lin-ind__stop" :class="{ 'druk-is-inverse': isInverse }"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'druk-linear-indicator',

        props: {
            progress: [String, Number],

            isIndeterminate: {
                type: Boolean,
                default: false,
            },

            isInverse: Boolean,
        },

        computed: {
            trackWidth() {
                return 100 - +this.progress;
            },

            isTrackExist() {
                return +this.progress < 100;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .druk-c-lin-ind {
        margin: 0px 4px;
        &__bar {
            position: relative;
            display: flex;
            align-items: center;
            padding: 2px 0px;
            width: 100%;
        }
        &__prog,
        &__stop {
            background-color: var(--druk-primary);
            &.druk-is-inverse {
                background-color: var(--druk-inverse-primary);
            }
        }
        &__prog,
        &__track,
        &__stop {
            content: '';
            height: 4px;
            border-radius: 8px;
        }
        &__track {
            margin-left: 0px;
            background-color: var(--druk-secondary-container);
            transition: margin-left 0.2s ease-in-out;
            &.is-exist {
                margin-left: 4px;
            }
            &.druk-is-inverse {
                background-color: var(--druk-inverse-secondary-container-variant);
            }
        }
        &__stop {
            position: absolute;
            right: 0px;
            width: 4px;
            border-radius: 50%;
        }
    }
</style>
