<template>
    <div class="login">
        <div class="login__logo">
            <img :src="require('@img/logo.svg')" alt="logo" @error="$fn.fixImageError" />
        </div>

        <div class="form">
            <div class="form__head">
                <div class="form__title">
                    <div class="form__title-text">{{ $t('login.title') }}</div>
                </div>
            </div>

            <div v-if="errorMessage" class="form__body-support">
                <druk-support :isError="true">{{ errorMessage }}</druk-support>
            </div>

            <template v-if="hasBugs">
                <druk-support v-for="(bug, index) in currentBugs" :key="index" :isError="true">
                    {{ $t($customNoty[bug]) }}
                </druk-support>
            </template>

            <div class="form__item">
                <form-input-login v-model="payload.email" :label="$t('form.login')" rules="required|email" name="email" />
            </div>

            <div class="form__item">
                <form-input-login
                    v-model="payload.password"
                    type="password"
                    :label="$t('form.password')"
                    rules="required"
                    name="password" />
            </div>

            <div class="form__footer">
                <druk-button :label="$t('admin.btn.enter')" :isWaiting="loadingLogin" :isFull="true" @click="onSubmit" />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    import mixinBuildApp from '@mixins/mixinBuildApp';

    import ModalLockedUser from '@common/login/ModalLockedUser';

    export default {
        name: 'login-index',

        mixins: [mixinBuildApp],

        data() {
            return {
                payload: {
                    email: null,
                    password: null,
                },

                authBugs: ['is_locked', 'is_deactivated'],
                currentBugs: [],
            };
        },

        created() {
            this.SET_PROJECT_THEME(this.THEME_LIGHT);
            this.SET_LAYOUT(this.KEY_LAYOUT_LOGIN);
        },

        computed: {
            ...mapState({
                KEY_LAYOUT_LOGIN: (state) => state.KEY_LAYOUT_LOGIN,
                VERIFY_ERROR: (state) => state.VERIFY_ERROR,
                VERIFY_ERROR_MESSAGE: (state) => state.VERIFY_ERROR_MESSAGE,

                user: (state) => state.auth.user,

                loadingLogin: (state) => state.auth.loadingLogin,
            }),

            errorMessage() {
                return this.VERIFY_ERROR
                    ? this.$t('auth.verify_error')
                    : !this.VERIFY_ERROR && this.VERIFY_ERROR_MESSAGE
                      ? this.$t('auth.user_not_found')
                      : null;
            },

            hasBugs() {
                return !!this.currentBugs.length;
            },
        },

        methods: {
            ...mapMutations({
                SET_LAYOUT: 'SET_LAYOUT',
            }),
            ...mapActions({
                AUTH_LOGIN: 'AUTH_LOGIN',
                USER_FETCH: 'USER_FETCH',
            }),

            async onSubmit(e) {
                let result = await this.$validator.validate();

                if (result) {
                    try {
                        await this.AUTH_LOGIN(this.payload);

                        try {
                            await this.USER_FETCH();

                            this.hasTypographyAccess ? this.buildTypographyApp(this.user.typography.id) : this.buildErpApp();
                        } catch (e) {
                            this.onCatchBug(e);
                        }
                    } catch (e) {
                        this.onCatchBug(e);
                    }
                } else $fn.showFormError(e.target.closest('.druk-c-btn'), this.$validator.errors.items);
            },

            onCatchBug(error) {
                let errors = error?.response?.data?.errors,
                    isSpecificBug = !!this.authBugs.find((item) => !!errors?.[item]);

                if (!isSpecificBug) {
                    this.setCurrentBugs();
                    return $fn.setValidateErrors(error, this.errors);
                }

                errors.is_locked ? this.onModalLockedUser() : this.setCurrentBugs(Object.keys(errors));
            },

            setCurrentBugs(bugs = []) {
                this.currentBugs = bugs;
            },

            onModalLockedUser() {
                this.$modal.show(ModalLockedUser, {}, $MODAL_OPTIONS.normal);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .login {
        max-width: 550px;
        width: 100%;
        margin: 0 auto;
        padding: 0 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        &__error {
            height: 30px;
            text-align: center;
        }
        &__logo {
            display: flex;
            justify-content: center;
            margin: 0px 0px 24px;
        }
        @media (max-width: $druk-breakpoints-def) {
            padding: 0 5%;
        }
    }

    .form {
        padding: 24px 16px;
        border-radius: 16px;
        background-color: var(--druk-background);
        border: 1px solid var(--druk-outline-variant);
        &__head {
            margin-bottom: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &__title {
            text-align: center;
            color: var(--druk-on-surface-variant);
            margin-bottom: 0;
            &-text {
                font-size: 22px;
                line-height: 28px;
                letter-spacing: 0;
                font-weight: 400;
            }
        }
        &__item {
            margin-bottom: 20px;
            position: relative;
            &--option {
                display: flex;
                .input {
                    width: 100%;
                }
                .form__option {
                    padding-top: 10px;
                }
            }
        }
        &__editor {
            margin-bottom: 20px;
        }
        &__footer {
            display: flex;
            justify-content: flex-end;
            padding-top: 12px;
            border-top: none;
        }
    }
</style>
