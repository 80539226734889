<template>
    <component
        :is="!!path ? 'router-link' : 'button'"
        :to="path"
        class="druk-c-icon-btn-tglbl"
        :class="{ 'druk-is-waitnig': isWaiting, 'druk-is-disabled': isDisabled }"
        @click="$emit('click', $event)">
        <div
            class="druk-c-icon-btn-tglbl__wrapper"
            :class="{ [`druk-u-${type}`]: !!type, 'druk-is-active': isActive, 'druk-is-disabled': isDisabled }">
            <div
                v-if="icon"
                class="druk-c-icon-btn-tglbl__icon"
                :class="{ 'druk-is-shown': !isWaiting, 'druk-is-disabled': isDisabled }">
                <druk-icon
                    class="druk-c-icon-btn-tglbl__icon-cnt"
                    :class="{ 'druk-is-shown': isActive }"
                    :color="palette ? $material[palette.active_color] : isDisabled ? DISABLED_COLOR : iconColorsMap[type]"
                    :name="icon"
                    :variant="'solid'" />

                <druk-icon
                    class="druk-c-icon-btn-tglbl__icon-cnt"
                    :class="{ 'druk-is-shown': !isActive }"
                    :color="palette ? $material[palette.color] : isDisabled ? DISABLED_COLOR : iconColorsMap[type]"
                    :name="icon"
                    :variant="'regular'" />
            </div>

            <div v-if="isWaiting && !isDisabled" class="druk-c-icon-btn-tglbl__loader">
                <druk-circular-indicator :size="'s'" :color="iconColorsMap[type]" />
            </div>

            <div class="druk-c-icon-btn-tglbl__state" :class="{ [`druk-u-${type}`]: !!type, 'druk-is-active': isActive }"></div>
        </div>
    </component>
</template>

<script>
    export default {
        name: 'druk-icon-toggle-button',

        props: {
            type: {
                type: String,
                default: 'filled', // @Explanation: Possible values - filled, tonal, outlined, standard
            },
            icon: {
                type: String,
                default: 'gear',
            },

            path: Object,
            palette: Object,
            // @TODO: Will be need develop color and outine and state-layers logic
            // @Explanation: Object parameters:
            // @color: Button's icon/text color
            // @bg: Button's background color
            // @outline: Button's outline color

            isActive: Boolean,
            isWaiting: Boolean,
            isDisabled: [Boolean, Number],
        },

        data() {
            return {
                DISABLED_COLOR: 'on-surface',
            };
        },

        computed: {
            iconColorsMap() {
                return {
                    filled: this.isActive
                        ? 'tokens-icon-toggle-button-filled-active-color'
                        : 'tokens-icon-toggle-button-filled-disactive-color',
                    tonal: this.isActive ? 'on-secondary-container' : 'on-surface-variant',
                    outlined: this.isActive ? 'tokens-icon-toggle-button-outlined-active-color' : 'on-surface-variant',
                    standard: this.isActive ? 'primary' : 'on-surface-variant',
                };
            },
        },
    };
</script>

<style lang="scss" scoped>
    .druk-c-icon-btn-tglbl {
        outline: initial;
        border: initial;
        background-color: transparent;
        &.druk-is-waitnig,
        &.druk-is-disabled {
            cursor: initial;
            pointer-events: none;
            opacity: 1;
        }
        &__wrapper {
            cursor: pointer;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 4px;
            width: 40px;
            height: 40px;
            border-radius: 100px;
            transition-duration: var(--druk-duration-short-4);
            transition-timing-function: var(--druk-easing-emphasized-accelerate);
            &:hover,
            &:active {
                transition-duration: var(--druk-duration-medium-2);
                transition-timing-function: var(--druk-easing-emphasized-decelerate);
            }
            &.druk-u-filled {
                background-color: var(--druk-tokens-icon-toggle-button-filled-disactive-background);
            }
            &.druk-u-filled.druk-is-active {
                background-color: var(--druk-tokens-icon-toggle-button-filled-active-background);
            }
            &.druk-u-tonal {
                background-color: var(--druk-tokens-icon-toggle-button-tonal-disactive-background);
            }
            &.druk-u-tonal.druk-is-active {
                background-color: var(--druk-secondary-container);
            }
            &.druk-u-outlined {
                border: 1px solid var(--druk-outline);
            }
            &.druk-u-outlined.druk-is-active {
                border: 1px solid var(--druk-tokens-icon-toggle-button-outlined-active-outline);
                background-color: var(--druk-tokens-icon-toggle-button-outlined-active-background);
            }

            &.druk-is-disabled {
                cursor: initial;
                pointer-events: none;
                box-shadow: initial;
                opacity: 1;
            }
            &.druk-is-disabled.druk-u-filled {
                background-color: var(--druk-state-layers-on-surface-0-12);
            }
            &.druk-is-disabled.druk-u-tonal {
                background-color: var(--druk-state-layers-on-surface-0-12);
            }
            &.druk-is-disabled.druk-u-outlined {
                border: 1px solid var(--druk-state-layers-on-surface-0-12);
            }
            &.druk-is-disabled.druk-u-outlined.druk-is-active {
                border: 1px solid var(--druk-tokens-icon-toggle-button-outlined-active-disabled-outline);
                background-color: var(--druk-tokens-icon-toggle-button-outlined-active-disabled-background);
            }
        }
        &__icon {
            pointer-events: none;
            position: relative;
            z-index: 1;
            opacity: 0;
            &.druk-is-shown {
                opacity: 1;
            }
            &.druk-is-disabled {
                opacity: 0.38;
            }
            &-cnt {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 0;
                transition-property: opacity;
                transition-duration: var(--druk-duration-short-1);
                transition-timing-function: var(--druk-easing-emphasized-accelerate);
            }
            &-cnt.druk-is-shown {
                opacity: 1;
                transition-duration: var(--druk-duration-short-2);
                transition-timing-function: var(--druk-easing-emphasized-decelerate);
            }
        }
        &__loader {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &__state {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 100px;
            background-color: transparent;
            transition-property: background-color;
            transition-duration: var(--druk-duration-short-4);
            transition-timing-function: var(--druk-easing-emphasized-accelerate);
            &:hover,
            &:active {
                transition-duration: var(--druk-duration-medium-2);
                transition-timing-function: var(--druk-easing-emphasized-decelerate);
            }
            &.druk-u-filled:hover {
                background-color: var(--druk-state-layers-primary-0-08);
            }
            &.druk-u-filled:active {
                background-color: var(--druk-state-layers-primary-0-12);
            }
            &.druk-u-filled.druk-is-active:hover {
                background-color: var(--druk-tokens-icon-toggle-button-filled-active-hover);
            }
            &.druk-u-filled.druk-is-active:active {
                background-color: var(--druk-tokens-icon-toggle-button-filled-active-pressed);
            }
            &.druk-u-tonal:hover {
                background-color: var(--druk-state-layers-on-surface-variant-0-08);
            }
            &.druk-u-tonal:active {
                background-color: var(--druk-state-layers-on-surface-variant-0-12);
            }
            &.druk-u-tonal.druk-is-active:hover {
                background-color: var(--druk-state-layers-on-secondary-container-0-08);
            }
            &.druk-u-tonal.druk-is-active:active {
                background-color: var(--druk-state-layers-on-secondary-container-0-12);
            }
            &.druk-u-outlined:hover {
                background-color: var(--druk-state-layers-on-surface-variant-0-08);
            }
            &.druk-u-outlined:active {
                background-color: var(--druk-state-layers-on-surface-variant-0-12);
            }
            &.druk-u-outlined.druk-is-active:hover {
                background-color: var(--druk-tokens-icon-toggle-button-outlined-active-hover);
            }
            &.druk-u-outlined.druk-is-active:active {
                background-color: var(--druk-tokens-icon-toggle-button-outlined-active-pressed);
            }
            &.druk-u-standard:hover {
                background-color: var(--druk-state-layers-on-surface-variant-0-08);
            }
            &.druk-u-standard:active {
                background-color: var(--druk-state-layers-on-surface-variant-0-12);
            }
            &.druk-u-standard.druk-is-active:hover {
                background-color: var(--druk-state-layers-primary-0-08);
            }
            &.druk-u-standard.druk-is-active:active {
                background-color: var(--druk-state-layers-primary-0-12);
            }
        }
    }
</style>
