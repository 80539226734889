<template>
    <div
        class="selector selector--input"
        :class="{
            'has-focus': hasActive,
            'has-full': hasFull,
            'has-error': errors.has(name) || hasMaxLengthError,
            'has-loading': loader,
        }">
        <div class="selector__label" :class="{ [`druk-l-surface-${surface}`]: surface }" @click="onToggle">
            <span>
                <label>{{ label }}</label>
                <i v-if="hasRequired">*</i>
            </span>

            <druk-hint
                v-if="hint"
                :tooltip="{ text: hint.text || hint, from: 'top', maxWidth: hint.maxWidth, isNotCollapse: hint.isNotCollapse }"
                :icon="{ name: 'circle-info', size: 'xs', color: hasActive ? 'primary' : 'outline' }" />
        </div>

        <div class="selector__main" :class="{ [`druk-l-surface-${surface}`]: surface }">
            <div class="selector__beforeText" v-if="beforeText" @click="onToggle">
                {{ beforeText }}
            </div>

            <template>
                <input
                    :value="value"
                    class="selector__area"
                    :class="{
                        'has-password': type === TYPE_PASSWORD,
                        'has-before': beforeText,
                        'has-after': afterText,
                    }"
                    ref="area"
                    step="any"
                    :data-vv-name="name"
                    :data-vv-as="validatorLabel || label"
                    v-validate="rules || ''"
                    :key="name"
                    :placeholder="placeholder && !hasFull ? placeholder : null"
                    :type="type || 'text'"
                    :autocomplete="autocomplete || 'off'"
                    @focus="onFocus"
                    @blur="onBlur"
                    @keyup.enter="$emit('enter')"
                    @keyup="$emit('keyup')"
                    @input="$emit('input', $event.target.value)" />
            </template>

            <!-- <div v-if="placeholder && !hasFull" class="selector__placeholder">
				<span>{{placeholder}}</span>
			</div> -->

            <div class="selector__afterText" v-if="afterText" @click="onToggle">
                {{ afterText }}
            </div>

            <div
                class="selector__nav"
                v-if="isFilterType && !hasFull"
                :class="{ 'has-active': hasFull || hasActive }"
                @click="onToggle">
                <font-awesome-icon icon="fa-regular fa-magnifying-glass" />
            </div>

            <div class="selector__clear" v-if="isFilterType && hasFull" @click="onClear">
                <font-awesome-icon icon="fa-regular fa-xmark" />
            </div>

            <div
                v-if="buttonIcon"
                class="selector__option"
                :class="{
                    'druk-is-disabled': !hasFull && !canSaveEmpty,
                    'has-animate': !staticButton,
                }"
                @click="$emit('submit')">
                <druk-icon :name="buttonIcon" :color="!hasFull && !canSaveEmpty ? 'on-surface' : 'on-surface-variant'" />
            </div>

            <div
                v-if="hasShowLength"
                class="selector__label selector__label--bottom"
                :class="{ [`druk-l-surface-${surface}`]: surface }">
                {{ $t('common.symbols') }}: {{ value ? value.length : '0' }}<template v-if="maxLength">/{{ maxLength }}</template>
            </div>
        </div>

        <div v-if="errors.has(name)" class="selector__error">
            <span :class="{ [`druk-l-surface-${surface}`]: surface }">{{ errorText || errors.first(name) }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'form-input',

        inject: ['$validator'],

        props: {
            value: {
                required: true,
            },
            name: {
                type: String,
                required: true,
            },
            hint: [String, Object],
            mask: String,
            placeholder: [String, Object],
            type: String,
            label: String,
            validatorLabel: String,
            rules: [String, Object],
            autocomplete: String,
            errorText: String,
            buttonIcon: String,
            defaultCount: [Number, String],
            maxLength: [Number, String],
            loader: Boolean,
            canSaveEmpty: Boolean,
            staticButton: Boolean,
            isFilterType: Boolean,
            hasShowLength: Boolean,

            //
            surface: {
                type: String,
                default: 'tint-pale',
            },
        },

        data() {
            return {
                TYPE_PASSWORD: 'password',
                NUMBER_RULES: ['decimal', 'max_value', 'min_value'],

                count: this.defaultCount,

                hasActive: false,
            };
        },

        created() {
            if (!!this.value) this.onStartValidation();
        },

        watch: {
            defaultCount() {
                this.count = this.defaultCount;
            },

            loader() {
                this.onRemoveFocus();
            },
        },

        computed: {
            beforeText() {
                return typeof this.placeholder === 'object' ? this.placeholder.before : null;
            },

            afterText() {
                return typeof this.placeholder === 'object' ? this.placeholder.after : null;
            },

            hasFull() {
                return this.value !== null && this.value !== '' && this.value !== undefined;
            },

            hasRequired() {
                if (this.rules && (typeof this.rules === 'string' ? this.rules.indexOf('required') !== -1 : this.rules.required))
                    return true;

                return false;
            },

            hasMaxLengthError() {
                return this.maxLength && this.value?.length > this.maxLength;
            },
        },

        methods: {
            onToggle() {
                this.$refs.area.focus();
            },

            onClear() {
                if (!this.hasFull) return;
                this.$emit('input', null);
            },

            onFocus() {
                this.hasActive = true;
                this.$emit('onFocus', this.hasActive);
            },

            onBlur() {
                this.hasActive = false;
                this.$emit('onFocus', this.hasActive);
            },

            onRemoveFocus() {
                this.hasActive = false;
                this.$refs.area.blur();
            },

            onStartValidation() {
                let timer = setTimeout(() => {
                    clearTimeout(timer);
                    this.$validator.validate(this.name);
                }, 200);
            },
        },

        // @TODO: It's look like uneccessary super-logic. Maybe will be need remove that in the future, with project adaptation
        mounted() {
            let selectorLabel = this.$el.querySelector('.selector__label'),
                labelOffsetHeight = selectorLabel.getElementsByTagName('label')[0].offsetHeight;

            if (labelOffsetHeight > 30) selectorLabel.className += ' selector__label--double';
        },
    };
</script>

<style lang="scss" scoped></style>
