<template>
    <div class="druk-c-badge">
        <div class="druk-c-badge__wrapper" :class="{ 'druk-is-small': isSmall }">
            <div class="druk-c-badge__content">
                <div v-if="label" class="druk-c-badge__label druk-u-text-label-s">{{ label }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'druk-badge',

        props: {
            label: [String, Number],
        },

        computed: {
            isSmall() {
                return !this.label;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .druk-c-badge {
        display: inline-block;
        &__wrapper {
            padding: 0 4px;
            min-width: 16px;
            border-radius: 100px;
            color: var(--druk-on-error);
            background-color: var(--druk-error);
            &.druk-is-small {
                padding: 3px;
                min-width: initial;
            }
        }
        &__content {
            content: '';
        }
    }
</style>
