<template>
    <div class="vmodal">
        <div class="vmodal__head">
            <druk-icon-button :type="'standard'" :icon="'xmark'" @click="$emit('close')" />
        </div>

        <div class="vmodal__container">
            <div class="vmodal__main">
                <employees-item :id="user.id" :isModal="true" />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    import EmployeesItem from './EmployeesItem';

    export default {
        name: 'modal-employees-item',

        components: {
            EmployeesItem,
        },

        computed: {
            ...mapState({
                user: (state) => state.auth.user,
            }),
        },
    };
</script>

<style lang="scss" scoped></style>
