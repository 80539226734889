//utils
import './utils/filters';
import './utils/global-components';
import './utils/vee-validate';

import './utils/fontawesome/index.js';
import './utils/fontawesome/regular.js';
import './utils/fontawesome/solid.js';

import './utils/material';
Vue.prototype.$material = $material;

import './utils/fn';
Vue.prototype.$fn = $fn;

import './utils/deviceDetect';
Vue.prototype.$device = $device;

import './utils/colorFilter';
Vue.prototype.$colorFilter = $colorFilter;

import ChartJSCustomPlugins from './utils/chart-js/index.js';
Vue.prototype.$ChartJSCustomPlugins = ChartJSCustomPlugins;

//plugins
import './utils/axios/index';
import './utils/axios/customNoty';
Vue.prototype.$customNoty = $customNoty;

import i18n from './utils/i18n';

import Vue from './utils/vue_base.js';
import App from './App.vue';
Vue.config.productionTip = false;

import store from './store';
import router from './router';

//--------bus events----------------/
Vue.prototype.$bus = new Vue();

//--------noty--------------------------/
import VueNoty from 'vuejs-noty';
import 'vuejs-noty/dist/vuejs-noty.css';

Vue.use(VueNoty, {
    timeout: 4000,
    theme: 'sunset',
    progressBar: true,
    layout: 'bottomRight',
});

//--------echo---------------------/
import VueEcho from 'vue-echo-laravel';
import Pusher from 'pusher-js';

Vue.use(VueEcho, {
    broadcaster: 'pusher',
    key: 'druk',
    wsHost: process.env.VUE_APP_WS_HOST,
    wsPort: process.env.VUE_APP_WS_PORT,
    disableStats: true,
});

//-----hotjar---------------------/
import Hotjar from 'vue-hotjar';
Vue.use(Hotjar, {
    id: process.env.VUE_APP_HOTJAR_ID || '3194969', //@TODO delete default data
    snippetVersion: 6,
});

//--------modal--------------------------/
import VModal from 'vue-js-modal';
Vue.use(VModal, {
    dynamic: true,
    injectModalsContainer: true,
});

window.$MODAL_OPTIONS = {
    default: {
        delay: 100,
        draggable: false,
        adaptive: true,
        scrollable: true,
        height: 'auto',
        clickToClose: true,
    },
};
$MODAL_OPTIONS.tiny = {
    ...$MODAL_OPTIONS.default,
    width: 445,
    maxWidth: 445,
};
$MODAL_OPTIONS.normal = {
    ...$MODAL_OPTIONS.default,
    width: 700,
    maxWidth: 700,
};
$MODAL_OPTIONS.big = {
    ...$MODAL_OPTIONS.default,
    width: 900,
    maxWidth: 900,
};
$MODAL_OPTIONS.full = {
    ...$MODAL_OPTIONS.default,
    width: 1280,
    maxWidth: 1280,
};
$MODAL_OPTIONS.entire = {
    ...$MODAL_OPTIONS.default,
    width: '100%',
    maxWidth: 1600,
};
$MODAL_OPTIONS.alert = {
    ...$MODAL_OPTIONS.default,
    width: 280,
    maxWidth: 280,
    clickToClose: false,
};

//--------vue-awesome-swiper--------------------------/
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

Vue.use(VueAwesomeSwiper);

//--------vue-slider-component--------------------------/
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/material.css';

Vue.component('vue-slider', VueSlider);

//-------druk-snackbar--------------/
import snackbar from './utils/snackbar';
Vue.use(snackbar);

//--------------------------------------/
let app = null;

let urlParams = new URLSearchParams(window.location.search);

let routeLocale = urlParams.get('lang'),
    verifyError = urlParams.get('verify-error'),
    verifyErrorMessage = urlParams.get('error-message');

Vue.set(store.state, 'VERIFY_ERROR', !!+verifyError);
Vue.set(store.state, 'VERIFY_ERROR_MESSAGE', verifyErrorMessage);

i18n.setLang(routeLocale || i18n.locale).then(() => {
    app = new Vue({
        router,
        store,
        i18n,
        render: (h) => h(App),
    }).$mount('#app');

    window.$VUE_APP = app;
});
