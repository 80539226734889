import Vue from 'vue';

const COMMON_FORM_DATA = {
    api_key: null,
    delivery_warehouse: null,
};

const listParser = (list) => {
    return Object.entries(list)
        .map(([id, title]) => ({ id, title }))
        .sort((prev, next) => prev.title.localeCompare(next.title));
};

export default {
    namespaced: true,

    state: {
        formData: { ...COMMON_FORM_DATA },

        DPDBalticWarehouses: [],

        loadingGetDPDBalticWarehouses: false,
    },

    getters: {},

    actions: {
        GET_DPD_BALTIC_WAREHOUSES: async ({ state, rootState, rootGetters, getters, commit }, text) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetDPDBalticWarehouses', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `api/typographies/${rootGetters.currentTypography.id}/delivery/dpd-baltic/warehouses?text=${text}`
                    : `api/typographies/${rootState.typographies.item.id}/delivery/dpd-baltic/warehouses?text=${text}`;

                let resp = await $axios.get(path);

                commit('SET_DPD_BALTIC_WAREHOUSES', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetDPDBalticWarehouses', status: false });
            }
        },
    },

    mutations: {
        SET_FORM_DATA(state, formData) {
            Vue.set(state, 'formData', { ...formData });
        },

        SET_DPD_BALTIC_WAREHOUSES(state, list) {
            state.DPDBalticWarehouses = listParser(list);
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        RESET_FORM_DATA(state) {
            Vue.set(state, 'formData', { ...COMMON_FORM_DATA });
        },
    },
};
