<template>
    <div class="vmodal">
        <div class="vmodal__container">
            <div class="vmodal__main">
                <div class="alert">
                    <div class="alert__text" v-html="question || $t('alert.question')"></div>

                    <div class="alert__footer">
                        <template v-if="options.length">
                            <druk-button
                                v-for="(option, index) in options"
                                :key="index"
                                class="alert__footer-option"
                                :type="option.type"
                                :icon="option.icon"
                                :label="option.label"
                                @click="onHandleEvent(option)" />
                        </template>

                        <template v-else>
                            <druk-button
                                class="alert__footer-option"
                                :type="'outlined'"
                                :icon="'xmark'"
                                :label="$t('alert.no')"
                                @click="onCancel" />

                            <druk-button class="alert__footer-option" :icon="'check'" :label="$t('alert.yes')" @click="onVerify" />
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'modal-alert',

        props: {
            question: String,

            options: {
                type: Array,
                default: () => {
                    return [];
                },
            },

            handlers: Object,

            fnWait: Function,
            fnCancel: Function,
        },

        methods: {
            onHandleEvent(option) {
                this.handlers[option.handler]();
                this.$emit('close');
            },

            onCancel() {
                if (this.fnCancel) this.fnCancel();
                this.$emit('close');
            },

            onVerify() {
                this.fnWait();
                this.$emit('close');
            },
        },
    };
</script>

<style lang="scss" scoped>
    .alert {
        &__text {
            margin-bottom: 16px;
            text-align: center;
            color: var(--druk-on-surface);
        }
        &__footer {
            display: flex;
            align-items: center;
            justify-content: center;
            &-option {
                margin-right: 8px;
            }
            &-option:last-child {
                margin-right: 0;
            }
        }
    }
</style>
