import Vue from 'vue';

import filter from './filter';

export default {
    namespaced: true,

    modules: {
        filter,
    },

    state: {
        OPERATION_CAST: {
            index: null,
            group: null,
            item: null,

            contractor: null,
            contractorItem: null,

            contractorItems: [],

            material: null,
            format: null,
            equipment: null,

            materials: [],
            formats: [],
            equipments: [],

            materialParams: [],
            equipmentParams: [],

            repeated: 2,

            related_operation_id: null,

            isContractor: false,
            hasRepeats: false,
        },

        list: [],
        listPagination: {},

        contractorsAlgList: [],

        materialThreeParams: [],
        materialThreeFormats: [],

        equipmentThreeParams: [],

        item: {},
        filteredItem: {},

        listFilter: {
            title: null,
            is_custom: 0,
            page: null,
            limit: 30,
            sort: 'new',
            direction: 'desc',
        },

        formData: {},

        loadingGetList: false,
        loadingGetItem: false,
        loadingGetFilteredItem: false,
        loadingGetMaterialParamsByFormat: false,
        loadingGetMaterialParamsByOption: false,
        loadingGetEquipmentParamsByOption: false,
        loadingGetMaterialThree: false,
        loadingGetEquipmentThree: false,
        loadingSaveItem: false,
        loadingDeleteItem: false,
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },

        isFilterNotEmpty(state, getters, rootState) {
            return !!Object.keys(getters.listActiveFilter).filter(
                (key) => !rootState.constant.INACTIVE_FILTERING_KEYS.concat(state.INACTIVE_FILTERING_KEYS).includes(key),
            ).length;
        },
    },

    actions: {
        GET_LIST: async ({ commit, rootState, rootGetters, state, getters }, payload = { filters: {} }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/algorithms`, {
                    params: Object.keys(payload.filters).length ? payload.filters : getters.listActiveFilter,
                });

                commit('SET_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_CONTRACTORS_LIST: async ({ commit, rootState, rootGetters, state, getters }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/algorithms`, {
                    params: {
                        only_contractors: 1,
                        limit: 1000,
                    },
                });

                commit('SET_CONTRACTORS_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_ITEM: async ({ state, getters, rootGetters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/algorithms/${id}`);

                commit('SET_ITEM', resp.single);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        SAVE_ITEM: async ({ state, rootState, rootGetters, commit, dispatch }, { formData, without_fetch }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let resp = await $axios.post(`/api/typographies/${rootGetters.currentTypography.id}/algorithms`, formData);

                if (!without_fetch) dispatch('GET_LIST');

                if (formData.id && Object.keys(state.item).length) dispatch('GET_ITEM', formData.id);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        DELETE_ITEM: async ({ commit, rootGetters, dispatch }, { id, without_fetch, without_noty }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let path = `/api/typographies/${rootGetters.currentTypography.id}/algorithms/${id}`;

                let resp = await $axios.delete(path, {
                    headers: {
                        'Without-Noty': without_noty,
                    },
                });

                if (!without_fetch) dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_CONTRACTORS_LIST(state, list) {
            state.contractorsAlgList = list;
        },

        SET_ITEM(state, item) {
            state.item = item;
        },

        SET_FILTRED_ITEM(state, item) {
            state.filteredItem = item;
        },

        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },

        SET_FORM_DATA(state, formData) {
            Vue.set(state, 'formData', formData);
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        UPDATE_LIST_FILTER(state, newFilterlist) {
            Object.entries(newFilterlist).forEach(([key, value]) => Vue.set(state.listFilter, key, value));
            Vue.set(state.listFilter, 'page', newFilterlist.page || 1);
        },

        UPDATE_FORM_DATA(state, { key, index, value }) {
            Vue.set(state.formData[key], index, {
                ...state.formData[key][index],
                ...value,
            });
        },

        PUSH_TO_FORM_DATA(state, { key, value }) {
            state.formData[key].push(value);
        },

        SLICE_FROM_FORM_DATA(state, { index, key }) {
            Vue.delete(state.formData[key], index);
        },

        RESET_LIST(state) {
            state.list = [];
        },

        RESET_CONTRACTORS_LIST(state) {
            state.contractorsAlgList = [];
        },

        RESET_FORM_DATA(state) {
            state.formData = {};
        },

        RESET_STORE(state) {
            state.listFilter = {
                title: null,
                is_custom: 0,
                page: null,
                limit: 30,
                sort: 'new',
                direction: 'desc',
            };
        },
    },
};
