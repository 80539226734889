<template>
    <div class="chart druk-l-surface-tint-pale druk-l-shape-lg druk-is-in-development">
        <div class="chart__header druk-u-text-title-m">
            {{ $t('dashboard.orders.status_stacked_chart.title') }}
        </div>

        <div class="chart__body">
            <Bar :ref="'chart'" :chart-data="chartConfig.data" :chart-options="chartConfig.options" />
        </div>

        <div class="chart__footer"></div>

        <div v-if="loadingGetData" class="chart__loader">
            <div class="druk-l-card druk-l-card-m druk-l-surface druk-l-shape-lg druk-has-inverse-surface">
                <druk-loader :color="'inverse-on-surface'" :size="'lg'" />
            </div>
        </div>

        <div v-else class="chart__development-label druk-u-text-label-m">
            {{ $t('dashaboard.prompt.in_development') }}
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    import { Bar } from 'vue-chartjs/legacy';

    import mixinChart from '@mixins/mixinChart';

    export default {
        name: 'orders-stacked-chart',

        mixins: [mixinChart],

        components: {
            Bar,
        },

        computed: {
            ...mapState({
                ACTIVE_ORDER_STATUSES: (state) => state.orders.statuses.ACTIVE_ORDER_STATUSES,

                ordersData: (state) => state.dashboard.ordersData,
                statuses: (state) => state.orders.statuses.list,

                loadingGetData: (state) => state.dashboard.loadingGetData,
                loadingGetStatuses: (state) => state.orders.statuses.loadingGetList,
            }),

            chartConfig() {
                return {
                    data: {
                        labels: [this.clientOrders.label, this.managerOrders.label],

                        datasets: this.activeStatuses.map((status, index) => ({
                            label: status.title,
                            data: [this.clientOrders.data[index], this.managerOrders.data[index]],
                            borderWidth: 1,
                            borderColor: this.COLORS_MAP[status.color]?.border,
                            backgroundColor: this.COLORS_MAP[status.color]?.background,
                            hoverBackgroundColor: this.COLORS_MAP[status.color]?.layer_0_7,
                        })),
                    },

                    options: {
                        ...this.chartOptions,

                        scales: {
                            ...this.scalesPluginOptions,
                            x: {
                                ...this.scalesPluginOptions.x,
                                ...this.stackedScalesPluginOptions.x,
                                ticks: {
                                    ...this.scalesPluginOptions.x.ticks,
                                    ...this.stackedScalesPluginOptions.x.ticks,
                                },
                            },
                            y: {
                                ...this.scalesPluginOptions.y,
                                ...this.stackedScalesPluginOptions.y,
                                ticks: {
                                    ...this.scalesPluginOptions.y.ticks,
                                    ...this.stackedScalesPluginOptions.y.ticks,
                                },
                            },
                        },

                        plugins: {
                            datalabels: {
                                ...this.dataLabelsPluginOptions,
                                ...this.stackedLabelsPluginOptions,
                            },
                            doughnutlabel: {
                                display: false,
                            },
                            legend: {
                                ...this.legendPluginOptions,
                            },
                            tooltip: {
                                ...this.tooltipPluginOptions,
                                ...this.barTooltipPluginOptions,
                                ...this.stackedTooltipPluginOptions,
                            },
                        },
                    },
                };
            },

            activeStatuses() {
                return this.ACTIVE_ORDER_STATUSES.map((active_status) => ({
                    ...active_status,
                    ...this.statuses.find((status) => status.alias === active_status.alias),
                    data: this.ordersData[active_status.alias],
                }));
            },

            clientOrders() {
                return {
                    label: this.$t('dashboard.orders.source_chart.created_by_client'),
                    data: this.activeStatuses.map((active_status) => (Math.random() * (375 - 125) + 125).toFixed(0)),
                };
            },

            managerOrders() {
                return {
                    label: this.$t('dashboard.orders.source_chart.created_by_manager'),
                    data: this.activeStatuses.map((active_status) => (Math.random() * (500 - 245) + 245).toFixed(0)),
                };
            },
        },
    };
</script>
