// @Explanation: This is a mixin that provides basic data and methods for classic module building.
//                  You can integrate this code to the module and replace provided methods when you need it

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

export default {
    data() {
        return {
            hasLoadedGeneralData: false,
            hasBuildedData: false,
            hasLoadedSoftData: false,
            hasLoadedFilterData: false,
            hasLoadedSensitiveData: false,
        };
    },

    created() {
        if (!this.isInnerModule) this.initGeneralData();
        this.initSoftData();
    },

    activated() {
        if (this.isInnerModule) this.initGeneralData();
    },

    watch: {
        selectedLang() {
            this.updateGeneralData();
            this.updateSensitiveData();
        },
    },

    computed: {
        ...mapState({
            selectedLang: (state) => state.selectedLang,
        }),

        hasLoadedData() {
            return this.hasLoadedGeneralData && this.hasLoadedSoftData && this.hasBuildedData;
        },
    },

    methods: {
        async initGeneralData() {
            // @Explanation: This method is used to get the basic data needed for the module to work (such us data tables)
            await this.getList();

            this.buildData();
            this.hasLoadedGeneralData = true;
        },

        async buildData() {
            // @Explanation: This method is used to build module editable data (sach as formData or module tab)
            this.hasBuildedData = true;
        },

        async initSoftData() {
            // @Explanation: This method is used to get the support data needed for the module to work (such us sub-modules)
            this.initFilterData();
            this.hasLoadedSoftData = true;
        },

        async initFilterData() {
            // @Explanation: This method is used to get the basic data you need to build a filter block
            await this.initSensitiveData();
            this.hasLoadedFilterData = true;
        },

        async initSensitiveData() {
            // @Explanation: This method is used to get system language-sensitive data
            this.hasLoadedSensitiveData = true;
        },

        updateGeneralData() {
            // @Explanation: This method calls when system language has been changed
            this.hasLoadedGeneralData = false;
            this.hasBuildedData = false;

            this.initGeneralData();
        },

        updateSensitiveData() {
            // @Explanation: This method calls when system language has been changed
            this.hasLoadedSensitiveData = false;
            this.initSensitiveData();
        },
    },
};
