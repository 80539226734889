export default {
    //PAGES---------------------/
    'pages.index.title.stat': 'Статические страницы',
    'pages.index.title.sample_stat': 'Шаблон статических страниц',
    'pages.index.table.sample': 'Базовый шаблон',
    'pages.modalSave.title.staticpage': 'статическую страницу',
    'pages.modalSave.input.title': 'Заголовок страницы',
    'pages.modalSave.input.subtitle': 'Вторичный заголовок',
    'pages.modalSave.input.description': 'Контент страницы',
    'pages.modalSave.input.seo_text': 'SEO контент',
    'pages.modalSave.input.meta_title': 'SEO заголовок',
    'pages.modalSave.input.meta_desciprion': 'SEO описание',

    //BLOGS------------------/
    'admin.blog.title': 'Блоги',
    'admin.blog.title.sm': 'блог',
    'blog.form.description': 'Короткое описание',
    'blog.form.title': 'Заголовок блога',
    'blog.form.seo.description': 'Текст блога',
    'blog.form.seo.text': 'SEO контент',
    'blog.form.meta.title': 'SEO заголовок',
    'blog.form.meta.description': 'SEO описание',
    '': '',
    '': '',
    '': '',
};
