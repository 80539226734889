<template>
    <div
        class="wrapper druk-l-bg"
        :class="{
            'has-active-drawer': hasActiveDrawer,
            'has-elusive-thief': hasElusiveThief,
        }">
        <elusive-thief v-if="hasElusiveThief" />

        <header class="wrapper__header">
            <header-index class="wrapper__menu"></header-index>
            <header-top></header-top>
            <div class="dim" @click="hasActiveDrawer = false"></div>
        </header>

        <main class="wrapper__main druk-l-bg">
            <transition name="fade" mode="out-in">
                <router-view :key="$route.path"></router-view>
            </transition>
        </main>

        <druk-snackbar-container />
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    import ElusiveThief from '@common/doppler/ElusiveThief';
    import HeaderIndex from '@common/header/HeaderIndex';
    import HeaderTop from '@common/header/HeaderTop';

    export default {
        name: 'default-layout',

        components: {
            ElusiveThief,
            HeaderIndex,
            HeaderTop,
        },

        computed: {
            ...mapState({
                MEDIUM_ADNIMATION_DURATION: (state) => state.constant.MEDIUM_ADNIMATION_DURATION,

                hasActiveDrawer: (state) => state.hasActiveDrawer,
            }),
            ...mapGetters({
                hasElusiveThief: 'hasElusiveThief',
            }),

            hasActiveDrawer: {
                get() {
                    return this.$store.state.hasActiveDrawer;
                },
                set(status) {
                    this.SET_DRAWER_STATUS(status);

                    this.$bus.$emit('set-table-scroll', this.MEDIUM_ADNIMATION_DURATION);
                    this.$bus.$emit('set-fixed-drop-position', this.MEDIUM_ADNIMATION_DURATION);
                },
            },
        },

        methods: {
            ...mapMutations({
                SET_DRAWER_STATUS: 'SET_DRAWER_STATUS',
            }),
        },
    };
</script>

<style scoped lang="scss">
    .wrapper {
        padding-left: 80px;
        transition: all 0.3s;
        height: 100%;
        display: flex;
        flex-direction: column;
        @media (max-width: $druk-breakpoints-md) {
            padding-left: 0;
        }
        &.has-active-drawer {
            padding-left: 280px;
            @media (max-width: $druk-breakpoints-def) {
                padding-left: 0;
            }
            @media (max-width: $druk-breakpoints-md) {
                overflow: hidden;
            }
            .dim {
                opacity: 1;
                visibility: visible;
            }
        }
        &.has-elusive-thief {
            padding-top: 30px;
            .wrapper__menu {
                padding-top: 30px;
            }
        }
        &__header {
            position: relative;
            z-index: 10;
        }
    }

    .dim {
        opacity: 0;
        visibility: hidden;
        position: fixed;
        z-index: 9;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: var(--druk-state-layers-scrim-0-32);
        transition: all 0.3s;
        display: none;
        @media (max-width: $druk-breakpoints-def) {
            display: block;
        }
    }
</style>
