<template>
    <div
        class="selector selector--tel-input"
        :class="{
            'has-focus': hasActive,
            'has-full': hasFull,
            'has-error': errors.has(name) || hasError === true,
        }">
        <div class="selector__label" :class="{ [`druk-l-surface-${surface}`]: surface }" @click="onToggle">
            <span>
                <label>{{ label }}</label>
                <i v-if="hasRequired">*</i>
            </span>

            <druk-hint
                v-if="hint"
                :tooltip="{ text: hint.text || hint, from: 'top', maxWidth: hint.maxWidth, isNotCollapse: hint.isNotCollapse }"
                :icon="{ name: 'circle-info', size: 'xs', color: hasActive ? 'primary' : 'outline' }" />
        </div>

        <div class="selector__main" :class="{ [`druk-l-surface-${surface}`]: surface }">
            <template>
                <vue-tel-input
                    v-model="introducedNumber"
                    v-bind="bindProps"
                    :style="{ [`--druk-content-background`]: contentBackground }"
                    :value="value"
                    ref="area"
                    :name="hasName ? name : ''"
                    :placeholder="''"
                    :data-vv-name="name"
                    :data-vv-as="label"
                    v-validate="rules || rule || ''"
                    @open="hasActive = true"
                    @close="hasActive = false"
                    @focus="hasActive = true"
                    @blur="hasActive = false"
                    class="vti">
                    <template v-slot:arrow-icon>
                        <font-awesome-icon
                            icon="fa-regular fa-caret-down"
                            class="_transition_300"
                            :class="{ _rotate_180: hasActive }" />
                    </template>
                </vue-tel-input>
            </template>
        </div>

        <div v-if="hasError === true || errors.has(name)" class="selector__error">
            <span v-if="hasError === true" :class="{ [`druk-l-surface-${surface}`]: surface }">{{ errorText }}</span>
            <span v-else-if="errors.has(name)" :class="{ [`druk-l-surface-${surface}`]: surface }">{{ errors.first(name) }}</span>
        </div>
    </div>
</template>

<script>
    import { VueTelInput } from 'vue-tel-input';

    export default {
        name: 'form-input-tel',
        inject: ['$validator'],
        components: {
            VueTelInput,
        },
        props: {
            value: {
                required: true,
            },
            name: {
                type: String,
                required: true,
            },
            mask: String,
            placeholder: String,
            hasName: Boolean,
            type: String,
            maxlength: String,
            label: String,
            translate: String,
            rule: String,
            rules: String,
            filterType: Boolean,
            errorText: String,
            hasError: Boolean,
            list: String,
            hint: [String, Object],

            surface: {
                type: String,
                default: 'tint-pale',
            },

            surfaceVariant: {
                type: String,
                default: 'tint-bright',
            },
        },

        data() {
            return {
                hasActive: false,
                bindProps: {
                    mode: 'international',
                    defaultCountry: 'UA',
                    disabledFetchingCountry: false,
                    disabled: false,
                    disabledFormatting: false,
                    placeholder: '',
                    required: false,
                    enabledCountryCode: true,
                    enabledFlags: true,
                    preferredCountries: ['UA', 'CZ', 'PL'],
                    onlyCountries: [],
                    ignoredCountries: [],
                    autocomplete: 'on',
                    name: 'telephone',
                    maxLen: 25,
                    wrapperClasses: '',
                    inputClasses: '',
                    inputOptions: {
                        showDialCode: false,
                    },
                },
            };
        },

        computed: {
            introducedNumber: {
                get() {
                    return this.value;
                },
                set(val) {
                    this.$emit('input', val);
                },
            },

            contentBackground() {
                let base = this.surfaceVariant.split('-');
                return `var(--druk-${[...base].shift()}-surface-container-${[...base].pop()})`;
            },

            hasFull() {
                return !!this.value;
            },

            hasRequired() {
                if ((this.rules && this.rules.indexOf('required') != -1) || this.rule) return true;
                return false;
            },
        },

        methods: {
            onToggle() {
                this.$refs.area.focus();
            },

            onClear() {
                if (!this.hasFull) return;
                this.$emit('input', null);
            },
        },
    };
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>
;

<style lang="scss">
    .vti {
        position: relative;
        width: 100%;
        height: 40px;
        border-radius: initial;
        font-size: 14px;
        &__input {
            background-color: inherit;
        }
        &__dropdown {
            position: initial;
            transition: background-color 0.2s ease-in-out;
            &:hover {
                background-color: var(--druk-state-layers-on-surface-0-08);
            }
            &:active,
            &:focus {
                background-color: var(--druk-state-layers-on-surface-0-12);
            }
            &.open {
                background-color: var(--druk-state-layers-on-surface-0-12);
            }
        }
        &__dropdown-list {
            width: 100%;
            z-index: 4;
            border-radius: 4px;
            border: initial;
            background-color: var(--druk-content-background);
            box-shadow: var(--druk-elevation-2);
            &.below {
                position: absolute;
                top: 42px;
                left: 0;
                width: 100% !important;
                border: none;
                color: var(--druk-on-surface-variant);
                background-color: var(--druk-content-background);
                box-shadow: var(--druk-elevation-2);
            }
        }
        &__dropdown-item {
            cursor: pointer;
            padding: 10px 12px;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.25px;
            font-weight: 400;
            &:hover {
                color: var(--druk-on-surface);
                background-color: var(--druk-state-layers-on-surface-0-08);
            }
            &:active,
            &:focus {
                background-color: var(--druk-state-layers-on-surface-0-12);
            }
            &.highlighted {
                background-color: inherit;
                &:hover {
                    background-color: var(--druk-state-layers-on-surface-0-08);
                }
                &:active,
                &:focus {
                    background-color: var(--druk-state-layers-on-surface-0-12);
                }
            }
            &.last-preferred {
                border-bottom: 1px solid var(--druk-outline-variant);
            }
            strong {
                font-weight: 400;
            }
        }
        &__selection {
            svg {
                position: static !important;
                transform: initial !important;
            }
        }
    }

    .vue-tel-input {
        width: 100%;
        border: none;
        &:focus-within {
            box-shadow: initial;
            border-color: initial;
        }
    }
</style>
