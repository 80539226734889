<template>
    <div
        class="formula__variable"
        :class="{
            [`formula__variable--${item.value.groupKey}`]: item.value.groupKey,
            'has-icon': item.value.icon,
            'has-open': isOpenBracket,
        }">
        <font-awesome-icon
            v-if="item.value.icon"
            :icon="item.value.icon"
            :style="{ color: isBracket ? $material[bracketColor] : null }" />
        <span v-else>{{ $fn.tItemSystem(item.value).title }}</span>
    </div>
</template>

<script>
    export default {
        name: 'calc-formula-variable',

        props: {
            item: Object,
            bracketColor: String,
            isBracket: Boolean,
            isOpenBracket: Boolean,
        },
    };
</script>

<style lang="scss" scoped></style>
