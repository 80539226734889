import Vue from 'vue';

import ranges from './ranges';
import curve from './curve';
import formula from './formula';

export default {
    namespaced: true,

    modules: {
        ranges,
        curve,
        formula,
    },

    state: {
        CALCULATION_MODES: [
            {
                title: null,
                key: 'employ.type.range',
                value: 'range',
                layout: 'calc-ranges',
            },
            {
                title: null,
                key: 'employ.type.bize',
                value: 'curve',
                layout: 'calc-curve',
            },
            {
                title: null,
                key: 'employ.type.formula',
                value: 'formula',
                layout: 'calc-formula',
            },
        ],

        CALCULATION_MODE_RANGE: 'range',
        CALCULATION_MODE_CURVE: 'curve',
        CALCULATION_MODE_FORMULA: 'formula',

        mode: null,
    },

    getters: {
        hasRangesCalcMode(state) {
            return state.mode?.value === state.CALCULATION_MODE_RANGE;
        },

        hasCurveCalcMode(state) {
            return state.mode?.value === state.CALCULATION_MODE_CURVE;
        },

        hasFormulaCalcMode(state) {
            return state.mode?.value === state.CALCULATION_MODE_FORMULA;
        },
    },

    mutations: {
        SET_MODE(state, mode) {
            Vue.set(state, 'mode', mode);
        },

        RESET_MODE(state) {
            state.mode = null;
        },
    },
};
