<template>
    <div class="druk-c-dialog-option" :class="{ 'druk-is-inline': isInline, 'druk-is-unavailable': isUnavailable }" @click="onDialog">
        <div class="druk-c-dialog-option__wrapper">
            <slot v-bind="handlers"></slot>
        </div>
    </div>
</template>

<script>
    import ModalAlert from './ModalAlert';

    export default {
        name: 'druk-dialog-option',

        props: {
            question: String,
            size: String,

            options: {
                type: Array,
                default: () => [],
            },

            isInline: Boolean,
            isUnavailable: [Boolean, Number],
        },

        computed: {
            handlers() {
                return Object.fromEntries(this.options.map((option) => [option.handler, () => this.$emit(option.handler)]));
            },
        },

        methods: {
            onDialog(e) {
                this.$modal.show(
                    ModalAlert,
                    {
                        question: this.question,
                        options: this.options,
                        handlers: this.handlers,
                        fnWait: () => this.$emit('success', e),
                        fnCancel: () => this.$emit('cancel', e),
                    },
                    $MODAL_OPTIONS[this.size || 'alert'],
                );
            },
        },
    };
</script>

<style lang="scss" scoped>
    .druk-c-dialog-option {
        &.druk-is-inline {
            display: inline-block;
            vertical-align: text-top;
        }
        &.druk-is-unavailable {
            pointer-events: none;
        }
    }
</style>
