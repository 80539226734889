import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        COMMON_FORM_DATA_DOMAIN: {
            domain: null,
            default_language: null,
            languages: [],
            is_default: false,
        },

        COMMON_FORM_DATA_LANGUAGE: {
            id: null,
            is_default: false,
        },

        list: [],
        alphabets: [],

        formData: {},

        loadingGetList: false,
        loadingGetAlphabets: false,
        loadingSaveList: false,
    },

    getters: {
        hasDefaultDomain(state) {
            return !!(!state.formData.domains || state.formData.domains?.find((domain) => domain.is_default));
        },
    },

    actions: {
        GET_LIST: async ({ state, getters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let path = `/api/typographies/${id}/domains`;

                let resp = await $axios.get(path);

                commit('SET_LIST', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_ALPHABETS: async ({ state, getters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetAlphabets', status: true });

                let path = `/api/client-account-languages`;

                let resp = await $axios.get(path);

                commit('SET_ALPHABETS', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetAlphabets', status: false });
            }
        },

        SAVE_LIST: async ({ state, getters, commit, dispatch }, { id, formData }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveList', status: true });

                let path = `/api/typographies/${id}/domains`;

                let resp = await $axios.post(path, formData);

                dispatch('GET_LIST', id);
                dispatch('typographies/GET_ITEM', id, { root: true });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveList', status: false });
            }
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_ALPHABETS(state, list) {
            state.alphabets = list;
        },

        SET_FORM_DATA(state, formData) {
            Vue.set(state, 'formData', formData);
        },

        SET_FORM_DATA_DOMAIN_PROP(state, { index, key, value }) {
            Vue.set(state.formData.domains[index], key, value);
        },

        SET_DEFAULT_DOMAIN(state, pickedIndex) {
            state.formData.domains.forEach((domain, index) => {
                if (index !== pickedIndex) Vue.set(state.formData.domains[index], 'is_default', false);
            });
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        ADD_DOMAIN(state) {
            state.formData.domains.push({ ...state.COMMON_FORM_DATA_DOMAIN });
        },

        DELETE_DOMAIN(state, index) {
            Vue.delete(state.formData.domains, index);
        },

        RESET_FORM_DATA(state) {
            state.formData = {};
        },
    },
};
