import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        URGENCY_ID_COMMON: 1,
        URGENCY_ID_SOFT_URGENT: 2,
        URGENCY_ID_URGENT: 3,

        list: [],

        loadingGetList: false,
    },

    getters: {
        reducedList(state) {
            return state.list.filter((item) => item.id !== state.URGENCY_ID_SOFT_URGENT);
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },
    },

    actions: {
        GET_LIST: async ({ state, rootGetters, getters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.get(`/api/urgencies`);

                commit('SET_LIST', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },
    },
};
