<template>
    <div class="druk-l-sb-cont">
        <druk-snackbar
            v-if="hasActiveSnackbar"
            :key="snackbar.hash"
            :text="snackbar.text"
            :action="snackbar.action"
            :timer="snackbar.timer"
            :position="snackbar.position"
            :isColumn="snackbar.isColumn"
            :hasIndicator="snackbar.hasIndicator"
            @close="close" />

        <!-- <druk-snackbar v-for="(snackbar) in snackbars"
                :key="snackbar.hash"
                :hash="snackbar.hash"
                :text="snackbar.text"
                :timer="snackbar.timer"
                :position="snackbar.position"
                :hasIndicator="snackbar.hasIndicator"
                @close="close" /> -->
    </div>
</template>

<script>
    import snackbar from '../../../utils/snackbar';

    import DrukSnackbar from './DrukSnackbar';

    export default {
        name: 'druk-snackbar-container',

        components: {
            DrukSnackbar,
        },

        data() {
            return {
                snackbar: {},
            };
        },

        beforeMount() {
            snackbar.bus.$on('show', (data) => this.show(data));
            snackbar.bus.$on('close', () => this.close());
        },

        computed: {
            hasActiveSnackbar() {
                return Object.keys(this.snackbar).length;
            },
        },

        methods: {
            show(data = {}) {
                this.snackbar = { ...data, hash: $fn.generateHash() };
            },

            close() {
                this.snackbar = {};
            },
        },
    };
</script>

<style lang="scss" scoped>
    .druk-l-sb-cont {
        pointer-events: none;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        padding: 32px;
        z-index: 101;
    }
</style>
