<template>
    <div class="form">
        <div class="formula__wrapper" :class="{ [`druk-l-surface-${surface}`]: surface, 'has-alt': hasAltStyles }">
            <div ref="area" id="area" class="formula__area">
                <div v-for="(row, rowIndex) in areaRows" :key="rowIndex" class="formula__row">
                    <template v-for="item in row">
                        <calc-formula-item
                            :key="item.index"
                            :item="item"
                            :index="item.index"
                            :surface="surface"
                            :hasHiddenEmptyCursor="hasHiddenEmptyCursor" />
                    </template>
                </div>
            </div>

            <div ref="mask" id="mask" class="formula__mask" :style="{ width: `${areaSetup.width}px` }">
                <div v-for="item in currentArea" :key="item.index" class="formula__space">
                    <calc-formula-item
                        :item="item"
                        :index="item.index"
                        :hasHiddenEmptyCursor="hasHiddenEmptyCursor"
                        :isMaskItem="true" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    import CalcFormulaItem from './CalcFormulaItem';
    import CalcFormulaNumber from './CalcFormulaNumber';

    export default {
        name: 'calc-formula-area',

        inject: ['$validator'],

        components: {
            CalcFormulaItem,
            CalcFormulaNumber,
        },

        props: {
            surface: {
                type: String,
                default: 'default',
            },

            hasAltStyles: Boolean,
        },

        data() {
            return {
                CURSOR_WIDTH: 24,
                CURSOR_MARGIN: 4,

                areaSetup: {},
                rowsSetup: [],

                hasHiddenEmptyCursor: false,
            };
        },

        created() {
            this.$bus.$on('on-hide-empty-cursor', () => (this.hasHiddenEmptyCursor = true));
            this.$bus.$on('on-show-empty-cursor', () => (this.hasHiddenEmptyCursor = false));
            this.$bus.$on('on-area-update', () => this.setRowsSetup());

            this.setCommonData();
        },

        mounted() {
            window.addEventListener('click', this.onDetectClick);
            window.addEventListener('contextmenu', this.onDetectClick);
            window.addEventListener('keydown', this.setKeyboardInteractions);

            this.setAreaSetup();
            this.setRowsSetup();
        },

        destroyed() {
            this.$bus.$off('on-hide-empty-cursor');
            this.$bus.$off('on-show-empty-cursor');
            this.$bus.$off('on-area-update');

            window.removeEventListener('click', this.onDetectClick);
            window.removeEventListener('contextmenu', this.onDetectClick);
            window.removeEventListener('keydown', this.setKeyboardInteractions);
        },

        computed: {
            ...mapState({
                COMMON_CURSOR: (state) => state.calcModes.formula.COMMON_CURSOR,

                RIGTH_MOUSE_BUTTON_CODE: (state) => state.constant.RIGTH_MOUSE_BUTTON_CODE,

                LEFT_ARROW_KEY: (state) => state.constant.LEFT_ARROW_KEY,
                RIGHT_ARROW_KEY: (state) => state.constant.RIGHT_ARROW_KEY,

                BACKSPACE_KEY: (state) => state.constant.BACKSPACE_KEY,
                DELETE_KEY: (state) => state.constant.DELETE_KEY,
                MAC_DELETE_KEY: (state) => state.constant.MAC_DELETE_KEY,

                cursor: (state) => state.calcModes.formula.cursor,
            }),
            ...mapGetters({
                currentArea: 'calcModes/formula/currentArea',
                emptyIndex: 'calcModes/formula/emptyIndex',

                hasCursorInArea: 'calcModes/formula/hasCursorInArea',
                hasActiveCursor: 'calcModes/formula/hasActiveCursor',
            }),

            areaRows() {
                return this.rowsSetup.map((row, index) => {
                    let slicedArea = [...this.currentArea].slice([...row].shift(), [...row].pop() + 1);
                    if (index === this.rowsSetup.length - 1) slicedArea.push([...this.currentArea].pop());

                    return slicedArea;
                });
            },
        },

        methods: {
            ...mapActions({
                CHANGE_EMPTY_CURSOR_POSITION: 'calcModes/formula/CHANGE_EMPTY_CURSOR_POSITION',
                REMOVE_AREA_ITEM: 'calcModes/formula/REMOVE_AREA_ITEM',
            }),
            ...mapMutations({
                SET_CURSOR: 'calcModes/formula/SET_CURSOR',
            }),

            setCommonData() {
                this.SET_CURSOR({ ...this.COMMON_CURSOR });
            },

            onDetectClick(e) {
                if (e?.button === this.RIGTH_MOUSE_BUTTON_CODE) e.preventDefault();

                let targetIndex = +e.target.closest('.formula__item')?.getAttribute('id');
                if (Number.isNaN(targetIndex) && this.hasActiveCursor) this.$bus.$emit(`on-reset-${this.cursor.index}`);
            },

            setAreaSetup() {
                let element = document.getElementById('area');

                this.areaSetup = {
                    width: element.offsetWidth,
                    left: element.getBoundingClientRect().left,
                };
            },

            setRowsSetup() {
                let defer = setTimeout(() => {
                    this.rowsSetup = [];
                    clearTimeout(defer);

                    this.rowsSetup.push([]);

                    [...document.querySelectorAll('#layout')].forEach((element, index) => {
                        let elementRightGap =
                            this.areaSetup.width - element.offsetWidth - (element.getBoundingClientRect().left - this.areaSetup.left);

                        if (elementRightGap < this.CURSOR_WIDTH + this.CURSOR_MARGIN) this.rowsSetup.push([]);
                        this.rowsSetup[this.rowsSetup.length - 1].push(index);
                    });
                }, 0);
            },

            setKeyboardInteractions(e) {
                if (this.hasHiddenEmptyCursor || e?.target instanceof HTMLInputElement) return;

                // Arrows interactions
                if (e.keyCode === this.LEFT_ARROW_KEY && this.emptyIndex !== 0)
                    this.CHANGE_EMPTY_CURSOR_POSITION({ index: this.emptyIndex - 1 });
                if (e.keyCode === this.RIGHT_ARROW_KEY && this.emptyIndex !== this.currentArea.length - 1)
                    this.CHANGE_EMPTY_CURSOR_POSITION({ index: this.emptyIndex + 1 });

                // Delete interactions
                if (e.keyCode === this.BACKSPACE_KEY && !this.$device.isMacOs) this.REMOVE_AREA_ITEM(this.emptyIndex - 1);
                if (e.keyCode === this.DELETE_KEY && !this.$device.isMacOs) this.REMOVE_AREA_ITEM(this.emptyIndex + 1);

                // MacOs Delete interactions
                if (e.keyCode === this.MAC_DELETE_KEY && this.$device.isMacOs) this.REMOVE_AREA_ITEM(this.emptyIndex - 1);
                if (e.keyCode === this.MAC_DELETE_KEY && this.$device.isMacOs && (e.ctrlKey || e.metaKey))
                    this.REMOVE_AREA_ITEM(this.emptyIndex + 1);

                this.setRowsSetup();
            },
        },
    };
</script>

<style lang="scss" scoped>
    .formula {
        &__wrapper {
            position: relative;
            width: 100%;
            padding: 24px 12px 24px 24px;
            background-size: 12px 12px;
            background-position: -6px -6px;
            background-image: radial-gradient(circle at 1px 1px, var(--druk-outline-variant) 1px, transparent 0);
            border-radius: 8px;
        }
        &__area {
            display: flex;
            flex-direction: column;
        }
        &__row {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }
        &__mask {
            pointer-events: none;
            position: absolute;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            top: 30px;
            left: 20px;
            opacity: 0;
            max-height: 0px;
        }
    }
</style>
