export default {
    namespaced: true,
    state: {
        ACCOUNT_TYPE_CASH_ID: 1,
        ACCOUNT_TYPE_CARD_ID: 2,
        ACCOUNT_TYPE_ACC_ID: 3,

        list: [],
        item: {},
        listPagination: {},
        listFilter: {
            page: null,
            limit: 30,
        },

        accountTypes: [],

        loadingGetList: false,
        loadingGetItem: false,
        loadingSaveItem: false,
        loadingDeleteItem: false,
        loadingGetAccountTypes: false,
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },
        SET_ACCOUNT_TYPES(state, list) {
            state.accountTypes = list;
        },
        SET_ITEM(state, item) {
            state.item = item;
        },
        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },
        UPDATE_LIST_FILTER(state, newFilterObj) {
            state.listFilter = $fn.assign(state.listFilter, newFilterObj, {
                page: newFilterObj.page || 1,
            });
        },
        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },
        RESET_STORE(state) {
            state.listFilter = {
                page: null,
                limit: null,
            };
        },
    },

    actions: {
        GET_LIST: async ({ state, rootGetters, getters, commit, dispatch }, payload = { filters: {} }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/accounts`, {
                    params: Object.keys(payload.filters || {}).length ? payload.filters : getters.listActiveFilter,
                });

                commit('SET_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);

                // dispatch('transactions/GET_LIST', null, { root: true });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_ITEM: async ({ state, rootGetters, getters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/accounts/${id}`);

                commit('SET_ITEM', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        SAVE_ITEM: async ({ commit, rootGetters, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let resp = await $axios.post(`/api/typographies/${rootGetters.currentTypography.id}/accounts`, formData);

                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        DELETE_ITEM: async ({ commit, rootGetters, dispatch }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let resp = await $axios.delete(`/api/typographies/${rootGetters.currentTypography.id}/accounts/delete/${id}`);
                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },

        GET_ACCOUNT_TYPES: async ({ state, rootGetters, getters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetAccountTypes',
                    status: true,
                });

                let resp = await $axios.get(`/api/account-purposes`);

                commit('SET_ACCOUNT_TYPES', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetAccountTypes',
                    status: false,
                });
            }
        },

        RUN_TRANSFER: async ({ commit, rootGetters, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let resp = await $axios.post(
                    `/api/typographies/${rootGetters.currentTypography.id}/accounts/transactions/transfer`,
                    formData,
                );

                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },
    },
};
