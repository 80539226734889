var render = function render(){var _vm=this,_c=_vm._self._c;return _c(!!_vm.path ? 'router-link' : 'button',{tag:"component",staticClass:"druk-c-fab",class:{ 'druk-is-full': _vm.isFull },attrs:{"to":_vm.path},on:{"click":function($event){return _vm.$emit('click')}}},[_c('div',{staticClass:"druk-c-fab__wrapper",class:{
            [`druk-u-${_vm.base}`]: !!_vm.base,
            [`druk-l-${_vm.size}`]: !!_vm.size,
            'druk-is-expanded': !!_vm.label,
            'druk-is-inside-drawer': _vm.isInsideDrawer,
            'druk-is-inverse': _vm.isInverse,
        }},[_c('div',{staticClass:"druk-c-fab__icon",class:{ [`druk-l-${_vm.size}`]: !!_vm.size, 'druk-is-expanded': !!_vm.label }},[_c('druk-icon',{attrs:{"color":_vm.iconColorsMap[_vm.base],"name":_vm.icon,"size":_vm.iconSizesMap[_vm.size]}})],1),_c('div',{staticClass:"druk-c-fab__label",class:{ 'druk-u-text-label-lg': !_vm.isLarge, 'druk-u-text-title-m': _vm.isLarge, [`druk-u-${_vm.base}`]: !!_vm.base }},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('div',{staticClass:"druk-c-fab__state",class:{
                [`druk-u-${_vm.base}`]: !!_vm.base,
                [`druk-l-${_vm.size}`]: !!_vm.size,
                'druk-is-inside-drawer': _vm.isInsideDrawer,
                'druk-is-inverse': _vm.isInverse,
            }})])])
}
var staticRenderFns = []

export { render, staticRenderFns }