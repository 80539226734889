<template>
    <div :class="{ [`section__card druk-l-surface-${surface}`]: !hasAltStyles }">
        <div v-if="currentMode" class="form">
            <div class="form__title druk-is-flexed">
                <div class="form__title-text druk-u-margin-right-8">{{ title }}</div>

                <div class="form__title-content">
                    <form-select
                        v-if="!hasSoloMode"
                        v-model="currentMode"
                        class="_normalcase _width_auto"
                        :notEmpty="true"
                        :options="tModesList"
                        option_id="value"
                        name="currentMode"
                        :surface="surface"
                        @input="onChangeMode" />
                </div>
            </div>

            <transition name="fade" mode="out-in">
                <component
                    :is="currentMode.layout"
                    :type="type"
                    :translatesBase="translatesBase"
                    :surface="surface"
                    :surfaceVariant="surfaceVariant"
                    :hasPreviewMode="hasPreviewMode"
                    :hasAltStyles="hasAltStyles" />
            </transition>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    import mixinModes from './mixins/mixinModes';

    export default {
        name: 'calc-modes',

        mixins: [mixinModes],

        props: {
            title: String,
            type: String, // full, equipment, material
            translatesBase: String,

            surface: {
                type: String,
                default: 'tint-bright',
            },

            surfaceVariant: {
                type: String,
                default: 'tint-brighter',
            },

            hasPreviewMode: Boolean,
            hasAltStyles: Boolean,
            hasSoloMode: Boolean,
        },

        methods: {
            ...mapMutations({
                RESTORE_RANGES: 'calcModes/ranges/RESTORE_LIST',
                RESTORE_CURVE: 'calcModes/curve/RESTORE_ITEM',
                RESTORE_FORMULA: 'calcModes/formula/RESTORE_AREA',
                RESET_RANGES: 'calcModes/ranges/RESET_LIST',
                RESET_CURVE: 'calcModes/curve/RESET_ITEM',
                RESET_FORMULA: 'calcModes/formula/RESET_AREA',
            }),

            onChangeMode() {
                if (this.hasRangesCalcMode) this.setRangesModeData();
                if (this.hasCurveCalcMode) this.setCurveModeData();
                if (this.hasFormulaCalcMode) this.setFormulaModeData();
            },

            setRangesModeData() {
                this.RESTORE_RANGES();
                this.RESET_CURVE();
                this.RESET_FORMULA();
            },

            setCurveModeData() {
                this.RESTORE_CURVE();
                this.RESET_RANGES();
                this.RESET_FORMULA();
            },

            setFormulaModeData() {
                this.RESTORE_FORMULA();
                this.RESET_RANGES();
                this.RESET_CURVE();
            },
        },
    };
</script>

<style lang="scss" scoped></style>
