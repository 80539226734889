export default {
    namespaced: true,
    state: {
        list: [],
        item: {},
        itemRobots: {},
        itemProtector: {},

        loadingGetList: false,
        loadingGetItem: false,
        loadingGetRobotsItem: false,
        loadingGetProtectorItem: false,
        loadingSaveItem: false,
        loadingSaveRobotsItem: false,
        loadingSaveProtectorItem: false,
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },
        SET_ITEM(state, item) {
            state.item = item;
        },
        SET_ROBOTS_ITEM(state, item) {
            state.itemRobots = item;
        },
        SET_PROTECTOR_ITEM(state, item) {
            state.itemProtector = item;
        },
        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },
    },

    actions: {
        GET_LIST: async ({ state, getters, rootGetters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let path = `/api/typographies/${rootGetters.currentTypography.id}/seo/templates`;

                let resp = await $axios.get(path, {});

                commit('SET_LIST', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_ITEM: async ({ state, getters, rootGetters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                let path = `/api/typographies/${rootGetters[`currentTypography`].id}/seo/templates/${id}`;

                let resp = await $axios.get(path);

                commit('SET_ITEM', resp.single);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        GET_ROBOTS_ITEM: async ({ state, getters, rootGetters, dispatch, commit }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetRobotsItem',
                    status: true,
                });

                let path = `/api/typographies/${rootGetters[`currentTypography`].id}/seo/robots`;

                let resp = await $axios.get(path);

                if (resp.single === null) {
                    resp = await dispatch('SAVE_ROBOTS_ITEM', {
                        id: null,
                        content: 'User-agent: Googlebot',
                    });
                }

                commit('SET_ROBOTS_ITEM', resp.single.content);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetRobotsItem',
                    status: false,
                });
            }
        },

        GET_PROTECTOR_ITEM: async ({ state, getters, rootGetters, dispatch, commit }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetProtectorItem',
                    status: true,
                });

                let path = `/api/typographies/${rootGetters[`currentTypography`].id}/seo/protectors`;

                let resp = await $axios.get(path);

                if (resp.single === null) {
                    resp = await dispatch('SAVE_PROTECTOR_ITEM', {
                        id: null,
                        text: {
                            en: 'protector',
                            ru: 'protector-ru',
                            uk: 'protector-ua',
                        },
                    });
                }

                commit('SET_PROTECTOR_ITEM', resp.content);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetProtectorItem',
                    status: false,
                });
            }
        },

        SAVE_ITEM: async ({ state, commit, rootGetters, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let path = `/api/products`;
                if (rootGetters.hasTypographyAccess) {
                    path = `/api/typographies/${rootGetters[`currentTypography`].id}/seo/templates`;
                }

                let resp = await $axios.post(path, formData);

                dispatch('GET_LIST');

                if (formData.id && Object.keys(state.item).length) dispatch('GET_ITEM', formData.id);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        SAVE_ROBOTS_ITEM: async ({ state, commit, rootGetters, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingSaveRobotsItem',
                    status: true,
                });

                let path = `/api/products`;
                if (rootGetters.hasTypographyAccess) {
                    path = `/api/typographies/${rootGetters[`currentTypography`].id}/seo/robots`;
                }

                let resp = await $axios.post(path, formData);

                dispatch('GET_ROBOTS_ITEM');

                if (formData.id && Object.keys(state.item).length) dispatch('GET_ROBOTS_ITEM', formData);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingSaveRobotsItem',
                    status: false,
                });
            }
        },

        SAVE_PROTECTOR_ITEM: async ({ state, commit, rootGetters, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingSaveProtectorItem',
                    status: true,
                });

                let path = `/api/products`;
                if (rootGetters.hasTypographyAccess) {
                    path = `/api/typographies/${rootGetters[`currentTypography`].id}/seo/protectors`;
                }

                let resp = await $axios.post(path, formData);

                dispatch('GET_PROTECTOR_ITEM');

                if (formData.id && Object.keys(state.item).length) dispatch('GET_PROTECTOR_ITEM', formData);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingSaveProtectorItem',
                    status: false,
                });
            }
        },

        ATTACH_ITEM: async ({ state, commit, rootGetters, dispatch }, { productId, formData }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let resp = await $axios.post(
                    `/api/typographies/${rootGetters.currentTypography.id}/products/attach/${productId}`,
                    formData,
                );

                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },
    },
};
