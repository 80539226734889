import Vue from 'vue';

// @Explanation: It's simple object, where key is alias of error and value is custom message. If you put null at value - noty will not be shown
const customNoty = {
    destination_custom: 'noty.error.destination_custom',
    is_locked: null,
    'delete.equipment_used': null,
    'delete.materials_used': null,
    'delete.work_used': null,
    'delete.algorithms_used': null,
    'delete.catalog_not_empty': null,
    'delete.formats_used': null,
    'delete.details_used': null,
    is_deactivated: 'auth.employee_is_deactivated',
};

window.$customNoty = customNoty;
