<template>
    <div class="currency has-mobile" v-if="list.length">
        <div class="currency__item">
            <druk-dropdown :position="{ horizontal: 'ctr', vertical: 'bot' }" :hasStaticContent="true" :isReduced="true">
                <template v-slot:druk-c-dd-base>
                    <druk-button :type="'outlined'" :label="selectedCurrencyCode" />
                </template>

                <template v-slot:druk-c-dd-list>
                    <ul class="list">
                        <li
                            v-for="(currency, index) in list"
                            :key="index"
                            class="list__item"
                            :class="{ 'druk-is-active': currency.code === selectedCurrencyCode }"
                            @click="setSelectedCurrency(currency.code)">
                            {{ currency.code }}
                        </li>
                    </ul>
                </template>
            </druk-dropdown>
        </div>

        <div class="currency__item">
            <druk-icon-button
                v-if="$fn.hasAccess(PERM_MODULE_CURRENCY, PERM_ACTION_VIEW)"
                :type="'standard'"
                :icon="'euro-sign'"
                @click="onCurrencyEdit" />
        </div>
    </div>
</template>

<script>
    import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';

    import ModalEditCurrency from './components/ModalEditCurrency';

    export default {
        name: 'header-currency',

        computed: {
            ...mapState({
                PERM_MODULE_CURRENCY: (state) => state.permissions.PERM_MODULE_CURRENCY,
                PERM_ACTION_VIEW: (state) => state.permissions.PERM_ACTION_VIEW,
                DEFAULT_ORDER_CURRENCY: (state) => state.currency.DEFAULT_ORDER_CURRENCY,

                list: (state) => state.currency.list,
                selectedCurrencyCode: (state) => state.selectedCurrencyCode,

                loadingGetList: (state) => state.currency.loadingGetList,
            }),
        },

        created() {
            this.initCommonData().then(() => {});

            this.getList();
        },

        methods: {
            ...mapActions({
                GET_LIST: 'currency/GET_LIST',
            }),
            ...mapMutations({
                SET_SELECTED_CURRENCY: 'SET_SELECTED_CURRENCY',
            }),

            setSelectedCurrency(val) {
                this.$axios.defaults.headers.common['Currency-Code'] = val;
                this.SET_SELECTED_CURRENCY(val);
                this.$noty.success(this.$t('currency.change'));
            },

            initCommonData() {
                return Promise.all([
                    this.SET_SELECTED_CURRENCY(localStorage.getItem('_currency_druk_crm') || this.DEFAULT_ORDER_CURRENCY),
                ]);
            },

            getList: $fn.debounce(function () {
                this.GET_LIST();
            }, 300),

            onCurrencyEdit() {
                this.$modal.show(ModalEditCurrency, {}, $MODAL_OPTIONS.normal);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .currency {
        display: flex;
        align-items: center;
        font-size: 14px;
        &.has-mobile {
            @media (max-width: $druk-breakpoints-md) {
                display: none;
            }
        }
        &__item {
            display: flex;
            align-items: center;
            white-space: nowrap;
            margin-right: 4px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
</style>
