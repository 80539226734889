<template>
    <div
        ref="snackbar"
        class="druk-c-sb"
        :class="{
            [`druk-is-from-${position.horizontal}-${position.vertical}`]: position,
            'druk-is-wide': isWide,
        }"
        @mouseenter="onStopTimer"
        @mouseleave="onStartTimer">
        <div class="druk-c-sb__wrap">
            <div class="druk-c-sb__body" :class="{ 'druk-is-column': isColumn }">
                <div class="druk-c-sb__text druk-u-text-body-m">{{ text }}</div>

                <div class="druk-c-sb__actions">
                    <druk-button
                        v-if="action"
                        :type="'text'"
                        :icon="'gears'"
                        :label="action.label"
                        :isInverse="true"
                        @click="$bus.$emit(action.busEvent)" />

                    <druk-icon-button :type="'standard'" :icon="'xmark'" :isInverse="true" @click="onClose" />
                </div>
            </div>

            <div v-if="hasIndicator" class="druk-c-sb__ind">
                <druk-linear-indicator :progress="progress" :isInverse="true" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'druk-snackbar',

        props: {
            text: String,

            action: Object,

            timer: {
                type: Number,
                default: 40000,
            },

            position: {
                type: Object,
                default: () => {
                    return {
                        horizontal: 'end', // @Explanation: Additional values: strt, ctr
                        vertical: 'bot', // @Explanation: Additional values: top
                    };
                },
            },

            isColumn: {
                type: Boolean,
                default: false,
            },

            isWide: {
                type: Boolean,
                default: false,
            },

            hasIndicator: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                ANIMATION_WAITER: 100,

                timeInterval: null,

                time: 0,
                progress: 0,
            };
        },

        mounted() {
            this.onShowSnackbar();
            this.onStartTimer();
        },

        beforeDestroy() {
            clearInterval(this.timeInterval);
        },

        computed: {
            interval() {
                return this.timer / 1000;
            },

            timeStep() {
                return this.timer / 100 / this.interval;
            },

            progressStep() {
                return parseFloat((1 / this.interval).toFixed(2));
            },

            isTimeUp() {
                return this.time === this.timer;
            },
        },

        methods: {
            onShowSnackbar() {
                let timer = setTimeout(() => {
                    clearTimeout(timer);
                    this.$refs.snackbar.classList.add('druk-is-shown');
                }, this.ANIMATION_WAITER);
            },

            onStartTimer() {
                this.timeInterval = setInterval(() => {
                    this.time = this.sumParser(this.time, this.timeStep, 0);

                    if (this.hasIndicator) this.progress = this.sumParser(this.progress, this.progressStep, 2);
                    if (this.isTimeUp) this.onClose();
                }, 10);
            },

            onClose() {
                this.onStopTimer();
                // this.$refs.snackbar.classList.add('druk-is-before-destroy');

                let timer = setTimeout(() => {
                    clearTimeout(timer);
                    this.$emit('close');
                }, this.ANIMATION_WAITER);

                // this.$emit('close', this.hash);
            },

            onStopTimer() {
                clearInterval(this.timeInterval);
            },

            sumParser(a, b, digits) {
                return parseFloat((a + b).toFixed(digits));
            },
        },
    };
</script>

<style lang="scss" scoped>
    .druk-c-sb {
        position: absolute;
        pointer-events: auto;
        width: 344px;
        opacity: 0;
        transform: var(--transform-translate) scale(0.5);
        transform-origin: center;
        transition:
            opacity 0.1s ease-out,
            transform 0.2s ease-out;
        box-shadow: var(--druk-elevation-3);
        &.druk-is-wide {
            width: auto;
            min-width: 344px;
        }
        &.druk-is-shown {
            opacity: 1;
            transform: var(--transform-translate) scale(1);
        }
        &.druk-is-before-destroy {
            opacity: 0;
            transform: var(--transform-translate) scale(0);
        }
        &__wrap {
            border-radius: 4px;
            color: var(--druk-inverse-on-surface);
            background-color: var(--druk-surface-inverse-surface);
        }
        &__body {
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0 4px 0 16px;
            &.druk-is-column {
                align-items: flex-start;
                flex-direction: column;
                padding: 0;
                .druk-c-sb__text {
                    width: 100%;
                    padding: 14px 16px 0px 16px;
                }
                .druk-c-sb__actions {
                    margin-top: 10px;
                    padding: 4px 4px 4px 8px;
                }
            }
        }
        &__text {
            padding: 14px 4px 14px 0;
        }
        &__actions {
            display: flex;
            align-items: center;
            margin-left: auto;
        }
        &__ind {
            height: 8px;
            margin: -2px 0;
        }
    }

    .druk-is-from {
        &-strt-top {
            top: 32px;
            left: 32px;
            --transform-translate: translate(0, 0);
        }
        &-strt-bot {
            bottom: 32px;
            left: 32px;
            --transform-translate: translate(0, 0);
        }
        &-ctr-top {
            top: 32px;
            left: 50%;
            --transform-translate: translate(-50%, 0);
        }
        &-ctr-bot {
            bottom: 32px;
            left: 50%;
            --transform-translate: translate(-50%, 0);
        }
        &-end-top {
            top: 32px;
            right: 32px;
            --transform-translate: translate(0, 0);
        }
        &-end-bot {
            right: 32px;
            bottom: 32px;
            --transform-translate: translate(0, 0);
        }
    }
</style>
