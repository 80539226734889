export default {
    //PRODUCT-------------------------/
    'product.title': 'Товары',
    'product.title.singl': 'Товар',
    'product.title.alt': 'Продукт',
    'product.title.alt.sm': 'продукт',
    'product.export': 'Экспорт товаров',
    'product.catalog': 'Каталог',
    'product.seo': 'Seo данные',
    'product.format': 'Форматы товара',
    'product.form.format': 'Варианты форматов',
    'product.form.format.info':
        'В этом поле вы перечесляете все доступные варианты форматов этого продукта. После чего, при разчете цены печати, выберите формат для исчесления стоимости.',
    'product.controll': 'Управление товаром',
    'product.form.popular': 'Популярный товар',
    'product.not.maket': 'Макет не нужен',
    'product.shape': 'Задать форму товара',
    'product.shape.info': 'Пока чек-бокс не активен, на сайте доступен выбор из всех форм',
    'product.count': 'Тираж по умолчанию',
    'product.recommendations': 'Добавить рекомендованные товары',
    'product.recommendations.info':
        'Выбранные товары будут отображаться на странице этого товара как рекомендованные. Вы можете добавить до 8 товаров.',
    'product.recommendations.msg': 'Пока не выбрано ни одного товара, блок с рекомендованными товарами на сайте не отображается',
    'product.attach.title': '',
    'product.alias': 'Алиас (для отображения в url)',
    'product.title.seo': 'SEO данные',
    'product.form.meta.title': 'SEO Заголовок',
    'product.form.meta.description': 'SEO Описание',
    'product.og.title': 'OG-дані продукту',
    'product.og.info':
        'OG-дані використовується для опису лінку сторінки при відправки до соцмереж та чатів, як Telegram, Viber, Instagram та ін.',
    'product.og.form.title': 'OG: Название',
    'product.og.form.description': 'OG: Краткое описание',
    'product.og.msg.part1': 'Ви не заповнили',
    'product.og.msg.part2': 'Будь ласка, заповнити усі OG-поля для коректної роботи функціоналу',
    'product.shape.title': 'Форма товара',
    'product.empty': 'Нет в наличии',
    'product.category': 'Категория',
    'product.description': 'Описание товара',
    'product.add': 'Добавить продукт',
    'product.calc.log': 'Расчет времени',
    'product.calc.end': 'Расчёт стоимости сборки завершён',
    '': '',

    //KINDS----------------------------/
    'views.title': 'Виды товаров',
    'views.title.single': 'Вид',
    'views.title.sm': 'вид',
    'views.btn.create': 'Создать вид',
    'views.tech.error': 'Тех карта не заполнена',
    'views.default': 'По умолчанию',
    'views.controll': 'Управления видом',
    'views.form.default': 'Выбран по умолчанию',
    'views.form.orientation': 'Возможность выбрать ориентацию',
    'views.form.deadline': 'Время на разработку дизайна (м)',
    'views.log.title': 'Лог расчёта алгоритма',
    'views.log.sub.part1': 'детали в товаре / валюта -',
    'views.log.sub.part2': '/ время - с. / вес - г.',
    'views.log.title.sub': 'Выбор алгоритма для',
    'views.log.detail': 'детали',
    'views.log.steps': 'по критерию',
    'views.log.time.fast': 'Быстро',
    'views.log.time.min': 'минимальное время',
    'views.log.time.econom': 'Экономно',
    'views.log.time.max': 'максимальная экономия',
    'views.log.time.opt': 'оптимально',
    'views.log.winner': 'Победил алгоритм под номером ID',
    'views.log.calc.alg': 'Подсчёт алгоритма',
    'views.log.alg.final': 'Финальные подсчёты для алгоритма',
    'views.log.alg.name': 'Название алгоритма',
    'views.log.cost': 'Расчет цены',
    'views.log.formula': 'Формула',
    'views.log.result': 'Результат',
    'views.log.weight': 'Расчет веса',
    'views.sort.title': 'Изменить порядок видов',
    'views.save.form.key': 'Уникальный ключ',
    'views.calc.best': 'Лучший результат',
    'views.calc.result': 'Расчёт',
    'views.calc.format': 'Формат товара',
    'views.calc.count': 'Тираж',
    'views.calc.roll.title': 'Параметры для рулонной печати',
    'views.calc.form.width': 'Ширина наклейки (мм)',
    'views.calc.form.height': 'Высота наклейки (мм)',
    'views.calc.form.bleeds': 'Расстояние до края ленты (мм)',
    'views.calc.form.padding': 'Расстояние между этикетками (мм)',
    'views.calc.form.rotation': 'Угол поворота (0, 90, 180, 270)',
    'views.calc.list.title': 'Параметры для листовой печати',
    'views.calc.label.count': 'Количество наклеек',
    'views.calc.msg.error': 'Неверно заполненная тех-карта',
    'views.calc.log': 'Смотреть лог ошибки',
    'views.calc.msg.warning': 'Ни одна типография не имеет рабочей тех-карты для этого продукта',
    'views.alg.title.type1': 'детали',
    'views.alg.title.type2': 'этапу',
    'views.alg.algorithms': 'Выберите алгоритм c библиотеки',
    'views.alg.or': 'Или',
    'views.alg.contractors': 'Выберите алгоритм с работой подрядчика',
    'views.table.rate': 'Цена Коэф.',
    'views.table.deadline': 'Срок Коэф.',
    'views.description': 'Описание вида',
    'views.not.name': 'Без названия',
    'views.alg.add': 'Добавить алгоритм к',
    'views.selected': 'Выбран',
    'views.table.alg': 'Способ',
    '': '',

    //TECHCARD-------------------------/
    'tech.detail.title': 'Изготовление деталей',
    'tech.detail.title.custom': 'Техкарта производства',
    'tech.detail.title.sm': 'Деталь',
    'tech.detail.format': 'Формат товара',
    'tech.detail.bleeds': 'Блиды (мм)',
    'tech.detail.element': 'Элементов в детали (шт)',
    'tech.detail.roll': 'Рулонная печать',
    'tech.detail.list': 'Наклейка в листах',
    'tech.detail.maket.one': 'Один макет на все Элементы',
    'tech.detail.alg': 'Алгоритм изготовления:',
    'tech.detail.alg.multi': 'Алгоритмы изготовления:',
    'tech.detail.or': 'ИЛИ',
    'tech.detail.alg.add': 'Добавить алгоритм изготовления',
    'tech.detail.add': 'Добавить деталь',
    'tech.detail.noty.success': 'Техкарта успешно загрузилась.',
    'tech.detail.save.title': 'деталь для вида',
    'tech.detail.form.name': 'Название детали',
    'tech.detail.format.sm': 'Формат',
    'tech.detail.parma.use': 'Использовать параметры продукта',
    'tech.detail.form.roll': 'Деталь печатается на рулонном принтере',
    'tech.detail.padding.default': 'Станд/рас между этикетками (мм)',
    'tech.detail.padding.max': 'Макс/рас между этикетками (мм)',
    'tech.detail.bleeds.default': 'Станд/рас до края ленты (мм)',
    'tech.detail.bleeds.max': 'Макс/рас до края ленты (мм)',
    'tech.detail.width.default': 'Стандартная ширина (мм)',
    'tech.detail.width.max': 'Максимальная ширина (мм)',
    'tech.detail.height.default': 'Стандартная высота (мм)',
    'tech.detail.height.max': 'Максимальная высота (мм)',
    'tech.detail.form.comment': 'Комментарий технолога',
    'tech.detail.form.count': 'Элементов в детали',
    'tech.detail.form.same': 'Один макет на все Элементы',
    'tech.detail.not.maket': 'Для этой детали макет не нужен',
    'tech.stage.title': 'Этап',
    'tech.stage.title.alt': 'этапы работ',
    'tech.stage.add': 'Добавить этап',

    //CATEGORIES---------------------/
    'categ.title': 'Категории',
    'categ.title.sm': 'категорию',
    'categ.title.singl': 'Категория',
    'categ.title.seo': 'SEO данные категории',
    'categ.export': 'Экспорт категорий',
    'categ.seo.controll': 'Управления Seo данными',
    'categ.edit': 'Редактировать категорию',
    'categ.controll': 'Управления категорией',
    'categ.deadline': 'Срок (д)',
    'categ.rate': 'Коэф',
    'categ.creating': 'Создание',
    'categ.parent': 'Родительский каталог',
    'categ.form.popular': 'Популярный каталог',
    'categ.posit.title': 'Изменить порядок категорий',
    'categ.posit.': '',
    'categ.posit.': '',
    'categ.posit.': '',
    'categ.': '',
    'categ.': '',
    '': '',
    '': '',
};
