var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table",class:{
        'has-loading': _vm.hasLoadingList,
        'has-scroll': _vm.hasModuleScroll,
        'has-scroll-left': _vm.hasScrollBorderLeft,
        'has-scroll-right': _vm.hasScrollBorderRight,
        'has-empty': _vm.hasEmpty,
        'has-box': _vm.inBox,
    }},[_c('div',{staticClass:"table__container",class:{
            [`druk-l-surface-${_vm.surface}`]: _vm.surface,
            [`druk-l-shape-${_vm.shape}`]: _vm.shape,
            'druk-l-shape-none-bot': _vm.hasPagination,
            'is-inner': _vm.isInner,
        }},[_c('div',{staticClass:"table__border"},[_c('div',{ref:"tableWrap",staticClass:"table__main",on:{"scroll":_vm.onTouchScroll}},[_c('table',{ref:"table"},[((!(!!_vm.emptyPrompt && _vm.hasEmpty) || !_vm.emptyPrompt) && _vm.headers)?_c('thead',{ref:"mainHead"},[_c('tr',[_vm._l((_vm.headers),function(head,index){return [(!head.hideTh)?_c('th',{key:index,class:{ 'has-nav': head.key === _vm.KEY_NAV, 'has-wrap': head.hasWrap, 'has-hot': head.hasHot }},[_c('div',{staticClass:"_flex",class:{
                                            'has-active': head.key === _vm.sortKey,
                                            _align_center: !head.hasActions,
                                            'has-actions': head.hasActions,
                                        }},[_c('span',{class:head.class},[_vm._v(_vm._s(head.text))]),(head.hasSort)?_c('div',{staticClass:"table__icon",class:{
                                                'has-active': head.key === _vm.sortKey,
                                                'table__icon--desc': _vm.direction === _vm.DIRECTION_DESC_KEY && head.key === _vm.sortKey,
                                            },on:{"click":function($event){return _vm.directionCheck(head.key, head.keyPayload)}}},[(head.key === _vm.sortKey)?_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-arrow-down"}}):_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-arrow-down"}})],1):_vm._e()])]):_vm._e()]})],2)]):_vm._e(),_c('tbody',{ref:"mainBody",staticClass:"table__body"},[_vm._t("default")],2)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasModuleScroll),expression:"hasModuleScroll"}],staticClass:"table__scrollWrap",style:({ bottom: `${_vm.scrollPosition}px` })},[_c('div',{ref:"scrollWrap",staticClass:"table__scroll",class:{ [`druk-l-surface-${_vm.surface}`]: _vm.surface },style:({ width: `${_vm.tableWrapWidth}px` })},[_c('span',{style:({ width: `${_vm.tableWidth}px` })})])]),_c('div',{staticClass:"table__empty druk-u-text-label-lg"},[_vm._v(" "+_vm._s(_vm.emptyPrompt || _vm.$t('common.table.empty'))+" ")])]),_c('div',{staticClass:"table__spinner"},[_c('druk-loader'),_c('span',[_vm._v(_vm._s(_vm.$t('common.table.loading')))])],1)]),(_vm.hasPagination)?_c('div',{staticClass:"table__footer"},[_c('druk-pagination',{attrs:{"pagination":_vm.pagination,"list":_vm.items,"hasLoadingList":_vm.hasLoadingList},on:{"changeLimit":function($event){return _vm.$emit('changeLimit', $event)},"changePage":function($event){return _vm.$emit('changePage', $event)}}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }