import Vue from 'vue';

import axios from 'axios';

import comments from './comments';

const CancelToken = axios.CancelToken;

let cancel;

let getFomrattedItem = (item, index, group_index) => {
    return {
        ...item,
        index,
        group_index,

        preview:
            item.product?.mockup_with_preview?.preview ||
            item.product?.mockup_with_preview?.preview_original ||
            item.product?.preview ||
            null,
        placeholder: !item.creator ? 'placeholder.png' : `${item.stage.id}.png`,

        has_loading: false,
    };
};

export default {
    namespaced: true,

    modules: {
        comments,
    },

    state: {
        KEY_OPERATIONS: 'common',
        KEY_PREPRESS: 'prepress',
        KEY_PRINT: 'print',
        KEY_POSTPRESS: 'postpress',
        KEY_LOGISTIC: 'logistic',

        INACTIVE_FILTERING_KEYS: ['group'],

        WITHOUT_ASSIGNEE_FILTER_OPTION: {
            title: null,
            translation_alias: 'tasks.filters.assignee_without',
            key: 'without_manager',
            active: true,
        },

        GROUP_OPTIONS: [
            {
                title: null,
                key: 'grouping.default',
                value: 'default',
            },
            {
                title: null,
                key: 'grouping.order',
                value: 'order',
            },
            {
                title: null,
                key: 'grouping.product',
                value: 'product',
            },
            // @TODO: Will be need in the future, maybe
            // {
            // 	title: null,
            // 	key: 'grouping.operation',
            // 	value: 'operation',
            // },
        ],

        GROUP_KEY_DEFAULT: 'default',
        GROUP_KEY_ORDER: 'order',
        GROUP_KEY_PRODUCT: 'product',

        OPERATION_ITEM_ROW_TYPE: 'operations-row',
        OPERATION_ITEM_CARD_TYPE: 'operations-card',

        STAGE_ID_PREPRESS: 1,
        STAGE_ID_PRINT: 2,
        STAGE_ID_POSTPRESS: 3,
        STAGE_ID_LOGISTIC: 4,

        OPERATION_STATUS_NEW: 1,
        OPERATION_STATUS_IN_PROGRESS: 2,
        OPERATION_STATUS_CANCELED: 3,
        OPERATION_STATUS_DONE: 4,
        OPERATION_STATUS_STOPPED: 5,
        OPERATION_STATUS_WAITING: 6,

        OPERATION_TYPE_MOCKUP_AUTO: 'mockup_auto',
        OPERATION_TYPE_MOCKUP_MANUAL: 'mockup_manual',
        OPERATION_TYPE_DESCENT_AUTO: 'descent_auto',
        OPERATION_TYPE_DESCENT_MANUAL: 'descent_manual',
        OPERATION_TYPE_MANUFACTURING: 'manufacturing',
        OPERATION_TYPE_PACK: 'pack',
        OPERATION_TYPE_DELIVERY: 'delivery',

        PERMISSION_MODULE_TO_STAGE_RELATIONS: {
            front_tasksPre: 1,
            front_tasksPrint: 2,
            front_tasksPost: 3,
            front_tasksLogistic: 4,
        },

        TAB_MAIN: 'production-main',
        TAB_OPERATIONS: 'production-operations',
        TAB_COMMENTS: 'production-comments',
        TAB_ACTIVITY: 'production-activity',

        list: [],
        listPagination: {},
        listFilters: {},

        production: [],
        extendedProduction: [],
        item: {},
        activeItem: null,

        booking: {
            materials: [],
            comment: null,
        },

        stages: [],
        statuses: [],

        listFilter: {
            page: null,
            limit: 30,
            order: null,
            urgency_id: null,
            client_id: null,
            assignee_id: null,
            status_id: null,
            stage_id: null,
            operation_type: null,
            'created_at[from]': null,
            'created_at[to]': null,
            'deadline[from]': null,
            'deadline[to]': null,
            'completed_at[from]': null,
            'completed_at[to]': null,
            group: 'default',
            sort: 'new',
            direction: 'desc',
        },

        loadingGetList: false,
        loadingGetFilters: false,
        loadingGetProduction: false,
        loadingGetStages: false,
        loadingGetStatuses: false,
        loadingGetItem: false,
        loadingSaveItem: false,
        loadingSaveItemBooking: false,
        loadingExportList: false,
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },

        stagePermissionRelations(state, getters, rootState) {
            return {
                [state.STAGE_ID_PREPRESS]: rootState.permissions.PERM_MODULE_TM_OPERATIONS_PRE,
                [state.STAGE_ID_PRINT]: rootState.permissions.PERM_MODULE_TM_OPERATIONS_PRINT,
                [state.STAGE_ID_POSTPRESS]: rootState.permissions.PERM_MODULE_TM_OPERATIONS_POST,
                [state.STAGE_ID_LOGISTIC]: rootState.permissions.PERM_MODULE_TM_OPERATIONS_LOGISTIC,
            };
        },

        isFilterNotEmpty(state, getters, rootState) {
            return !!Object.keys(getters.listActiveFilter).filter(
                (key) => !rootState.constant.INACTIVE_FILTERING_KEYS.concat(state.INACTIVE_FILTERING_KEYS).includes(key),
            ).length;
        },
    },

    actions: {
        GET_LIST: async ({ state, rootState, getters, rootGetters, commit, dispatch }, filters = {}) => {
            try {
                if (cancel !== undefined) cancel();

                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetFilters',
                    status: true,
                });

                let path = `/api/typographies/${rootGetters.currentTypography.id}/orders/productions/operations`,
                    filterPath = `api/typographies/${rootGetters.currentTypography.id}/orders/productions/operations/filters`;

                let config = {
                    params: !!Object.keys(filters).length ? filters : getters.listActiveFilter,

                    cancelToken: new CancelToken(function executor(c) {
                        cancel = c;
                    }),
                };

                let [resp, filterResp] = await Promise.all([axios.get(path, config), axios.get(filterPath, config)]);

                commit('SET_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);
                commit('SET_FILTERS', filterResp.filters);

                return [resp, filterResp];
            } catch (e) {
                if (!e.response) return;
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetFilters',
                    status: false,
                });
            }
        },

        GET_PRODUCTION: async ({ state, rootGetters, getters, commit }, { orderId, productionId, extended_list = 0 }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetProduction',
                    status: true,
                });

                let params = { extended_list };

                let path = `/api/typographies/${rootGetters.currentTypography.id}/orders/${orderId}/products/productions/${productionId}`;

                let resp = await axios.get(path, { params });

                commit(extended_list ? 'SET_EXTENDED_PRODUCTION' : 'SET_PRODUCTION', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetProduction',
                    status: false,
                });
            }
        },

        GET_STAGES: async ({ state, getters, rootGetters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetStages', status: true });

                let path = `/api/production-stages`;

                let resp = await axios.get(path);

                commit('SET_STAGES', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetStages', status: false });
            }
        },

        GET_STATUSES: async ({ state, getters, rootGetters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetStatuses', status: true });

                let path = `/api/production-statuses`;

                let resp = await axios.get(path);

                commit('SET_STATUSES', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetStatuses', status: false });
            }
        },

        GET_ITEM: async ({ state, getters, rootGetters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                let resp = await axios.get(
                    `/api/typographies/${rootGetters.currentTypography.id}/orders/productions/operations/${id}`,
                );

                commit('SET_ITEM', resp.single);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        SAVE_ITEM: async ({ state, rootGetters, commit, dispatch }, { formData, productionData, without_fetch }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let resp = await axios.post(
                    `/api/typographies/${rootGetters.currentTypography.id}/orders/productions/operations`,
                    formData,
                );

                if (without_fetch) return resp;

                productionData ? dispatch('GET_PRODUCTION', productionData) : dispatch('GET_LIST');

                if (formData.id && Object.keys(state.item).length) dispatch('GET_ITEM', formData.id);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        SAVE_ITEM_BOOKING: async ({ state, rootGetters, commit, dispatch }, { id, formData, without_fetch }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingSaveItemBooking',
                    status: true,
                });

                let path = `api/typographies/${rootGetters.currentTypography.id}/orders/productions/operations/${id}/booking`;

                let resp = await $axios.post(path, formData);

                if (!without_fetch) dispatch('GET_ITEM', id);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingSaveItemBooking',
                    status: false,
                });
            }
        },

        EXPORT_LIST: async ({ state, commit, rootGetters, dispatch }, params) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingExportList',
                    status: true,
                });

                let path = `/api/typographies/${rootGetters.currentTypography.id}/orders/productions/operations/export`;

                let resp = await axios.get(path, { params });

                const url = resp.link,
                    link = document.createElement('a');

                link.href = url;
                link.click();

                URL.revokeObjectURL(link.href);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingExportList',
                    status: false,
                });
            }
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = (Array.isArray(list) ? list : [{ ...list, id: 'operations' }]).map((item, index) => ({
                id: item.id,
                operations: item.operations.map((operation, operationIndex) => getFomrattedItem(operation, operationIndex, index)),
            }));
        },

        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },

        SET_FILTERS(state, filters) {
            state.listFilters = filters;
        },

        SET_PRODUCTION(state, production) {
            state.production = Object.entries(production).map((item, index) => ({
                id: item[0],
                operations: item[1].map((operation, operationIndex) => getFomrattedItem(operation, operationIndex, index)),
            }));
        },

        SET_EXTENDED_PRODUCTION(state, production) {
            state.extendedProduction = production.operations;
        },

        SET_STAGES(state, stages) {
            state.stages = stages;
        },

        SET_STATUSES(state, statuses) {
            state.statuses = statuses;
        },

        SET_ITEM(state, item) {
            state.item = item;
        },

        SET_LIST_ITEM_DATA(state, { operation, data, isProductionMode }) {
            Vue.set(state[isProductionMode ? 'production' : 'list'][operation.group_index].operations, operation.index, {
                ...operation,
                ...data,
            });
        },

        SET_LIST_ITEM_PROP(state, { operation, key, value, isProductionMode }) {
            Vue.set(state[isProductionMode ? 'production' : 'list'][operation.group_index].operations[operation.index], key, value);
        },

        SET_ACTIVE_ITEM(state, item) {
            Vue.set(state, 'activeItem', item || null);
        },

        SET_ACTIVE_ITEM_PROP(state, { key, value }) {
            if (state.activeItem) Vue.set(state.activeItem, key, value);
        },

        SET_BOOKING_MATERIAL(state, { formData }) {
            state.booking.materials.push(formData);
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        CHANGE_BOOKING_MATERIAL_OPTION(state, { index, key, value }) {
            Vue.set(state.booking.materials[index], key, value);
        },

        DELETE_BOOKING_MATERIAL(state, index) {
            state.booking.materials = state.booking.materials.slice(0, index).concat(state.booking.materials.slice(index + 1));
        },

        UPDATE_LIST_FILTER(state, newFilterlist) {
            Object.entries(newFilterlist).forEach(([key, value]) => Vue.set(state.listFilter, key, value));
            Vue.set(state.listFilter, 'page', newFilterlist.page || 1);
        },

        RESET_ACTIVE_ITEM(state) {
            Vue.delete(state, 'activeItem');
        },

        RESET_BOOKING(state) {
            state.booking = {
                materials: [],
                comment: null,
            };
        },

        RESET_STORE(state) {
            state.listFilter = {
                page: null,
                limit: 30,
                order: null,
                urgency_id: null,
                client_id: null,
                assignee_id: null,
                status_id: null,
                stage_id: null,
                operation_type: null,
                'created_at[from]': null,
                'created_at[to]': null,
                'deadline[from]': null,
                'deadline[to]': null,
                'completed_at[from]': null,
                'completed_at[to]': null,
                group: 'default',
                sort: 'new',
                direction: 'desc',
            };
        },
    },
};
