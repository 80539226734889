<template>
    <div class="check__group">
        <form-checkbox
            v-for="(option, index) in options"
            :key="index"
            v-model="checkList[index]"
            :label="option.title"
            @change="pickOption(item)" />

        <span v-if="hasError" class="form__error">{{ errorText }}</span>
    </div>
</template>

<script>
    export default {
        name: 'form-check-group',

        props: {
            value: Array,
            name: String,
            options: Array,
            rules: [String, Object],
            errorText: String,

            hasError: Boolean,
        },

        data() {
            return {
                checkList: this.value || [],
            };
        },

        methods: {
            pickOption(item) {
                let pickedItem = item;
                pickedItem.picked = true;
                this.$emit('input', this.checkList);
            },
        },
    };
</script>

<style scoped lang="scss"></style>
