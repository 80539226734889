<template>
    <div class="checkbox-group">
        <div class="checkbox-group__header">
            <form-checkbox
                v-model="checked"
                :label="label"
                :prompt="prompt"
                :name="name"
                :isIndeterminate="isIndeterminate"
                :isGroupParent="true"
                :isDisabled="isDisabled" />
        </div>

        <div class="checkbox-group__body">
            <form-checkbox
                v-for="(option, index) in options"
                :key="index"
                v-model="value[option.key]"
                :label="option.title || $fn.tItemSystem(option).title"
                :name="`${name}_${option.key}`"
                :tooltip="option.tooltip"
                :isDisabled="isDisabled" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'form-checkbox-group',

        model: {
            prop: 'value',
            event: 'change',
        },

        props: {
            value: Object,
            label: String,
            options: Array,
            prompt: String,
            name: String,
            isDisabled: [Boolean, Number],
        },

        computed: {
            checked: {
                get() {
                    return Object.keys(this.value).length === this.activeOptions.length && !!this.activeOptions.length;
                },
                set(value) {
                    this.onSetOptions(value);
                },
            },

            activeOptions() {
                return Object.values(this.value).filter((item) => !!item);
            },

            isIndeterminate() {
                return !!this.activeOptions.length && !this.checked;
            },
        },

        methods: {
            onSetOptions(value) {
                Object.keys(this.value).forEach((key) => (this.value[key] = value));
            },
        },
    };
</script>

<style lang="scss" scoped></style>
