import Vue from 'vue';

import plans from './plans';

export default {
    namespaced: true,

    modules: {
        plans,
    },

    state: {},

    getters: {
        hasReachedEmployeesLimit(state, getters, rootState, rootGetters) {
            return !!(
                rootGetters.hasTypographyAccess &&
                rootState.typographies.item.pricing_plan.employees &&
                rootState.typographies.item.employees >= rootState.typographies.item.pricing_plan.employees
            );
        },
    },

    actions: {},

    mutations: {},
};
