import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        list: [],
        listPagination: {},
        item: {},

        commentsList: [],

        listFilter: {
            page: null,
            limit: 30,
        },

        loadingGetList: false,
        loadingGetCommentsList: false,
        loadingSaveItem: false,
        loadingSaveComment: false,
        loadingDeleteItem: false,
        loadingSaveContact: false,
        loadingDeleteContact: false,
    },

    getters: {
        listActiveFilter(state) {
            let filter = {};

            $fn.forIn(state.listFilter, (val, key) => {
                if (val !== null && val !== '') filter[key] = val;
            });
            return filter;
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },

        SET_ITEM(state, item) {
            state.item = item;
        },

        SET_COMMENTS_LIST(state, list) {
            state.commentsList = list;
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        UPDATE_LIST_FILTER(state, newFilterObj) {
            $fn.forIn(newFilterObj, (val, key) => {
                Vue.set(state.listFilter, key, val);
            });

            Vue.set(state.listFilter, 'page', newFilterObj.page || 1);
        },

        RESET_STORE(state) {
            state.listFilter = {
                page: null,
                limit: null,
            };
        },
    },

    actions: {
        GET_LIST: async ({ state, getters, rootGetters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let path = `/api/partners`;

                let resp = await $axios.get(path, {
                    params: getters.listActiveFilter,
                });

                commit('SET_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_COMMENTS_LIST: async ({ state, getters, rootGetters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetCommentsList',
                    status: true,
                });
                let resp = await $axios.get(`/api/partners/${id}/comments`);

                commit('SET_COMMENTS_LIST', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetCommentsList',
                    status: false,
                });
            }
        },

        GET_ITEM: async ({ state, getters, rootGetters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                let path = `/api/partners/${id}`;

                let resp = await $axios.get(path);

                commit('SET_ITEM', resp.single);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        SAVE_ITEM: async ({ state, commit, rootGetters, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let path = `/api/partners`;

                let resp = await $axios.post(path, formData);

                dispatch('GET_LIST');

                if (formData.id && Object.keys(state.item).length) dispatch('GET_ITEM', formData.id);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        SAVE_CONTACT: async ({ state, commit, dispatch }, { formData, id }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingSaveContact',
                    status: true,
                });

                let resp = await $axios.post(`/api/partners/${id}/contacts`, formData);

                dispatch('GET_ITEM', id);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingSaveContact',
                    status: false,
                });
            }
        },

        SAVE_COMMENT: async ({ state, commit, dispatch }, { formData, typography_id }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingSaveComment',
                    status: true,
                });

                let resp = await $axios.post(`/api/partners/${typography_id}/comments`, formData);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingSaveComment',
                    status: false,
                });
            }
        },

        DELETE_CONTACT: async ({ state, commit, dispatch }, { typography_id, contact_id }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingDeleteContact',
                    status: true,
                });

                let resp = await $axios.delete(`/api/partners/${typography_id}/contacts/${contact_id}`);

                dispatch('GET_ITEM', typography_id);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingDeleteContact',
                    status: false,
                });
            }
        },

        DELETE_ITEM: async ({ commit, rootGetters, dispatch }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let resp = await $axios.delete(`/api/partners/${id}`);
                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },
    },
};
