import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        list: [],
        item: {},
        listPagination: {},

        listFilter: {
            page: null,
            limit: 30,
            sort: 'new',
            direction: 'desc',
        },

        loadingGetList: false,
        loadingGetItem: false,
        loadingSaveItem: false,
        loadingDeleteItem: false,
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },
        SET_ITEM(state, item) {
            state.item = item;
        },
        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },
        SET_FILTER_LIST(state, { key, val }) {
            state.listFilter[key] = val;
        },
        MERGE_FILTER_LIST(state, val) {
            state.listFilter = Object.assign(state.listFilter, val);
        },
        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },
        UPDATE_LIST_FILTER(state, newFilterObj) {
            $fn.forIn(newFilterObj, (val, key) => {
                Vue.set(state.listFilter, key, val);
            });

            Vue.set(state.listFilter, 'page', newFilterObj.page || 1);
        },
        RESET_STORE(state) {
            state.listFilter = {
                page: null,
                limit: 30,
                sort: 'new',
                direction: 'desc',
            };
        },
    },

    actions: {
        GET_LIST: async ({ state, rootGetters, getters, commit }, payload = { filters: {} }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/materials/booking`, {
                    params: Object.keys(payload.filters).length ? payload.filters : getters.listActiveFilter,
                });

                commit('SET_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_ITEM: async ({ state, getters, rootGetters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/materials/booking/${id}`);

                commit('SET_ITEM', resp.single);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        SAVE_ITEM: async ({ state, rootGetters, commit, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let resp = await $axios.post(`/api/typographies/${rootGetters.currentTypography.id}/materials/booking`, formData);

                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        DELETE_ITEM: async ({ commit, rootGetters, dispatch }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let resp = await $axios.delete(`/api/typographies/${rootGetters.currentTypography.id}/materials/booking/${id}`);

                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },
    },
};
