import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

export default {
    data() {
        return {
            HEADLINE_SMALL_FONT: {
                family: 'Montserrat',
                size: 24,
                weight: 400,
                lineHeight: 1.4,
            },

            LABEL_LARGE_FONT: {
                family: 'Montserrat',
                size: 14,
                weight: 500,
                lineHeight: 1.2,
            },

            LABEL_MEDIUM_FONT: {
                family: 'Montserrat',
                size: 12,
                weight: 500,
                lineHeight: 1.2,
            },

            LABEL_SMALL_FONT: {
                family: 'Montserrat',
                size: 11,
                weight: 500,
                lineHeight: 1,
            },
        };
    },

    watch: {
        isDarkTheme() {
            this.$refs.chart?.renderChart(this.chartConfig.data, this.chartConfig.options);
        },
    },

    computed: {
        ...mapState({
            SYSTEM_COLORS_MAP: (state) => state.constant.SYSTEM_COLORS_MAP,
        }),

        ...mapGetters({
            isDarkTheme: 'isDarkTheme',
        }),

        COLORS_MAP() {
            return Object.fromEntries(this.SYSTEM_COLORS_MAP.map((color) => [[color], this.getBarStyles(color)]));
        },

        TEXT_COLOR() {
            return this.isDarkTheme ? this.$material['on-surface__blue-dark'] : this.$material['on-surface__blue-light'];
        },

        TOOL_TIP_COLOR() {
            return this.isDarkTheme
                ? this.$material['inverse-on-surface__blue-dark']
                : this.$material['inverse-on-surface__blue-light'];
        },

        TOOL_TIP_BG() {
            return this.isDarkTheme ? this.$material['iverse-surface__blue-dark'] : this.$material['iverse-surface__blue-light'];
        },

        BORDER_COLOR() {
            return this.isDarkTheme ? this.$material['outline-variant__blue-dark'] : this.$material['outline-variant__blue-light'];
        },

        BACKDROP_COLOR() {
            return this.isDarkTheme ? this.$material['surface-low__blue-dark'] : this.$material['surface-low__blue-light'];
        },

        chartOptions() {
            return {
                responsive: true,
                maintainAspectRatio: false,

                onHover: (e, chartElement) => {
                    e.native.target.style.cursor = !!chartElement.length ? 'pointer' : 'default';
                },
            };
        },

        barChartOptions() {
            return {};
        },

        legendPluginOptions() {
            return {
                labels: {
                    boxPadding: 8,
                    boxWidth: 56,
                    padding: 16,
                    font: this.LABEL_LARGE_FONT,
                    color: this.TEXT_COLOR,
                },

                onHover: (event, legendItem, legend) => {
                    event.native.target.style.cursor = !!legendItem ? 'pointer' : 'default';
                },
            };
        },

        barLegendPluginOptions() {
            return {
                position: 'bottom',
                align: 'start',

                labels: {
                    ...this.legendPluginOptions.labels,

                    generateLabels: (chart) => {
                        let dataset = [...chart.data.datasets].shift();

                        return chart.data.labels.map((label, index) => ({
                            index,
                            text: label,
                            fillStyle: dataset.backgroundColor[index],
                            strokeStyle: dataset.borderColor[index],
                            hidden: !chart.getDataVisibility(index),
                        }));
                    },
                },

                onClick: (event, legendItem, legend) => {
                    legend.chart.toggleDataVisibility(legendItem.index);
                    legend.chart.update();
                },
            };
        },

        doughnutLegendPluginOptions() {
            return {
                position: 'bottom',
            };
        },

        scalesPluginOptions() {
            return {
                x: {
                    ticks: {
                        display: false,
                    },
                    grid: {
                        borderColor: this.BORDER_COLOR,
                        color: this.BORDER_COLOR,
                    },
                },
                y: {
                    ticks: {
                        font: this.LABEL_SMALL_FONT,
                        color: this.TEXT_COLOR,
                    },
                    grid: {
                        borderColor: this.BORDER_COLOR,
                        color: this.BORDER_COLOR,
                    },
                    grace: '5%',
                },
            };
        },

        stackedScalesPluginOptions() {
            return {
                x: {
                    ticks: {
                        display: true,
                        font: this.LABEL_SMALL_FONT,
                        color: this.TEXT_COLOR,
                    },
                    stacked: true,
                },

                y: {
                    stacked: true,
                },
            };
        },

        polarScalesPluginOptions() {
            return {
                r: {
                    ticks: {
                        font: this.LABEL_SMALL_FONT,
                        color: this.TEXT_COLOR,
                        backdropColor: this.BACKDROP_COLOR,
                    },
                    angleLines: {
                        color: this.BORDER_COLOR,
                    },
                    grid: {
                        borderColor: this.BORDER_COLOR,
                        color: this.BORDER_COLOR,
                    },
                },
            };
        },

        dataLabelsPluginOptions() {
            return {
                align: 'top',
                anchor: 'end',
                offset: 0,
                font: this.LABEL_SMALL_FONT,
                color: this.TEXT_COLOR,
            };
        },

        stackedLabelsPluginOptions() {
            return {
                align: 'center',
                anchor: 'center',
            };
        },

        tooltipPluginOptions() {
            return {
                position: 'nearest',

                bodyColor: this.TOOL_TIP_COLOR,
                bodyFont: this.LABEL_MEDIUM_FONT,
                bodyAlign: 'start',

                padding: {
                    x: 8,
                    y: 4,
                },
                cornerRadius: 4,
                backgroundColor: this.TOOL_TIP_BG,

                boxPadding: 4,

                caretPadding: 0,
                caretSize: 0,

                animations: {
                    width: {
                        duration: 0,
                    },
                    x: {
                        duration: 300,
                    },
                    y: {
                        duration: 300,
                    },
                },

                callbacks: {
                    title: () => {
                        return null;
                    },

                    label: (target) => {
                        return `${target.label}: ${target.formattedValue}`;
                    },
                },
            };
        },

        barTooltipPluginOptions() {
            return {
                intersect: false,
            };
        },

        stackedTooltipPluginOptions() {
            return {
                callbacks: {
                    title: () => {
                        return null;
                    },

                    label: (target, e) => {
                        return `${target.dataset.label}: ${target.formattedValue}`;
                    },
                },

                intersect: true,
            };
        },

        doghnutChartOptions() {
            return {};
        },
    },

    methods: {
        getBarStyles(keyCode) {
            let themeTag = this.isDarkTheme ? 'blue-dark' : 'blue-light';

            return {
                border: this.$material[`chart-${keyCode}-border__${themeTag}`],
                background: this.$material[`chart-${keyCode}-background__${themeTag}`],
                backgroundHover: this.$material[`chart-${keyCode}-background-hover__${themeTag}`],
            };
        },
    },
};
