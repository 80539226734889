<!--@TODO: Temporary modal for konica-->
<template>
    <div class="vmodal">
        <div class="vmodal__container">
            <div class="vmodal__head">
                <div class="vmodal__title">
                    <div class="vmodal__title-text">{{ $t('tech.information') }}</div>
                </div>

                <druk-icon-button :type="'standard'" :icon="'xmark'" @click="$emit('close')" />
            </div>

            <div class="vmodal__main">
                <div class="form">
                    <div class="form__block druk-u-margin-bottom-20">
                        <div class="form__title">
                            <div class="form__title-text">{{ $t('techin.check') }}</div>

                            <div class="form__title-support">
                                <druk-support>
                                    <span v-html="deviceInfo"></span>
                                </druk-support>
                            </div>
                        </div>

                        <div class="form__title">
                            <div class="form__title-text">{{ $t('techin.device') }}</div>

                            <div class="form__title-support">
                                <druk-support>
                                    <span v-html="deviceDetect"></span>
                                </druk-support>
                            </div>
                        </div>

                        <div class="form__title">
                            <div class="form__title-text">{{ $t('techin.screen') }}</div>

                            <div class="form__title-support">
                                <druk-support>
                                    <span v-html="window"></span>
                                </druk-support>
                            </div>
                        </div>
                    </div>

                    <div class="form__block">
                        <div class="form__title">
                            <div class="form__title-text">{{ $t('common.color_theme.title') }}</div>
                        </div>

                        <div class="grid">
                            <div class="grid__col grid__col--3">
                                <div class="from__item druk-u-margin-bottom-8 has-alt">
                                    <alternative-header-logo />

                                    <div class="form__body-support">
                                        <druk-support>{{ $t('common.color_theme.alternative_logo_prompt') }}</druk-support>
                                    </div>
                                </div>
                            </div>

                            <div class="grid__col grid__col--3">
                                <div class="from__item druk-u-margin-bottom-8">
                                    <inverse-sidebar-picker />

                                    <div class="form__body-support">
                                        <druk-support>{{ $t('common.color_theme.contrast_drawer_prompt') }}</druk-support>
                                    </div>
                                </div>
                            </div>

                            <div class="grid__col grid__col--3">
                                <div class="from__item druk-u-margin-bottom-8">
                                    <theme-picker />

                                    <div class="form__body-support">
                                        <druk-support>{{ $t('common.color_theme.dark_theme_prompt') }}</druk-support>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form__footer">
                        <druk-button
                            :path="{ name: 'druk_developer' }"
                            :icon="'wand-magic-sparkles'"
                            :label="$t('developer_page.title')"
                            @click.native="$emit('close')" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import AlternativeHeaderLogo from '@views/typographies/theme/AlternativeHeaderLogo.vue';
    import InverseSidebarPicker from '@views/typographies/theme/InverseSidebarPicker';
    import ThemePicker from '@views/typographies/theme/ThemePicker';

    export default {
        name: 'modal-tech-data',

        components: {
            AlternativeHeaderLogo,
            InverseSidebarPicker,
            ThemePicker,
        },

        computed: {
            deviceInfo() {
                return Object.entries(this.$device)
                    .filter(([key, value]) => !!value && typeof value !== 'function')
                    .map(([key, value]) => `${key}: <b>${value}</b>`)
                    .join('; ');
            },

            deviceDetect() {
                return Object.entries(this.$device.deviceDetect())
                    .map(([key, value]) => `${key}: <b>${value}</b>`)
                    .join('; ');
            },

            window() {
                return [
                    `offsetWidth: <b>${window.innerWidth}</b>`,
                    `offsetHeight: <b>${window.innerHeight}</b>`,
                    `screenWidth: <b>${window.screen.width}</b>`,
                    `screenHeight: <b>${window.screen.height}</b>`,
                ].join('; ');
            },
        },
    };
</script>

<style lang="scss" scoped></style>
