import availability from './availability';
import movement from './movement';
import supplier from './supplier';
import inventory from './inventory';
import supply from './supply';
import logs from './logs';
import booking from './booking';
import cut from './cut';
import internal from './internal';
import readyProducts from './readyProducts';

export default {
    namespaced: true,

    modules: {
        availability,
        movement,
        supplier,
        inventory,
        supply,
        logs,
        booking,
        cut,
        internal,
        readyProducts,
    },

    state: {
        DIMENSION_LABEL_DEFAULT: 'metrics.pieces',
        DIMENSION_LABEL_STOCK: 'movem.count',
        STATUS_SUPPLY_NOTPAY: 1,
        STATUS_SUPPLY_PAY: 2,
        STATUS_SUPPLY_PARTPAY: 3,
        OPERATION_TYPE_CREATE: 'receipt_item',
        OPERATION_TYPE_WRITE_OFF: 'write-off',
        OPERATION_TYPE_CONSUMPTION: 'consumption',
        OPERATION_TYPE_INTERNAL: 'internal',
        OPERATION_TYPE_RECEIPT: 'receipt',
        OPERATION_TYPE_CANCELLATION: 'cancellation',

        dimensionMaterials: [],

        stockStatuses: [],

        loadingGetDimensionMaterialList: false,
        loadingGetDimensionStockList: false,
        loadingGetStockStatusesList: false,
    },

    getters: {},

    mutations: {
        SET_STOCK_STATUSES(state, list) {
            state.stockStatuses = list;
        },

        SET_DIMENSION_MATERIAL(state, list) {
            state.dimensionMaterials = list;
        },

        SET_DIMENSION_STOCK(state, list) {
            state.dimensionStock = list;
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },
    },

    actions: {
        GET_STOCK_STATUSES: async ({ state, rootGetters, getters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetStockStatusesList', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/store/receipts/statuses`);

                commit('SET_STOCK_STATUSES', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetStockStatusesList', status: false });
            }
        },

        GET_DIMENSION_MATERIAL: async ({ state, rootGetters, getters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetDimensionMaterialList', status: true });

                let resp = await $axios.get(`/api/dimensions`);

                commit('SET_DIMENSION_MATERIAL', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetDimensionMaterialList', status: false });
            }
        },
    },
};
