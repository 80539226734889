<!-- @Explanation: Super secret functionality -->
<template>
    <form-switch v-model="pickedTheme" :label="$t('common.color_theme.dark_theme')" name="pickedTheme" />
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    export default {
        name: 'typographies-theme-picker',

        computed: {
            ...mapState({
                THEME_LIGHT: (state) => state.THEME_LIGHT,
                THEME_DARK: (state) => state.THEME_DARK,

                theme: (state) => state.theme,
            }),

            ...mapGetters({
                isDarkTheme: 'isDarkTheme',
            }),

            pickedTheme: {
                get() {
                    return this.isDarkTheme;
                },
                set(value) {
                    this.SET_PROJECT_THEME(value ? this.THEME_DARK : this.THEME_LIGHT);
                },
            },
        },

        methods: {
            ...mapMutations({
                SET_PROJECT_THEME: 'SET_PROJECT_THEME',
            }),
        },
    };
</script>

<style lang="scss" scoped></style>
