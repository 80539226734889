<template>
    <div class="druk-u-inline">
        <druk-support :isError="true">
            <span>{{ $t('products.has_not_roll_printing_module.error') }}, </span>

            <druk-link
                class="druk-u-white-space-nowrap"
                :path="link"
                :label="{ value: $t('products.has_not_roll_printing_module.link'), class: 'druk-u-text-label-m' }"
                :isExternal="true" />
        </druk-support>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    export default {
        name: 'roll-module-error',

        computed: {
            ...mapState({
                MAIN_LANGUAGE: (state) => state.languages.MAIN_LANGUAGE,
                DRUKWEB_LANGUAGE: (state) => state.languages.DRUKWEB_LANGUAGE,

                selectedLang: (state) => state.selectedLang,
            }),

            link() {
                return !this.hasMainDomainLanguage
                    ? 'https://drukweb.pl/contact'
                    : `https://druk.ua/${this.hasMainDomainMainLocale ? '' : `${this.selectedLang}/`}contact`;
            },

            hasMainDomainLanguage() {
                return this.selectedLang !== this.DRUKWEB_LANGUAGE;
            },

            hasMainDomainMainLocale() {
                return this.selectedLang === this.MAIN_LANGUAGE;
            },
        },
    };
</script>

<style lang="scss" scoped></style>
