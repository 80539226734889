export default {
    'typog.title': 'Настройки типографии',
    'typog.tab.type1': 'Профиль',
    'typog.tab.type2': 'Google Analytics',
    'typog.tab.type3': 'Счет',
    'typog.tab.type4': 'Контакты',
    'typog.tab.type5': 'Оформление',
    'typog.btn.item1': 'Настройка сервисов',
    'typog.btn.item2': 'Настройка Bitrix24',
    'typog.main.name1': 'Название типографии:',
    'typog.main.name2': 'Элиас:',
    'typog.main.name3': 'Домен:',
    'typog.main.sub': 'Подключенные модули',
    'typog.tab.order': 'Заказ',
    'typog.anal.title1': 'Основные настройки GA',
    'typog.anal.title2': 'Настройки GA для соц.сетей',
    'typog.anal.title3': 'Настройки GA для соц.сетей',
    'typog.anal.type1.el1': 'GA ключ',
    'typog.anal.type1.el2': 'Добавление товара в корзину',
    'typog.anal.type1.el3': 'Обращение через форму обратной связи',
    'typog.anal.type1.el4': 'Вход/Регистрация через соц. сети',
    'typog.anal.type1.el5': 'Выбор доставки и способа оплаты',
    'typog.anal.type1.el6': 'Оформление заказа',
    'typog.anal.type1.el7': 'Оплата заказа из кабинета',
    'typog.anal.type1.el8': 'Пополнение баланса',
    'typog.anal.type1.el9': 'Формирование счета',
    'typog.acc.title': 'Реквизиты для формирования счёта',
    'typog.acc.item1': 'Поставщик',
    'typog.acc.item2': 'Код ЕГРПОУ',
    'typog.acc.item3': 'Банк',
    'typog.acc.item4': 'Счёт',
    'typog.acc.item5': 'МФО',
    'typog.acc.item6': 'Адрес',
    'typog.acc.item7': 'Телефон/Факс',
    'typog.acc.item8': 'Электронная почта',
    'typog.acc.item9': 'Категория налога',
    'typog.contact.title1': 'Локация',
    'typog.contact.title2': 'Новая Почта: Отделение для отправки',
    'typog.contact.title2.info': 'Для формирования ТТН “Новой Почты”, укажите отделение, с которого делаете отправку заказов',
    'typog.contact.title3': 'Расписание',
    'typog.contact.title4': 'Телефоны',
    'typog.contact.phone.label': 'Номер телефона',
    'typog.contact.title5': 'Ключи GA для телефонов',
    'typog.contact.title6': 'Соц. сети',
    'typog.contact.title7': 'Ключи GA для соц.сетей',
    'typog.contact.day1': 'ПН',
    'typog.contact.day2': 'ВТ',
    'typog.contact.day3': 'СР',
    'typog.contact.day4': 'ЧТ',
    'typog.contact.day5': 'ПТ',
    'typog.contact.day6': 'СБ',
    'typog.contact.day7': 'ВС',
    'typog.contact.location1': 'Координаты на карте (lat,lon)',
    'typog.contact.location2': 'Url точки на карте',
    'typog.contact.location3': 'Адрес',
    'typog.contact.address1': 'Город',
    'typog.contact.address2': 'Отделение',
    'typog.msg.create': 'Начался процесс создания типографии. Ориентировочное время окончания 10 минут',
    'typog.design.title1': 'Настройка альтернативного дизайна',
    'typog.design.title2': 'Настройка альтернативного дизайна',
    'typog.design.logo.info': '*Рекомендованные размеры логотипа: Ширина — от 60рх до 200рх;  Высота — от 60рх до 120рх',
    'typog.design.logo.info.detail': ' *Доступные расширения: PNG;',
    'typog.design.logo.form': 'Лого типографии',
    'typog.design.color': 'Главный цвет',
    'typog.design.alter.label': 'Установить карточку товара “по-умолчанию”',
    'typog.design.alter.info': '*Активное положение настройки включает отображение слайдера с видами товаров',
    'typog.modal.title': 'типографию',
    'typog.form.domen': 'Домен типографии (без https)',
    'typog.form.domen.bitrix': 'Bitrix Домен',
    'typog.form.domen.preflight': 'Preflight Домен',
    'typog.form.modules': 'Подключенные модули',
    'typog.form.logo.order': 'Логотип для счёта',
    'typog.modal.bitrix.title': 'Настройки подключения Bitrix24',
    'typog.modal.bitrix.token': 'Токены',
    'typog.modal.bitrix.token.add': 'Добавить токен',
    'typog.modal.bitrix.edit.client': 'Настройки клиента',
    'typog.form.balance': 'Баланс клиента',
    'typog.form.discount': 'Скидка клиента',
    'typog.modal.bitrix.edit.order': 'Настройки заказа',
    'typog.form.area.order': 'Поле заказа',
    'typog.form.quantity': 'Тираж',
    'typog.form.design': 'Наличие макета',
    'typog.form.link': 'Ссылка на счет',
    'typog.form.paymentType': 'Тип платежа',
    'typog.form.delivery': 'Адрес доставки',
    'typog.form.order.name': 'Название заказа',
    'typog.form.comment': 'Комментарий к заказу',
    'typog.form.paid': 'Оплачен заказ',
    'typog.modal.bitrix.lead': 'Лиды',
    'typog.form.orderId': 'Лид - Поле заказа',
    'typog.form.lead.quantity': 'Лид - Тираж',
    'typog.form.lead.design': 'Лид - Нужен дизайн элемент',
    'typog.form.lead.link': 'Лид - Ссылка на счет',
    'typog.form.lead.paymentType': 'Лид - Тип платежа',
    'typog.form.lead.address': 'Лид - Адрес доставки',
    'typog.form.lead.name': 'Лид - Название заказа',
    'typog.form.lead.comment': 'Лид - Комментарий к заказу',
    'typog.form.lead.paid': 'Лид - Оплачен заказ',
    'typog.form.lead.assigned': 'ID интегратора',
    'typog.form.lead.assigned.info': 'Пользователь системы bitrix24, от чего имени будет происходить взаимодействия',
    'typog.form.lead.assignee': 'Исполнитель по умолчанию',
    'typog.form.lead.assignee.info': 'Менеджер, который назначается по умолчанию для новых клиентов',
    'typog.hook.title': 'Хуки',
    'typog.form.hook.company': 'Хук компании',
    'typog.form.hook.contact': 'Хук контактов',
    'typog.form.hook.order': 'Хук сделок',
    'typog.form.hook.lead': 'Хук заявок',
    'typog.form.hook.task': 'Хук задач',
    'typog.stages.title': 'Направления сделок',
    'typog.form.stages.id': 'Номер воронки продаж',
    'typog.form.stages.new': 'Статус: Новый',
    'typog.form.stages.accepted': 'Статус: Принят',
    'typog.form.stages.canceled': 'Статус: Отменён',
    'typog.form.stages.completed': 'Статус: Завершён',
    'typog.form.stages.production': 'Производство',
    'typog.index.title': 'Список типографий',
    'typog.widget.title': 'Настройки виджетов',
    'typog.widget.key.public': 'Публичный ключ',
    'typog.widget.key.privat': 'Приватный ключ',
    'typog.widget.ppo.title': 'пРРО Checkbox',
    'typog.widget.ppo.login': 'Логин кассира',
    'typog.widget.ppo.pass': 'Пароль кассира',
    'typog.widget.ppo.key': 'Лицензированный ключ кассы',
    'typog.token.title': 'токен',
    'typog.token.label': 'Токен безопасности',
    'typog.logo.title': 'История логов типографии',
    'typog.orders.title': 'История заказов типографии',
};
