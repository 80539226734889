var render = function render(){var _vm=this,_c=_vm._self._c;return _c(!!_vm.path ? 'router-link' : 'button',{tag:"component",staticClass:"druk-c-btn",class:{ 'druk-is-full': _vm.isFull, 'druk-is-waitnig': _vm.isWaiting, 'druk-is-disabled': _vm.isDisabled },attrs:{"to":_vm.path},on:{"click":function($event){return _vm.$emit('click', $event)}}},[_c('div',{staticClass:"druk-c-btn__wrapper",class:{
            [`druk-u-${_vm.type}`]: !!_vm.type,
            [`druk-has-icon`]: !!_vm.icon,
            'druk-is-highlighted': _vm.isHighlighted,
            'druk-is-inverse': _vm.isInverse,
            'druk-is-disabled': _vm.isDisabled,
        }},[_c('div',{staticClass:"druk-c-btn__content",class:{ 'druk-is-shown': !_vm.isWaiting || _vm.isDisabled }},[(_vm.icon)?_c('div',{staticClass:"druk-c-btn__icon",class:{ 'druk-is-disabled': _vm.isDisabled }},[_c('druk-icon',{attrs:{"size":'xs',"color":_vm.isDisabled ? _vm.DISABLED_COLOR : _vm.iconColor,"name":_vm.icon}})],1):_vm._e(),_c('div',{staticClass:"druk-c-btn__label druk-u-text-label-lg",class:{ [`druk-u-${_vm.type}`]: !!_vm.type, 'druk-is-disabled': _vm.isDisabled }},[_vm._v(" "+_vm._s(_vm.label)+" ")])]),(_vm.isWaiting && !_vm.isDisabled)?_c('div',{staticClass:"druk-c-btn__loader"},[_c('druk-circular-indicator',{attrs:{"size":'s',"color":_vm.iconColor}})],1):_vm._e(),_c('div',{staticClass:"druk-c-btn__state",class:{ [`druk-u-${_vm.type}`]: !!_vm.type, 'druk-is-highlighted': _vm.isHighlighted, 'druk-is-inverse': _vm.isInverse }})])])
}
var staticRenderFns = []

export { render, staticRenderFns }