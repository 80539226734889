var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"formula"},[_c('div',{staticClass:"formula__body form"},[_c('div',{staticClass:"grid"},[_c('div',{staticClass:"grid__col grid__col--8"},[_c('div',{staticClass:"formula__card form"},[_c('div',{staticClass:"formula__board form__block",class:{ [`druk-l-surface-${_vm.surfaceVariant}`]: _vm.surfaceVariant }},[_c('div',{staticClass:"formula__groups"},[_c('div',{staticClass:"formula__variables has-divider"},[_c('div',{staticClass:"formula__variable has-icon",class:{ 'druk-is-disabled': _vm.version <= 0 },on:{"click":_vm.onBackward}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-arrow-rotate-left"}})],1),_c('div',{staticClass:"formula__variable has-icon",class:{ 'druk-is-disabled': _vm.version === _vm.history.length - 1 },on:{"click":_vm.onForward}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-arrow-rotate-right"}})],1)]),_c('div',{staticClass:"formula__variables has-divider"},_vm._l((_vm.ADDITIONAL_VARIABLES),function(variable){return _c('div',{key:variable.key,staticClass:"formula__variable formula__variable--additional",class:{ 'has-icon': variable.icon },on:{"click":function($event){return _vm.onSetAreaItem(variable)}}},[(variable.icon)?_c('font-awesome-icon',{attrs:{"icon":variable.icon}}):_c('span',[_vm._v(_vm._s(_vm.$fn.tItemSystem(variable).title))])],1)}),0),(_vm.isDefaultType)?_c('div',{staticClass:"formula__variables has-divider"},_vm._l((_vm.formattedVariables.functions),function(variable){return _c('div',{key:variable.key,staticClass:"formula__variable",class:{
                                        [`formula__variable--${variable.groupKey}`]: variable.groupKey,
                                    },on:{"click":function($event){return _vm.onSetAreaFunction(variable)}}},[_vm._v(" "+_vm._s(_vm.$fn.tItemSystem(variable).title)+" ")])}),0):_vm._e(),_c('div',{staticClass:"formula__variables"},[_c('div',{staticClass:"formula__variable has-icon",class:{ 'druk-is-disabled': !_vm.isCloneAvailable },on:{"click":_vm.onClone}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-copy"}})],1),_c('div',{staticClass:"formula__variable has-icon",class:{ 'druk-is-disabled': !_vm.isPasteAvailable },on:{"click":_vm.onPaste}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-paste"}})],1)])]),_c('div',{staticClass:"formula__actions"},[_c('calc-formula-standard'),_c('tool-tip',{attrs:{"text":_vm.$t('common.formula_info')}},[_c('druk-icon-toggle-button',{attrs:{"type":'standard',"icon":'circle-question',"isActive":_vm.hasShownPrompt},on:{"click":function($event){_vm.hasShownPrompt = !_vm.hasShownPrompt}}})],1)],1)])]),_c('calc-formula-prompt',{attrs:{"surface":_vm.surfaceVariant,"hasShownPrompt":_vm.hasShownPrompt}}),_c('calc-formula-area',{attrs:{"surface":_vm.surfaceVariant}})],1),_c('div',{staticClass:"grid__col grid__col--4"},[_c('div',{staticClass:"formula__cards"},[(_vm.isDefaultType)?_vm._l((_vm.variablesGroups),function(group){return _c('div',{key:group.key,staticClass:"formula__card formula__board form",class:{ [`druk-l-surface-${_vm.surfaceVariant}`]: _vm.surfaceVariant }},[_c('div',{staticClass:"form__block"},[_c('div',{staticClass:"form__title"},[_c('div',{staticClass:"form__title-text"},[_vm._v(_vm._s(group.title))]),_c('div',{staticClass:"form__title-support"},[_c('druk-support',[_vm._v(_vm._s(group.prompt))])],1)]),_c('div',{staticClass:"formula__variables"},_vm._l((_vm.formattedVariables[group.key]),function(variable){return _c('div',{key:variable.key,staticClass:"formula__variable",class:{
                                            [`formula__variable--${variable.groupKey}`]: variable.groupKey,
                                        },on:{"click":function($event){return _vm.onSetAreaItem(variable)}}},[_vm._v(" "+_vm._s(_vm.$fn.tItemSystem(variable).title)+" ")])}),0)])])}):_vm._e(),(_vm.filteredVariables.length)?_c('div',{staticClass:"form"},[_c('div',{staticClass:"form__block formula__groups formula__board",class:{ [`druk-l-surface-${_vm.surfaceVariant}`]: _vm.surfaceVariant }},[_c('div',{staticClass:"formula__variables"},_vm._l((_vm.filteredVariables),function(variable){return _c('div',{key:variable.key,staticClass:"formula__variable",class:{
                                        [`formula__variable--${variable.groupKey}`]: variable.groupKey,
                                    },on:{"click":function($event){return _vm.onSetAreaItem(variable)}}},[_vm._v(" "+_vm._s(_vm.$fn.tItemSystem(variable).title)+" ")])}),0)])]):_vm._e()],2)])]),(_vm.isDefaultType)?_c('div',{staticClass:"form__footer"},[_c('druk-button',{attrs:{"icon":'floppy-disk',"label":_vm.$t('admin.btn.continue')},on:{"click":_vm.onSave}})],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }