import Vue from 'vue';

const DEFAULT_FILTER = {
    page: null,
    limit: 30,
    client_id: null,
    sort: 'new',
    direction: 'desc',
};

export default {
    namespaced: true,

    state: {
        listFilter: { ...DEFAULT_FILTER },
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },
    },

    mutations: {
        UPDATE_LIST_FILTER(state, newFilterlist) {
            Object.entries(newFilterlist).forEach(([key, value]) => Vue.set(state.listFilter, key, value));
            Vue.set(state.listFilter, 'page', newFilterlist.page || 1);
        },

        RESET_LIST_FILTER(state) {
            state.listFilter = { ...DEFAULT_FILTER };
        },
    },
};
