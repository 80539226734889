export default {
    'task.title': 'Задачи (все)',
    'task.btn.create': 'Создать задачу',
    'task.castom': 'Кастомная задача',
    'task.table.details': 'Параметры задачи',
    'task.order': 'Заказ',
    'task.card.product': 'Карточка товара',
    'task.chain': 'Цепочка задач:',
    'task.table.title': 'Задача (работа)',
    'task.table.stage': 'Тип работы',
    'task.table.details': 'Параметры',
    'task.table.count': 'Кол-во',
    'task.table.created': 'Дата создания',
    'task.table.deadline': 'Сроки',
    'task.search': 'Поиск',
    'task.form.find': 'Поиск осуществляется по товару или заказу',
    'task.form.urgency': 'Срочность',
    'task.form.group': 'Группировка',
    'task.print.title': 'Задачи печати',
    'task.print.next.task': 'Следующие задачи',
    'task.pre.title': 'Задачи пре-пресса',
    'task.post.title': 'Задачи пост-пресса',
    'task.loginc.title': 'Задачи логистики',
    'task.order.product': 'Заказ и товар',
    'task.item.booked': 'Использованный материал',
    'task.item.booked.dop': 'Дополнительный материал',
    'task.item.type': 'Вид использованного материала',
    'task.item.type.dop': 'Вид дополнительного материала',
    'task.item.count': 'Количество материала',
    'task.item.palceholder': 'В случае необходимости закфиксировать информацию, оставьте комментарий',
    'task.report.add': 'Добавить другой материал',
    'task.form.title': 'Что нужно сделать',
    'task.form.details': 'Детали задачи',
    'task.form.details.info': 'Укажите материал или другие подробности задачи',
    'task.form.employee': 'Сотрудник',
    'task.form.stage': 'Раздел',
    'task.form.deadline': 'Срок выполнения',
    'task.form.order': 'Связь с заказом (опционально)',
    'task.form.product': 'Связь с товаром (опционально)',
    'task.form.employeer.add': 'Назначение исполнителя',
    'task.form.employeer.current': 'Текущий исполнитель',
    'task.order.sm': 'заказа',
    'task.order.type': 'Тип задачи',
    'task.name': 'Задача',
    'task.cancel': 'Списание материала',
    'task.message.title': 'Заметки к задаче',
    'task.form.message': 'Введите здесь своё сообщение...',
    'task.form.file': 'Вложение',
    'task.yesterday': 'Вчера',
    'task.today': 'Сегодня',
    'task.task.sm': 'задачу',
    '': '',
    '': '',
    '': '',
    '': '',
};
