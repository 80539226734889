<template>
    <div class="section__wrap">
        <div class="section__cnt">
            <div class="section__title">
                <div class="druk-l-title druk-has-button">
                    <div class="druk-l-title__text">{{ $t('dashboard.title') }}</div>

                    <div class="druk-l-title__content">
                        <tool-tip :text="$t('common.update')" :from="'right'" :isInline="true">
                            <druk-icon-button
                                :type="'tonal'"
                                :icon="'arrow-rotate-right'"
                                :isWaiting="loadingGetData"
                                @click="getData" />
                        </tool-tip>
                    </div>

                    <div class="druk-l-title__support">
                        <druk-support :hasLoader="loadingGetData">
                            <span>{{ $t('dashboard.data_is_current_on') }} </span>
                            <span v-if="!loadingGetData">{{ currentOn }}</span>
                        </druk-support>
                    </div>
                </div>
            </div>

            <div v-if="hasLoadedData" class="section__main dashboard">
                <div class="grid">
                    <div class="dashboard__item grid__col grid__col--6 grid__col--def-12 druk-u-margin-bottom-32">
                        <orders-status-bar-chart />
                    </div>

                    <div class="dashboard__item grid__col grid__col--6 grid__col--def-12 druk-u-margin-bottom-32">
                        <clients-base-bar-chart />
                    </div>

                    <div class="dashboard__item grid__col grid__col--12 druk-is-top-elevation">
                        <div class="section__filter druk-u-margin-bottom-32">
                            <advanced-filter
                                :items="filterList"
                                :hasOpenedFilter="true"
                                :hasHiddenChips="true"
                                :hasFilterLoader="hasFilterLoader"
                                :withoutSorting="true"
                                @onChange="applyFilters" />
                        </div>
                    </div>

                    <div class="dashboard__item grid__col grid__col--12 druk-u-margin-bottom-32">
                        <orders-base-mosaic-chart />
                    </div>

                    <div class="dashboard__item grid__col grid__col--6 grid__col--def-12 druk-u-margin-bottom-32">
                        <orders-source-doughnut-chart />
                    </div>

                    <div class="dashboard__item grid__col grid__col--6 grid__col--def-12 druk-u-margin-bottom-32">
                        <product-popularity-grid-chart />
                    </div>

                    <div class="dashboard__item grid__col grid__col--6 grid__col--def-12 druk-u-margin-bottom-def-32">
                        <orders-status-stacked-chart />
                    </div>

                    <div class="dashboard__item grid__col grid__col--6 grid__col--def-12">
                        <orders-status-polar-chart />
                    </div>
                </div>
            </div>

            <div v-else class="section__loader">
                <druk-loader :size="'m'" />
                <span>{{ $t('common.module_loading') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    import moduleBuild from '@/mixins/module-build.mixin.js';
    import tableFiltering from '@/mixins/table-filtering.mixin.js';

    import OrdersStatusBarChart from './orders/StatusBarChart';
    import OrdersBaseMosaicChart from './orders/BaseMosaicChart';
    import OrdersSourceDoughnutChart from './orders/SourceDoughnutChart';
    import OrdersStatusStackedChart from './orders/StatusStackedChart';
    import OrdersStatusPolarChart from './orders/StatusPolarChart';

    import ClientsBaseBarChart from './clients/BaseBarChart';

    import ProductPopularityGridChart from './products/PopularityGridChart';

    export default {
        name: 'typography-dashboard',

        mixins: [moduleBuild, tableFiltering],

        components: {
            OrdersStatusBarChart,
            OrdersBaseMosaicChart,
            OrdersSourceDoughnutChart,
            OrdersStatusStackedChart,
            OrdersStatusPolarChart,

            ClientsBaseBarChart,

            ProductPopularityGridChart,
        },

        computed: {
            ...mapState({
                PERIOD_FILTER_OPTIONS: (state) => state.dashboard.PERIOD_FILTER_OPTIONS,

                currentOn: (state) => state.dashboard.currentOn,
                listFilter: (state) => state.dashboard.listFilter,

                loadingGetData: (state) => state.dashboard.loadingGetData,
            }),

            ...mapGetters({
                listActiveFilter: 'dashboard/listActiveFilter',
                isFilterNotEmpty: 'dashboard/isFilterNotEmpty',
            }),

            filterList() {
                return [
                    {
                        type: 'form-select',
                        key: 'created_at',
                        options: this.tPeriodOptions,
                        option_id: 'from',
                        value: this.tPeriodOptions.find(
                            (period) =>
                                period.from === this.listFilter['created_at[from]'] && period.to === this.listFilter['created_at[to]'],
                        ),
                        label: this.$t('dashboard.period_filter.title'),
                        is_protected: true,
                        loader: this.loadingGetData,
                    },
                ];
            },

            tPeriodOptions() {
                return $fn.tArray(this.PERIOD_FILTER_OPTIONS);
            },
        },

        methods: {
            ...mapActions({
                GET_DATA: 'dashboard/GET_DATA',
                GET_ORDER_STATUSES: 'orders/statuses/GET_LIST',
            }),

            ...mapMutations({
                UPDATE_LIST_FILTER: 'dashboard/UPDATE_LIST_FILTER',
            }),

            async initGeneralData() {
                await this.getData();

                this.buildData();
                this.hasLoadedGeneralData = true;
            },

            async initSoftData() {
                this.initFilterData();

                await this.GET_ORDER_STATUSES();
                this.hasLoadedSoftData = true;
            },

            async getData() {
                await this.GET_DATA();
                this.setFilterDataToUrl();
            },
        },
    };
</script>

<style lang="scss" scoped></style>
