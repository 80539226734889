import Vue from 'vue';

import movement from './movement';
import booking from './booking';

export default {
    namespaced: true,

    modules: {
        movement,
        booking,
    },

    state: {
        COMMON_FORM_DATA: {
            date_at: null,
            receipts: [],
        },

        COMMON_RECEIPT_DATA: {
            kinds: [],
            product: null,
            kind: null,
            price: null,
            amount: null,
        },

        COMMON_WRITE_OFFS_DATA: {
            items: [],
        },

        COMMON_WRITE_OFF_ITEM: {
            kinds: [],
            product: null,
            kind: null,
            amount: null,
        },

        list: [],
        listPagination: {},

        item: {},

        formData: {},
        writeOffsData: {},

        listFilter: {
            title: null,
            product_id: null,
            'catalog-id': null,
            remains: null,
            'created_at[from]': null,
            'created_at[to]': null,
            page: null,
            limit: 30,
            sort: 'new',
            direction: 'desc',
        },

        loadingGetList: false,
        loadingGetItem: false,
        loadingSaveReceipt: false,
        loadingSaveReceipts: false,
        loadingSaveWriteOff: false,
        loadingSaveWriteOffs: false,
        loadingExportList: false,
        loadingResetStockData: false,
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },

        isFilterNotEmpty(state, getters, rootState) {
            return !!Object.keys(getters.listActiveFilter).filter(
                (key) => !rootState.constant.INACTIVE_FILTERING_KEYS.concat(state.INACTIVE_FILTERING_KEYS).includes(key),
            ).length;
        },
    },

    actions: {
        GET_LIST: async ({ state, rootGetters, getters, commit }, filters = {}) => {
            try {
                commit('SET_LOADING_STATUS', { key: 'loadingGetList', value: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/ready-products`, {
                    params: Object.keys(filters).length ? filters : getters.listActiveFilter,
                });

                commit('SET_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { key: 'loadingGetList', value: false });
            }
        },

        GET_ITEM: async ({ state, rootGetters, getters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { key: 'loadingGetItem', value: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/ready-products/${id}`);

                commit('SET_ITEM', resp.single);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { key: 'loadingGetItem', value: false });
            }
        },

        SAVE_RECEIPT: async ({ state, commit, rootGetters, dispatch }, { id, formData, filters, without_fetch, without_noty }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingSaveReceipt',
                    status: true,
                });

                let path = `/api/typographies/${rootGetters[`currentTypography`].id}/ready-products/receipt/${id}`;

                let resp = await $axios.post(path, formData, {
                    headers: {
                        'Without-Noty': without_noty,
                    },
                });

                if (!without_fetch) dispatch('GET_LIST', filters);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingSaveReceipt',
                    status: false,
                });
            }
        },

        SAVE_RECEIPTS: async ({ state, commit, rootGetters, dispatch }, { formData, filters, without_fetch, without_noty }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingSaveReceipts',
                    status: true,
                });

                let path = `/api/typographies/${rootGetters[`currentTypography`].id}/ready-products/receipt-multiple`;

                let resp = await $axios.post(path, formData, {
                    headers: {
                        'Without-Noty': without_noty,
                    },
                });

                if (!without_fetch) dispatch('GET_LIST', filters);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingSaveReceipts',
                    status: false,
                });
            }
        },

        SAVE_WRITE_OFF: async ({ state, commit, rootGetters, dispatch }, { id, formData, filters, without_fetch, without_noty }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingSaveWriteOff',
                    status: true,
                });

                let path = `/api/typographies/${rootGetters[`currentTypography`].id}/ready-products/write-off/${id}`;

                let resp = await $axios.post(path, formData, {
                    headers: {
                        'Without-Noty': without_noty,
                    },
                });

                if (!without_fetch) dispatch('GET_LIST', filters);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingSaveWriteOff',
                    status: false,
                });
            }
        },

        SAVE_WRITE_OFFS: async ({ state, commit, rootGetters, dispatch }, { formData, filters, without_fetch, without_noty }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingSaveWriteOffs',
                    status: true,
                });

                let path = `/api/typographies/${rootGetters[`currentTypography`].id}/ready-products/write-off-multiple`;

                let resp = await $axios.post(path, formData, {
                    headers: {
                        'Without-Noty': without_noty,
                    },
                });

                if (!without_fetch) dispatch('GET_LIST', filters);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingSaveWriteOffs',
                    status: false,
                });
            }
        },

        RESET_STOCK_DATA: async ({ state, commit, rootGetters, dispatch }, { formData, without_noty }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingResetStockData',
                    status: true,
                });

                let path = `/api/typographies/${rootGetters[`currentTypography`].id}/ready-products/reset-multiple`;

                let resp = await $axios.post(path, formData, {
                    headers: {
                        'Without-Noty': without_noty,
                    },
                });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingResetStockData',
                    status: false,
                });
            }
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },

        SET_ITEM(state, item) {
            state.item = item;
        },

        SET_LOADING_STATUS(state, { key, value }) {
            Vue.set(state, key, value);
        },

        SET_FORM_DATA(state, formData) {
            state.formData = formData;
        },

        SET_FORM_DATA_ITEM_DATA(state, { index, data }) {
            Vue.set(state.formData.receipts, index, {
                ...state.formData.receipts[index],
                ...data,
            });
        },

        SET_WRITE_OFFS_DATA(state, formData) {
            state.writeOffsData = formData;
        },

        SET_WRITE_OFFS_DATA_ITEM_DATA(state, { index, data }) {
            Vue.set(state.writeOffsData.items, index, {
                ...state.writeOffsData.items[index],
                ...data,
            });
        },

        PUSH_TO_FORM_DATA(state, { key, value }) {
            state.formData[key].push(value);
        },

        PUSH_TO_WRITE_OFFS_DATA(state, { key, value }) {
            state.writeOffsData[key].push(value);
        },

        SLICE_FROM_FORM_DATA(state, { index, key }) {
            state.formData[key] = state.formData[key].slice(0, index).concat(state.formData[key].slice(index + 1));
        },

        SLICE_FROM_WRITE_OFFS_DATA(state, { index, key }) {
            state.writeOffsData[key] = state.writeOffsData[key].slice(0, index).concat(state.writeOffsData[key].slice(index + 1));
        },

        UPDATE_LIST_FILTER(state, newFilterlist) {
            Object.entries(newFilterlist).forEach(([key, value]) => Vue.set(state.listFilter, key, value));

            Vue.set(state.listFilter, 'page', newFilterlist.page || 1);
        },

        RESET_LIST(state) {
            state.list = [];
        },

        RESET_FORM_DATA(state) {
            state.formData = {};
        },

        RESET_WRITE_OFFS_DATA(state) {
            state.writeOffsData = {};
        },

        RESET_STORE(state) {
            state.listFilter = {
                title: null,
                product_id: null,
                'catalog-id': null,
                remains: null,
                'created_at[from]': null,
                'created_at[to]': null,
                page: null,
                limit: 30,
                sort: 'new',
                direction: 'desc',
            };
        },
    },
};
