<template>
    <div class="selector selector--img">
        <div class="selector__label" :class="{ [`druk-l-surface-${surface}`]: surface }" @click="onToggle">
            <span>
                <label>{{ label }}</label>
                <i v-if="hasRequired">*</i>
            </span>

            <druk-hint
                v-if="hint"
                :tooltip="{ text: hint.text || hint, from: 'top', maxWidth: hint.maxWidth, isNotCollapse: hint.isNotCollapse }"
                :icon="{ name: 'circle-info', size: 'xs', color: hasActive ? 'primary' : 'outline' }" />
        </div>

        <div class="selector__main" :class="{ [`druk-l-surface-${surface}`]: surface }" :style="{ height: height || DEFAULT_HEIGHT }">
            <input
                class="selector__area selector__area--file-picker"
                type="file"
                ref="file"
                title=""
                tabindex="-1"
                :name="name"
                @change="onChange" />

            <div class="selector__mask">
                <font-awesome-icon v-if="!img" class="selector__mask-icon" icon="fa-regular fa-plus" />

                <img
                    v-else
                    class="selector__mask-img"
                    :style="previewStyles"
                    :src="img"
                    :accept="accept"
                    alt=""
                    @error="$fn.fixImageError" />
            </div>
        </div>

        <div v-if="errors.has(name)" class="selector__error">
            <span :class="{ [`druk-l-surface-${surface}`]: surface }">{{ errorText || errors.first(name) }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'form-img',
        props: {
            value: String,
            label: String,
            accept: String,
            rules: String,
            hint: [String, Object],
            name: String,
            height: String,

            surface: {
                type: String,
                default: 'tint-pale',
            },
        },
        data() {
            return {
                DEFAULT_HEIGHT: `240px`,
                DEFAULT_WIDTH: `240px`,
                IMAGE_PREVIEW_PADDING: `40px`,

                img: this.value || null,
            };
        },

        watch: {
            value() {
                this.img = this.value;
            },
        },

        computed: {
            previewStyles() {
                return {
                    'max-height': `${parseInt(this.height || this.DEFAULT_HEIGHT) - parseInt(this.IMAGE_PREVIEW_PADDING)}px`,
                    'max-width': `${parseInt(this.height || this.DEFAULT_HEIGHT) - parseInt(this.IMAGE_PREVIEW_PADDING)}px`,
                };
            },

            hasRequired() {
                if (this.rules && this.rules.indexOf('required') != -1) return true;
                return false;
            },
        },

        methods: {
            onToggle() {
                this.$refs.area.focus();
            },

            async onChange(e) {
                let file = e.target.files[0],
                    image_64 = null;

                await this.getBase64(file).then((img) => {
                    image_64 = img;
                });

                this.img = image_64;
                this.$emit('input', image_64);
            },

            getBase64(file) {
                let reader = new FileReader();
                reader.readAsDataURL(file);

                return new Promise((resolve, reject) => {
                    reader.onload = function () {
                        return resolve(reader.result);
                    };
                    reader.onerror = function (error) {
                        return reject(error);
                    };
                });
            },
        },
    };
</script>

<style lang="scss" scoped></style>
