import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        OPERATION_TYPE_COMMON: 'operation',
        OPERATION_TYPE_CONTRACTOR: 'contractor-operation',

        filteredOperation: {},

        loadingFilterOperation: false,
    },

    actions: {
        GET_FILTERED_OPERATION: async ({ state, getters, rootGetters, commit }, formData) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingFilterOperation', status: true });

                let resp = await $axios.post(`/api/typographies/${rootGetters.currentTypography.id}/operations/filtering`, formData);

                commit('SET_FILTERED_OPERATION', resp);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingFilterOperation', status: false });
            }
        },
    },

    mutations: {
        SET_FILTERED_OPERATION(state, item) {
            Vue.set(state, 'filteredOperation', item);
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            Vue.set(state, value_key, status);
        },

        RESET_FILTERED_OPERATION(state) {
            state.filteredOperation = {};
        },
    },
};
