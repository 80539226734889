<template>
    <div class="selector selector">
        <div class="selector__main selector__main--time-body">
            <div class="selector__time">
                <div class="selector__time-item" :class="{ 'has-full': hasStartValue }">
                    <vue-timepicker
                        v-model="ranges.startTime"
                        close-on-complete
                        :placeholder="$t('time.range.start')"
                        @change="onChange">
                        <template v-slot:clearButton>
                            <font-awesome-icon icon="fa-regular fa-xmark" />
                        </template>
                    </vue-timepicker>
                </div>

                <div class="selector__time-text">{{ $t('time.range.for') }}</div>

                <div class="selector__time-item" :class="{ 'has-full': hasEndValue }">
                    <vue-timepicker v-model="ranges.endTime" close-on-complete :placeholder="$t('time.range.end')" @change="onChange">
                        <template v-slot:clearButton>
                            <font-awesome-icon icon="fa-regular fa-xmark" />
                        </template>
                    </vue-timepicker>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import VueTimepicker from 'vue2-timepicker';

    export default {
        name: 'form-time-range',
        props: ['value'],
        components: {
            VueTimepicker,
        },

        data() {
            return {
                ranges: {
                    startTime: {
                        HH: '08',
                        mm: '00',
                    },
                    endTime: {
                        HH: '09',
                        mm: '00',
                    },
                },
            };
        },

        computed: {
            hasStartValue() {
                return this.ranges.startTime;
            },

            hasEndValue() {
                return this.ranges.endTime;
            },
        },

        created() {
            if (!this.value) {
                this.ranges.startTime = null;
                this.ranges.endTime = null;
                return;
            }

            let arr = this.value.split('-');
            let start = arr[0];
            let end = arr[1];

            this.ranges.startTime = {
                HH: start.split(':')[0],
                mm: start.split(':')[1],
            };
            this.ranges.endTime = {
                HH: end.split(':')[0],
                mm: end.split(':')[1],
            };
        },

        methods: {
            onChange(data) {
                let start = this.ranges.startTime || {};
                let end = this.ranges.endTime || {};

                if (!start || !end || !start.HH || !start.mm || !end.HH || !end.mm) {
                    this.$emit('change', null);
                } else {
                    let time = `${start.HH}:${start.mm}-${end.HH}:${end.mm}`;
                    this.$emit('change', time);
                }
            },
        },
    };
</script>

<style src="vue2-timepicker/dist/VueTimepicker.css"></style>

<style lang="scss" scoped></style>
