<template>
    <component
        :is="!!path ? 'router-link' : 'button'"
        :to="path"
        class="druk-c-icon-btn"
        :class="{ 'druk-is-waitnig': isWaiting, 'druk-is-disabled': isDisabled }"
        @click="$emit('click', $event)">
        <div
            :style="{
                '--druk-icon-btn-palette-color': palette ? $material[palette.color] : null,
                '--druk-icon-btn-palette-background-color': palette ? $material[palette.bg] : null,
                '--druk-icon-btn-palette-border-color': palette ? $material[palette.outline] : null,
            }"
            class="druk-c-icon-btn__wrapper"
            :class="{
                [`druk-u-${type}`]: !!type,
                'druk-is-inverse': isInverse,
                'druk-is-highlighted': isHighlighted,
                'druk-is-disabled': isDisabled,
            }">
            <div class="druk-c-icon-btn__icon" :class="{ 'druk-is-shown': !isWaiting, 'druk-is-disabled': isDisabled }">
                <druk-icon :color="isDisabled ? DISABLED_COLOR : iconColor" :name="icon" />
            </div>

            <div v-if="isWaiting && !isDisabled" class="druk-c-icon-btn__loader">
                <druk-circular-indicator :size="'s'" :color="iconColor" />
            </div>

            <div v-if="badge" class="druk-c-icon-btn__badge" :class="{ 'druk-is-small': hasSmallBadge }">
                <druk-badge :label="hasSmallBadge ? '' : badge" />
            </div>

            <div
                class="druk-c-icon-btn__state"
                :class="{ [`druk-u-${type}`]: !!type, 'druk-is-highlighted': isHighlighted, 'druk-is-inverse': isInverse }"></div>
        </div>
    </component>
</template>

<script>
    export default {
        name: 'druk-icon-button',

        props: {
            type: {
                type: String,
                default: 'filled', // @Explanation: Possible values - filled, tonal, outlined, elevated, standard
            },
            icon: {
                type: String,
                default: 'd',
            },

            path: Object,
            badge: [String, Number, Boolean],
            palette: Object,
            // @TODO: Will be need develop color and outine and state-layers logic
            // @Explanation: Object parameters:
            // @color: Button's icon/text color
            // @bg: Button's background color
            // @outline: Button's outline color

            isHighlighted: Boolean,
            isInverse: Boolean,
            isWaiting: Boolean,
            isDisabled: [Boolean, Number],
        },

        data() {
            return {
                DISABLED_COLOR: 'on-surface',
            };
        },

        computed: {
            iconColor() {
                return !this.isInverse ? this.iconColorsMap[this.type] : this.inverseIconColorMap[this.type];
            },

            iconColorsMap() {
                return {
                    filled: this.isHighlighted ? 'tokens-icon-button-filled-highlighted-color' : 'tokens-icon-button-filled-color',
                    tonal: this.isHighlighted ? 'tokens-icon-button-tonal-highlighted-color' : 'on-secondary-container',
                    outlined: this.isHighlighted ? 'error' : 'on-surface-variant',
                    elevated: this.isHighlighted ? 'error' : 'on-surface-variant',
                    standard: this.isHighlighted ? 'error' : 'on-surface-variant',
                };
            },

            inverseIconColorMap() {
                return {
                    filled: 'tokens-icon-button-filled-inverse-color',
                    tonal: 'inverse-on-secondary-container',
                    outlined: 'inverse-on-surface-variant',
                    elevated: 'inverse-on-surface-variant',
                    standard: 'inverse-on-surface-variant',
                };
            },

            hasSmallBadge() {
                return typeof this.badge === 'boolean';
            },
        },
    };
</script>

<style lang="scss" scoped>
    .druk-c-icon-btn {
        outline: initial;
        border: initial;
        background-color: transparent;
        &.druk-is-waitnig,
        &.druk-is-disabled {
            cursor: initial;
            pointer-events: none;
            opacity: 1;
        }
        &__wrapper {
            cursor: pointer;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 4px;
            width: 40px;
            height: 40px;
            border-radius: 100px;
            transition-duration: var(--druk-duration-short-4);
            transition-timing-function: var(--druk-easing-emphasized-accelerate);
            &:hover,
            &:active {
                transition-duration: var(--druk-duration-medium-2);
                transition-timing-function: var(--druk-easing-emphasized-decelerate);
            }
            &.druk-u-filled {
                --druk-icon-btn-palette-background-color: var(--druk-tokens-icon-button-filled-background);
                background-color: var(--druk-icon-btn-palette-background-color);
            }
            &.druk-u-filled.druk-is-highlighted {
                background-color: var(--druk-tokens-icon-button-filled-highlighted-background);
            }
            &.druk-u-filled.druk-is-inverse {
                background-color: var(--druk-tokens-icon-button-filled-inverse-background);
            }
            &.druk-u-tonal {
                background-color: var(--druk-secondary-container);
            }
            &.druk-u-tonal.druk-is-highlighted {
                background-color: var(--druk-tokens-icon-button-tonal-highlighted-background);
            }
            &.druk-u-tonal.druk-is-inverse {
                background-color: var(--druk-inverse-secondary-container);
            }
            &.druk-u-outlined {
                border: 1px solid var(--druk-outline);
                transition-property: border;
            }
            &.druk-u-tonal.druk-is-inverse {
                border: 1px solid var(--druk-inverse-outline);
            }
            &.druk-u-elevated {
                background-color: var(--druk-tokens-icon-button-elevated-background);
                box-shadow: var(--druk-elevation-1);
                transition-property: box-shadow;
            }
            &.druk-u-elevated:hover {
                box-shadow: var(--druk-elevation-2);
            }
            &.druk-u-elevated:active {
                box-shadow: var(--druk-elevation-1);
            }
            &.druk-u-elevated.druk-is-inverse {
                background-color: var(--druk-tokens-icon-button-elevated-inverse-background);
            }
            &.druk-is-disabled {
                cursor: initial;
                pointer-events: none;
                box-shadow: initial;
                opacity: 1;
            }
            &.druk-is-disabled.druk-u-filled {
                background-color: var(--druk-state-layers-on-surface-0-12);
            }
            &.druk-is-disabled.druk-u-tonal {
                background-color: var(--druk-state-layers-on-surface-0-12);
            }
            &.druk-is-disabled.druk-u-outlined {
                border: 1px solid var(--druk-state-layers-on-surface-0-12);
            }
            &.druk-is-disabled.druk-u-elevated {
                background-color: var(--druk-state-layers-on-surface-0-12);
            }
        }
        &__icon {
            pointer-events: none;
            position: relative;
            z-index: 1;
            opacity: 0;
            &.druk-is-shown {
                opacity: 1;
            }
            &.druk-is-disabled {
                opacity: 0.38;
            }
        }
        &__loader {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &__badge {
            position: absolute;
            top: -3px;
            right: -3px;
            &.druk-is-small {
                top: -1px;
                right: 7px;
            }
        }
        &__state {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 100px;
            background-color: transparent;
            transition-property: background-color;
            transition-duration: var(--druk-duration-short-4);
            transition-timing-function: var(--druk-easing-emphasized-accelerate);
            &:hover,
            &:active {
                transition-duration: var(--druk-duration-medium-2);
                transition-timing-function: var(--druk-easing-emphasized-decelerate);
            }
            &.druk-u-filled:hover {
                background-color: var(--druk-tokens-icon-button-filled-hover);
            }
            &.druk-u-filled:active {
                background-color: var(--druk-tokens-icon-button-filled-pressed);
            }
            &.druk-u-filled.druk-is-highlighted:hover {
                background-color: var(--druk-tokens-icon-button-filled-highlighted-hover);
            }
            &.druk-u-filled.druk-is-highlighted:active {
                background-color: var(--druk-tokens-icon-button-filled-highlighted-pressed);
            }
            &.druk-u-filled.druk-is-inverse:hover {
                background-color: var(--druk-tokens-icon-button-filled-inverse-hover);
            }
            &.druk-u-filled.druk-is-inverse:active {
                background-color: var(--druk-tokens-icon-button-filled-inverse-pressed);
            }
            &.druk-u-tonal:hover {
                background-color: var(--druk-state-layers-on-secondary-container-0-08);
            }
            &.druk-u-tonal:active {
                background-color: var(--druk-state-layers-on-secondary-container-0-12);
            }
            &.druk-u-tonal.druk-is-highlighted:hover {
                background-color: var(--druk-tokens-icon-button-tonal-highlighted-hover);
            }
            &.druk-u-tonal.druk-is-highlighted:active {
                background-color: var(--druk-tokens-icon-button-tonal-highlighted-pressed);
            }
            &.druk-u-tonal.druk-is-inverse:hover {
                background-color: var(--druk-state-layers-inverse-on-secondary-container-0-08);
            }
            &.druk-u-tonal.druk-is-inverse:active {
                background-color: var(--druk-state-layers-inverse-on-secondary-container-0-12);
            }
            &.druk-u-outlined:hover {
                background-color: var(--druk-state-layers-on-surface-variant-0-08);
            }
            &.druk-u-outlined:active {
                background-color: var(--druk-state-layers-on-surface-variant-0-12);
            }
            &.druk-u-outlined.druk-is-highlighted:hover {
                background-color: var(--druk-state-layers-error-0-08);
            }
            &.druk-u-outlined.druk-is-highlighted:active {
                background-color: var(--druk-state-layers-error-0-12);
            }
            &.druk-u-outlined.druk-is-inverse:hover {
                background-color: var(--druk-state-layers-inverse-on-surface-variant-0-08);
            }
            &.druk-u-outlined.druk-is-inverse:active {
                background-color: var(--druk-state-layers-inverse-on-surface-variant-0-12);
            }
            &.druk-u-elevated:hover {
                background-color: var(--druk-state-layers-on-surface-variant-0-08);
            }
            &.druk-u-elevated:active {
                background-color: var(--druk-state-layers-on-surface-variant-0-12);
            }
            &.druk-u-elevated.druk-is-highlighted:hover {
                background-color: var(--druk-state-layers-error-0-08);
            }
            &.druk-u-elevated.druk-is-highlighted:active {
                background-color: var(--druk-state-layers-error-0-12);
            }
            &.druk-u-elevated.druk-is-inverse:hover {
                background-color: var(--druk-state-layers-inverse-on-surface-variant-0-08);
            }
            &.druk-u-elevated.druk-is-inverse:active {
                background-color: var(--druk-state-layers-inverse-on-surface-variant-0-12);
            }
            &.druk-u-standard:hover {
                background-color: var(--druk-state-layers-on-surface-variant-0-08);
            }
            &.druk-u-standard:active {
                background-color: var(--druk-state-layers-on-surface-variant-0-12);
            }
            &.druk-u-standard.druk-is-highlighted:hover {
                background-color: var(--druk-state-layers-error-0-08);
            }
            &.druk-u-standard.druk-is-highlighted:active {
                background-color: var(--druk-state-layers-error-0-12);
            }
            &.druk-u-standard.druk-is-inverse:hover {
                background-color: var(--druk-state-layers-inverse-on-surface-variant-0-08);
            }
            &.druk-u-standard.druk-is-inverse:active {
                background-color: var(--druk-state-layers-inverse-on-surface-variant-0-12);
            }
        }
    }
</style>
