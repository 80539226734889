<template>
    <component
        ref="druk-link"
        :is="component"
        v-bind="{ [isExternal ? 'href' : 'to']: path || false }"
        :target="isBlank || isExternal ? '_blank' : '_self'"
        :download="!!isDownload"
        class="druk-c-link"
        :class="{ 'druk-is-active': isActive, 'druk-is-disabled': isDisabled }"
        :style="{ '--color': colorHex }"
        @click="$emit('click', $event)">
        <div class="druk-c-link__wrapper" :class="{ [`${labelClass}`]: labelClass }">
            <druk-avatar
                v-if="avatar"
                class="druk-c-link__avatar"
                :img="avatar.img"
                :label="avatar.label"
                :icon="avatar.icon"
                :size="avatar.size" />

            <div class="druk-c-link__label">
                <span v-if="iconValue" class="druk-c-link__icon">
                    <druk-icon :name="iconValue" :size="iconSize" :color="iconColor" :variant="iconVariant" />
                </span>

                <span class="druk-c-link__text" :class="{ 'druk-u-word-break-break-word': hasWordBreakUtility }">{{
                    labelValue
                }}</span>

                <span
                    v-if="(isBlank || isExternal) && !isMail"
                    class="druk-c-link__external"
                    :class="{ [`druk-u-on-${surface}-surface`]: surface }">
                    <druk-icon :name="DRUK_EXTERNAL_ICON" :size="iconSize" :color="DRUK_EXTERNAL_ICON_COLOR" />
                </span>

                <span v-if="trailValue" class="druk-c-link__trail">
                    <druk-icon :name="trailValue" :size="trailSize" :color="trailColor" :variant="trailVariant" />
                </span>

                <span v-if="hasLoader" class="druk-c-link__loader">
                    <druk-loader :size="iconSize" />
                </span>
            </div>
        </div>
    </component>
</template>

<script>
    export default {
        name: 'druk-link',

        props: {
            path: [Object, String],

            label: {
                type: [String, Number, Object], // @Explanation: You can pass object same to the default value or just a string/number value
                default: () => {
                    return {
                        value: '', // @Explanation: String value
                        color: 'primary', // @Explanation: Sass-variable name or hex-code. Primary is default color
                        class: 'druk-u-text-label-lg', // @Explanation: Text class for label. Combine it with icon sizes for better expirience
                    };
                },
            },

            icon: {
                type: [String, Object], // @Explanation: You can pass object same to the default value or just a string value
                default: () => {
                    return {
                        value: '', // @Explanation: String value with font-awesome icon name
                        color: 'primary', // @Explanation: Sass-variable name or hex-code. Primary is default color
                        size: 'xs', // @Caution: Standart labels uses only 'xs' icons. Use non-standart sizes only with label classes
                        variant: 'regular', // @Caution: Use only 'bold' variant instead 'regular' and only for 'is-active' state
                    };
                },
            },

            avatar: {
                type: Object,
                default: null,
                // @Explanation: Object parameters:
                // @img: String value with source link to the avatar preview
                // @icon: String with font-awesome icon name or object same to the icon prop
                // @label: Standart string
                // @size: Avatar has next variants - sm, md (default), lg
            },

            trail: {
                type: [String, Object], // @Explanation: You can pass object same to the icons default value or just a string value
                // @Caution: Don't use only as additional link-icon. Remember that the main icon is always located to the left of the text
                default: null,
            },

            surface: {
                type: String,
                default: 'tint-pale',
            },

            isActive: [Boolean, Number],
            isDisabled: [Boolean, Number],
            isBlank: Boolean,
            isExternal: Boolean,
            isDownload: Boolean,
            isMail: Boolean,

            hasWordBreakUtility: Boolean,
            hasLoader: Boolean,
        },

        data() {
            return {
                DRUK_STANDARD_LABEL_COLOR: 'primary',
                DRUK_STANDARD_LABEL_CLASS: 'druk-u-text-label-lg',

                DRUK_STANDARD_ICON_COLOR: 'primary',
                DRUK_STANDARD_ICON_SIZE: 'xs',
                DRUK_STANDARD_ICON_VARIANT: 'regular',

                DRUK_EXTERNAL_ICON: 'arrow-up-right-from-square',
                DRUK_EXTERNAL_ICON_COLOR: 'outline',
            };
        },

        computed: {
            component() {
                return this.isExternal ? 'a' : this.path ? 'router-link' : 'span';
            },

            labelValue() {
                return typeof this.label === 'string' || typeof this.label === 'number' ? this.label : this.label.value;
            },

            labelColor() {
                return typeof this.label === 'string' || typeof this.label === 'number' || !this.label.color
                    ? this.DRUK_STANDARD_LABEL_COLOR
                    : this.label.color;
            },

            labelClass() {
                return typeof this.label === 'string' || typeof this.label === 'number' || !this.label.class
                    ? this.DRUK_STANDARD_LABEL_CLASS
                    : this.label.class;
            },

            iconValue() {
                return typeof this.icon === 'string' ? this.icon : this.icon.value;
            },

            iconColor() {
                return typeof this.icon === 'string' || !this.icon.color ? this.DRUK_STANDARD_ICON_COLOR : this.icon.color;
            },

            iconSize() {
                return typeof this.icon === 'string' || !this.icon.size ? this.DRUK_STANDARD_ICON_SIZE : this.icon.size;
            },

            iconVariant() {
                return typeof this.icon === 'string' || !this.icon.variant ? this.DRUK_STANDARD_ICON_VARIANT : this.icon.variant;
            },

            trailValue() {
                return typeof this.trail === 'string' ? this.trail : this.trail?.value || null;
            },

            trailColor() {
                return typeof this.trail === 'string' || !this.trail.color ? this.DRUK_STANDARD_ICON_COLOR : this.trail.color;
            },

            trailSize() {
                return typeof this.trail === 'string' || !this.trail.size ? this.DRUK_STANDARD_ICON_SIZE : this.trail.size;
            },

            trailVariant() {
                return typeof this.trail === 'string' || !this.trail.variant ? this.DRUK_STANDARD_ICON_VARIANT : this.trail.variant;
            },

            colorHex() {
                return this.$material[this.labelColor] || this.labelColor;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .druk-c-link {
        cursor: pointer;
        display: inline-flex;
        text-transform: none;
        color: var(--color);
        &:hover {
            .druk-c-link__text {
                text-decoration-color: inherit;
            }
        }
        &.druk-is-active {
            .druk-c-link__icon {
                transform: rotate(180deg);
            }
        }
        &.druk-is-disabled {
            cursor: initial;
            pointer-events: none;
            color: var(--md-on-surface);
            opacity: 0.38;
            &::v-deep .druk-c-link__icon svg {
                color: var(--md-on-surface);
            }
        }
        &__wrapper {
            position: relative;
            display: inline-flex;
            align-items: center;
        }
        &__icon {
            margin-right: 4px;
            transform-origin: center;
            transition: transform var(--druk-duration-short-4) ease-in-out;
        }
        &__icon,
        &__external,
        &__trail,
        &__loader {
            display: inline-flex;
            vertical-align: text-top;
        }
        &__avatar {
            flex-shrink: 0;
            margin-right: 8px;
        }
        &__text {
            text-decoration: underline;
            text-decoration-color: transparent;
            transition: 0.2s text-decoration-color ease-in-out;
        }
        &__external {
            &::v-deep {
                &.druk-u-on-lowest-surface {
                    svg {
                        color: var(--druk-outline-variant);
                    }
                }
                &.druk-u-on-low-surface {
                    svg {
                        color: var(--druk-outline-low);
                    }
                }
                &.druk-u-on-high-surface {
                    svg {
                        color: var(--druk-outline-high);
                    }
                }
                &.druk-u-on-highest-surface {
                    svg {
                        color: var(--druk-outline);
                    }
                }
            }
        }
        &__external,
        &__trail,
        &__loader {
            margin-left: 4px;
        }
    }
</style>
