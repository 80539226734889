<template>
    <div class="chart druk-l-surface-tint-pale druk-l-shape-lg" :class="{ 'has-no-data': hasNoData }">
        <div class="chart__header druk-u-text-title-m">
            {{ $t('dashboard.products.popularity_chart.title') }}
        </div>

        <div class="chart__body druk-u-margin-right-negative-12 druk-u-margin-left-negative-24">
            <v-table :headers="headers" :items="filteredProductsData" :emptyPrompt="'...'" :isInner="true">
                <tr v-for="product in filteredProductsData" :key="product.id">
                    <td>
                        <druk-link
                            :path="{ name: 'products_item', params: { id: product.id } }"
                            :label="product.title || `${$t('common.without_name')} - #${product.id} (${product.alias})`"
                            :isBlank="true" />
                    </td>

                    <td>
                        {{ product.orders_count }}
                    </td>

                    <td>
                        {{ product.price_sum }}
                    </td>
                </tr>
            </v-table>
        </div>

        <div class="chart__footer"></div>

        <div v-if="loadingGetData" class="chart__loader">
            <div class="druk-l-card druk-l-card-m druk-l-surface druk-l-shape-lg druk-has-inverse-surface">
                <druk-loader :color="'inverse-on-surface'" :size="'lg'" />
            </div>
        </div>

        <div v-if="hasNoData && !loadingGetData" class="chart__lock">
            <div class="chart__lock-label druk-u-text-label-m">
                {{ $t('common.multiple_async.no_result') }}
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    export default {
        name: 'products-popularity-grid-chart',

        computed: {
            ...mapState({
                filteredProductsData: (state) => state.dashboard.filteredProductsData,

                loadingGetData: (state) => state.dashboard.loadingGetData,
            }),

            headers() {
                return [
                    { key: 'title', text: this.$t('product.title.singl') },
                    { key: 'orders_count', text: this.$t('order.counter') },
                    { key: 'total', text: this.$t('orders.dashboard.orders_total_price') },
                ];
            },

            hasNoData() {
                return !this.filteredProductsData.length;
            },
        },
    };
</script>

<style lang="scss" scoped></style>
