import Vue from 'vue';

import axios from 'axios';

const CancelToken = axios.CancelToken;

let cancel;

const NEW_MATERIAL_DATA = {
    format: null,
    amount: null,
};

export default {
    namespaced: true,

    state: {
        recommendations: [],
        additional: {},

        calculation: {},

        newMaterials: [{ ...NEW_MATERIAL_DATA }],

        loadingGetCalculation: false,
        loadingGetRecommendations: false,
        loadingSaveItem: false,
    },

    getters: {
        newMaterialsRequestData(state) {
            return state.newMaterials
                .filter((material) => material.format)
                .map((material) => ({
                    material_table_id: material.format.id,
                    amount: material.amount || 0,
                }));
        },
    },

    actions: {
        GET_RECOMMENDATIONS: async ({ state, rootGetters, getters, commit }, material_table_id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetRecommendations', status: true });

                let path = `/api/typographies/${rootGetters.currentTypography.id}/store/internals/cut/recommendation/`;

                let formData = {
                    cut: { material_table_id },
                    new: [...getters.newMaterialsRequestData],
                };

                let resp = await axios.post(path, formData);

                commit('SET_RECOMMENDATIONS', resp.list);
                commit('SET_ADDITIONAL', resp.additional);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetRecommendations', status: false });
            }
        },

        GET_CUT_CALCULATION: async ({ state, rootGetters, getters, commit }, cut) => {
            try {
                if (cancel !== undefined) cancel();

                commit('SET_LOADING_STATUS', { value_key: 'loadingGetCalculation', status: true });

                let path = `/api/typographies/${rootGetters.currentTypography.id}/store/internals/cut/calculate/`;

                let formData = {
                    cut,
                    new: [...getters.newMaterialsRequestData],
                };

                let resp = await axios.post(path, formData, {
                    cancelToken: new CancelToken(function executor(c) {
                        cancel = c;
                    }),
                });

                commit('SET_CALCULATION', resp?.single || {});

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetCalculation', status: false });
            }
        },

        SAVE_CUT: async ({ state, rootGetters, getters, commit }, formData) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let resp = await axios.post(`/api/typographies/${rootGetters.currentTypography.id}/store/internals/cut`, {
                    ...formData,
                    new: [...getters.newMaterialsRequestData],
                });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },
    },

    mutations: {
        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        SET_RECOMMENDATIONS(state, recommendations) {
            state.recommendations = recommendations;
        },

        SET_ADDITIONAL(state, additional) {
            state.additional = additional;
        },

        SET_CALCULATION(state, calculation) {
            state.calculation = calculation;
        },

        SET_NEW_MATERIAL_PROP(state, { index, key, value }) {
            Vue.set(state.newMaterials[index], key, value);
        },

        ADD_NEW_MATERIAL(state, material) {
            state.newMaterials.push(material || { ...NEW_MATERIAL_DATA });
        },

        DELETE_NEW_MATERIAL(state, index) {
            Vue.delete(state.newMaterials, index);
        },

        RESET_CUT_DATA(state) {
            state.recommendations = [];
            state.additional = {};
            state.calculation = {};
        },

        RESET_NEW_MATERIALS(state) {
            state.newMaterials = [];
            state.newMaterials.push({ ...NEW_MATERIAL_DATA });
        },
    },
};
