import Vue from 'vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { faImage, faCircle, faSquare, faStar } from '@fortawesome/pro-light-svg-icons';

import { faSquare as faSquareSharpLight } from '@fortawesome/sharp-light-svg-icons';

import { faSpinner, faGear, faSortUp, faSortDown, faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';

import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

library.add(
    faImage,
    faCircle,
    faSquare,
    faStar,
    faSquareSharpLight,
    faSquare,
    faSpinner,
    faGear,
    faSortUp,
    faSortDown,
    faSpinnerThird,
    faFacebook,
    faInstagram,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
