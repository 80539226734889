import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        list: {},
        item: {},

        loadingGetList: false,
        loadingGetItem: false,
        loadingSaveItem: false,
        loadingDeleteItem: false,
    },

    actions: {
        GET_LIST: async ({ state, rootState, rootGetters, getters, commit }, { orderId, productId }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.get(
                    `api/typographies/${rootGetters.currentTypography.id}/orders/${orderId}/products/${productId}/comments`,
                );

                commit('SET_LIST', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_ITEM: async ({ state, rootState, rootGetters, getters, commit }, { orderId, productId, id }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                let resp = await $axios.get(
                    `api/typographies/${rootGetters.currentTypography.id}/orders/${orderId}/products/${productId}/comments/${id}`,
                );

                commit('SET_ITEM', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        SAVE_ITEM: async ({ state, commit, rootGetters, dispatch }, { orderId, productId, formData }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let resp = await $axios.post(
                    `/api/typographies/${rootGetters.currentTypography.id}/orders/${orderId}/products/${productId}/comments`,
                    formData,
                );

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        DELETE_ITEM: async ({ state, commit, rootGetters, dispatch }, { orderId, productId, id }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let resp = await $axios.delete(
                    `/api/typographies/${rootGetters.currentTypography.id}/orders/${orderId}/products/${productId}/comments/${id}`,
                );

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_ITEM(state, item) {
            Vue.set(state, 'item', item);
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        RESET_ITEM(state) {
            state.item = {};
        },
    },
};
