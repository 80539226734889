import store from '../../../store/auth';

export default class UploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }

    upload() {
        return this.loader.file.then((uploadedFile) => {
            return new Promise((resolve, reject) => {
                const data = new FormData();

                data.append('file', uploadedFile);

                $axios({
                    url: !store.state.user.typography
                        ? `api/media/image`
                        : `api/typographies/${store.state.user.typography.id}/media/image`,
                    method: 'post',
                    data,
                    headers: {
                        'Content-Type': 'multipart/form-data;',
                    },
                    withCredentials: false,
                })
                    .then((response) => {
                        if (response.success) {
                            resolve({
                                default: response.file,
                            });
                        } else {
                            reject(response.data.message);
                        }
                    })
                    .catch((response) => {
                        reject('Upload failed');
                    });
            });
        });
    }
}
