<template>
    <druk-icon
        :name="name"
        :variant="variant"
        :size="size"
        :animation="animation"
        :color="color"
        :isInline="isInline"
        :hasSpin="true" />
</template>

<script>
    export default {
        name: 'druk-loader',

        props: {
            name: {
                type: String,
                default: 'spinner-third',
            },
            variant: {
                type: String,
                default: 'duotone',
            },

            size: String,

            animation: {
                type: Object,
                default: () => {
                    return { duration: '.5s' };
                },
            },

            color: String,

            isInline: Boolean,
        },
    };
</script>

<style lang="scss" scoped></style>
