<template>
    <div
        ref="ss"
        class="druk-l-ss"
        :class="{
            'druk-has-elusive-thief': hasElusiveThief,
            [`druk-l-surface-${surface}`]: !!surface,
            'druk-is-inner': isInnerSection,
        }">
        <div ref="wrapper" class="druk-l-ss__wrapper" :class="{ 'druk-has-loader': hasLoading }">
            <div
                class="druk-l-ss__header"
                :class="{
                    [`druk-l-surface-${surface}`]: !!surface,
                    [`druk-l-surface-${scrollSurface} druk-has-scrolled-wrapper`]: hasScrolledWrapper,
                }">
                <slot name="druk-ss-header"></slot>

                <druk-icon-button :type="'standard'" :icon="'xmark'" @click="onClose" />
            </div>

            <div class="druk-l-ss__container">
                <slot name="druk-ss-body"></slot>
            </div>

            <div class="druk-l-ss__footer">
                <slot name="druk-ss-footer"></slot>
            </div>
        </div>

        <div class="druk-l-ss__loader druk-u-text-body-m druk-l-shape-m druk-l-elevation-2" :class="{ 'druk-is-shown': hasLoading }">
            <druk-loader :color="'on-surface'" :size="'m'" />
            <span>{{ $t('common.data_loading') }}</span>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    export default {
        name: 'druk-side-sheet',

        props: {
            surface: {
                type: String,
                default: 'secondary-bright',
            },
            scrollSurface: {
                type: String,
                default: 'secondary-bright',
            },

            isInnerSection: Boolean,
            hasLoading: Boolean,
        },

        data() {
            return {
                hasScrolledWrapper: false,
            };
        },

        mounted() {
            this.setSSIteractions();

            this.$bus.$on('on-show-side-sheet', () => this.onShow());
            this.$bus.$on('on-hide-side-sheet', () => this.onHide());
        },

        beforeDestroy() {
            this.$bus.$off('on-show-side-sheet');
            this.$bus.$off('on-hide-side-sheet');
        },

        destroyed() {
            this.onHide();
        },

        computed: {
            ...mapState({
                LONG_ANIMATION_DURATION: (state) => state.constant.LONG_ANIMATION_DURATION,

                hasActiveSideSheet: (state) => state.hasActiveSideSheet,
            }),

            ...mapGetters({
                hasElusiveThief: 'hasElusiveThief',
            }),
        },

        methods: {
            ...mapMutations({
                SHOW_SIDE_SHEET: 'SHOW_SIDE_SHEET',
                HIDE_SIDE_SHEET: 'HIDE_SIDE_SHEET',
            }),

            setSSIteractions() {
                this.$refs.wrapper.addEventListener('scroll', () => (this.hasScrolledWrapper = this.$refs.wrapper.scrollTop > 0));
            },

            onShow() {
                this.SHOW_SIDE_SHEET();

                this.$refs.ss.classList.remove('druk-is-hidden');
                this.$refs.ss.classList.add('druk-is-shown');
            },

            onHide() {
                this.HIDE_SIDE_SHEET();

                this.$bus.$emit('set-table-scroll', this.LONG_ANIMATION_DURATION);

                this.$refs.ss?.classList.remove('druk-is-shown');
                this.$refs.ss?.classList.add('druk-is-hidden');
            },

            onClose() {
                this.$emit('close');
                this.onHide();
            },
        },
    };
</script>

<style lang="scss" scoped>
    .druk-l-ss {
        position: sticky;
        flex-shrink: 1;
        margin: -76px 0 -36px 24px;
        top: 0;
        right: 0;
        transform: scale(0, 0);
        width: var(--druk-side-sheet-width);
        height: 100vh;
        transform-origin: right;
        z-index: 11;

        &.druk-has-elusive-thief {
            top: 30px;
            height: calc(100vh - 30px);
        }

        &.druk-is-shown {
            animation: show-ss var(--druk-duration-long-1) forwards var(--druk-easing-emphasized-decelerate) var(--druk-delay-medium);
        }

        &.druk-is-hidden {
            animation: hide-ss var(--druk-duration-short-4) forwards var(--druk-easing-emphasized-accelerate);
        }

        &.druk-is-shown .druk-l-ss__wrapper {
            animation: show-wrapper var(--druk-duration-extra-long-4) forwards var(--druk-easing-emphasized-decelerate)
                var(--druk-delay-extra-long);
        }

        &.druk-is-hidden .druk-l-ss__wrapper {
            animation: hide-wrapper var(--druk-duration-short-4) forwards var(--druk-easing-emphasized-accelerate)
                var(--druk-delay-extra-long);
        }

        &.druk-is-inner {
            margin: -176px 0 -36px 24px;
        }

        &__wrapper {
            overflow: auto;
            height: 100%;
            opacity: 0;
            transition: opacity var(--druk-duration-short-4) ease-in-out var(--druk-delay-extra-long);
            &::before {
                content: '';
                pointer-events: none;
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                height: 100%;
                transform: translate(-50%, -50%);
                background-color: var(--druk-state-layers-scrim-0-32);

                opacity: 0;
                z-index: 13;
            }
            &.druk-is-active {
                opacity: 1;
            }
            &.druk-has-loader {
                &::before {
                    opacity: 1;
                }
            }
        }
        &__header {
            position: sticky;
            display: flex;
            justify-content: space-between;
            top: 0;
            padding: 24px 12px 12px 16px;
            transition: background-color var(--druk-duration-short-2) var(--druk-easing-emphasized-accelerate);

            z-index: 12;
            &.druk-has-scrolled-wrapper {
                transition: background-color var(--druk-duration-short-4) var(--druk-easing-emphasized-decelerate);
            }
        }
        &__container {
            padding: 12px 12px 12px 16px;
        }
        &__loader {
            pointer-events: none;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 12px 16px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            white-space: nowrap;
            color: var(--druk-on-surface);
            background-color: var(--druk-surface-container);

            z-index: 13;
            opacity: 0;
            transition: opacity 0.1s var(--druk-easing-emphasized-accelerate);
            &.druk-is-shown {
                opacity: 1;
                transition: opacity 0.2s var(--druk-easing-emphasized-decelerate);
            }
        }
        @keyframes show-ss {
            0% {
                transform: scale(0, 1);
            }
            100% {
                transform: scale(1, 1);
            }
        }
        @-webkit-keyframes show-ss {
            0% {
                transform: scale(0, 1);
            }
            100% {
                transform: scale(1, 1);
            }
        }
        @keyframes hide-ss {
            0% {
                transform: scale(1, 1);
                opacity: 1;
            }
            50% {
                transform: scale(0.5, 1);
                opacity: 1;
            }
            100% {
                transform: scale(0, 1);
                opacity: 0;
            }
        }
        @-webkit-keyframes hide-ss {
            0% {
                transform: scale(1, 1);
                opacity: 1;
            }
            50% {
                transform: scale(0.5, 1);
                opacity: 1;
            }
            100% {
                transform: scale(0, 1);
                opacity: 0;
            }
        }
        @keyframes show-wrapper {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
        @-webkit-keyframes show-wrapper {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
        @keyframes hide-wrapper {
            0% {
                opacity: 1;
            }
            100% {
                opacity: 0;
            }
        }
        @-webkit-keyframes hide-wrapper {
            0% {
                opacity: 1;
            }
            100% {
                opacity: 0;
            }
        }
        @media (max-width: $druk-breakpoints-lg) {
            width: var(--druk-side-sheet-width-lg);
        }
        @media (max-width: $druk-breakpoints-md) {
            display: none;
        }
    }
</style>
