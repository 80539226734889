import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        list: [],
        item: {},
        listPagination: {},
        listFilter: {
            page: null,
            limit: 30,
        },

        loadingGetList: false,
        loadingGetItem: false,
        loadingSaveItem: false,
        loadingDeleteItem: false,

        MAP_PAGE_ACCESS: {
            index: 'meta,title,image,subtitle,description,seo_text',
            blog: 'meta,title,seo_text',
            contacts: 'meta,title,seo_text',
            'o-kompanii': 'meta,title,description,seo_text',
            terms: 'title,description',
            druk: 'meta,title,description,seo_text',
            corporate: 'meta,title,description,seo_text',
            'knowledge-base': 'meta,title,description,seo_text',
            popular: 'meta,title,seo_text',
            delivery: 'meta,title,description,seo_text',
            catalog: 'meta,title,seo_text',
            oferta: 'meta,title,description,seo_text',
            policy: 'meta,title,description,seo_text',
            presentation: 'meta,title',
            requirements: 'meta,title,description',
        },

        MAP_WEBSITE_URL: {
            index: '',
            blog: 'blog',
            contacts: 'contact',
            'o-kompanii': 'about',
            terms: 'terms',
            policy: 'policy',
            'knowledge-base': 'faq',
            druk: 'druk',
            corporate: 'corporate',
            popular: 'popular',
            delivery: 'delivery',
            catalog: 'catalog',
            oferta: 'oferta',
            requirements: 'requirements',
        },

        PERMANENT_PAGES: ['index', 'contacts', 'policy', 'oferta', 'terms', 'catalog', 'popular'],
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_ITEM(state, item) {
            state.item = item;
        },

        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },

        UPDATE_LIST_FILTER(state, newFilterObj) {
            $fn.forIn(newFilterObj, (val, key) => {
                Vue.set(state.listFilter, key, val);
            });

            Vue.set(state.listFilter, 'page', newFilterObj.page || 1);
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        RESET_STORE(state) {
            state.listFilter = {
                page: null,
                limit: null,
            };
        },
    },

    actions: {
        GET_LIST: async ({ rootState, rootGetters, state, getters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let pathApi = '/api/pages';
                if (rootGetters.hasTypographyAccess) {
                    pathApi = `/api/typographies/${rootGetters.currentTypography.id}/pages`;
                }

                let resp = await $axios.get(pathApi, {
                    params: getters.listActiveFilter,
                });

                commit('SET_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_ITEM: async ({ state, getters, rootGetters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                let pathApi = '/api/pages';
                if (rootGetters.hasTypographyAccess) {
                    pathApi = `/api/typographies/${rootGetters.currentTypography.id}/pages`;
                }

                let resp = await $axios.get(`${pathApi}/${id}`);

                commit('SET_ITEM', resp.single);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        SAVE_ITEM: async ({ rootState, rootGetters, commit, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let pathApi = '/api/pages';
                if (rootGetters.hasTypographyAccess) {
                    pathApi = `/api/typographies/${rootGetters.currentTypography.id}/pages`;
                }

                let resp = await $axios.post(pathApi, formData);
                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        DELETE_ITEM: async ({ rootGetters, commit, dispatch }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let pathApi = `/api/pages`;
                if (rootGetters.hasTypographyAccess) {
                    pathApi = `/api/typographies/${rootGetters.currentTypography.id}/pages`;
                }

                let resp = await $axios.delete(`${pathApi}/${id}`);
                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },
    },
};
