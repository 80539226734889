import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        list: [],
        listPagination: {},

        listFilter: {
            page: null,
            limit: 30,
            email: null,
            typography_id: null,
            role_id: null,
            status_id: null,
            permission: null,
            sort: 'new',
            direction: 'desc',
        },

        loadingGetList: false,
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },

        isFilterNotEmpty(state, getters, rootState) {
            return !!Object.keys(getters.listActiveFilter).filter(
                (key) => !rootState.constant.INACTIVE_FILTERING_KEYS.concat(state.INACTIVE_FILTERING_KEYS).includes(key),
            ).length;
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        UPDATE_LIST_FILTER(state, newFilterlist) {
            Object.entries(newFilterlist).forEach(([key, value]) => Vue.set(state.listFilter, key, value));
            Vue.set(state.listFilter, 'page', newFilterlist.page || 1);
        },

        RESET_STORE(state) {
            state.listFilter = {
                page: null,
                limit: null,
                email: null,
                role_id: null,
                typography_id: null,
                status_id: null,
                permission: null,
                sort: 'new',
                direction: 'desc',
            };
        },
    },

    actions: {
        GET_LIST: async ({ state, getters, commit, rootGetters }, filters = {}) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let params = Object.keys(filters).length ? filters : getters.listActiveFilter;

                let resp = await $axios.get('/api/employees', {
                    params: { ...params, with_typography_employees: true },
                });

                commit('SET_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },
    },
};
