import {
    isArray,
    kebabCase,
    merge,
    mergeWith,
    camelCase,
    concat,
    find,
    findIndex,
    filter,
    assign,
    debounce,
    forIn,
    map,
    cloneDeep,
    sortBy,
    unionBy,
    has,
    forEach,
    entries,
    keys,
    first,
    findKey,
    mapKeys,
    rearg,
    last,
    uniq,
    chunk,
    keyBy,
    max,
    isEqual,
} from 'lodash';

import i18n from '@/utils/i18n';

import Vue from './vue_base';

let fn = {};

fn.setValidateErrors = (err, validateErrors) => {
    let list = err.response ? err.response.data.errors : [];

    forIn(list, (item, key) => {
        validateErrors.add({
            field: key,
            msg: item[0],
        });
    });
};

fn.hasAccess = (key, action) => {
    let permissions = JSON.parse(localStorage.getItem('_permissions_druk_crm')) || window.$permissions;
    let module = permissions ? permissions[key] : null;

    if (!module) return false;
    return module.indexOf(action) !== -1;
};

fn.tSingle = (item, key) => {
    return Object.assign(item, { title: i18n.t(key ? item[key] : item.key) });
};

fn.tArray = (list, key) => {
    return list.map((item) => fn.tSingle(item, key));
};

fn.tShift = (value, items) => {
    if (!items) return value;

    Object.keys(items).forEach((key) => (value = value.replaceAll(`{{${key}}}`, items[key])));
    return value;
};

fn.tItemSystem = (item, key) => {
    if (!key) key = Object.keys(item).find((key) => key.includes('translation_alias'));

    return {
        ...item,
        title: i18n.t(item[key] || item.title),
    };
};

fn.tArraySystem = (list, key) => {
    return list.filter((item) => !!item).map((item) => fn.tItemSystem(item, key));
};

fn.uniqBy = (list, key) => {
    return list.reduce((acc, curr) => (!acc.find((item) => item[key] === curr[key]) ? acc.concat(curr) : acc), []);
};

fn.nbsClear = (value) => {
    return value.replace(/&nbsp;/g, '');
};

fn.capitalize = (item) => {
    return `${item.charAt(0).toUpperCase()}${item.slice(1)}`;
};

fn.slugGenerator = (lang, string, hash) => {
    const langMap = {
        uk: {
            А: 'a',
            а: 'a',
            Б: 'b',
            б: 'b',
            В: 'v',
            в: 'v',
            Г: 'h',
            г: 'h',
            Ґ: 'g',
            ґ: 'g',
            Д: 'd',
            д: 'd',
            Е: 'e',
            е: 'e',
            Є: 'ie',
            є: 'ie',
            Ж: 'zh',
            ж: 'zh',
            З: 'z',
            з: 'z',
            И: 'y',
            и: 'y',
            І: 'i',
            і: 'i',
            Ї: 'i',
            ї: 'i',
            Й: 'i',
            й: 'i',
            К: 'k',
            к: 'k',
            Л: 'l',
            л: 'l',
            М: 'm',
            м: 'm',
            Н: 'n',
            н: 'n',
            О: 'o',
            о: 'o',
            П: 'p',
            п: 'p',
            Р: 'r',
            р: 'r',
            С: 's',
            с: 's',
            Т: 't',
            т: 't',
            У: 'u',
            у: 'u',
            Ф: 'f',
            ф: 'f',
            Х: 'kh',
            х: 'kh',
            Ц: 'ts',
            ц: 'ts',
            Ч: 'ch',
            ч: 'ch',
            Ш: 'sh',
            ш: 'sh',
            Щ: 'shch',
            щ: 'shch',
            Ь: "'",
            ь: "'",
            Ю: 'iu',
            ю: 'iu',
            Я: 'ia',
            я: 'ia',
            "'": '',
            ' ': '-',
            ',': '',
            '.': '',
            '-': '-',
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
            6: '6',
            7: '7',
            8: '8',
            9: '9',
            0: '0',
        },
        pl: {
            A: 'a',
            a: 'a',
            Ą: 'on',
            ą: 'om',
            B: 'b',
            b: 'b',
            C: 'ts',
            c: 'ts',
            Ć: 'ch',
            ć: 'ch',
            D: 'd',
            d: 'd',
            E: 'e',
            e: 'e',
            Ę: 'en',
            ę: 'em',
            F: 'f',
            f: 'f',
            G: 'g',
            g: 'g',
            H: 'ch',
            h: 'ch',
            I: 'i',
            i: 'i',
            J: 'y',
            j: 'y',
            K: 'k',
            k: 'k',
            L: 'l',
            l: 'l',
            Ł: 'w',
            ł: 'w',
            M: 'm',
            m: 'm',
            N: 'n',
            n: 'n',
            Ń: 'ny',
            ń: 'ny',
            O: 'o',
            o: 'o',
            Ó: 'u',
            ó: 'u',
            P: 'p',
            p: 'p',
            R: 'r',
            r: 'r',
            S: 's',
            s: 's',
            Ś: 'sh',
            ś: 'sh',
            T: 't',
            t: 't',
            U: 'u',
            u: 'u',
            W: 'v',
            w: 'v',
            Y: 'y',
            y: 'y',
            Z: 'z',
            z: 'z',
            Ź: 'zh',
            ź: 'zh',
            Ż: 'zh',
            ż: 'zh',
            Q: 'q',
            q: 'q',
            V: 'v',
            v: 'v',
            X: 'x',
            x: 'x',
            ' ': '-',
            ',': '',
            '.': '',
            '-': '-',
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
            6: '6',
            7: '7',
            8: '8',
            9: '9',
            0: '0',
        },
        en: {
            A: 'a',
            a: 'a',
            B: 'b',
            b: 'b',
            C: 'c',
            c: 'c',
            D: 'd',
            d: 'd',
            E: 'e',
            e: 'e',
            F: 'f',
            f: 'f',
            G: 'g',
            g: 'g',
            H: 'h',
            h: 'h',
            I: 'i',
            i: 'i',
            J: 'j',
            j: 'j',
            K: 'k',
            k: 'k',
            L: 'l',
            l: 'l',
            M: 'm',
            m: 'm',
            N: 'n',
            n: 'n',
            O: 'o',
            o: 'o',
            P: 'p',
            p: 'p',
            Q: 'q',
            q: 'q',
            R: 'r',
            r: 'r',
            S: 's',
            s: 's',
            T: 't',
            t: 't',
            U: 'u',
            u: 'u',
            V: 'v',
            v: 'v',
            W: 'w',
            w: 'w',
            X: 'x',
            x: 'x',
            Y: 'y',
            y: 'y',
            Z: 'z',
            z: 'z',
            ' ': '-',
            ',': '',
            '.': '',
            '-': '-',
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
            6: '6',
            7: '7',
            8: '8',
            9: '9',
            0: '0',
        },
        lv: {
            A: 'a',
            a: 'a',
            Ą: 'aa',
            ą: 'aa',
            B: 'b',
            b: 'b',
            C: 'ts',
            c: 'ts',
            Č: 'ch',
            č: 'ch',
            D: 'd',
            d: 'd',
            E: 'e',
            e: 'e',
            Ę: 'ee',
            ę: 'ee',
            Ė: 'ei',
            ė: 'ei',
            F: 'f',
            f: 'f',
            G: 'g',
            g: 'g',
            H: 'h',
            h: 'h',
            I: 'i',
            i: 'i',
            Į: 'ii',
            į: 'ii',
            Y: 'y',
            y: 'y',
            J: 'j',
            j: 'j',
            L: 'l',
            l: 'l',
            M: 'm',
            m: 'm',
            N: 'n',
            n: 'n',
            O: 'o',
            o: 'o',
            P: 'p',
            p: 'p',
            R: 'r',
            r: 'r',
            S: 's',
            s: 's',
            Š: 'sh',
            š: 'sh',
            T: 't',
            t: 't',
            U: 'u',
            u: 'u',
            Ų: 'uu',
            ų: 'uu',
            Ū: 'uu',
            ū: 'uu',
            V: 'v',
            v: 'v',
            Z: 'z',
            z: 'z',
            Ž: 'z',
            ž: 'z',
            ' ': '-',
            ',': '',
            '.': '',
            '-': '-',
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
            6: '6',
            7: '7',
            8: '8',
            9: '9',
            0: '0',
        },
        lt: {
            A: 'a',
            a: 'a',
            Ā: 'aa',
            ā: 'aa',
            B: 'b',
            b: 'b',
            C: 'ts',
            c: 'ts',
            Č: 'ch',
            č: 'ch',
            D: 'd',
            d: 'd',
            E: 'e',
            e: 'e',
            Ē: 'ee',
            ē: 'ee',
            F: 'f',
            f: 'f',
            G: 'g',
            g: 'g',
            Ģ: 'dj',
            ģ: 'dj',
            H: 'h',
            h: 'h',
            I: 'i',
            i: 'i',
            Ī: 'ii',
            ī: 'ii',
            J: 'j',
            j: 'j',
            K: 'k',
            k: 'k',
            Ķ: 'c',
            ķ: 'c',
            L: 'l',
            l: 'l',
            Ļ: "l'",
            ļ: "l'",
            M: 'm',
            m: 'm',
            N: 'n',
            n: 'n',
            Ņ: "n'",
            ņ: "n'",
            O: 'o',
            o: 'o',
            P: 'p',
            p: 'p',
            R: 'r',
            r: 'r',
            S: 's',
            s: 's',
            Š: 'sh',
            š: 'sh',
            T: 't',
            t: 't',
            U: 'u',
            u: 'u',
            Ū: 'uu',
            ū: 'uu',
            V: 'v',
            v: 'v',
            Z: 'z',
            z: 'z',
            Ž: 'zh',
            ž: 'zh',
            ' ': '-',
            ',': '',
            '.': '',
            '-': '-',
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
            6: '6',
            7: '7',
            8: '8',
            9: '9',
            0: '0',
        },
        et: {
            A: 'a',
            a: 'a',
            B: 'p',
            b: 'p',
            C: 'c',
            c: 'c',
            D: 't',
            d: 't',
            E: 'e',
            e: 'e',
            F: 'f',
            f: 'f',
            G: 'k',
            g: 'k',
            H: 'h',
            h: 'h',
            I: 'ee',
            i: 'ee',
            J: 'y',
            j: 'y',
            K: 'k',
            k: 'k',
            L: 'l',
            l: 'l',
            M: 'm',
            m: 'm',
            N: 'n',
            n: 'n',
            O: 'o',
            o: 'o',
            P: 'p',
            p: 'p',
            Q: 'q',
            q: 'q',
            R: 'r',
            r: 'r',
            S: 's',
            s: 's',
            Š: 'sh',
            š: 'sh',
            Z: 'z',
            z: 'z',
            Ž: 'j',
            ž: 'j',
            T: 't',
            t: 't',
            U: 'u',
            u: 'u',
            V: 'v',
            v: 'v',
            W: 'w',
            w: 'w',
            Õ: 'oo',
            õ: 'oo',
            Ä: 'a',
            ä: 'a',
            Õ: 'oo',
            õ: 'oo',
            Ü: 'ou',
            ü: 'ou',
            X: 'x',
            x: 'x',
            Y: 'y',
            y: 'y',
            ' ': '-',
            ',': '',
            '.': '',
            '-': '-',
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
            6: '6',
            7: '7',
            8: '8',
            9: '9',
            0: '0',
        },
        cs: {
            A: 'a',
            a: 'a',
            Á: 'a',
            á: 'a',
            B: 'b',
            b: 'b',
            C: 'ts',
            c: 'ts',
            Č: 'ch',
            č: 'ch',
            D: 'd',
            d: 'd',
            Ď: 'dy',
            ď: 'dy',
            E: 'e',
            e: 'e',
            É: 'ai',
            é: 'ai',
            Ě: 'ye',
            ě: 'ye',
            F: 'f',
            f: 'f',
            G: 'g',
            g: 'g',
            H: 'h',
            h: 'h',
            I: 'i',
            i: 'i',
            Í: 'ee',
            í: 'ee',
            J: 'y',
            j: 'y',
            K: 'k',
            k: 'k',
            L: 'l',
            l: 'l',
            M: 'm',
            m: 'm',
            N: 'n',
            n: 'n',
            Ň: 'ny',
            ň: 'ny',
            O: 'o',
            o: 'o',
            Ó: 'oo',
            ó: 'oo',
            P: 'p',
            p: 'p',
            R: 'r',
            r: 'r',
            Ř: 'rz',
            ř: 'rz',
            S: 's',
            s: 's',
            Š: 'sh',
            š: 'sh',
            T: 't',
            t: 't',
            Ť: 'ty',
            ť: 'ty',
            U: 'u',
            u: 'u',
            Ú: 'oo',
            ú: 'oo',
            Ů: 'oo',
            ů: 'oo',
            V: 'v',
            v: 'v',
            Y: 'i',
            y: 'i',
            Ý: 'ee',
            ý: 'ee',
            Z: 'z',
            z: 'z',
            Ž: 'zh',
            ž: 'zh',
            Q: 'q',
            q: 'q',
            V: 'v',
            v: 'v',
            X: 'x',
            x: 'x',
            ' ': '-',
            ',': '',
            '.': '',
            '-': '-',
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
            6: '6',
            7: '7',
            8: '8',
            9: '9',
            0: '0',
        },
        sk: {
            A: 'a',
            a: 'a',
            Á: 'aa',
            á: 'aa',
            Ä: 'ae',
            ä: 'ae',
            B: 'b',
            b: 'b',
            C: 'ts',
            c: 'ts',
            Č: 'ch',
            č: 'ch',
            D: 'd',
            d: 'd',
            Ď: 'dj',
            ď: 'dj',
            E: 'e',
            e: 'e',
            É: 'ee',
            é: 'ee',
            F: 'f',
            f: 'f',
            G: 'g',
            g: 'g',
            H: 'h',
            h: 'h',
            I: 'i',
            i: 'i',
            Í: 'ee',
            í: 'dd',
            J: 'y',
            j: 'y',
            K: 'k',
            k: 'k',
            L: 'l',
            l: 'l',
            Ĺ: 'l',
            ĺ: 'l',
            Ľ: "l'",
            ľ: "l'",
            M: 'm',
            m: 'm',
            N: 'n',
            n: 'n',
            Ň: 'ny',
            ň: 'ny',
            O: 'o',
            o: 'o',
            Ó: 'oo',
            ó: 'oo',
            Ô: 'uo',
            ô: 'uo',
            P: 'p',
            p: 'p',
            Q: 'kv',
            q: 'kv',
            R: 'r',
            r: 'r',
            Ŕ: 'r',
            ŕ: 'r',
            S: 's',
            s: 's',
            Š: 'sh',
            š: 'sh',
            T: 't',
            t: 't',
            Ť: 'ty',
            ť: 'ty',
            U: 'u',
            u: 'u',
            Ú: 'uu',
            ú: 'uu',
            V: 'v',
            v: 'v',
            W: 'v',
            w: 'v',
            X: 'ks',
            x: 'ks',
            Y: 'i',
            y: 'i',
            Ý: 'ee',
            ý: 'ee',
            Z: 'z',
            z: 'z',
            Ž: 'zh',
            ž: 'zh',
            ' ': '-',
            ',': '',
            '.': '',
            '-': '-',
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
            6: '6',
            7: '7',
            8: '8',
            9: '9',
            0: '0',
        },
        de: {
            A: 'a',
            a: 'a',
            Ä: 'e',
            ä: 'e',
            B: 'b',
            b: 'b',
            C: 'c',
            c: 'c',
            D: 'd',
            d: 'd',
            E: 'e',
            e: 'e',
            F: 'f',
            f: 'f',
            G: 'g',
            g: 'g',
            H: 'h',
            h: 'h',
            I: 'i',
            i: 'i',
            J: 'j',
            j: 'j',
            K: 'k',
            k: 'k',
            L: 'l',
            l: 'l',
            M: 'm',
            m: 'm',
            N: 'n',
            n: 'n',
            O: 'o',
            o: 'o',
            Ö: 'u',
            ö: 'u',
            P: 'p',
            p: 'p',
            Q: 'q',
            q: 'q',
            R: 'r',
            r: 'r',
            S: 's',
            s: 's',
            ß: 's',
            T: 't',
            t: 't',
            U: 'u',
            u: 'u',
            V: 'v',
            v: 'v',
            W: 'w',
            w: 'w',
            X: 'x',
            x: 'x',
            Y: 'y',
            y: 'y',
            Z: 'z',
            z: 'z',
            ' ': '-',
            ',': '',
            '.': '',
            '-': '-',
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
            6: '6',
            7: '7',
            8: '8',
            9: '9',
            0: '0',
        },
        fr: {
            A: 'a',
            a: 'a',
            À: 'a',
            à: 'a',
            Â: 'a',
            â: 'a',
            Ä: 'aa',
            ä: 'aa',
            B: 'b',
            b: 'b',
            C: 'c',
            c: 'c',
            Ç: 'cc',
            ç: 'cc',
            D: 'd',
            d: 'd',
            E: 'e',
            e: 'e',
            È: 'e',
            è: 'e',
            É: 'e',
            é: 'e',
            Ê: 'e',
            ê: 'e',
            Ë: 'ee',
            ë: 'ee',
            F: 'f',
            f: 'f',
            G: 'g',
            g: 'g',
            H: 'h',
            h: 'h',
            I: 'i',
            i: 'i',
            Î: 'i',
            î: 'i',
            Ï: 'i',
            ï: 'i',
            J: 'j',
            j: 'j',
            K: 'k',
            k: 'k',
            L: 'l',
            l: 'l',
            M: 'm',
            m: 'm',
            N: 'n',
            n: 'n',
            O: 'o',
            o: 'o',
            Ô: 'o',
            ô: 'o',
            P: 'p',
            p: 'p',
            Q: 'q',
            q: 'q',
            R: 'r',
            r: 'r',
            S: 's',
            s: 's',
            T: 't',
            t: 't',
            U: 'u',
            u: 'u',
            Ù: 'u',
            ù: 'u',
            Û: 'u',
            û: 'u',
            Ü: 'uu',
            ü: 'uu',
            V: 'v',
            v: 'v',
            W: 'w',
            w: 'w',
            X: 'x',
            x: 'x',
            Y: 'y',
            y: 'y',
            Z: 'z',
            z: 'z',
            Œ: 'ou',
            œ: 'ou',
            Æ: 'ei',
            æ: 'ei',
            ' ': '-',
            ',': '',
            '.': '',
            '-': '-',
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
            6: '6',
            7: '7',
            8: '8',
            9: '9',
            0: '0',
        },
        ro: {
            A: 'a',
            a: 'a',
            Ă: 'e',
            ă: 'e',
            Â: 'i',
            â: 'i',
            B: 'b',
            b: 'b',
            C: 'c',
            c: 'c',
            D: 'd',
            d: 'd',
            E: 'e',
            e: 'e',
            F: 'f',
            f: 'f',
            G: 'g',
            g: 'g',
            H: 'h',
            h: 'h',
            I: 'i',
            i: 'i',
            Î: 'i',
            î: 'i',
            J: 'j',
            j: 'j',
            K: 'k',
            k: 'k',
            L: 'l',
            l: 'l',
            M: 'm',
            m: 'm',
            N: 'n',
            n: 'n',
            O: 'o',
            o: 'o',
            P: 'p',
            p: 'p',
            Q: 'q',
            q: 'q',
            R: 'r',
            r: 'r',
            S: 's',
            s: 's',
            Ș: 'sh',
            ș: 'sh',
            T: 't',
            t: 't',
            Ț: 'ts',
            ț: 'ts',
            U: 'u',
            u: 'u',
            V: 'v',
            v: 'v',
            W: 'w',
            w: 'w',
            X: 'x',
            x: 'x',
            Y: 'y',
            y: 'y',
            Z: 'z',
            z: 'z',
            ' ': '-',
            ',': '',
            '.': '',
            '-': '-',
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
            6: '6',
            7: '7',
            8: '8',
            9: '9',
            0: '0',
        },
        hu: {
            A: 'a',
            a: 'a',
            Á: 'ai',
            á: 'ai',
            B: 'b',
            b: 'b',
            C: 'c',
            c: 'c',
            D: 'd',
            d: 'd',
            E: 'e',
            e: 'e',
            Ë: 'e',
            ë: 'e',
            É: 'ee',
            é: 'ee',
            F: 'f',
            f: 'f',
            G: 'g',
            g: 'g',
            H: 'h',
            h: 'h',
            I: 'i',
            i: 'i',
            J: 'j',
            j: 'j',
            K: 'k',
            k: 'k',
            L: 'l',
            l: 'l',
            M: 'm',
            m: 'm',
            N: 'n',
            n: 'n',
            O: 'o',
            o: 'o',
            Ó: 'ou',
            ó: 'ou',
            Ö: 'u',
            ö: 'u',
            Ő: 'uu',
            ő: 'uu',
            P: 'p',
            p: 'p',
            Q: 'q',
            q: 'q',
            R: 'r',
            r: 'r',
            S: 's',
            s: 's',
            T: 't',
            t: 't',
            U: 'u',
            u: 'u',
            Ú: 'uu',
            ú: 'uu',
            Ü: 'u',
            ü: 'u',
            Ű: 'uu',
            ű: 'uu',
            V: 'v',
            v: 'v',
            W: 'w',
            w: 'w',
            X: 'x',
            x: 'x',
            Y: 'y',
            y: 'y',
            Z: 'z',
            z: 'z',
            ' ': '-',
            ',': '',
            '.': '',
            '-': '-',
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
            6: '6',
            7: '7',
            8: '8',
            9: '9',
            0: '0',
        },
        bu: {
            А: 'a',
            а: 'a',
            Б: 'b',
            б: 'b',
            В: 'v',
            в: 'v',
            Г: 'g',
            г: 'g',
            Д: 'd',
            д: 'd',
            Е: 'e',
            е: 'e',
            Ж: 'zh',
            ж: 'zh',
            З: 'z',
            з: 'z',
            И: 'i',
            и: 'i',
            Й: 'y',
            й: 'y',
            К: 'k',
            к: 'k',
            Л: 'l',
            л: 'l',
            М: 'm',
            м: 'm',
            Н: 'n',
            н: 'n',
            О: 'o',
            о: 'o',
            П: 'p',
            п: 'p',
            Р: 'r',
            р: 'r',
            С: 's',
            с: 's',
            Т: 't',
            т: 't',
            У: 'u',
            у: 'u',
            Ф: 'f',
            ф: 'f',
            Х: 'kh',
            х: 'kh',
            Ц: 'ts',
            ц: 'ts',
            Ч: 'ch',
            ч: 'ch',
            Ш: 'sh',
            ш: 'sh',
            Щ: 'shch',
            щ: 'shch',
            Ъ: "''",
            ъ: "''",
            Ы: 'y',
            ы: 'y',
            Ь: "'",
            ь: "'",
            Ю: 'iu',
            ю: 'iu',
            Я: 'ia',
            я: 'ia',
            ' ': '-',
            ',': '',
            '.': '',
            '-': '-',
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
            6: '6',
            7: '7',
            8: '8',
            9: '9',
            0: '0',
        },
        es: {
            A: 'a',
            a: 'a',
            B: 'b',
            b: 'b',
            C: 'c',
            c: 'c',
            D: 'd',
            d: 'd',
            E: 'e',
            e: 'e',
            F: 'f',
            f: 'f',
            G: 'g',
            g: 'g',
            H: "'",
            h: "'",
            I: 'i',
            i: 'i',
            J: 'h',
            j: 'h',
            K: 'k',
            k: 'k',
            L: 'l',
            l: 'l',
            M: 'm',
            m: 'm',
            N: 'n',
            n: 'n',
            Ñ: "n'",
            ñ: "'",
            O: 'o',
            o: 'o',
            P: 'p',
            p: 'p',
            Q: 'k',
            q: 'k',
            R: 'r',
            r: 'r',
            S: 's',
            s: 's',
            T: 't',
            t: 't',
            U: 'u',
            u: 'u',
            V: 'b',
            v: 'b',
            W: 'v',
            w: 'v',
            X: 'ks',
            x: 'ks',
            Y: 'y',
            y: 'y',
            Z: 'z',
            z: 'z',
            ' ': '-',
            ',': '',
            '.': '',
            '-': '-',
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
            6: '6',
            7: '7',
            8: '8',
            9: '9',
            0: '0',
        },
        it: {
            A: 'a',
            a: 'a',
            B: 'b',
            b: 'b',
            C: 'c',
            c: 'c',
            D: 'd',
            d: 'd',
            E: 'e',
            e: 'e',
            F: 'f',
            f: 'f',
            G: 'g',
            g: 'g',
            H: 'h',
            h: 'h',
            I: 'i',
            i: 'i',
            J: 'j',
            j: 'j',
            K: 'k',
            k: 'k',
            L: 'l',
            l: 'l',
            M: 'm',
            m: 'm',
            N: 'n',
            n: 'n',
            O: 'o',
            o: 'o',
            P: 'p',
            p: 'p',
            Q: 'q',
            q: 'q',
            R: 'r',
            r: 'r',
            S: 's',
            s: 's',
            T: 't',
            t: 't',
            U: 'u',
            u: 'u',
            V: 'v',
            v: 'v',
            W: 'w',
            w: 'w',
            X: 'x',
            x: 'x',
            Y: 'y',
            y: 'y',
            Z: 'ts',
            z: 'ts',
            ' ': '-',
            ',': '',
            '.': '',
            '-': '-',
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
            6: '6',
            7: '7',
            8: '8',
            9: '9',
            0: '0',
        },
        pt: {
            A: 'a',
            a: 'a',
            B: 'b',
            b: 'b',
            C: 'k',
            c: 'k',
            D: 'd',
            d: 'd',
            E: 'e',
            e: 'e',
            F: 'f',
            f: 'f',
            G: 'g',
            g: 'g',
            H: "'",
            h: "'",
            I: 'i',
            i: 'i',
            J: 'zh',
            j: 'zh',
            K: 'k',
            k: 'k',
            L: 'l',
            l: 'l',
            M: 'm',
            m: 'm',
            N: 'n',
            n: 'n',
            O: 'o',
            o: 'o',
            P: 'p',
            p: 'p',
            Q: 'k',
            q: 'k',
            R: 'r',
            r: 'r',
            S: 's',
            s: 's',
            T: 't',
            t: 't',
            U: 'u',
            u: 'u',
            V: 'v',
            v: 'v',
            W: 'w',
            w: 'w',
            X: 'ks',
            x: 'ks',
            Y: 'y',
            y: 'y',
            Z: 'z',
            z: 'z',
            Ç: 's',
            ç: 's',
            ' ': '-',
            ',': '',
            '.': '',
            '-': '-',
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
            6: '6',
            7: '7',
            8: '8',
            9: '9',
            0: '0',
        },
        kk: {
            А: 'a',
            а: 'a',
            Ә: 'e',
            ә: 'e',
            Б: 'b',
            б: 'b',
            В: 'v',
            в: 'v',
            Г: 'g',
            г: 'g',
            Ғ: 'gh',
            ғ: 'gh',
            Д: 'd',
            д: 'd',
            Е: 'e',
            е: 'e',
            Ё: 'yo',
            ё: 'yo',
            Ж: 'zh',
            ж: 'zh',
            З: 'z',
            з: 'z',
            И: 'i',
            и: 'i',
            Й: 'y',
            й: 'y',
            К: 'k',
            к: 'k',
            Қ: 'q',
            қ: 'q',
            Л: 'l',
            л: 'l',
            М: 'm',
            м: 'm',
            Н: 'n',
            н: 'n',
            Ң: 'ng',
            ң: 'ng',
            О: 'o',
            о: 'o',
            Ө: 'ue',
            ө: 'ue',
            П: 'p',
            п: 'p',
            Р: 'r',
            р: 'r',
            С: 's',
            с: 's',
            Т: 't',
            т: 't',
            У: 'u',
            у: 'u',
            Ұ: 'ou',
            ұ: 'ou',
            Ү: 'y',
            ү: 'y',
            Ф: 'f',
            ф: 'f',
            Х: 'kh',
            х: 'kh',
            Ц: 'ts',
            ц: 'ts',
            Ч: 'ch',
            ч: 'ch',
            Ш: 'sh',
            ш: 'sh',
            Щ: 'shch',
            щ: 'shch',
            Ъ: "''",
            ъ: "''",
            Ы: 'y',
            ы: 'y',
            І: 'i',
            і: 'i',
            Ь: "'",
            ь: "'",
            Э: 'e',
            э: 'e',
            Ю: 'iu',
            ю: 'iu',
            Я: 'ia',
            я: 'ia',
            ' ': '-',
            ',': '',
            '.': '',
            '-': '-',
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
            6: '6',
            7: '7',
            8: '8',
            9: '9',
            0: '0',
        },
        uz: {
            A: 'a',
            a: 'a',
            B: 'b',
            b: 'b',
            C: 'c',
            c: 'c',
            D: 'd',
            d: 'd',
            E: 'e',
            e: 'e',
            F: 'f',
            f: 'f',
            G: 'g',
            g: 'g',
            H: 'h',
            h: 'h',
            I: 'i',
            i: 'i',
            J: 'j',
            j: 'j',
            K: 'k',
            k: 'k',
            L: 'l',
            l: 'l',
            M: 'm',
            m: 'm',
            N: 'n',
            n: 'n',
            O: 'o',
            o: 'o',
            P: 'p',
            p: 'p',
            Q: 'q',
            q: 'q',
            R: 'r',
            r: 'r',
            S: 's',
            s: 's',
            T: 't',
            t: 't',
            U: 'u',
            u: 'u',
            V: 'v',
            v: 'v',
            W: 'w',
            w: 'w',
            X: 'kh',
            x: 'kh',
            Y: 'y',
            y: 'y',
            Z: 'z',
            z: 'z',
            '’': "'",
            '‘': "'",
            ' ': '-',
            ',': '',
            '.': '',
            '-': '-',
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
            6: '6',
            7: '7',
            8: '8',
            9: '9',
            0: '0',
        },
        tk: {
            A: 'a',
            a: 'a',
            B: 'b',
            b: 'b',
            Ç: 'ch',
            ç: 'ch',
            D: 'd',
            d: 'd',
            Ä: 'e',
            ä: 'e',
            F: 'f',
            f: 'f',
            G: 'g',
            g: 'g',
            H: 'kh',
            h: 'kh',
            I: 'i',
            i: 'i',
            J: 'dzh',
            j: 'dzh',
            Ž: 'zh',
            ž: 'zh',
            K: 'k',
            k: 'k',
            L: 'l',
            l: 'l',
            M: 'm',
            m: 'm',
            N: 'n',
            n: 'n',
            Ň: 'n',
            ň: 'n',
            O: 'o',
            o: 'o',
            Ö: 'u',
            ö: 'u',
            P: 'p',
            p: 'p',
            R: 'r',
            r: 'r',
            S: 's',
            s: 's',
            Ş: 'sh',
            Ş: 'sh',
            T: 't',
            t: 't',
            U: 'u',
            u: 'u',
            Ü: 'u',
            ü: 'u',
            W: 'v',
            w: 'v',
            Y: 'y',
            y: 'y',
            Ý: 'y',
            ý: 'y',
            Z: 'z',
            z: 'z',
            ' ': '-',
            ',': '',
            '.': '',
            '-': '-',
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
            6: '6',
            7: '7',
            8: '8',
            9: '9',
            0: '0',
        },
        ka: {
            ა: 'a',
            ბ: 'b',
            გ: 'g',
            დ: 'd',
            ე: 'e',
            ვ: 'v',
            ზ: 'z',
            თ: "t'",
            ი: 'i',
            კ: 'k',
            ლ: 'l',
            მ: 'm',
            ნ: 'n',
            ო: 'o',
            პ: 'p',
            ჟ: 'zh',
            რ: 'r',
            ს: 's',
            ტ: 't',
            უ: 'u',
            ფ: "p'",
            ქ: "k'",
            ღ: 'gh',
            ყ: 'q',
            შ: 'sh',
            ჩ: "ch'",
            ც: "ts'",
            ძ: 'dz',
            წ: 'ts',
            ჭ: 'ch',
            ხ: 'kh',
            ჯ: 'j',
            ჰ: 'h',
            ' ': '-',
            ',': '',
            '.': '',
            '-': '-',
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
            6: '6',
            7: '7',
            8: '8',
            9: '9',
            0: '0',
        },
        az: {
            A: 'a',
            a: 'a',
            B: 'b',
            b: 'b',
            C: 'dzh',
            c: 'dzh',
            Ç: 'ch',
            ç: 'ch',
            D: 'd',
            d: 'd',
            E: 'e',
            e: 'e',
            Ə: 'ae',
            ə: 'ae',
            F: 'f',
            f: 'f',
            G: 'g',
            g: 'g',
            Ğ: 'gh',
            ğ: 'gh',
            H: 'h',
            h: 'h',
            X: 'kh',
            x: 'kh',
            I: 'y',
            ı: 'y',
            İ: 'i',
            i: 'i',
            J: 'j',
            j: 'j',
            K: 'k',
            k: 'k',
            Q: 'g',
            q: 'g',
            L: 'l',
            l: 'l',
            M: 'm',
            m: 'm',
            N: 'n',
            n: 'n',
            O: 'o',
            o: 'o',
            Ö: "'o",
            ö: "'o",
            P: 'p',
            p: 'p',
            R: 'r',
            r: 'r',
            S: 's',
            s: 's',
            Ş: 'sh',
            ş: 'sh',
            T: 't',
            t: 't',
            U: 'u',
            u: 'u',
            Ü: 'uu',
            ü: 'uu',
            V: 'v',
            v: 'v',
            Y: 'y',
            y: 'y',
            Z: 'z',
            z: 'z',
            ' ': '-',
            ',': '',
            '.': '',
            '-': '-',
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
            6: '6',
            7: '7',
            8: '8',
            9: '9',
            0: '0',
        },
        ru: {
            А: 'a',
            а: 'a',
            Б: 'b',
            б: 'b',
            В: 'v',
            в: 'v',
            Г: 'g',
            г: 'g',
            Д: 'd',
            д: 'd',
            Е: 'e',
            е: 'e',
            Ё: 'yo',
            ё: 'yo',
            Ж: 'zh',
            ж: 'zh',
            З: 'z',
            з: 'z',
            И: 'i',
            и: 'i',
            Й: 'y',
            й: 'y',
            К: 'k',
            к: 'k',
            Л: 'l',
            л: 'l',
            М: 'm',
            м: 'm',
            Н: 'n',
            н: 'n',
            О: 'o',
            о: 'o',
            П: 'p',
            п: 'p',
            Р: 'r',
            р: 'r',
            С: 's',
            с: 's',
            Т: 't',
            т: 't',
            У: 'u',
            у: 'u',
            Ф: 'f',
            ф: 'f',
            Х: 'kh',
            х: 'kh',
            Ц: 'ts',
            ц: 'ts',
            Ч: 'ch',
            ч: 'ch',
            Ш: 'sh',
            ш: 'sh',
            Щ: 'shch',
            щ: 'shch',
            Ъ: "''",
            ъ: "''",
            Ы: 'y',
            ы: 'y',
            Ь: "'",
            ь: "'",
            Э: 'e',
            э: 'e',
            Ю: 'iu',
            ю: 'iu',
            Я: 'ia',
            я: 'ia',
            ' ': '-',
            ',': '',
            '.': '',
            '-': '-',
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
            6: '6',
            7: '7',
            8: '8',
            9: '9',
            0: '0',
        },
    };

    let result = '';

    string.split('').forEach((char) => {
        if (!langMap[lang][char]) {
            let fillLangKey = Object.keys(langMap).find((langKey) => langMap[langKey][char]);
            result += langMap[fillLangKey]?.[char] || '';
        } else result += langMap[lang]?.[char] || '';
    });

    return `${result}-${hash}`;
};

fn.generateHash = () => {
    return (Math.random() + 1).toString(32).substring(2, 10);
};

fn.generateShortHash = () => {
    return (Math.random() + 1).toString(32).substring(2, 6);
};

fn.fixImageError = (e) => {
    e.target.onerror = null;
    e.target.src = 'https://druk.s3.amazonaws.com/static/not-images.svg';
};

fn.showFormError = async (target, errorBag = null) => {
    target?.classList.add('has-invalid');

    setTimeout(() => {
        target?.classList.remove('has-invalid');
    }, 1000);

    if (!!Array.isArray(errorBag)) {
        errorBag.reduce((acc, curr) => {
            if (!acc.find((error) => error.field === curr.field)) {
                Vue.prototype.$noty.error(curr.msg);
                return acc.concat(curr);
            } else return acc;
        }, []);
    } else Vue.prototype.$noty.error(errorBag || i18n.t('common.form_error'));
};

fn.detailNameParser = (title, altTitle, lang) => {
    let result = title === '[productname]' || title === '[rollproductname]' || !title ? altTitle : title;
    return typeof result === 'string' ? result : result[lang];
};

fn.dataPropLocalize = ({ languages, source, value }) => {
    return languages
        .map((lang) => ({
            [lang.code]: source ? source[lang.code] || value || '' : value || '',
        }))
        .reduce((obj, item) => Object.assign(obj, item), {});
};

fn.toggle = (e, name, exceptions = []) => {
    let element = e.target,
        rules = exceptions.map((rule) => element.closest(rule)).filter((item) => !!item);

    if (rules.length) return;

    let parent = element.closest(name);
    parent.classList.toggle('has-active');
};

fn.collator = () => {
    return new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
};

fn.groupBy = (array, key) => {
    return array.reduce((acc, curr) => {
        return Object.assign(acc, {
            [curr[key]]: acc[curr[key]] ? acc[curr[key]].concat(curr) : [curr],
        });
    }, {});
};

fn.getBoundProps = (bounds) => {
    const { x, y, top, right, bottom, left, width, height } = bounds;
    return { x, y, top, right, bottom, left, width, height };
};

fn.toFloat = (value) => {
    return value ? parseFloat(value.toString().replace(',', '.')) : 0;
};

fn.arrayToFloat = (array, key) => {
    return array.map((item) => (key ? { ...item, [key]: fn.toFloat(item[key]) } : fn.toFloat(item)));
};

fn.objectToFloat = (object) => {
    return Object.fromEntries(Object.entries(object).map(([key, value]) => [key, fn.toFloat(value)]));
};

fn.paramsRestruct = (data) => {
    return Object.entries(data).map(([key, value]) => ({ alias: key, value }));
};

fn.paramsParser = (data) => {
    data = Array.isArray(data) ? data : fn.paramsRestruct(data);

    if (!data.length) return {};

    let standard = [],
        other = [];

    data.forEach((item) =>
        item.value && typeof item.value === 'object' && !Array.isArray(item.value)
            ? (other = other.concat(fn.paramsRestruct(item.value)))
            : standard.push(item),
    );

    return Object.fromEntries(standard.concat(other).map((item) => [item.alias, item.value]));
};

fn.map = map;
fn.cloneDeep = cloneDeep;
fn.forIn = forIn;
fn.debounce = debounce;
fn.assign = assign;
fn.find = find;
fn.findIndex = findIndex;
fn.concat = concat;
fn.filter = filter;
fn.kebabCase = kebabCase;
fn.camelCase = camelCase;
fn.sortBy = sortBy;
fn.unionBy = unionBy;
fn.merge = merge;
fn.mergeWith = mergeWith;
fn.isArray = isArray;
fn.has = has;
fn.forEach = forEach;
fn.entries = entries;
fn.keys = keys;
fn.first = first;
fn.findKey = findKey;
fn.mapKeys = mapKeys;
fn.rearg = rearg;
fn.last = last;
fn.uniq = uniq;
fn.chunk = chunk;
fn.keyBy = keyBy;
fn.max = max;
fn.isEqual = isEqual;

window.$fn = fn;
