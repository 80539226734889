import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        ITEM_COMMON_DATA: {
            id: null,
            title: null,
        },

        list: [],
        listPagination: {},
        listFilter: {
            page: null,
            limit: 30,
            email: null,
        },

        item: {},
        itemDependencies: {},

        formData: {},

        selectedVariables: [],

        loadingGetList: false,
        loadingGetItem: false,
        loadingGetItemDependencies: false,
        loadingSaveItem: false,
        loadingSaveOption: false,
        loadingDeleteItem: false,
        loadingGetLogs: false,
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },

        SET_ITEM(state, item) {
            state.item = item;
        },

        SET_ITEM_DEPENDENCIES(state, { resp, id }) {
            state.itemDependencies = {
                id,
                materials: resp.materials || [],
                operations: resp.operation_ids || [],
            };
        },

        SET_VARIABLE(state, { variables, type }) {
            if (type === 'set') {
                state.selectedVariables = variables;
            }

            if (type === 'push') {
                state.selectedVariables.push(variables);
            }
        },

        SET_VARIABLE_VALUE(state, { value, id }) {
            let selectedIndex = $fn.findIndex(state.selectedVariables, ['id', id]);
            Vue.set(state.selectedVariables[selectedIndex], 'value', value);
        },

        SET_FILTER_LIST(state, { key, val }) {
            state.listFilter[key] = val;
        },

        SET_FORM_DATA(state, formData) {
            state.formData = formData;
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        DELETE_VARIABLE(state, id) {
            let selectedIndex = $fn.findIndex(state.selectedVariables, ['id', id]);
            Vue.delete(state.selectedVariables, selectedIndex);
        },

        UPDATE_LIST_FILTER(state, newFilterlist) {
            Object.entries(newFilterlist).forEach(([key, value]) => Vue.set(state.listFilter, key, value));
            Vue.set(state.listFilter, 'page', newFilterlist.page || 1);
        },

        RESET_STORE(state) {
            state.selectedVariables = [];
        },
    },

    actions: {
        GET_LIST: async ({ state, rootGetters, getters, commit }, filters) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/materials/variables`, {
                    params: filters || getters.listActiveFilter,
                });

                commit('SET_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_ITEM: async ({ state, rootGetters, getters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/materials/variables/${id}`);

                commit('SET_ITEM', resp.single);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        GET_ITEM_DEPENDENCIES: async ({ state, rootGetters, getters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetItemDependencies',
                    status: true,
                });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/materials/variables/${id}/usage`);

                commit('SET_ITEM_DEPENDENCIES', { resp, id });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetItemDependencies',
                    status: false,
                });
            }
        },

        SAVE_ITEM: async ({ state, rootGetters, commit, dispatch }, { formData, without_fetch }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let resp = await $axios.post(`/api/typographies/${rootGetters.currentTypography.id}/materials/variables`, formData);

                if (!without_fetch) {
                    dispatch('GET_LIST');
                    dispatch('calcModes/formula/GET_VARIABLES', null, { root: true });
                }

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        DELETE_ITEM: async ({ commit, rootGetters, dispatch }, { id, without_fetch, without_noty }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let resp = await $axios.delete(`/api/typographies/${rootGetters.currentTypography.id}/materials/variables/${id}`, {
                    headers: {
                        'Without-Noty': without_noty,
                    },
                });

                if (!without_fetch) {
                    dispatch('GET_LIST');
                    dispatch('materials/GET_LIST', null, { root: true });
                }

                if (!resp.success) commit('SET_ITEM_DEPENDENCIES', { resp, id });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },
    },
};
