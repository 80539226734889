(function (t) {
    const e = (t['en'] = t['en'] || {});
    e.dictionary = Object.assign(e.dictionary || {}, {
        '(may require <kbd>Fn</kbd>)': '(may require <kbd>Fn</kbd>)',
        '%0 of %1': '%0 of %1',
        Accept: 'Accept',
        Accessibility: 'Accessibility',
        'Accessibility help': 'Accessibility help',
        'Advanced options': 'Advanced options',
        'Align cell text to the bottom': 'Align cell text to the bottom',
        'Align cell text to the center': 'Align cell text to the center',
        'Align cell text to the left': 'Align cell text to the left',
        'Align cell text to the middle': 'Align cell text to the middle',
        'Align cell text to the right': 'Align cell text to the right',
        'Align cell text to the top': 'Align cell text to the top',
        'Align center': 'Align center',
        'Align left': 'Align left',
        'Align right': 'Align right',
        'Align table to the left': 'Align table to the left',
        'Align table to the right': 'Align table to the right',
        Alignment: 'Alignment',
        All: 'All',
        'Almost equal to': 'Almost equal to',
        Angle: 'Angle',
        'Approximately equal to': 'Approximately equal to',
        Aquamarine: 'Aquamarine',
        Arrows: 'Arrows',
        'Asterisk operator': 'Asterisk operator',
        'Austral sign': 'Austral sign',
        'back with leftwards arrow above': 'back with leftwards arrow above',
        Background: 'Background',
        'Below, you can find a list of keyboard shortcuts that can be used in the editor.':
            'Below, you can find a list of keyboard shortcuts that can be used in the editor.',
        Big: 'Big',
        'Bitcoin sign': 'Bitcoin sign',
        Black: 'Black',
        'Block quote': 'Block quote',
        'Block styles': 'Block styles',
        Blue: 'Blue',
        'Blue marker': 'Blue marker',
        Bold: 'Bold',
        'Bold text': 'Bold text',
        Border: 'Border',
        'Break text': 'Break text',
        'Bulleted List': 'Bulleted List',
        'Bulleted list styles toolbar': 'Bulleted list styles toolbar',
        Cancel: 'Cancel',
        'Caption for image: %0': 'Caption for image: %0',
        'Caption for the image': 'Caption for the image',
        'Cedi sign': 'Cedi sign',
        'Cell properties': 'Cell properties',
        'Cent sign': 'Cent sign',
        'Center table': 'Center table',
        'Centered image': 'Centered image',
        'Change image text alternative': 'Change image text alternative',
        'Character categories': 'Character categories',
        'Characters: %0': 'Characters: %0',
        'Choose heading': 'Choose heading',
        Circle: 'Circle',
        Clear: 'Clear',
        'Click to edit block': 'Click to edit block',
        Close: 'Close',
        'Close contextual balloons, dropdowns, and dialogs': 'Close contextual balloons, dropdowns, and dialogs',
        Code: 'Code',
        'Code block': 'Code block',
        'Colon sign': 'Colon sign',
        Color: 'Color',
        'Color picker': 'Color picker',
        Column: 'Column',
        'Contains as member': 'Contains as member',
        'Content editing keystrokes': 'Content editing keystrokes',
        'Copy selected content': 'Copy selected content',
        'Copyright sign': 'Copyright sign',
        'Create link': 'Create link',
        'Cruzeiro sign': 'Cruzeiro sign',
        Currency: 'Currency',
        'Currency sign': 'Currency sign',
        Custom: 'Custom',
        'Custom image size': 'Custom image size',
        Dashed: 'Dashed',
        Decimal: 'Decimal',
        'Decimal with leading zero': 'Decimal with leading zero',
        'Decrease indent': 'Decrease indent',
        'Decrease list item indent': 'Decrease list item indent',
        Default: 'Default',
        'Degree sign': 'Degree sign',
        'Delete column': 'Delete column',
        'Delete row': 'Delete row',
        'Dim grey': 'Dim grey',
        Dimensions: 'Dimensions',
        'Disable editing': 'Disable editing',
        Disc: 'Disc',
        'Division sign': 'Division sign',
        'Document colors': 'Document colors',
        'Dollar sign': 'Dollar sign',
        'Dong sign': 'Dong sign',
        Dotted: 'Dotted',
        Double: 'Double',
        'Double dagger': 'Double dagger',
        'Double exclamation mark': 'Double exclamation mark',
        'Double low-9 quotation mark': 'Double low-9 quotation mark',
        'Double question mark': 'Double question mark',
        Downloadable: 'Downloadable',
        'downwards arrow to bar': 'downwards arrow to bar',
        'downwards dashed arrow': 'downwards dashed arrow',
        'downwards double arrow': 'downwards double arrow',
        'downwards simple arrow': 'downwards simple arrow',
        'Drachma sign': 'Drachma sign',
        'Drag to move': 'Drag to move',
        'Dropdown toolbar': 'Dropdown toolbar',
        'Edit block': 'Edit block',
        'Edit link': 'Edit link',
        'Editor block content toolbar': 'Editor block content toolbar',
        'Editor contextual toolbar': 'Editor contextual toolbar',
        'Editor dialog': 'Editor dialog',
        'Editor editing area: %0': 'Editor editing area: %0',
        'Editor menu bar': 'Editor menu bar',
        'Editor toolbar': 'Editor toolbar',
        'Element of': 'Element of',
        'Em dash': 'Em dash',
        'Empty set': 'Empty set',
        'En dash': 'En dash',
        'Enable editing': 'Enable editing',
        'end with leftwards arrow above': 'end with leftwards arrow above',
        'Enter image caption': 'Enter image caption',
        'Enter table caption': 'Enter table caption',
        'Entering %0 code snippet': 'Entering %0 code snippet',
        'Entering a to-do list': 'Entering a to-do list',
        'Entering code snippet': 'Entering code snippet',
        'Error during image upload': 'Error during image upload',
        'Euro sign': 'Euro sign',
        'Euro-currency sign': 'Euro-currency sign',
        'Exclamation question mark': 'Exclamation question mark',
        'Execute the currently focused button. Executing buttons that interact with the editor content moves the focus back to the content.':
            'Execute the currently focused button. Executing buttons that interact with the editor content moves the focus back to the content.',
        Find: 'Find',
        'Find and replace': 'Find and replace',
        'Find in text…': 'Find in text…',
        'Find in the document': 'Find in the document',
        'Font Background Color': 'Font Background Color',
        'Font Color': 'Font Color',
        'Font Family': 'Font Family',
        'Font Size': 'Font Size',
        'For all': 'For all',
        'Fraction slash': 'Fraction slash',
        'French franc sign': 'French franc sign',
        'Full size image': 'Full size image',
        'German penny sign': 'German penny sign',
        'Greater-than or equal to': 'Greater-than or equal to',
        'Greater-than sign': 'Greater-than sign',
        Green: 'Green',
        'Green marker': 'Green marker',
        'Green pen': 'Green pen',
        Grey: 'Grey',
        Groove: 'Groove',
        'Guarani sign': 'Guarani sign',
        'Header column': 'Header column',
        'Header row': 'Header row',
        Heading: 'Heading',
        'Heading 1': 'Heading 1',
        'Heading 2': 'Heading 2',
        'Heading 3': 'Heading 3',
        'Heading 4': 'Heading 4',
        'Heading 5': 'Heading 5',
        'Heading 6': 'Heading 6',
        Height: 'Height',
        'Help Contents. To close this dialog press ESC.': 'Help Contents. To close this dialog press ESC.',
        HEX: 'HEX',
        Highlight: 'Highlight',
        'Horizontal ellipsis': 'Horizontal ellipsis',
        'Horizontal line': 'Horizontal line',
        'Horizontal text alignment toolbar': 'Horizontal text alignment toolbar',
        'Hryvnia sign': 'Hryvnia sign',
        'HTML object': 'HTML object',
        Huge: 'Huge',
        'Identical to': 'Identical to',
        'Image from computer': 'Image from computer',
        'Image resize list': 'Image resize list',
        'Image toolbar': 'Image toolbar',
        'Image upload complete': 'Image upload complete',
        'image widget': 'image widget',
        'In line': 'In line',
        'Increase indent': 'Increase indent',
        'Increase list item indent': 'Increase list item indent',
        'Indian rupee sign': 'Indian rupee sign',
        Infinity: 'Infinity',
        Insert: 'Insert',
        'Insert a hard break (a new paragraph)': 'Insert a hard break (a new paragraph)',
        'Insert a new paragraph directly after a widget': 'Insert a new paragraph directly after a widget',
        'Insert a new paragraph directly before a widget': 'Insert a new paragraph directly before a widget',
        'Insert a new table row (when in the last cell of a table)': 'Insert a new table row (when in the last cell of a table)',
        'Insert a soft break (a <code>&lt;br&gt;</code> element)': 'Insert a soft break (a <code>&lt;br&gt;</code> element)',
        'Insert code block': 'Insert code block',
        'Insert column left': 'Insert column left',
        'Insert column right': 'Insert column right',
        'Insert image': 'Insert image',
        'Insert image via URL': 'Insert image via URL',
        'Insert media': 'Insert media',
        'Insert paragraph after block': 'Insert paragraph after block',
        'Insert paragraph before block': 'Insert paragraph before block',
        'Insert row above': 'Insert row above',
        'Insert row below': 'Insert row below',
        'Insert table': 'Insert table',
        Inset: 'Inset',
        Integral: 'Integral',
        Intersection: 'Intersection',
        'Invalid start index value.': 'Invalid start index value.',
        'Inverted exclamation mark': 'Inverted exclamation mark',
        'Inverted question mark': 'Inverted question mark',
        Italic: 'Italic',
        'Italic text': 'Italic text',
        Justify: 'Justify',
        'Justify cell text': 'Justify cell text',
        'Keystrokes that can be used in a list': 'Keystrokes that can be used in a list',
        'Keystrokes that can be used in a table cell': 'Keystrokes that can be used in a table cell',
        'Keystrokes that can be used when a widget is selected (for example: image, table, etc.)':
            'Keystrokes that can be used when a widget is selected (for example: image, table, etc.)',
        'Kip sign': 'Kip sign',
        Latin: 'Latin',
        'Latin capital letter a with breve': 'Latin capital letter a with breve',
        'Latin capital letter a with macron': 'Latin capital letter a with macron',
        'Latin capital letter a with ogonek': 'Latin capital letter a with ogonek',
        'Latin capital letter c with acute': 'Latin capital letter c with acute',
        'Latin capital letter c with caron': 'Latin capital letter c with caron',
        'Latin capital letter c with circumflex': 'Latin capital letter c with circumflex',
        'Latin capital letter c with dot above': 'Latin capital letter c with dot above',
        'Latin capital letter d with caron': 'Latin capital letter d with caron',
        'Latin capital letter d with stroke': 'Latin capital letter d with stroke',
        'Latin capital letter e with breve': 'Latin capital letter e with breve',
        'Latin capital letter e with caron': 'Latin capital letter e with caron',
        'Latin capital letter e with dot above': 'Latin capital letter e with dot above',
        'Latin capital letter e with macron': 'Latin capital letter e with macron',
        'Latin capital letter e with ogonek': 'Latin capital letter e with ogonek',
        'Latin capital letter eng': 'Latin capital letter eng',
        'Latin capital letter g with breve': 'Latin capital letter g with breve',
        'Latin capital letter g with cedilla': 'Latin capital letter g with cedilla',
        'Latin capital letter g with circumflex': 'Latin capital letter g with circumflex',
        'Latin capital letter g with dot above': 'Latin capital letter g with dot above',
        'Latin capital letter h with circumflex': 'Latin capital letter h with circumflex',
        'Latin capital letter h with stroke': 'Latin capital letter h with stroke',
        'Latin capital letter i with breve': 'Latin capital letter i with breve',
        'Latin capital letter i with dot above': 'Latin capital letter i with dot above',
        'Latin capital letter i with macron': 'Latin capital letter i with macron',
        'Latin capital letter i with ogonek': 'Latin capital letter i with ogonek',
        'Latin capital letter i with tilde': 'Latin capital letter i with tilde',
        'Latin capital letter j with circumflex': 'Latin capital letter j with circumflex',
        'Latin capital letter k with cedilla': 'Latin capital letter k with cedilla',
        'Latin capital letter l with acute': 'Latin capital letter l with acute',
        'Latin capital letter l with caron': 'Latin capital letter l with caron',
        'Latin capital letter l with cedilla': 'Latin capital letter l with cedilla',
        'Latin capital letter l with middle dot': 'Latin capital letter l with middle dot',
        'Latin capital letter l with stroke': 'Latin capital letter l with stroke',
        'Latin capital letter n with acute': 'Latin capital letter n with acute',
        'Latin capital letter n with caron': 'Latin capital letter n with caron',
        'Latin capital letter n with cedilla': 'Latin capital letter n with cedilla',
        'Latin capital letter o with breve': 'Latin capital letter o with breve',
        'Latin capital letter o with double acute': 'Latin capital letter o with double acute',
        'Latin capital letter o with macron': 'Latin capital letter o with macron',
        'Latin capital letter r with acute': 'Latin capital letter r with acute',
        'Latin capital letter r with caron': 'Latin capital letter r with caron',
        'Latin capital letter r with cedilla': 'Latin capital letter r with cedilla',
        'Latin capital letter s with acute': 'Latin capital letter s with acute',
        'Latin capital letter s with caron': 'Latin capital letter s with caron',
        'Latin capital letter s with cedilla': 'Latin capital letter s with cedilla',
        'Latin capital letter s with circumflex': 'Latin capital letter s with circumflex',
        'Latin capital letter t with caron': 'Latin capital letter t with caron',
        'Latin capital letter t with cedilla': 'Latin capital letter t with cedilla',
        'Latin capital letter t with stroke': 'Latin capital letter t with stroke',
        'Latin capital letter u with breve': 'Latin capital letter u with breve',
        'Latin capital letter u with double acute': 'Latin capital letter u with double acute',
        'Latin capital letter u with macron': 'Latin capital letter u with macron',
        'Latin capital letter u with ogonek': 'Latin capital letter u with ogonek',
        'Latin capital letter u with ring above': 'Latin capital letter u with ring above',
        'Latin capital letter u with tilde': 'Latin capital letter u with tilde',
        'Latin capital letter w with circumflex': 'Latin capital letter w with circumflex',
        'Latin capital letter y with circumflex': 'Latin capital letter y with circumflex',
        'Latin capital letter y with diaeresis': 'Latin capital letter y with diaeresis',
        'Latin capital letter z with acute': 'Latin capital letter z with acute',
        'Latin capital letter z with caron': 'Latin capital letter z with caron',
        'Latin capital letter z with dot above': 'Latin capital letter z with dot above',
        'Latin capital ligature ij': 'Latin capital ligature ij',
        'Latin capital ligature oe': 'Latin capital ligature oe',
        'Latin small letter a with breve': 'Latin small letter a with breve',
        'Latin small letter a with macron': 'Latin small letter a with macron',
        'Latin small letter a with ogonek': 'Latin small letter a with ogonek',
        'Latin small letter c with acute': 'Latin small letter c with acute',
        'Latin small letter c with caron': 'Latin small letter c with caron',
        'Latin small letter c with circumflex': 'Latin small letter c with circumflex',
        'Latin small letter c with dot above': 'Latin small letter c with dot above',
        'Latin small letter d with caron': 'Latin small letter d with caron',
        'Latin small letter d with stroke': 'Latin small letter d with stroke',
        'Latin small letter dotless i': 'Latin small letter dotless i',
        'Latin small letter e with breve': 'Latin small letter e with breve',
        'Latin small letter e with caron': 'Latin small letter e with caron',
        'Latin small letter e with dot above': 'Latin small letter e with dot above',
        'Latin small letter e with macron': 'Latin small letter e with macron',
        'Latin small letter e with ogonek': 'Latin small letter e with ogonek',
        'Latin small letter eng': 'Latin small letter eng',
        'Latin small letter f with hook': 'Latin small letter f with hook',
        'Latin small letter g with breve': 'Latin small letter g with breve',
        'Latin small letter g with cedilla': 'Latin small letter g with cedilla',
        'Latin small letter g with circumflex': 'Latin small letter g with circumflex',
        'Latin small letter g with dot above': 'Latin small letter g with dot above',
        'Latin small letter h with circumflex': 'Latin small letter h with circumflex',
        'Latin small letter h with stroke': 'Latin small letter h with stroke',
        'Latin small letter i with breve': 'Latin small letter i with breve',
        'Latin small letter i with macron': 'Latin small letter i with macron',
        'Latin small letter i with ogonek': 'Latin small letter i with ogonek',
        'Latin small letter i with tilde': 'Latin small letter i with tilde',
        'Latin small letter j with circumflex': 'Latin small letter j with circumflex',
        'Latin small letter k with cedilla': 'Latin small letter k with cedilla',
        'Latin small letter kra': 'Latin small letter kra',
        'Latin small letter l with acute': 'Latin small letter l with acute',
        'Latin small letter l with caron': 'Latin small letter l with caron',
        'Latin small letter l with cedilla': 'Latin small letter l with cedilla',
        'Latin small letter l with middle dot': 'Latin small letter l with middle dot',
        'Latin small letter l with stroke': 'Latin small letter l with stroke',
        'Latin small letter long s': 'Latin small letter long s',
        'Latin small letter n preceded by apostrophe': 'Latin small letter n preceded by apostrophe',
        'Latin small letter n with acute': 'Latin small letter n with acute',
        'Latin small letter n with caron': 'Latin small letter n with caron',
        'Latin small letter n with cedilla': 'Latin small letter n with cedilla',
        'Latin small letter o with breve': 'Latin small letter o with breve',
        'Latin small letter o with double acute': 'Latin small letter o with double acute',
        'Latin small letter o with macron': 'Latin small letter o with macron',
        'Latin small letter r with acute': 'Latin small letter r with acute',
        'Latin small letter r with caron': 'Latin small letter r with caron',
        'Latin small letter r with cedilla': 'Latin small letter r with cedilla',
        'Latin small letter s with acute': 'Latin small letter s with acute',
        'Latin small letter s with caron': 'Latin small letter s with caron',
        'Latin small letter s with cedilla': 'Latin small letter s with cedilla',
        'Latin small letter s with circumflex': 'Latin small letter s with circumflex',
        'Latin small letter t with caron': 'Latin small letter t with caron',
        'Latin small letter t with cedilla': 'Latin small letter t with cedilla',
        'Latin small letter t with stroke': 'Latin small letter t with stroke',
        'Latin small letter u with breve': 'Latin small letter u with breve',
        'Latin small letter u with double acute': 'Latin small letter u with double acute',
        'Latin small letter u with macron': 'Latin small letter u with macron',
        'Latin small letter u with ogonek': 'Latin small letter u with ogonek',
        'Latin small letter u with ring above': 'Latin small letter u with ring above',
        'Latin small letter u with tilde': 'Latin small letter u with tilde',
        'Latin small letter w with circumflex': 'Latin small letter w with circumflex',
        'Latin small letter y with circumflex': 'Latin small letter y with circumflex',
        'Latin small letter z with acute': 'Latin small letter z with acute',
        'Latin small letter z with caron': 'Latin small letter z with caron',
        'Latin small letter z with dot above': 'Latin small letter z with dot above',
        'Latin small ligature ij': 'Latin small ligature ij',
        'Latin small ligature oe': 'Latin small ligature oe',
        'Leaving %0 code snippet': 'Leaving %0 code snippet',
        'Leaving a to-do list': 'Leaving a to-do list',
        'Leaving code snippet': 'Leaving code snippet',
        'Left aligned image': 'Left aligned image',
        'Left double quotation mark': 'Left double quotation mark',
        'Left single quotation mark': 'Left single quotation mark',
        'Left-pointing double angle quotation mark': 'Left-pointing double angle quotation mark',
        'leftwards arrow to bar': 'leftwards arrow to bar',
        'leftwards dashed arrow': 'leftwards dashed arrow',
        'leftwards double arrow': 'leftwards double arrow',
        'leftwards simple arrow': 'leftwards simple arrow',
        'Less-than or equal to': 'Less-than or equal to',
        'Less-than sign': 'Less-than sign',
        'Light blue': 'Light blue',
        'Light green': 'Light green',
        'Light grey': 'Light grey',
        Link: 'Link',
        'Link image': 'Link image',
        'Link URL': 'Link URL',
        'Link URL must not be empty.': 'Link URL must not be empty.',
        'Lira sign': 'Lira sign',
        'List properties': 'List properties',
        'Livre tournois sign': 'Livre tournois sign',
        'Logical and': 'Logical and',
        'Logical or': 'Logical or',
        'Lower-latin': 'Lower-latin',
        'Lower–roman': 'Lower–roman',
        Macron: 'Macron',
        'Manat sign': 'Manat sign',
        'Match case': 'Match case',
        Mathematical: 'Mathematical',
        'Media toolbar': 'Media toolbar',
        'Media URL': 'Media URL',
        'media widget': 'media widget',
        MENU_BAR_MENU_EDIT: 'Edit',
        MENU_BAR_MENU_FILE: 'File',
        MENU_BAR_MENU_FONT: 'Font',
        MENU_BAR_MENU_FORMAT: 'Format',
        MENU_BAR_MENU_HELP: 'Help',
        MENU_BAR_MENU_INSERT: 'Insert',
        MENU_BAR_MENU_TEXT: 'Text',
        MENU_BAR_MENU_TOOLS: 'Tools',
        MENU_BAR_MENU_VIEW: 'View',
        'Merge cell down': 'Merge cell down',
        'Merge cell left': 'Merge cell left',
        'Merge cell right': 'Merge cell right',
        'Merge cell up': 'Merge cell up',
        'Merge cells': 'Merge cells',
        'Mill sign': 'Mill sign',
        'Minus sign': 'Minus sign',
        'Move focus between form fields (inputs, buttons, etc.)': 'Move focus between form fields (inputs, buttons, etc.)',
        'Move focus in and out of an active dialog window': 'Move focus in and out of an active dialog window',
        'Move focus to the menu bar, navigate between menu bars': 'Move focus to the menu bar, navigate between menu bars',
        'Move focus to the toolbar, navigate between toolbars': 'Move focus to the toolbar, navigate between toolbars',
        'Move out of a link': 'Move out of a link',
        'Move out of an inline code style': 'Move out of an inline code style',
        'Move the caret to allow typing directly after a widget': 'Move the caret to allow typing directly after a widget',
        'Move the caret to allow typing directly before a widget': 'Move the caret to allow typing directly before a widget',
        'Move the selection to the next cell': 'Move the selection to the next cell',
        'Move the selection to the previous cell': 'Move the selection to the previous cell',
        'Multiple styles': 'Multiple styles',
        'Multiplication sign': 'Multiplication sign',
        'N-ary product': 'N-ary product',
        'N-ary summation': 'N-ary summation',
        Nabla: 'Nabla',
        'Naira sign': 'Naira sign',
        'Navigate editable regions': 'Navigate editable regions',
        'Navigate through the table': 'Navigate through the table',
        'Navigate through the toolbar or menu bar': 'Navigate through the toolbar or menu bar',
        'New sheqel sign': 'New sheqel sign',
        Next: 'Next',
        'Next editable region': 'Next editable region',
        'Next result': 'Next result',
        'No results found': 'No results found',
        'No searchable items': 'No searchable items',
        None: 'None',
        'Nordic mark sign': 'Nordic mark sign',
        'Not an element of': 'Not an element of',
        'Not equal to': 'Not equal to',
        'Not sign': 'Not sign',
        'Numbered List': 'Numbered List',
        'Numbered list styles toolbar': 'Numbered list styles toolbar',
        'on with exclamation mark with left right arrow above': 'on with exclamation mark with left right arrow above',
        'Open in a new tab': 'Open in a new tab',
        'Open link in new tab': 'Open link in new tab',
        'Open media in new tab': 'Open media in new tab',
        'Open the accessibility help dialog': 'Open the accessibility help dialog',
        Orange: 'Orange',
        Original: 'Original',
        Outset: 'Outset',
        Overline: 'Overline',
        Padding: 'Padding',
        'Page break': 'Page break',
        Paragraph: 'Paragraph',
        'Paragraph sign': 'Paragraph sign',
        'Partial differential': 'Partial differential',
        'Paste content': 'Paste content',
        'Paste content as plain text': 'Paste content as plain text',
        'Paste the media URL in the input.': 'Paste the media URL in the input.',
        'Per mille sign': 'Per mille sign',
        'Per ten thousand sign': 'Per ten thousand sign',
        'Peseta sign': 'Peseta sign',
        'Peso sign': 'Peso sign',
        'Pink marker': 'Pink marker',
        'Plain text': 'Plain text',
        'Please enter a valid color (e.g. "ff0000").': 'Please enter a valid color (e.g. "ff0000").',
        'Plus-minus sign': 'Plus-minus sign',
        'Pound sign': 'Pound sign',
        'Press %0 for help.': 'Press %0 for help.',
        'Press Enter to type after or press Shift + Enter to type before the widget':
            'Press Enter to type after or press Shift + Enter to type before the widget',
        Previous: 'Previous',
        'Previous editable region': 'Previous editable region',
        'Previous result': 'Previous result',
        'Proportional to': 'Proportional to',
        Purple: 'Purple',
        'Question exclamation mark': 'Question exclamation mark',
        Red: 'Red',
        'Red pen': 'Red pen',
        Redo: 'Redo',
        'Registered sign': 'Registered sign',
        'Remove color': 'Remove color',
        'Remove Format': 'Remove Format',
        'Remove highlight': 'Remove highlight',
        Replace: 'Replace',
        'Replace all': 'Replace all',
        'Replace from computer': 'Replace from computer',
        'Replace image': 'Replace image',
        'Replace image from computer': 'Replace image from computer',
        'Replace with…': 'Replace with…',
        'Resize image': 'Resize image',
        'Resize image (in %0)': 'Resize image (in %0)',
        'Resize image to %0': 'Resize image to %0',
        'Resize image to the original size': 'Resize image to the original size',
        'Restore default': 'Restore default',
        'Reversed order': 'Reversed order',
        'Reversed paragraph sign': 'Reversed paragraph sign',
        'Revert autoformatting action': 'Revert autoformatting action',
        'Rich Text Editor': 'Rich Text Editor',
        Ridge: 'Ridge',
        'Right aligned image': 'Right aligned image',
        'Right double quotation mark': 'Right double quotation mark',
        'Right single quotation mark': 'Right single quotation mark',
        'Right-pointing double angle quotation mark': 'Right-pointing double angle quotation mark',
        'rightwards arrow to bar': 'rightwards arrow to bar',
        'rightwards dashed arrow': 'rightwards dashed arrow',
        'rightwards double arrow': 'rightwards double arrow',
        'rightwards simple arrow': 'rightwards simple arrow',
        Row: 'Row',
        'Ruble sign': 'Ruble sign',
        'Rupee sign': 'Rupee sign',
        Save: 'Save',
        'Section sign': 'Section sign',
        'Select all': 'Select all',
        'Select column': 'Select column',
        'Select row': 'Select row',
        'Show blocks': 'Show blocks',
        'Show more items': 'Show more items',
        'Show source': 'Show source',
        'Side image': 'Side image',
        'Single left-pointing angle quotation mark': 'Single left-pointing angle quotation mark',
        'Single low-9 quotation mark': 'Single low-9 quotation mark',
        'Single right-pointing angle quotation mark': 'Single right-pointing angle quotation mark',
        Small: 'Small',
        Solid: 'Solid',
        'soon with rightwards arrow above': 'soon with rightwards arrow above',
        Source: 'Source',
        'Special characters': 'Special characters',
        'Spesmilo sign': 'Spesmilo sign',
        'Split cell horizontally': 'Split cell horizontally',
        'Split cell vertically': 'Split cell vertically',
        Square: 'Square',
        'Square root': 'Square root',
        'Start at': 'Start at',
        'Start index must be greater than 0.': 'Start index must be greater than 0.',
        Strikethrough: 'Strikethrough',
        'Strikethrough text': 'Strikethrough text',
        Style: 'Style',
        Styles: 'Styles',
        Subscript: 'Subscript',
        Superscript: 'Superscript',
        Table: 'Table',
        'Table alignment toolbar': 'Table alignment toolbar',
        'Table cell text alignment': 'Table cell text alignment',
        'Table properties': 'Table properties',
        'Table toolbar': 'Table toolbar',
        'Tenge sign': 'Tenge sign',
        Text: 'Text',
        'Text alignment': 'Text alignment',
        'Text alignment toolbar': 'Text alignment toolbar',
        'Text alternative': 'Text alternative',
        'Text highlight toolbar': 'Text highlight toolbar',
        'Text styles': 'Text styles',
        'Text to find must not be empty.': 'Text to find must not be empty.',
        'The color is invalid. Try "#FF0000" or "rgb(255,0,0)" or "red".':
            'The color is invalid. Try "#FF0000" or "rgb(255,0,0)" or "red".',
        'The URL must not be empty.': 'The URL must not be empty.',
        'The value is invalid. Try "10px" or "2em" or simply "2".': 'The value is invalid. Try "10px" or "2em" or simply "2".',
        'The value must not be empty.': 'The value must not be empty.',
        'The value should be a plain number.': 'The value should be a plain number.',
        'There exists': 'There exists',
        'These keyboard shortcuts allow for quick access to content editing features.':
            'These keyboard shortcuts allow for quick access to content editing features.',
        'This link has no URL': 'This link has no URL',
        'This media URL is not supported.': 'This media URL is not supported.',
        'Tilde operator': 'Tilde operator',
        Tiny: 'Tiny',
        'Tip: Find some text first in order to replace it.': 'Tip: Find some text first in order to replace it.',
        'Tip: Paste the URL into the content to embed faster.': 'Tip: Paste the URL into the content to embed faster.',
        'To-do List': 'To-do List',
        'Toggle caption off': 'Toggle caption off',
        'Toggle caption on': 'Toggle caption on',
        'Toggle the circle list style': 'Toggle the circle list style',
        'Toggle the decimal list style': 'Toggle the decimal list style',
        'Toggle the decimal with leading zero list style': 'Toggle the decimal with leading zero list style',
        'Toggle the disc list style': 'Toggle the disc list style',
        'Toggle the lower–latin list style': 'Toggle the lower–latin list style',
        'Toggle the lower–roman list style': 'Toggle the lower–roman list style',
        'Toggle the square list style': 'Toggle the square list style',
        'Toggle the upper–latin list style': 'Toggle the upper–latin list style',
        'Toggle the upper–roman list style': 'Toggle the upper–roman list style',
        'top with upwards arrow above': 'top with upwards arrow above',
        'Trade mark sign': 'Trade mark sign',
        'Tugrik sign': 'Tugrik sign',
        'Turkish lira sign': 'Turkish lira sign',
        Turquoise: 'Turquoise',
        'Two dot leader': 'Two dot leader',
        'Type or paste your content here.': 'Type or paste your content here.',
        'Type your title': 'Type your title',
        Underline: 'Underline',
        'Underline text': 'Underline text',
        Undo: 'Undo',
        Union: 'Union',
        Unlink: 'Unlink',
        'up down arrow with base': 'up down arrow with base',
        Update: 'Update',
        'Update image URL': 'Update image URL',
        'Upload failed': 'Upload failed',
        'Upload from computer': 'Upload from computer',
        'Upload image from computer': 'Upload image from computer',
        'Upload in progress': 'Upload in progress',
        'Uploading image': 'Uploading image',
        'Upper-latin': 'Upper-latin',
        'Upper-roman': 'Upper-roman',
        'upwards arrow to bar': 'upwards arrow to bar',
        'upwards dashed arrow': 'upwards dashed arrow',
        'upwards double arrow': 'upwards double arrow',
        'upwards simple arrow': 'upwards simple arrow',
        'Use the following keystrokes for more efficient navigation in the CKEditor 5 user interface.':
            'Use the following keystrokes for more efficient navigation in the CKEditor 5 user interface.',
        'User interface and content navigation keystrokes': 'User interface and content navigation keystrokes',
        'Vertical text alignment toolbar': 'Vertical text alignment toolbar',
        'Vulgar fraction one half': 'Vulgar fraction one half',
        'Vulgar fraction one quarter': 'Vulgar fraction one quarter',
        'Vulgar fraction three quarters': 'Vulgar fraction three quarters',
        White: 'White',
        'Whole words only': 'Whole words only',
        'Widget toolbar': 'Widget toolbar',
        Width: 'Width',
        'Won sign': 'Won sign',
        'Words: %0': 'Words: %0',
        'Wrap text': 'Wrap text',
        Yellow: 'Yellow',
        'Yellow marker': 'Yellow marker',
        'Yen sign': 'Yen sign',
    });
})(window.CKEDITOR_TRANSLATIONS || (window.CKEDITOR_TRANSLATIONS = {}));
