export default {
    'menu.knowledge': 'База Знань',
    'menu.knowledge_engine': 'Engine',
    'menu.knowledge_partner': 'Веб-сайт',
    'menu.knowledge_renewals': 'Оновлення',
    'menu.faq': 'Довідка/FAQ',
    'knowledge.create_category': 'Створити розділ',
    'knowledge.create_article': 'Створити статтю',
    'knowledge.engine.title': 'База знань для Engine',
    'knowledge.partner.title': 'База знань для веб-сайту',
    'knowledge.form.alias': 'Еліас',
    'knowledge.form.title': 'Заголовок',
    'knowledge.form.parent': 'Розділ',
    'knowledge.form.category_preview': 'Зображення розділу',
    'knowledge.form.description': 'Короткий опис',
    'knowledge.form.content': 'Контент',
    'knowledge.category.articles_amount': 'Статей',
    'knowledge.category.updated_at': 'Остання зміна',
    'knowledge.article_title': 'Нова стаття',
    'knowledge.base.is_empty': 'База знань порожня...',
    'knowledge.category.is_empty': 'Розділ порожній...',
    'knowledge.category.is_published': 'Розділ публікований',
    'knowledge.article.is_published': 'Стаття опублікована',
    'knowledge.base.loading': 'Завантаження бази зназь',
    'knowledge.category.loading': 'Завантаження розділу',
    'knowledge.article.loading': 'Завантаження статті',
    'knowledge.modal_sort.title': 'Порядок відображення вкладеностей',
    'knowledge.faq.title': 'Довідка/FAQ',
    'knowledge.faq.base_title': 'База Знань',
    'knowledge.faq.categories': 'Розділи довідки',
    'knowledge.faq.renewals': 'Свіже основлення',
    'knowledge.faq.loading': 'Завантаження довідки',
    'knowledge.button.learn_more': 'Дізнатися більше',
    'renewals.title': 'Оновлення',
    'renewals.create': 'Написати звіт',
    'renewals.table_headers.title': 'Заголовок',
    'renewals.table_headers.updated_at': 'Останнє оновлення',
    'renewals.table_headers.created_at': 'Дата створення',
    'renewals.table_headers.status': 'Статус',
    'renewals.table_headers.actions': 'Дії',
    'renewals.table_headers.description': 'Опис',
    'renewals.item_title': 'Свіжий звіт',
    'renewals.item.is_published': 'Опубліковане',
    'renewals.item.is_not_published': 'Неопубліковане',
    'renewals.item.created_at': 'Дата створення',
    'renewals.is_empty': 'Немає оновлень...',
    'knowledge.search_results.categories': 'Розділи',
    'knowledge.search_results.articles': 'Статті',
    'knowledge.results': 'Результати пошуку',
    'knowledge.results.show_all': 'Всі результати...',
    'knowledge.results.loading': 'Триває пошук...',
    'knowledge.results.no_data': 'Пошук не дав результатів...',

    //CKEditor---------------/
    'ckeditor.fonts': 'Стилізація тексту',
    'ckeditor.additional': 'Додатково',

    //Other---------------/
    'btn.cancel': 'Скасувати',
    'btn.preview': 'Попередній перегляд',
    'btn.publish': 'Опублікувати',
    'filters.search': 'Пошук',
    'filters.status': 'Статус',
    'filters.updated_at': 'Дата оновлення',
    'filters.created_at': 'Дата створення',
};
