export default {
    //WORK-----------------------/
    'librery.works.WorksIndex.title': 'Библиотека работ',
    'librery.works.WorksIndex.SaveGroup': 'Создать группу',
    'librery.works.WorksIndex.DeleteGroup': 'Удалить группу',
    'librery.works.WorksIndex.addWork': 'Работу',
    'librery.works.WorkItemSave.title': 'работу',
    'librery.works.WorkItemSave.breadList.title': 'Библиотека работ',
    'librery.works.tables.WorksTables.title': 'Цена/Время Работы (Просчет по тиражам)',
    'librery.works.tables.WorksTablesItem.input.count': 'Начало диапазона тиража',
    'librery.works.tables.WorksTablesSave.formTitle': 'Цена/время работы по тиражам',
    'librery.works.tables.WorksTablesSave.AddTable': 'Добавить диапазон',
    'librery.works.components.formSave.input': 'Название работы',
    'librery.works.components.formSave.title': 'Список доступного оборудования для выполнения данной работы',
    'librery.works.components.formSave.banList': 'Бан лист',
    'librery.works.components.formSave.addEquipment': 'Добавить оборудование',
    'librery.works.components.formSave.materialList': 'Список доступных материалов для выполнения данной работы',
    'librery.works.components.formSave.addMaterial': 'Добавить материал',
    'librery.works.components.formSave.formulaPrice': 'Формула цены',
    'librery.works.components.formSave.noFormula': 'Формула не указана',
    'librery.works.components.formSave.timeFormula': 'Формула времени',
    'librery.works.components.formSave.weightFormula': 'Формула веса',
    'librery.works.components.formSave.info':
        'Если формула не указана, вес будет браться тот который указан у вида товара, если и там не указан будет браться просто вес материала',
    'librery.works.components.formSave.input.manufacturing_time.infoText':
        'Время изготовления работы суммируется со временем изготовления оборудования',
    'librery.works.components.formSave.input.manufacturing_time.label': 'Время изготовления/ед работы (c)',
    'librery.works.components.formSave.input.makeready_time.infoText':
        'Время на приладку работы суммируется со временем на приладку оборудования',
    'librery.works.components.formSave.input.makeready_time.label': 'Время на приладку работы (c)',
    'librery.works.components.formSave.input.minimal_count': 'Минимальный тираж',
    'librery.works.components.modalAddEquipment.title': 'Добавить оборудование к работе',
    'librery.works.components.modalAddMaterial.title': 'Добавить материал к роботе',
    'librery.works.components.modalSaveFormula.title': 'Редактировать формулу',
    'librery.works.components.modalSaveFormula.exampl__cnt': 'Переменные указываются в квадратных скобках',
    'librery.works.components.modalSaveFormula.exampl__cnt_b':
        'например: [тираж] * [цена материала] + [тираж] * [цена печати] + [наценка]',
    'librery.works.components.modalSaveFormula.exampl__text':
        'ФУНКЦИИ - глобальные функции, для обозначения математических операций, например',
    'librery.works.components.modalSaveFormula.li1':
        'ГЛОБАЛЬНЫЕ ПЕРЕМЕННЫЕ - список переменных, которые задаются пользователем или исчисляются автоматически системой',
    'librery.works.components.modalSaveFormula.li2': 'ПАРАМЕТРЫ ДЕТАЛИ - переменные, которые описывают деталь',
    'librery.works.components.modalSaveFormula.li3':
        'ПАРАМЕТРЫ ОБОРУДОВАНИЯ - переменные, которые вручную создал технолог для таблицы оборудования (equipment/variables)',
    'librery.works.components.modalSaveFormula.li4':
        'ПАРАМЕТРЫ МАТЕРИАЛОВ - переменные, которые вручную создал технолог для таблицы материала (material/variables)',
    'librery.works.components.modalSaveFormula.formulaInput': 'Введите формулу в поле ниже',
    'librery.works.components.modalSaveFormula.detailParam': 'Параметры детали',
    'librery.works.components.modalSaveFormula.deviceParam': 'Параметры оборудования',
    'librery.works.components.modalSaveFormula.materialParam': 'Параметры материалов',
    'librery.works.components.modalSaveGroup.title': 'группу работ',
    'librery.works.formula': 'Формула',

    //ALGORITHMS--------------------------/
    'librery.algorithms.algorithmsIndex.title': 'Алгоритмы',
    'librery.algorithms.algorithmsItem.editAlgorithm': 'Редактировать алгоритм',
    'librery.algorithms.algorithmsItem.algorithmList': 'Список алгоритмов',
    'librery.algorithms.components.formSave.label': 'Название алгоритма',
    'librery.algorithms.components.formSave.addWork': 'Добавить работу',
    'librery.algorithms.components.modalSave.infoP1':
        'При выборе значения характеристик материала/оборудования включаеться процес "фильтрации", когда последующий выбор определяет доступные варианты для следующего выбора.',
    'librery.algorithms.components.modalSave.infoP2': 'Чтобы посмотреть доступные варианты характуристик - отключите фильтрацию.',
    'librery.algorithms.components.workItem.hasRepeats.label': 'Повторение работы',
    'librery.algorithms.components.workItem.hasRepeats.infoText':
        'Если данная работа в алгоритме производства используется больше одного раза - укажите количество повторов. Если параметр `Повторение работы` включён, количество повторений будет установлено на 2',
    'librery.algorithms.components.workItem.repeated': 'Кол. повторов',
    'librery.algorithms.components.workItem.hasContractors': 'Использовать подрядчика',
    'librery.algorithms.components.workItem.hasMaterialsAsync1': 'Применен фильтр для',
    'librery.algorithms.components.workItem.hasMaterialsAsync2': 'материала',
    'librery.algorithms.components.workItem.hasMaterialsAsync3': 'некоторые варианты характеристик могут быть неоступны',
    'librery.algorithms.components.workItem.resetMParams': 'Сбросить фильтр',
    'librery.algorithms.components.workItem.hasEquipmentsAsync1': 'Применен фильтр для',
    'librery.algorithms.components.workItem.hasEquipmentsAsync2': 'оборудования',
    'librery.algorithms.components.workItem.hasEquipmentsAsync3': 'некоторые варианты характеристик могут быть недоступны',
    'librery.algorithms.components.workItem.contractorWork': 'Работа подрядчика',

    //MATERIALS-------------------/
    'material.title': 'Библиотека материалов',
    'material.name': 'Материал',
    'material.name.sm': 'материал',
    'material.name.multi': 'Материалы',
    'material.view.title': 'Виды материала',
    'material.view.group': 'Группа',
    'material.view.add': 'Добавить вид',
    'material.info':
        '<b>Характеристики материала.</b><br> При выборе материала нужно задать значения характеристик, относящиеся к одному виду - тогда система идентифицирует весь вид (строку в таблице)',
    'material.dubl': 'Дубликат',
    'material.noty.copy': 'Строка скопирована',
    'mat.param.title': 'Характеристика материала',
    'mat.param.form.add': 'Выберите или добавьте характеристику',
    'mat.variable.title': 'Параметр материала',
    'mat.variable.form.add': 'Выберите или добавьте параметр',
    'mat.view.title': 'вид материала',
    'mat.tr.name': 'Название материала',
    'mat.tr.group': 'Группа материала',
    'mat.table.dimension': 'Единицы измерения',
    'mat.tr.params.title': 'Характеристики',
    'mat.tr.info': 'Для идентификации вида материала, он должен иметь хотя бы одну характеристику',
    'mat.tr.params.add': 'Добавить характеристику',
    'mat.table.count.min': 'Обязательный минимум на складе',
    'mat.tr.variable.add': 'Добавить параметр',
    'mat.noty.formula': 'Введите Формулу расчета цены материала',
    'mat.formula.info': 'Нажмите на параметр, чтобы добавить его в формулу. Не забудьте проверить наличие всех математических знаков.',
    'mat.formula.price': 'цена материала',
    'mat.formula.title': 'Расчёт цены материала',

    //FORMATS-------------------/
    'formats.title': 'Форматы',
    'formats.title.add': 'Добавить формат',
    'formats.title.sm': 'формат',
    'formats.info.part1': 'Оригинальное название формата -',
    'formats.info.part2':
        'Для удобства Вы можете указать то название формата, которое будет легче идентифицировать при дальнейшей работе. Например, А3',
    'formats.label.list': 'Список форматов',

    //EQUIPMENTS------------------/
    'equep.title': 'Библиотека оборудований',
    'equep.title.alt': 'Оборудование',
    'equep.view.title.group': 'Группа',
    'equep.view.info':
        '<b>Характеристики оборудования.</b> <br> При выборе оборудования нужно задать значения характеристик, относящиеся к одному виду - тогда система идентифицирует весь вид (строку в таблице)',
    'equep.params.title': 'Характеристика оборудования',
    'equep.params.label': 'Выберите или добавьте характеристику',
    'equep.variable.title': 'Параметр оборудования',
    'equep.variable.label': 'Выберите или добавьте параметр',
    'equep.modal.edit': 'Редактирование параметров',
    'equep.modal.create': 'Создать оборудование',
    'equep.form.time': 'Время на приладку (c)',
    'equep.form.clicks.min': 'Минимальный тираж',
    'equep.form.clicks.max': 'Максимальный тираж',
    'equep.form.print': 'Максимальная ширина печати (мм)',
    'equep.form.roll': 'Используется для рулонной печати',
    'equep.group.title': 'группу оборудования',
    'equep.tr.title': 'вид оборудования',
    'equep.tr.name': 'Название оборудования',
    'equep.tr.group': 'Группа оборудования',
    'equep.tr.parmas': 'Характеристики',
    'equep.tr.edit': 'Редактируеться',
    'equep.tr.edit.info':
        'С включеным значением, эта характеристика может быть изменена в редактировании техкарты при создании заказа.',
    'equep.tr.indent': 'Для идентификации выбранной строки оборудования, он должен иметь хотя бы одну характеристику',
    'equep.tr.params.add': 'Пожалуйста, задайте значения характеристикам',
    'equep.tr.btn.add': 'Добавить характеристику',
    'equep.tr.price': 'Себестоимость клика',
    'equep.tr.time': 'Время изготовления/ед (c)',
    'equep.tr.add.variable': 'Добавить параметр',
    'equep.tr.variable.msg': 'Укажите себестоимость для идентификации строки оборудования',
    'equep.tr.variable.add.account': 'Добавьте расчёт цены клика',
    'equep.curve.price.max': 'Макс. цена',
    'equep.curve.price.min': 'Мин. цена',
    'equep.curve.price.count': 'Граница кликов',
    'equep.curve.price.count.info': 'Клики - это количество проведённых действий над заказом',
    'equep.curve.legend': 'Легенда',
    'equep.curve.prompt': 'Введите параметры кривой для генерации диапазонов',
    'equep.curve.click.title': 'Расчёт цены',
    'equep.curve.click.label': 'Кликов',
    'equep.curve.settings': 'Настройки по умолчанию',
    'equep.curve.label.x': 'Клики',
    'equep.curve.label.y': 'Цена',
    'equep.curve.for.click': 'Для кликов',
    'equep.curve.set': 'Введите показатели',
    'equep.formula.info':
        'Нажмите на параметр, чтобы добавить его в формулу. Не забудьте проверить наличие всех математических знаков.',
    'equep.formula.click': 'цена клика',
    'equep.range.title': 'Цена операции по диапазону кликов',
    'equep.range.settings': 'Управление диапазоном',
    'equep.range.count': 'Начало диапазона',
    'equep.range.price': 'Цена',
    'equep.range.modal.title': 'Цена/время работы по кликам',
    'equep.range.modal.add': 'Добавить диапазон',
    'equep.range.modal.back': 'Вернуться',
    'equep.acc.click': 'Расчёт цены клика',
    'equep.copy': 'Строка скопирована',
    'equep.price': 'Цена',
    'equep.range': 'Диапазон',

    //DETAILS----------------------/
    'detail.title': 'Детали',
    'detail.title.sm': 'деталь',
    'detail.rulon': 'Рулонная печать',
    'detail.list': 'Наклейка в листах',
    'detail.form.print': 'Нужен макет для изготовления детали',
    'detail.form.sided': 'Двусторонняя печать',
    'detail.form.roll': 'Деталь печатается на рулонном принтере',
    'detail.form.labels': 'Наклейка в листах',
    'detail.form.description': 'Тех требования',
    'detail.form.comment': 'Комментарий технолога',
    'detail.form.image': 'Чертеж макета',

    //CONTRACTORS----------------------/
    'contr.title': 'Библиотека подрядчиков',
    'contr.title.full': 'Подрядчик',
    'contr.title.multi': 'Подрядчики',
    'contr.title.sm': 'подрядчика',
    'contr.group': 'Группа',
    'contr.import.loading': 'Идет процесс импорта',
    'contr.import': 'Импорт',
    'contr.import.file': 'Файлы импорта',
    'contr.import.name': 'Названия работы',
    'contr.work.info': 'Информация по роботе',
    'contr.work.currency': 'Валюта',
    'contr.days': 'Дней',
    'contr.tr.title': 'Работу подрядчика',
    'contr.tr.group': 'Группа подрядчиков',
    'contr.tr.admin.form.name': 'Название работы',
    'contr.tr.days': 'Срок',
    'contr.import.title': 'Импорт подрядчиков',
    'contr.import.form.title': 'Импорт подрядчиков',
    'contr.import.form.currency': 'Название файла',
    'contr.import.form.currency.info': 'По умолчанию данные импортируются в EUR',
    'contr.import.form.file': 'Файл со списком работ',
    'contr.import.btn.import': 'Импортировать',
    'contr.import.btn.save': 'Импортировать и сохранить',
    'contr.import.msg.warning': 'Для импорта, сначала введите название подрядчика.',
    'contr.group.sm': 'группу для подрядчиков',
    'contr.not.format': 'Без формата',
    'contr.range.title': 'Цена операции по диапазону кликов',
    'contr.range.controll': 'Управление диапазоном',
    'contr.range.form.price': 'Цена (EUR)',
    'contr.range.title.save': 'Цена/время работы по кликам',
    'contr.import.msg.success': 'Процесс импорта может занять несколько минут.',
};
