export default {
    namespaced: true,

    state: {
        list: [],
        item: {},

        loadingGetList: false,
        loadingGetItem: false,
        loadingSaveItem: false,
        loadingTestMail: false,
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_ITEM(state, item) {
            state.item = item;
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },
    },

    actions: {
        GET_LIST: async ({ state, getters, rootGetters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let path = `/api/typographies/${rootGetters.currentTypography.id}/mail-templates`;

                let resp = await $axios.get(path, {});

                commit('SET_LIST', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_ITEM: async ({ state, getters, rootGetters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                let path = `/api/typographies/${rootGetters[`currentTypography`].id}/mail-templates/${id}`;

                let resp = await $axios.get(path);

                commit('SET_ITEM', resp.single);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        SAVE_ITEM: async ({ state, commit, rootGetters, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let path = `/api/products`;
                if (rootGetters.hasTypographyAccess) {
                    path = `/api/typographies/${rootGetters[`currentTypography`].id}/mail-templates`;
                }

                let resp = await $axios.post(path, formData);

                dispatch('GET_LIST');

                if (formData.id && Object.keys(state.item).length) dispatch('GET_ITEM', formData.id);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        TEST_SEND: async ({ state, commit, rootGetters, dispatch }, { formData, id }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingTestMail',
                    status: true,
                });

                let path = `/api/products`;
                if (rootGetters.hasTypographyAccess) {
                    path = `/api/typographies/${rootGetters[`currentTypography`].id}/mail-templates/send/${id}`;
                }

                let resp = await $axios.post(path, formData);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingTestMail',
                    status: false,
                });
            }
        },
    },
};
