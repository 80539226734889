<template>
    <div
        id="doppler"
        class="doppler"
        :class="{
            'druk-l-elevation-1': !isWindowScrolled,
            'druk-l-elevation-3': isWindowScrolled,
        }">
        <div class="doppler__wrapper">
            <div class="doppler__text">{{ $t('root.prompt') }}</div>
            <div class="doppler__link" @click="onDopplerEffect">
                {{ $t('common.go.back') }}
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

    export default {
        name: 'elusive-tief',

        data() {
            return {
                isWindowScrolled: false,
            };
        },

        created() {
            document.addEventListener('scroll', this.onCheckWindowScroll);
        },

        destroyed() {
            document.removeEventListener('scroll', this.onCheckWindowScroll);
        },

        methods: {
            ...mapActions({
                AUTH_DOPPLER_LOGOUT: 'AUTH_DOPPLER_LOGOUT',
            }),

            onCheckWindowScroll() {
                this.isWindowScrolled = !!window.scrollY;
            },

            async onDopplerEffect() {
                await this.AUTH_DOPPLER_LOGOUT();

                this.$router.push({ name: 'dashboard', query: { doppler_redirect: 'typographies', with_doppler_reload: true } });
                this.$bus.$emit('on-check-doppler-auth');
            },
        },
    };
</script>

<style lang="scss" scoped>
    .doppler {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
        font-weight: 500;
        color: var(--druk-on-warning-container);
        background-color: var(--druk-warning-container);
        text-transform: uppercase;
        z-index: 20;
        transition: box-shadow 0.2s ease-in-out;
        @media (max-width: $druk-breakpoints-def) {
            font-size: 11px;
        }
        &__wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            @media (max-width: $druk-breakpoints-def) {
                flex-direction: column;
                height: 36px;
            }
        }
        &__text {
            margin-right: 5px;
        }
        &__link {
            cursor: pointer;
            font-weight: 600;
            text-decoration: underline;
        }
    }
</style>
