export default {
    namespaced: true,

    state: {
        LAYOUT_OPTIONS: [
            {
                key: 'params.title.material.param',
                value: 'material-params',
            },
            {
                key: 'params.title.material.options',
                value: 'material-param-options',
            },
            {
                key: 'params.title.material.variable',
                value: 'material-variables',
            },
            {
                key: 'params.title.equipment.param',
                value: 'equipment-params',
            },
            {
                key: 'params.title.equipment.options',
                value: 'equipment-param-options',
            },
            {
                key: 'params.title.equipment.variable',
                value: 'equipment-variables',
            },
        ],

        LAYOUT_MATERIAL_PARAM_OPTIONS: 'material-param-options',
        LAYOUT_EQUIPMENT_PARAM_OPTIONS: 'equipment-param-options',
    },
};
