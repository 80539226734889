export default {
    namespaced: true,
    state: {
        IMPORT_FILE_EXAMPLE_LINK: 'https://druk.s3.amazonaws.com/static/example-contractor-operations.xlsx',

        list: [],

        loadingGetList: false,
        loadingGetItem: false,
        loadingSaveItem: false,
        loadingDeleteItem: false,
        loadingImport: false,
    },

    getters: {},

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },
    },

    actions: {
        START_IMPORT: async ({ state, rootGetters, getters, commit, dispatch }, { id, currency, formData }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.post(
                    `/api/typographies/${rootGetters.currentTypography.id}/contractors/${id}/operations/import`,
                    formData,
                    {
                        headers: {
                            'Currency-Code': currency,
                        },
                    },
                );

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        RUN_IMPORT: async ({ state, rootGetters, getters, commit, dispatch }, { id, importId }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingImport',
                    status: true,
                });

                let resp = await $axios.post(
                    `/api/typographies/${rootGetters.currentTypography.id}/contractors/${id}/operations/import/${importId}/run`,
                );

                setTimeout(() => {
                    dispatch('GET_LIST', id);
                    dispatch('contractors/operations/GET_LIST', id, { root: true });
                }, 9000);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                setTimeout(() => {
                    commit('SET_LOADING_STATUS', {
                        value_key: 'loadingImport',
                        status: false,
                    });
                }, 9000);
            }
        },

        DOWNLOAD_ITEM: async ({ state, rootGetters, getters, commit, dispatch }, { contractorId, id }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios
                    .get(
                        `/api/typographies/${rootGetters.currentTypography.id}/contractors/${contractorId}/operations/import/${id}/download`,
                        {
                            responseType: 'blob',
                        },
                    )
                    .then((resp) => {
                        const blob = new Blob([resp], { type: 'text/html' });
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.download = 'ContractorsWork.xls';
                        link.click();

                        URL.revokeObjectURL(link.href);
                    });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_LIST: async ({ state, rootGetters, getters, commit }, contactorId) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.get(
                    `/api/typographies/${rootGetters.currentTypography.id}/contractors/${contactorId}/operations/import`,
                );

                commit('SET_LIST', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        DELETE_ITEM: async ({ commit, rootGetters, dispatch }, { contractorId, id }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingDeleteItem: false,',
                    status: true,
                });

                let resp = await $axios.delete(
                    `/api/typographies/${rootGetters.currentTypography.id}/contractors/${contractorId}/operations/import/${id}`,
                );
                let contrId = contractorId;

                dispatch('GET_LIST', contrId);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },
    },
};
