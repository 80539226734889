<template>
    <div class="wizard__progress" :class="{ 'has-inner': hasInner }">
        <div class="wizard__steps">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'wizard-steps-progress',

        inject: ['$validator'],

        props: {
            hasInner: Boolean,
        },
    };
</script>
