<template>
    <div class="druk-c-hint" :class="{ 'druk-is-inline': isInline }">
        <tool-tip
            :from="tooltip.from || TT_COMMON_SIDE"
            :text="ttText"
            :maxWidth="tooltip.maxWidth || TT_COMMON_MAX_WIDTH"
            :isInline="true"
            :isNotCollapse="!!tooltip.isNotCollapse"
            :hasUnparsedText="!!tooltip.hasUnparsedText">
            <druk-icon
                :name="icon.name || icon"
                :color="icon.color || ICON_COMMON_COLOR"
                :size="icon.size || ICON_COMMON_SIZE"
                :variant="icon.variant || ICON_COMMON_VARIANT" />
        </tool-tip>
    </div>
</template>

<script>
    export default {
        name: 'druk-hint',

        props: {
            tooltip: {
                type: [String, Number, Object], // @Explanation: You can pass object same to the default value or just a string/number value
                default: () => {
                    return {
                        text: '', // @Explanation: String or number value
                        from: 'top-left', // @Explanation: The side for which hint should be shown
                        maxWidth: '200px', // @Explanation: Custom width limit for tooltip text
                        isNotCollapse: false, // @Explanation: Prevent text collapse
                        hasUnparsedText: false, // @Explanation: Set tooltip content as plain text
                    };
                },
            },

            icon: {
                type: [String, Object], // @Explanation: You can pass object same to the default value or just a string value
                default: () => {
                    return {
                        name: 'circle-info', // @Explanation: String value with font-awesome icon name
                        color: 'primary', // @Explanation: Sass-variable name or hex-code. Primary is default color
                        size: 's', // @Caution: Standart labels uses only 'xs' icons. Use non-standart sizes only with label classes
                        variant: 'regular', // @Caution: Use only 'bold' variant instead 'regular' and only for 'is-active' state
                    };
                },
            },

            addlContent: String,
            isInline: Boolean,
        },

        data() {
            return {
                TT_COMMON_SIDE: 'top-left',
                TT_COMMON_MAX_WIDTH: '200px',

                ICON_COMMON_COLOR: 'primary',
                ICON_COMMON_SIZE: 's',
                ICON_COMMON_VARIANT: 'regular',
            };
        },

        computed: {
            ttText() {
                return `${!!this.addlContent ? `${this.addlTTContent} ` : ''}${this.tooltip.text || this.tooltip}`;
            },

            addlTTContent() {
                return `<span style="content: ''; display: inline-block; vertical-align: text-top; width: 14px; height: 14px; background-color: ${this.$material[this.addlContent]}""></span>`;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .druk-c-hint {
        &.druk-is-inline {
            display: inline;
        }
    }
</style>
