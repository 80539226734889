export default {
    //UPDATE v1.0---------------------------------------------//

    //BTN-----------------------------//
    'btn.edit': 'Редактировать',
    'btn.create': 'Создать',
    'btn.save': 'Сохранить',
    'btn.add': 'Добавить',
    'btn.group.create': 'Создать группу',
    'btn.group.delete': 'Удалить группу',
    'btn.clone': 'Клонувати',
    'btn.import': 'Імпорт',
    'btn.export': 'Експорт',
    'btn.generate': 'Згенерувати',
    'admin.btn.continue': 'Продовжити',
    'btn.appoint': 'Назначить',
    'btn.change': 'Изменить',
    'admin.btn.cancel': 'Отмена',
    'admin.btn.enter': 'Войти',
    'btn.apply': 'Применить',
    '': '',
    '': '',

    //COMMON--------------------------//
    'common.filter': 'Фильтры',
    'common.back': 'Вернуться',
    'common.add.range': 'Добавить диапазон',
    'common.price': 'Цена',
    'common.payment': 'Оплата',
    'common.sort': 'Порядок отображения',
    'common.published.not': 'Скрыт',
    'common.open': 'Развернуть',
    'common.close': 'Свернуть',
    'common.type.cheapest': 'Дешевле',
    'common.type.optimal': 'Стандарт',
    'common.type.fastest': 'Быстрее',
    'common.detail': 'Детальная информация',
    'common.link': 'Ссылка',
    'common.dimantions': 'шт',
    'common.uah': 'UAH',
    'common.delete': 'Удалить',
    'common.time': 'Срок',
    'common.min': 'мін',
    'common.max': 'макс',
    'common.equipment': 'Оборудование',
    'common.print.color': 'Цветность',
    'common.employeer': 'Исполнитель',
    'common.client': 'Клиент',
    'common.typog': 'Типография',
    'common.popular': 'Популярный',
    'common.published': 'Опубликован',
    'common.hide': 'Скрыт',
    'common.verify': 'Верификован',
    'log.pack': 'упаковки(-вок)',
    '': '',

    //TABLES--------------------------//
    'table.alias': 'Alias',
    'table.title': 'Название',
    'table.type': 'Тип',
    'table.key': 'Ключ',
    'table.domain': 'Домен',
    'table.modules': 'Подключенные модули',
    'table.articl': 'Артикул',
    'table.format': 'Формат',
    'table.weight': 'Вес (г)',
    'table.self': 'Себестоимость',
    'table.count': 'Наличие (шт)',
    'table.width': 'Ширина (мм)',
    'table.height': 'Высота (мм)',
    'table.phone': 'Телефон',
    'table.email': 'Email',
    'table.contact': 'Контактное имя',
    'table.web': 'Веб-сайт',
    'table.city': 'Город',
    'table.status': 'Статус',
    'table.access': 'Доступ',
    'table.value': 'Значения',
    'table.date': 'Дата',
    'table.amount': 'Кол-во',
    'table.address': 'Адрес',
    'table.comment': 'Комментарии',
    'table.sum': 'Сумма',
    'table.balance': 'Баланс',
    'table.status.pay': 'Статус оплаты',
    'table.material': 'Материал',
    '': '',
    '': '',
    '': '',

    //FORMS---------------------------//
    'form.title': 'Название',
    'form.alias': 'Элиас',
    'form.email': 'Email',
    'form.key': 'Ключ',
    'form.description': 'Описание',
    'form.currency': 'Валюта',
    'form.phone': 'Телефон',
    'form.contact': 'Контактное имя',
    'form.web': 'Веб-сайт',
    'form.address': 'Адрес',
    'form.count.start': 'Начало диапазона',
    'form.status': 'Статус',
    'form.published': 'Опубликован',
    'form.image': 'Превью',
    'form.value': 'Значения',
    'form.comment': 'Комментарий',
    'form.available': 'В наличии',
    'form.count': 'Тираж',
    'form.view': 'Вид',
    'form.delivery': 'Способ доставки',
    'form.name.last': 'Фамилия',
    'admin.form.name': 'Имя',
    'admin.form.patronymic': 'Отчество',
    'form.login': 'Логин',
    'form.password': 'Пароль',
    'form.principle': 'Способ изготовления',
    'form.sale': 'Скидка (%)',
    'form.role': 'Роль',
    'form.birthday': 'Дата рождения',
    'form.ava': 'Аватар',
    'form.country': 'Страна',
    'form.city': 'Город',
    '': '',
    '': '',

    //BREAD---------------------------//
    'bread.dashboard': 'Дашборд',
    'bread.typography': 'Типографии',
    '': '',
    '': '',
    '': '',

    //MAKETS
    'maket.title': 'Управление макетами продукта',
    'maket.origin': 'Исходники',
    'maket.maket': 'Макеты',
    'maket.line': 'Спуск полос',
    'maket.check.lable': 'Автоматическая проверка макета',
    'maket.check.info.part1': 'Функция доступна перед загрузкой исходника. *По умолчанию используется ручная проверка.',
    'maket.check.info.part2': 'Её цена составляет',
    'maket.controll': 'Управление макетами',
    '': '',
    '': '',

    //Old data -----------------------//
    'common.algorithm_': 'алгоритм',
    'common.algorithm': 'Алгоритм',
    'common.banList': 'Бан лист',
    'common.faster': 'Быстрее',
    'common.timing': 'времени',
    'common.variableGlobal': 'Глобальные переменные',
    'common.cheaper': 'Дешевле',
    'common.add': 'Добавить',
    'common.manufacturing': 'Изготовление',
    'common.copy': 'Копировать',
    'common.copys': 'Копия',
    'common.modalcontent': 'Контент в модальном окне',
    'common.dev': 'Модуль в процессе разработки',
    'common.materials': 'Материалы',
    'common.material': 'Материал',
    'admin.common.name': 'Название',
    'common.roundUp': 'округлвверх',
    'common.contractor': 'Подрядчик',
    'common.pre-press': 'Пре-пресс',
    'common.post-press': 'Пост-пресс',
    'common.print': 'Печать',
    'common.work': 'Работа',
    'common.save': 'Сохранить',
    'common.create': 'Создать',
    'common.creating': 'Создание',
    'common.rate': 'Ставка',
    'common.edit': 'Редактировать',
    'common.variableTypes': 'Типы переменных',
    'common.control': 'Управление',
    'common.format': 'Формат',
    'common.function': 'Функции',
    'common.filters': 'Фильтры',
    'common.prices': 'Цены',
    'common.notpublic': 'Эта не публичная страница',
    'form.urlpage': 'Ссылка на страницу',
    'table.title.work': 'Работа',
};
