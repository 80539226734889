<template>
    <div
        class="table"
        :class="{
            'has-loading': hasLoadingList,
            'has-scroll': hasModuleScroll,
            'has-scroll-left': hasScrollBorderLeft,
            'has-scroll-right': hasScrollBorderRight,
            'has-empty': hasEmpty,
            'has-box': inBox,
        }">
        <div
            class="table__container"
            :class="{
                [`druk-l-surface-${surface}`]: surface,
                [`druk-l-shape-${shape}`]: shape,
                'druk-l-shape-none-bot': hasPagination,
                'is-inner': isInner,
            }">
            <div class="table__border">
                <div ref="tableWrap" class="table__main" @scroll="onTouchScroll">
                    <table ref="table">
                        <thead ref="mainHead" v-if="(!(!!emptyPrompt && hasEmpty) || !emptyPrompt) && headers">
                            <tr>
                                <template v-for="(head, index) in headers">
                                    <th
                                        v-if="!head.hideTh"
                                        :key="index"
                                        :class="{ 'has-nav': head.key === KEY_NAV, 'has-wrap': head.hasWrap, 'has-hot': head.hasHot }">
                                        <div
                                            class="_flex"
                                            :class="{
                                                'has-active': head.key === sortKey,
                                                _align_center: !head.hasActions,
                                                'has-actions': head.hasActions,
                                            }">
                                            <span :class="head.class">{{ head.text }}</span>

                                            <div
                                                v-if="head.hasSort"
                                                class="table__icon"
                                                :class="{
                                                    'has-active': head.key === sortKey,
                                                    'table__icon--desc': direction === DIRECTION_DESC_KEY && head.key === sortKey,
                                                }"
                                                @click="directionCheck(head.key, head.keyPayload)">
                                                <font-awesome-icon v-if="head.key === sortKey" icon="fa-solid fa-arrow-down" />
                                                <font-awesome-icon v-else icon="fa-regular fa-arrow-down" />
                                            </div>
                                        </div>
                                    </th>
                                </template>
                            </tr>
                        </thead>

                        <tbody class="table__body" ref="mainBody">
                            <slot></slot>
                        </tbody>
                    </table>
                </div>

                <div class="table__scrollWrap" :style="{ bottom: `${scrollPosition}px` }" v-show="hasModuleScroll">
                    <div
                        ref="scrollWrap"
                        :style="{ width: `${tableWrapWidth}px` }"
                        class="table__scroll"
                        :class="{ [`druk-l-surface-${surface}`]: surface }">
                        <span :style="{ width: `${tableWidth}px` }"></span>
                    </div>
                </div>

                <div class="table__empty druk-u-text-label-lg">
                    {{ emptyPrompt || $t('common.table.empty') }}
                </div>
            </div>

            <div class="table__spinner">
                <druk-loader />
                <span>{{ $t('common.table.loading') }}</span>
            </div>
        </div>

        <div class="table__footer" v-if="hasPagination">
            <druk-pagination
                :pagination="pagination"
                :list="items"
                :hasLoadingList="hasLoadingList"
                @changeLimit="$emit('changeLimit', $event)"
                @changePage="$emit('changePage', $event)"></druk-pagination>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    import DrukPagination from '@components/druk/DrukPagination';

    export default {
        name: 'v-table',

        components: {
            DrukPagination,
        },

        props: {
            headers: Array,

            items: {
                type: Array,
                required: true,
            },

            pagination: Object,
            navigations: Array,
            hasLoadingList: Boolean,
            routeLink: String,
            hasSort: Boolean,
            sortKey: String,
            emptyPrompt: String,
            inBox: Boolean,
            hasHiddenPagination: Boolean,

            // Shape
            surface: {
                type: String,
                default: 'tint-pale',
            },

            shape: {
                type: String,
                default: 'm',
            },

            isInner: Boolean,
        },

        data() {
            return {
                KEY_NAV: 'nav',
                BOTTOM_SCROLL_HIDER_HEIGHT: 17, //before element height
                tableScroll: 0,
                tableWidth: 0,
                tableWrapWidth: 0,
                scrollPosition: 0,

                elTableWrap: null,
                elTable: null,
                elScrollWrap: null,

                hasLoadDom: false,
                hasModuleScroll: false,
                hasScrollBorderLeft: false,
                hasScrollBorderRight: false,

                direction: 'desc',
            };
        },

        created() {
            window.addEventListener('resize', this.onWindowScaleOrResize);
        },

        mounted() {
            this.elTableWrap = this.$refs['tableWrap'];
            this.elTable = this.$refs['table'];
            this.elScrollWrap = this.$refs['scrollWrap'];

            document.addEventListener('scroll', this.setScrollPostion);

            this.elTableWrap?.addEventListener('scroll', this.checkBorderScroll);
            this.elScrollWrap?.addEventListener('scroll', this.scrollControll);

            this.$bus.$on('on-change-menu', () => {
                setTimeout(() => {
                    this.initCustomScroll();
                }, 400); // @Explanation: waiting ending css animation
            });
        },

        destroyed() {
            window.removeEventListener('resize', this.onWindowScaleOrResize);
        },

        watch: {
            items() {
                let waiter = setTimeout(() => {
                    clearTimeout(waiter);

                    this.initCustomScroll();
                    this.setStickyCols();
                }, 0);
            },
        },

        computed: {
            ...mapState({
                DIRECTION_ASC_KEY: (state) => state.constant.DIRECTION_ASC_KEY,
                DIRECTION_DESC_KEY: (state) => state.constant.DIRECTION_DESC_KEY,
            }),

            addKeysList() {
                let list = [];

                this.headers.forEach((el) => {
                    if (el.keyPayload && !list.includes(el.keyPayload.addKey)) list.push(el.keyPayload.addKey);
                });

                return list;
            },

            hasEmpty() {
                return !this.items.length && !this.hasLoadingList;
            },

            hasPagination() {
                return !!this.pagination && !!this.pagination.total && !this.hasHiddenPagination;
            },
        },

        methods: {
            directionCheck(headKey, keyPayload) {
                let key = keyPayload ? keyPayload.sortKey : headKey;

                this.direction =
                    this.sortKey === headKey
                        ? this.direction === this.DIRECTION_ASC_KEY
                            ? this.DIRECTION_DESC_KEY
                            : null
                        : this.DIRECTION_ASC_KEY;

                let sortObj = {
                    sort: this.direction ? key : null,
                    direction: this.direction,
                };

                this.addKeysList.forEach((el) => (sortObj[el] = null));
                if (keyPayload) sortObj[keyPayload.addKey] = this.direction ? keyPayload.keyValue : null;

                this.$emit('onSort', sortObj);
            },

            initCustomScroll() {
                this.tableWrapWidth = this.elTableWrap?.offsetWidth;
                this.tableWidth = this.elTable?.offsetWidth;

                this.hasModuleScroll = this.tableWidth > this.tableWrapWidth;
            },

            scrollControll(e) {
                this.$bus.$emit('make-forced-dd-close');
                this.elTableWrap.scrollLeft = this.elScrollWrap?.scrollLeft;
            },

            checkBorderScroll() {
                this.hasScrollBorderLeft = !!this.elTableWrap.scrollLeft;
                this.hasScrollBorderRight = !(
                    this.elTableWrap.scrollWidth - Math.round(this.elTableWrap.scrollLeft) ===
                    this.elTableWrap.clientWidth
                );
            },

            setScrollPostion() {
                this.scrollPosition =
                    this.elTable?.getBoundingClientRect().bottom < window.innerHeight
                        ? window.innerHeight - this.elTable?.getBoundingClientRect().bottom - this.BOTTOM_SCROLL_HIDER_HEIGHT
                        : 0;
            },

            setStickyCols() {
                if (this.hasModuleScroll) {
                    this.$refs.mainHead.getElementsByTagName('tr')[0].getElementsByTagName('th')[0].classList.add('has-sticky');

                    this.$refs.mainBody.getElementsByTagName('tr').forEach((el) => {
                        let cols = el.getElementsByTagName('td');
                        if (!cols[0].classList.contains('table__subCell')) cols[0].classList.add('has-sticky');
                    });
                }
            },

            onWindowScaleOrResize(e) {
                this.initCustomScroll();
                this.setStickyCols();
            },

            onTouchScroll(e) {
                this.$bus.$emit('make-forced-dd-close');
                this.elScrollWrap.scrollLeft = e.target.scrollLeft;
            },
        },
    };
</script>

<style lang="scss" scoped></style>
