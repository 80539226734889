<template>
    <div class="druk-c-avatar" :class="{ [`druk-c-avatar--${size}`]: size }">
        <div v-if="img" class="druk-c-avatar__img">
            <img :src="img" alt="Druk" />
        </div>

        <druk-icon
            v-else-if="icon"
            class="druk-c-avatar__icon"
            :name="iconValue"
            :variant="iconVariant"
            :size="iconSize"
            :color="iconColor" />

        <div v-else class="druk-c-avatar__chart">
            {{ labelChart }}
        </div>
    </div>
</template>

<script>
    export default {
        name: 'druk-avatar',

        props: {
            img: String,

            label: {
                type: String,
                default: 'D',
            },
            icon: {
                type: [String, Object], // @Explanation: You can pass object same to the default value or just a string value
                default: null,
                // @Explanation: Object parameters:
                // @value: String value with font-awesome icon name
                // @color: Sass-variable name or hex-code. Primary is default color
                // @size: Standart avatar uses only 'md' icons
                // @varian: Use only 'bold' variant instead 'regular' and only for 'is-active' state
            },
            size: {
                type: String,
                default: 'm',
            },
        },

        data() {
            return {
                DRUK_STANDART_ICON_COLOR: 'on-primary-container',
                DRUK_STANDART_ICON_SIZE: 's',
                DRUK_STANDART_ICON_VARIANT: 'regular',
            };
        },

        computed: {
            labelChart() {
                return this.label.charAt(0);
            },

            iconValue() {
                return typeof this.icon === 'string' ? this.icon : this.icon?.value || null;
            },

            iconColor() {
                return typeof this.icon === 'string' || !this.icon.color ? this.DRUK_STANDART_ICON_COLOR : this.icon?.color || null;
            },

            iconSize() {
                return typeof this.icon === 'string' || !this.icon.size ? this.DRUK_STANDART_ICON_SIZE : this.icon?.size || null;
            },

            iconVariant() {
                return typeof this.icon === 'string' || !this.icon.variant
                    ? this.DRUK_STANDART_ICON_VARIANT
                    : this.icon?.variant || null;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .druk-c-avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border-radius: 50%;
        background-color: var(--druk-primary-container);
        &--s {
            width: 24px;
            height: 24px;
        }
        &--m {
            width: 30px;
            height: 30px;
        }
        &--lg {
            width: 48px;
            height: 48px;
        }
        &__img {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--druk-primary-tint-0-14);
        }
        &__chart {
            color: var(--druk-on-primary-container);
        }
        svg {
            --color: --icon-color;
            width: auto;
            height: 100%;
            color: #{var(--icon-color)};
        }
    }
</style>
