import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        ITEM_COMMON_DATA: {
            group: null,
            item: null,
        },

        list: [],
        globalParamsMaterials: [],

        formData: {},
    },

    getters: {},

    mutations: {
        SET_LIST(state, { materials, banlistMaterials }) {
            let mapMaterials = {};

            // need to get all materiasl params and options
            //step 1 - get material access params
            materials.forEach((material) => {
                mapMaterials[material.id] = {
                    id: material.id,
                    title: material.title,
                    params: {},
                };

                material.params.forEach((param) => {
                    mapMaterials[material.id].params[param.id] = {
                        id: param.id,
                        options: [],
                        optionsMap: param.options,
                    };
                });
            });

            //step2 - get banlist params
            banlistMaterials.forEach((item) => {
                if (!item.param_id) return;

                if (mapMaterials[item.material_id].params[item.param_id]) return;

                mapMaterials[item.material_id].params[item.param_id] = {
                    id: item.param_id,
                    options: [],
                    optionsMap: [],
                };
            });

            banlistMaterials.forEach((item) => {
                //set selected options
                if (!item.param_id) return;

                mapMaterials[item.material_id].params[item.param_id].options.push({
                    id: item.option_id,
                });

                mapMaterials[item.material_id].params[item.param_id].optionsMap.push({
                    id: item.option_id,
                });
            });

            //step4 - formated data to array
            let arrMapMaterials = Object.values(mapMaterials);

            arrMapMaterials = $fn.map(arrMapMaterials, (el) => {
                return {
                    id: el.id,
                    title: el.title,
                    params: Object.values(el.params),
                };
            });

            state.list = arrMapMaterials;
        },
        SET_OPTIONS_TO_MATERIAL(state, optionsList) {
            state.globalParamsMaterials = optionsList;

            state.list.forEach((material) => {
                material.params.forEach((item) => {
                    let selectedParam = $fn.find(optionsList, ['id', item.id]);
                    if (!selectedParam) return; //@TODO attantion fix back-end bag

                    item['title'] = selectedParam.title;
                    // item['optionsMap'] = selectedParam.options;

                    item.options = $fn.map(item.options, (option) => {
                        let selectedOption = $fn.find(selectedParam.options, ['id', option.id]);
                        return selectedOption;
                    });

                    item.optionsMap = $fn.map(item.optionsMap, (option) => {
                        let selectedOption = $fn.find(selectedParam.options, ['id', option.id]);
                        return selectedOption;
                    });
                });
            });
        },

        SET_PARAM_OPTION_MATERIAL(state, { item, materialId, paramId }) {
            let material = $fn.find(state.list, ['id', materialId]);
            let param = $fn.find(material.params, ['id', paramId]);
            param.options = item;
        },

        SET_FORM_DATA(state, formData) {
            state.formData = formData;
        },

        SET_FORM_DATA_PROP(state, { key, value }) {
            Vue.set(state.formData, key, value);
        },

        SET_FORM_DATA_ITEM_PROP(state, { key, value }) {
            Vue.set(state.formData.data, key, value);
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        ADD_MATERIAL(state, { material, params }) {
            state.list.push({
                id: material.id,
                title: material.title,
                params: [],
            });

            let selectedMaterial = $fn.find(state.list, ['id', material.id]);

            params.forEach((el) => {
                let selectedParam = $fn.find(state.globalParamsMaterials, ['id', el.id]);

                selectedMaterial.params.push({
                    id: el.id,
                    title: el.title,
                    options: [],
                    optionsMap: selectedParam.options,
                });
            });
        },

        DELETE_MATERIAL(state, id) {
            let index = $fn.findIndex(state.list, ['id', id]);
            Vue.delete(state.list, index);
        },

        RESET_STORE(store) {
            store.list = [];
        },

        RESET_FORM_DATA(state) {
            state.formData = {};
        },
    },

    actions: {
        GET_ALL_OPTIONS: async ({ state, rootGetters, getters, commit }, ids) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/materials/params/tree`, {
                    params: {
                        ids: ids,
                    },
                });

                commit('SET_OPTIONS_TO_MATERIAL', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },
    },
};
