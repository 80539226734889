<template>
    <div class="header" :class="{ 'has-iframe': hasIframe, 'has-elusive-thief': hasElusiveThief }">
        <div class="header__navs">
            <div class="header__nav">
                <druk-icon-button
                    v-if="!hasIframe"
                    :class="{ 'druk-u-margin-right-16': isLogged && hasERPAccess }"
                    :type="'outlined'"
                    :icon="hasActiveDrawer ? 'bars' : 'ellipsis-vertical'"
                    @click="hasActiveDrawer = !hasActiveDrawer" />
            </div>
        </div>

        <div class="header__navs">
            <div class="header__nav" v-if="isLogged && hasERPAccess">
                <header-currency />
            </div>

            <div
                class="header__nav has-mobile druk-u-margin-right-8"
                v-if="!hasIframe && $fn.hasAccess(PERM_MODULE_ORDERS, PERM_ACTION_ADD) && hasTypographyAccess">
                <div class="menu">
                    <druk-fab
                        :base="'tertiary'"
                        :icon="'cart-plus'"
                        :label="$t('create.orders.add')"
                        :size="'s'"
                        :path="{ name: 'create_order' }" />
                </div>
            </div>

            <div v-if="hasERPAccess" class="header__nav">
                <druk-icon-button :type="'standard'" :icon="'gear'" @click="onShowTechData()" />
            </div>

            <div class="header__nav">
                <druk-dropdown :position="{ horizontal: 'ctr', vertical: 'bot' }" :hasStaticContent="true">
                    <template v-slot:druk-c-dd-base="{ isShown }">
                        <druk-icon-toggle-button :type="'standard'" :icon="'globe'" :isActive="isShown" />
                    </template>

                    <template v-slot:druk-c-dd-list>
                        <ul class="list">
                            <li
                                class="list__item"
                                v-for="lang in languages"
                                :key="lang.code"
                                :class="{ 'druk-is-active': lang.code === $i18n.locale }"
                                @click="onSetLang(lang.code)">
                                <span>{{ lang.code }}</span>
                            </li>
                        </ul>
                    </template>
                </druk-dropdown>
            </div>

            <div class="header__nav druk-is-disabled" v-if="!hasIframe">
                <druk-dropdown :position="{ horizontal: 'ctr', vertical: 'bot' }" :hasStaticContent="true">
                    <template v-slot:druk-c-dd-base>
                        <druk-icon-toggle-button :type="'standard'" :icon="'bell'" />
                    </template>
                </druk-dropdown>
            </div>

            <div v-if="isLogged" class="header__nav">
                <druk-icon-button v-if="hasIframe" :type="'standard'" :icon="'bell'" @click="onEmployeesModal" />

                <druk-icon-button
                    v-else
                    :path="{ name: 'employees_item', params: { id: user.id } }"
                    :type="'standard'"
                    :icon="'user'"
                    @click="onEmployeesModal" />
            </div>

            <div class="header__option">
                <druk-icon-button :type="'standard'" :icon="'arrow-right-from-bracket'" @click="onLogout" />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    import HeaderCurrency from './HeaderCurrency';
    import ModalTechData from './components/ModalTechData';
    import ModalEmployeesItem from '@views/employees/ModalEmployeesItem';

    export default {
        name: 'header-top',

        components: {
            HeaderCurrency,
        },

        props: ['hasIframe'],

        computed: {
            ...mapState({
                PERM_MODULE_ORDERS: (state) => state.permissions.PERM_MODULE_ORDERS,
                PERM_ACTION_ADD: (state) => state.permissions.PERM_ACTION_ADD,

                MEDIUM_ADNIMATION_DURATION: (state) => state.constant.MEDIUM_ADNIMATION_DURATION,

                user: (state) => state.auth.user,
            }),
            ...mapGetters({
                languages: 'languages/editableSystemLanguages',

                isLogged: 'isLogged',
                hasERPAccess: 'hasERPAccess',
                hasTypographyAccess: 'hasTypographyAccess',
                hasElusiveThief: 'hasElusiveThief',
            }),

            hasActiveDrawer: {
                get() {
                    return this.$store.state.hasActiveDrawer;
                },
                set(status) {
                    this.SET_DRAWER_STATUS(status);

                    this.$bus.$emit('set-table-scroll', this.MEDIUM_ADNIMATION_DURATION);
                    this.$bus.$emit('set-fixed-drop-position', this.MEDIUM_ADNIMATION_DURATION);
                },
            },
        },

        mounted() {
            if (this.$device.isMobile) this.hasActiveDrawer = false;
        },

        methods: {
            ...mapActions({
                AUTH_LOGOUT: 'AUTH_LOGOUT',
            }),
            ...mapMutations({
                SET_DRAWER_STATUS: 'SET_DRAWER_STATUS',
                SET_HISTORY_ADDRESS: 'SET_HISTORY_ADDRESS',
                SET_SELECTED_LANG: 'SET_SELECTED_LANG',
            }),

            onLogout() {
                this.AUTH_LOGOUT().then(() => {
                    if (this.hasIframe) {
                        this.SET_HISTORY_ADDRESS(window.location.href);
                    }

                    this.$router.go({ name: 'login' });
                });
            },

            onSetLang(locale) {
                this.$i18n.setLang(locale).then(() => {
                    this.setValidateLocalization(locale);
                    this.SET_SELECTED_LANG(locale);
                });
            },

            onEmployeesModal() {
                this.$modal.show(ModalEmployeesItem, {}, $MODAL_OPTIONS.big);
            },

            onShowTechData() {
                this.$modal.show(ModalTechData, {}, $MODAL_OPTIONS.big);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .header {
        height: 60px;
        padding: 12px 24px 12px 48px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &.has-iframe.has-elusive-thief {
            padding: 42px 24px 12px 48px;
        }
        &__navs {
            display: flex;
            align-items: center;
            margin: 0 -4px;
        }
        @media (max-width: $druk-breakpoints-md) {
            padding: 12px 8px;
        }
    }

    .has-mobile {
        @media (max-width: $druk-breakpoints-md) {
            display: none;
        }
    }

    .bell {
        width: 200px;
        &__empty {
            font-size: 12px;
        }
    }
    .burger {
        position: relative;
        z-index: 5;
    }
</style>
