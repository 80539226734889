<template>
    <div
        class="advanced-filter"
        :class="{
            'advanced-filter--closed': hasFilterData && !hasOpenedFilter,
            'advanced-filter--hidden': !hasFilterData && !hasOpenedFilter,
            [` druk-l-surface-${surface}`]: surface,
            'has-loading': hasFilterLoader || extraLoader,
            'has-solo-mode': hasOneFllterMode,
        }">
        <div class="advanced-filter__wrapper" v-if="(!hasFilterLoader && !extraLoader) || hasOneFllterMode">
            <div
                :hasOpenedFilter="hasOpenedFilter"
                class="advanced-filter__items"
                :class="{ 'has-payload': hasFilterData && !hasHiddenChips }">
                <template v-for="(item, id) in items">
                    <div
                        v-if="!item.hasHide"
                        :key="id"
                        class="advanced-filter__item"
                        :class="{
                            'advanced-filter__item_sorting': item.type === 'sorting',
                            'advanced-filter__item_group': item.type === 'form-group',
                            'has-big': item.isBigFilter,
                        }">
                        <form-input
                            v-if="item.type === 'form-input'"
                            v-model="filterData[item.key]"
                            :class="{
                                'has-alt': item.hasAlt,
                                'has-loading': hasFilterLoader || extraLoader,
                            }"
                            :label="item.label"
                            :hint="item.hint || null"
                            :side="item.side || null"
                            :name="item.key"
                            :isFilterType="true"
                            :placeholder="item.placeholder || $t('common.all')"
                            :surface="item.surface || surface"
                            @input="onInput()"
                            @onFocus="$emit('onFocus', $event)" />

                        <form-select
                            v-if="item.type === 'form-select'"
                            v-model="filterData[item.key]"
                            :class="item.class || 'has-sm'"
                            :options="item.options"
                            :label="item.label"
                            :hint="item.hint || null"
                            :side="item.side || null"
                            :option_id="item.option_id || 'id'"
                            :option_label="item.option_label || 'title'"
                            :name="item.key"
                            :multiple="item.multiple || false"
                            :isActiveSeparate="item.isActiveSeparate || false"
                            :isFilterType="true"
                            :notEmpty="item.is_protected"
                            :placeholder="$t('common.all')"
                            :loader="item.loader"
                            :surface="item.surface || surface"
                            :surfaceVariant="item.surfaceVariant || surfaceVariant"
                            @input="onInput()" />

                        <form-search-async
                            v-if="item.type === 'form-search-async'"
                            v-model="filterData[item.key]"
                            :class="item.class || 'has-sm'"
                            :label="item.label"
                            :hint="item.hint || null"
                            :side="item.side || null"
                            :options="item.options || searchOptions[item.key]"
                            :option_id="item.option_id || 'id'"
                            :option_label="item.option_label || 'title'"
                            :customOption="item.customOption"
                            :name="item.key"
                            :filter_key="item.key"
                            :placeholder="$t('common.all')"
                            :keySearch="item.keySearch"
                            :path="item.path"
                            :extraOptions="item.extraOptions"
                            :isFilterType="true"
                            :surface="item.surface || surface"
                            @input="onInput()"
                            @search="onSearch" />

                        <form-select
                            v-if="item.type === 'form-group'"
                            v-model="filterData[item.key]"
                            :class="item.class || 'has-group'"
                            :options="item.options"
                            :label="item.label"
                            :hint="item.hint || null"
                            :side="item.side || null"
                            :option_id="item.option_id || 'id'"
                            :option_label="item.option_label || 'title'"
                            :name="item.key"
                            :isGroup="true"
                            :notEmpty="true"
                            :surface="item.surface || surface"
                            @input="onInput()" />

                        <form-date-picker
                            v-if="item.type === 'form-date-picker'"
                            v-model="filterData[item.key]"
                            :hasDateLimit="typeof item.hasDateLimit == 'boolean' ? item.hasDateLimit : true"
                            :isFilterType="true"
                            :label="item.label"
                            :hint="item.hint || null"
                            :side="item.side || null"
                            :surface="item.surface || surface"
                            :surfaceVariant="item.surfaceVariant || surfaceVariant"
                            @input="onInput()" />
                    </div>
                </template>

                <form-select
                    v-if="!withoutSorting && !hasOneFllterMode"
                    class="advanced-filter__item advanced-filter__item_sorting has-sorted"
                    v-model="sort"
                    :options="$fn.tArray(currentSortingOptions)"
                    :label="$t('filter.sort')"
                    option_id="value"
                    option_label="title"
                    :isSorting="true"
                    :notEmpty="true"
                    name="is_sorting"
                    :needTransation="true"
                    :surface="surface"
                    :surfaceVariant="surfaceVariant"
                    @input="onInput()"
                    @select="onSort()" />
            </div>

            <div class="advanced-filter__payload" v-if="!hasOneFllterMode && !hasHiddenChips">
                <div class="advanced-filter__clears" v-if="hasFilterData">
                    <div class="_inline-block" v-for="(item, name) in chipsData" :key="name">
                        <template v-if="item && item.length && $fn.find(items, ['key', name]).multiple">
                            <template v-for="(option, index) in item">
                                <span v-if="option" :key="index" class="advanced-filter__clear label label--filter druk-has-trail">
                                    <label class="advanced-filter__name">{{ option.title }}</label>
                                    <druk-icon
                                        class="label__trail"
                                        :name="'xmark'"
                                        :size="'xs'"
                                        @click="onClearMultipleOption(index, name)" />
                                </span>
                            </template>
                        </template>

                        <span
                            class="advanced-filter__clear label label--filter druk-has-trail"
                            v-else-if="
                                item && name !== GROUP_KEY && !(Object.keys(item).includes('from') && Object.keys(item).includes('to'))
                            ">
                            <label class="advanced-filter__name">{{
                                item[$fn.find(items, ['key', name]).option_label] || item.title || item
                            }}</label>

                            <druk-icon class="label__trail" :name="'xmark'" :size="'xs'" @click="onClearOption(item, name)" />
                        </span>

                        <span
                            class="advanced-filter__clear label label--filter druk-has-trail"
                            v-else-if="
                                item &&
                                name !== GROUP_KEY &&
                                Object.keys(item).includes('from') &&
                                Object.keys(item).includes('to') &&
                                item.from &&
                                item.to
                            ">
                            <label class="advanced-filter__name">{{ item.from + ' ― ' + item.to }}</label>

                            <druk-icon class="label__trail" :name="'xmark'" :size="'xs'" @click="onClearOption(item, name)" />
                        </span>
                    </div>

                    <druk-button :type="'text'" :icon="'eraser'" :label="$t('filter.clear_all')" @click="onClearAll()" />
                </div>
            </div>
        </div>

        <div class="advanced-filter__loader" v-else-if="!hasOneFllterMode">
            <druk-loader :size="'m'" />
            <span>{{ $t('adv.filter') }}</span>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex';

    export default {
        name: 'advanced-filter',

        props: {
            items: {
                type: Array,
                default: () => {
                    return [];
                },
            },
            activeSort: String,
            extraSorting: {
                type: Array,
                default: () => {
                    return [];
                },
            },
            hasOpenedFilter: Boolean,
            hasFilterLoader: Boolean,
            withoutSorting: Boolean,
            extraLoader: Boolean,
            hasOneFllterMode: Boolean,
            hasHiddenChips: Boolean,

            //
            surface: {
                type: String,
                default: 'filter-bright',
            },
            surfaceVariant: {
                type: String,
                default: 'filter-pale',
            },
        },

        data() {
            return {
                GROUP_KEY: 'group',
                SORT_KEY: 'created_at',

                filterData: {},
                searchOptions: {},
                formattedSorting: {},
            };
        },

        watch: {
            filterData() {
                this.buildFilterData();
            },

            items() {
                this.buildFilterData();
            },

            hasOpenedFilter() {
                this.$bus.$emit('set-table-scroll', 0);
                this.$bus.$emit('set-fixed-drop-position', 0);
            },
        },

        computed: {
            ...mapState({
                DEFAULT_SORTED_AREA_OPTIONS: (state) => state.constant.DEFAULT_SORTED_AREA_OPTIONS,
            }),

            currentSortingOptions() {
                return this.extraSorting.concat(this.DEFAULT_SORTED_AREA_OPTIONS);
            },

            sort: {
                get() {
                    return this.currentSortingOptions.find(
                        (item) => item.value === `${this.formattedSorting.sort}_${this.formattedSorting.direction}`,
                    );
                },

                set(obj) {
                    this.formattedSorting = this.onFormatSort(obj.value);
                },
            },

            hasFilterData() {
                let bool = false;

                for (const [key, item] of Object.entries(this.chipsData)) {
                    if (
                        item !== undefined &&
                        item &&
                        key !== this.GROUP_KEY &&
                        !(Object.keys(item).includes('from') && Object.keys(item).includes('to'))
                    )
                        bool = true;
                    if (
                        item !== undefined &&
                        item &&
                        key !== this.GROUP_KEY &&
                        Object.keys(item).includes('from') &&
                        Object.keys(item).includes('to') &&
                        item.from &&
                        item.to
                    )
                        bool = true;
                }

                return bool;
            },

            chipsData() {
                return Object.fromEntries(
                    Object.entries(this.filterData).filter(
                        ([key, value]) => !this.items.find((item) => item.key === key).is_protected,
                    ),
                );
            },

            formattedCommonData() {
                let obj = {};

                for (const [key, item] of Object.entries(this.filterData)) {
                    let option_id = this.items.find((filter) => filter.key === key)?.option_id;

                    if (
                        item !== undefined &&
                        !(item && Object.keys(item).includes('from') && Object.keys(item).includes('to')) &&
                        !key.toString().includes('sorting')
                    ) {
                        if (item) {
                            obj[key] =
                                $fn.has(item, 'id') && !option_id
                                    ? item.id
                                    : $fn.has(item, 'value') && !option_id
                                      ? !item
                                          ? parseInt(item.value)
                                          : item.value
                                      : item[option_id] !== null && item[option_id] !== undefined
                                        ? item[option_id]
                                        : item;
                        } else {
                            obj[key] = null;
                        }
                    }

                    if (item && typeof item === 'object' && $fn.find(this.items, ['key', key]).multiple) {
                        obj[key] = '';

                        for (const [itemKey, element] of Object.entries(item)) {
                            obj[key] += (element[option_id] || element.value || element.id) + ',';
                        }

                        obj[key] = obj[key].length ? obj[key].slice(0, obj[key].length - 1) : null;
                    }
                }

                return obj;
            },

            formattedDate() {
                let obj = {};

                for (const [key, item] of Object.entries(this.filterData)) {
                    if (item !== undefined && item && Object.keys(item).includes('from') && Object.keys(item).includes('to')) {
                        if (item !== null) {
                            obj[`${key}[from]`] = item.from;
                            obj[`${key}[to]`] = item.to;
                        } else {
                            obj[`${key}[from]`] = null;
                            obj[`${key}[to]`] = null;
                        }
                    }
                }

                return obj;
            },
        },

        created() {
            this.buildFilterData();
            this.setCommonSearchOptions();
        },

        methods: {
            buildFilterData() {
                this.items.forEach((item) => {
                    this.$set(this.filterData, item.key, item.value);
                });

                if (!this.hasOneFllterMode && !this.withoutSorting) this.formattedSorting = this.onFormatSort(this.activeSort);
            },

            setCommonSearchOptions() {
                this.items.forEach((item) => {
                    if (item.type === 'form-search-async') this.$set(this.searchOptions, item.key, []);
                });
            },

            onInput() {
                this.clearSearchOptions();

                this.$emit(
                    'onChange',
                    $fn.assign(
                        this.formattedCommonData,
                        this.formattedDate,
                        !this.withoutSorting && !this.hasOneFllterMode ? this.formattedSorting : {},
                    ),
                );
            },

            onSearch(value) {
                let filteredList = $fn.map(value.list, (el) => {
                    let option = value.customOption?.length ? '' : el[value.option];

                    if (value.customOption?.length)
                        value.customOption.forEach((optionPiece) => {
                            option += el[optionPiece] ? el[optionPiece] + ' ' : '';
                        });

                    return $fn.assign(el, {
                        title: option,
                    });
                });

                this.searchOptions[value.filter_key] = filteredList;
            },

            onFormatSort(item) {
                return {
                    sort: [...item.split('_')].shift(),
                    direction: [...item.split('_')].pop(),
                };
            },

            clearSearchOptions() {
                for (const [key, item] of $fn.entries(this.searchOptions)) {
                    this.searchOptions[key] = [];
                }
            },

            onClearOption(item, name) {
                this.filterData[name] =
                    Object.keys(item).includes('from') && Object.keys(item).includes('to') ? { from: null, to: null } : null;

                this.onInput();
            },

            onClearMultipleOption(index, name) {
                this.filterData[name].splice(index, 1);
                this.onInput();
            },

            onClearAll() {
                for (const [key, value] of Object.entries(this.filterData)) {
                    this.filterData[key] &&
                    typeof this.filterData[key] === 'object' &&
                    Object.keys(this.filterData[key]).includes('from') &&
                    Object.keys(this.filterData[key]).includes('to')
                        ? (this.filterData[key] = { from: null, to: null })
                        : key !== this.GROUP_KEY && key !== this.SORT_KEY
                          ? (this.filterData[key] = null)
                          : (this.filterData[key] = value);
                }

                this.onInput();
            },

            onSort() {
                this.$emit('onSort');
            },
        },
    };
</script>

<style lang="scss" scoped>
    .advanced-filter {
        width: 100%;
        padding: 30px 20px 10px;
        border-radius: 12px;
        margin-bottom: 20px;
        &--closed {
            padding: 20px 20px 10px;
            .advanced-filter__items {
                display: none;
            }
        }
        &--hidden {
            display: none;
        }
        &.has-loading {
            padding: 20px 20px;
        }
        &.has-solo-mode {
            padding: 0px;
            &::v-deep {
                .selector__main {
                }
                .selector__area {
                    margin: 0px;
                    padding: 2px 20px;
                    border-radius: 4px;
                }
                .selector__nav,
                .selector__clear {
                    &::before {
                        content: '';
                        position: absolute;
                        width: 42px;
                        height: 43px;
                        top: -1px;
                        left: -1px;
                        border-radius: 4px;
                    }
                }
            }
            .advanced-filter__items {
            }
            .advanced-filter__item {
                margin: 0px;
                width: 100%;
            }
        }
        &__toggle-button {
            display: none;
            @media (max-width: $druk-breakpoints-md) {
                display: block;
                margin-bottom: 20px;
            }
            svg {
                margin-right: 5px;
            }
        }
        &__items {
            display: flex;
            align-items: center;
            justify-content: left;
            flex-wrap: wrap;
            &.has-payload {
                border-bottom: 1px solid var(--druk-outline-variant);
                margin-bottom: 20px;
            }
            .multiselect {
                @media (max-width: $druk-breakpoints-md) {
                    width: 100% !important;
                    min-width: initial !important;
                }
            }
        }
        &__item {
            position: relative;
            margin: 0px 20px 20px 0px;
            min-width: 220px;
            @media (max-width: $druk-breakpoints-md) {
                width: 100% !important;
                margin-right: 0px;
            }
            &.has-big {
                min-width: 370px;
            }
        }
        &__clears {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 10px;
        }
        &__clear {
            padding: 4px 8px 4px 12px;
            margin-right: 8px;
        }
        &__name {
            margin-right: 4px;
        }
        &__loader {
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.1px;
            font-weight: 500;
            .druk-c-icon {
                margin-right: 8px;
            }
        }
    }
</style>
