export default {
    namespaced: true,
    state: {
        LAYOUT_ACCOUNTS: 'accounts-index',
        LAYOUT_TRANSACTIONS: 'transactions-index',

        layout: 'accounts-index',
    },

    mutations: {
        SET_LAYOUT(state, layout) {
            state.layout = layout;
        },
    },
};
