import common from './common.js';
import typographies from './modules/typographies.js';
import webControll from './modules/webControll.js';
import library from './modules/library.js';
import technicalData from './modules/technicalData.js';
import stock from './modules/stock.js';
import products from './modules/products.js';
import others from './modules/others.js';
import createOrder from './modules/createOrder.js';
import tasks from './modules/tasks.js';
import knowledge from './modules/knowledge';

export default {
    ...common,
    ...typographies,
    ...webControll,
    ...library,
    ...technicalData,
    ...stock,
    ...products,
    ...others,
    ...createOrder,
    ...tasks,
    ...knowledge,
};
