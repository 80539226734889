import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        PRODUCTS_DESTINATION_OPTIONS: [
            {
                id: 0,
                key: 'categories.destination.import_to_created',
                title: null,
            },
            {
                id: 1,
                key: 'categories.destination.import_to_new',
                title: null,
            },
            {
                id: 2,
                key: 'common.delete',
                title: null,
            },
        ],

        ID_PRODUCTS_DESTINATION_CREATED: 0,
        ID_PRODUCTS_DESTINATION_NEW: 1,
        ID_PRODUCTS_DESTINATION_DELETE: 2,

        CATEGORIES_DESTINATION: [
            {
                id: 0,
                key: 'categories.destination.import_to_same',
                title: null,
            },
            {
                id: 1,
                key: 'categories.destination.import_to_another',
                title: null,
            },
            {
                id: 2,
                key: 'categories.destination.save_separate',
                title: null,
            },
            {
                id: 3,
                key: 'common.delete',
                title: null,
            },
        ],

        ID_CATEGORIES_DESTINATION_SAME: 0,
        ID_CATEGORIES_DESTINATION_ANOTHER: 1,
        ID_CATEGORIES_DESTINATION_SEPARATE: 2,
        ID_CATEGORIES_DESTINATION_DELETE: 3,

        config: {
            value: null,
            data: null,
        },
    },

    getters: {
        limitedCategoriesDestinations(state) {
            return state.CATEGORIES_DESTINATION.filter((item) => item.id !== state.ID_CATEGORIES_DESTINATION_SAME);
        },
    },

    actions: {},

    mutations: {
        SET_VALUE(state, value) {
            state.config.value = value;
        },

        SET_DATA(state, value) {
            state.config.data = value;
        },

        RESET_CONFIG(state) {
            state.config.value = null;
            state.config.data = null;
        },
    },
};
