export default {
    //AVAILABILITY--------------/
    'availability.title': 'Наличие (остатки)',
    'availability.booking': 'Список бронированых материалов',
    'availability.solid': 'Списание материала',
    'availability.save': 'Приход материала',
    'availability.table.name': 'Материал / Характеристикa',
    'availability.table.group': 'Группа материалов',
    'availability.table.count': 'Количество',
    'availability.table.booking': 'Бронь',
    'availability.table.cost': 'Себестоимость',
    'availability.table.lust': 'Обязат. остаток ',
    'availability.table.order': 'Заказ',
    'availability.table.product': 'Продукт',
    'availability.table.task': 'Задача',
    'availability.table.date': 'Дата окончания',
    'availability.material': 'Материал',

    //BOOKING-------------------------------------/
    'booking.title': 'Список бронированых материалов',
    'booking.bread.avail': 'Склад/ Наличие',
    'booking.bread.book': 'Бронированые материалы',
    'inventory.title': 'Инвентаризация',

    //LOG------------------------------------/
    'log.title': 'Приход и расход материалов',
    'log.delete': 'Списание материала',
    'log.add': 'Приход материала',
    'log.fl.type': 'Тип операции',
    'log.table.material': 'Материал и характеристики',
    'log.table.amount': 'Кол-во',
    'log.table.unit': 'Себестоимость',
    'log.table.price': 'Сумма',
    'log.table.status': 'Статус оплаты',
    'log.table.supplier': 'Поставщик',
    'log.table.employee': 'Исполнитель',
    'log.table.score': 'Счет',
    'log.table.price.md': 'Сумма оплаты',
    '': '',
    '': '',

    //MOVEMENT------------------------------/
    'movem.title': 'Движение материала',
    'movem.detail': 'Детали материала',
    'movem.msg.prod': 'Себестоимость товара закупки',
    'movem.msg.stock': 'Себестоимость товара на складе до закупки',
    'movem.title.modal': 'Новое списание материала',
    'movem.form.material': 'Материал',
    'movem.form.material.view': 'Вид материала',
    'movem.form.amount': 'Впишите количество',
    'movem.sum': 'Сума списания',
    'movem.self': 'Себестоимость за',
    'movem.delete': 'Списать',
    'movem.count': 'Количество упаковок',
    'movem.stock': 'Сумма за упаковку',
    'movem.item.info.full': 'Полная сума за материал',
    'movem.info.part1': 'Количество упаковок * Кол-во',
    'movem.info.part2': 'в упаковке) / Сумма за упаковку',
    'movem.price.info': 'Себестоимость будет изменена автоматически',
    'movem.price.html':
        'Средневзвешенная цена имеющегося и нового. ФОРМУЛА: (текущее количество * текущая стоимость + добавленное количество * стоимость в приходе) / общее количество) <br> При условии что предыдущее количество "<= 0", ставим цену с приходу.',
    'movem.item.in.stock': 'в упаковке',
    'movem.modal.title': 'Поступление материала',
    'movem.form.data': 'Дата поступления',
    'movem.modal.material.create': 'Сначала добавте материал!',
    'movem.modal.material.add': 'Добавить еще материал',
    '': '',
    '': '',
    '': '',

    //SUPPLIER-----------------------------/
    'supplier.title': 'Наличие (остатки)',
    'supplier.btn.add': 'Добавить поставщика',
    'supplier.table.receipts.count': 'Кол-во поставок',
    'supplier.table.debt': 'Сумма поставок',
    'supplier.table.total': 'Сумма долга',
    'supplier.table.contact': 'Контактная информация',
    'supplier.title.sm': 'Новый поставщик',
    'supply.title': 'Поставки',
    'supply.material': 'Материалы',
    'supply.total': 'Итого',
    'supply.pay': 'Оплатить',
    'supply.pack': 'упаковоки',
    'supply.material.singl': 'Материал',
    '': '',
};
