import Vue from 'vue';

const today = new Date();

const dateParser = (date) => {
    return date.toLocaleDateString('en-GB').split('/').reverse().join('-');
};

const PERIOD_FILTER_OPTIONS = [
    {
        key: 'dashboard.period_filter.today',
        from: dateParser(today),
        to: dateParser(today),
    },
    {
        key: 'dashboard.period_filter.last_week',
        from: dateParser(new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000)),
        to: dateParser(today),
    },
    {
        key: 'dashboard.period_filter.last_month',
        from: dateParser(new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000)),
        to: dateParser(today),
    },
    {
        key: 'dashboard.period_filter.last_year',
        from: dateParser(new Date(today.getTime() - 365 * 24 * 60 * 60 * 1000)),
        to: dateParser(today),
    },
];

export default {
    namespaced: true,

    state: {
        PERIOD_FILTER_OPTIONS: PERIOD_FILTER_OPTIONS,

        clientsData: {},
        ordersData: {},
        filteredOrdersData: {},
        filteredProductsData: {},

        currentOn: null,

        listFilter: {
            'created_at[from]': PERIOD_FILTER_OPTIONS[1].from,
            'created_at[to]': PERIOD_FILTER_OPTIONS[1].to,
        },

        loadingGetData: false,
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },

        isFilterNotEmpty(state, getters, rootState) {
            return !!Object.keys(getters.listActiveFilter).filter(
                (key) => !rootState.constant.INACTIVE_FILTERING_KEYS.concat(state.INACTIVE_FILTERING_KEYS).includes(key),
            ).length;
        },
    },

    actions: {
        GET_DATA: async ({ state, getters, rootGetters, commit }, filters = {}) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetData',
                    status: true,
                });

                let path = `api/typographies/${rootGetters.currentTypography.id}/dashboard`;

                let resp = await $axios.get(path, {
                    params: Object.keys(filters).length ? filters : getters.listActiveFilter,
                });

                commit('SET_CURRENT_ON');
                commit('SET_CLIENTS_DATA', resp.common.clients);
                commit('SET_ORDERS_DATA', resp.common.orders);
                commit('SET_FILTERED_ORDERS_DATA', resp.filtered.orders);
                commit('SET_FILTERED_PRODUCTS_DATA', resp.filtered.products);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetData',
                    status: false,
                });
            }
        },
    },

    mutations: {
        SET_CURRENT_ON(state) {
            Vue.set(state, 'currentOn', new Date().toLocaleString('en-GB'));
        },

        SET_CLIENTS_DATA(state, data) {
            Vue.set(state, 'clientsData', data);
        },

        SET_ORDERS_DATA(state, data) {
            Vue.set(state, 'ordersData', data);
        },

        SET_FILTERED_ORDERS_DATA(state, data) {
            Vue.set(state, 'filteredOrdersData', data);
        },

        SET_FILTERED_PRODUCTS_DATA(state, data) {
            Vue.set(state, 'filteredProductsData', data);
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            Vue.set(state, value_key, status);
        },

        UPDATE_LIST_FILTER(state, newFilterlist) {
            Object.entries(newFilterlist).forEach(([key, value]) => Vue.set(state.listFilter, key, value));
            Vue.set(state.listFilter, 'page', newFilterlist.page || 1);
        },

        RESET_STORE(state) {
            Vue.set(state, 'listFilter', {
                'created_at[from]': PERIOD_FILTER_OPTIONS[1].from,
                'created_at[to]': PERIOD_FILTER_OPTIONS[1].to,
            });
        },
    },
};
