<template>
    <div class="section">
        <div class="container">
            <template v-if="hasAccessToDashboard">
                <!-- @Explanation: legacy -->
                <typographies-section v-if="hasAccessToERPDashboard" />

                <!-- @Explanation: New -->
                <typography-dashboard v-else-if="hasAccessToTypographyDashboard" />
            </template>

            <template v-else>
                <div class="section__wrap">
                    <div class="section__cnt">
                        <div class="druk-l-title druk-u-text-align-center">
                            <div class="druk-l-title__text">DRUK Engine</div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    import TypographyDashboard from './TypographyDashboard';

    // @Explanation: legacy
    import TypographiesSection from './components/legacy/TypographiesSection';

    export default {
        name: 'dashboard-index',

        components: {
            TypographyDashboard,

            // @Explanation: legacy
            TypographiesSection,
        },

        created() {
            this.onCheckDopplerReload();
            this.$bus.$on('on-check-doppler-auth', () => this.onCheckDopplerReload());
        },

        beforeDestroy() {
            this.$bus.$off('on-check-doppler-auth');
        },

        computed: {
            ...mapState({
                PERM_MODULE_DASHBOARD: (state) => state.permissions.PERM_MODULE_DASHBOARD,
                PERM_MODULE_TYPOGRAPHIES: (state) => state.permissions.PERM_MODULE_TYPOGRAPHIES,
                PERM_ACTION_VIEW: (state) => state.permissions.PERM_ACTION_VIEW,
            }),

            ...mapGetters({
                hasERPAccess: 'hasERPAccess',
                hasTypographyAccess: 'hasTypographyAccess',
            }),

            hasAccessToDashboard() {
                return this.hasAccessToERPDashboard || this.hasAccessToTypographyDashboard;
            },

            hasAccessToERPDashboard() {
                return this.hasERPAccess && $fn.hasAccess(this.PERM_MODULE_TYPOGRAPHIES, this.PERM_ACTION_VIEW);
            },

            hasAccessToTypographyDashboard() {
                return this.hasTypographyAccess && $fn.hasAccess(this.PERM_MODULE_DASHBOARD, this.PERM_ACTION_VIEW);
            },
        },

        methods: {
            onCheckDopplerReload() {
                if (this.$route.query.with_doppler_reload) {
                    this.$router.replace({ name: this.$route.name, query: { doppler_redirect: this.$route.query.doppler_redirect } });

                    window.location.reload();
                    return;
                }

                if (!!this.$route.query.doppler_redirect) this.$router.push({ name: this.$route.query.doppler_redirect });
            },
        },
    };
</script>

<style scoped lang="scss"></style>
