<template>
    <div class="formula__context context">
        <div class="context__wrapper">
            <div class="context__menu" :class="{ [`druk-l-surface-${surface}`]: surface }">
                <div class="formula__variable context__button" @click="onClone">
                    <font-awesome-icon icon="fa-solid fa-copy" />
                </div>

                <div class="formula__variable context__button" @click="onDelete">
                    <font-awesome-icon icon="fa-solid fa-trash" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

    export default {
        name: 'calc-formula-context',

        props: {
            item: Object,
            index: [Number, String],

            surface: {
                type: String,
                default: 'tint-brighter',
            },
        },

        methods: {
            ...mapActions({
                CLONE_AREA_ITEM: 'calcModes/formula/CLONE_AREA_ITEM',
                REMOVE_AREA_ITEM: 'calcModes/formula/REMOVE_AREA_ITEM',
            }),
            ...mapMutations({
                RESET_CURSOR: 'calcModes/formula/RESET_CURSOR',
            }),

            onClone() {
                this.CLONE_AREA_ITEM(this.index);
                this.onAreaReset();
            },

            onDelete() {
                this.REMOVE_AREA_ITEM(this.index);
                this.onAreaReset();
            },

            onAreaReset() {
                this.RESET_CURSOR();
                this.$bus.$emit('on-show-empty-cursor');
                this.$bus.$emit('on-area-update');
            },
        },
    };
</script>

<style lang="scss" scoped>
    .context {
        position: relative;
        z-index: 3;
        &__wrapper {
            position: absolute;
            top: 50%;
            left: 0px;
            transform: translate(0px, -50%);
        }
        &__menu {
            padding: 12px 10px;
            border-radius: 4px;
            box-shadow: var(--druk-elevation-2);
        }
        &__button {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin: 0px 0px 8px 0px;
            width: 24px;
            height: 24px;
            &:last-child {
                margin-bottom: 0px;
            }
        }
    }
</style>
