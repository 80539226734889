<template>
    <div class="perm">
        <div class="perm__header">
            <div class="perm__title druk-l-title druk-is-subtitle">
                <div class="druk-l-title__text">{{ $t('roles.role_permissions') }}: {{ role.title }}</div>

                <div v-if="isEditMode && isOwnerRole && hasTypographyAccess" class="druk-l-title__support">
                    <druk-support :isWarning="true">{{ $t('employees.owner_role_edit_unavailable') }}</druk-support>
                </div>

                <div v-else-if="isEditMode && isCurrentUserRole" class="druk-l-title__support">
                    <druk-support :isWarning="true">{{ $t('empl.not.edit') }}</druk-support>
                </div>
            </div>
        </div>

        <div v-if="hasLoadedData && !loadingGetRole" class="perm__body">
            <div v-for="(group, index) in list" :key="index" class="perm__group pGroup">
                <div class="pGroup__title section__subtitle">
                    <div class="section__subtitle-text">{{ $t(group.translation_alias_group) }}</div>
                </div>

                <div class="pGroup__modules grid">
                    <div
                        v-for="(module, moduleIndex) in group.modules"
                        :key="moduleIndex"
                        class="grid__col grid__col--4 grid__col--lg-6 grid__col--md-12 _flex_grow_0">
                        <role-permissions-module
                            v-if="!!group"
                            :groupKey="group.key"
                            :module="module"
                            :isEditMode="isEditMode"
                            :isCurrentUserRole="isCurrentUserRole" />
                    </div>
                </div>
            </div>
        </div>

        <div v-else class="form__loader _mb-40">
            <druk-loader />
            <span>{{ $t('common.module_loading') }}</span>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    import RolePermissionsModule from './RolePermissionsModule';

    export default {
        name: 'role-permissions',

        components: {
            RolePermissionsModule,
        },

        props: {
            list: Array,
            role: Object,

            isEditMode: Boolean,
            isCurrentUserRole: Boolean,

            loadingGetRole: Boolean,
        },

        data() {
            return {
                hasLoadedData: false,
            };
        },

        created() {
            this.buildFormData();
            this.$bus.$on('buildPermissionData', (roleTemplate) => this.buildFormData(roleTemplate));
        },

        destroyed() {
            this.$bus.$off('buildPermissionData');
        },

        computed: {
            ...mapGetters({
                hasTypographyAccess: 'hasTypographyAccess',
            }),

            pattern() {
                return this.role.permissions;
            },

            isOwnerRole() {
                return this.role?.owner;
            },
        },

        methods: {
            ...mapMutations({
                SET_PERMISSIONS_DATA: 'permissions/SET_FORM_DATA',
                RESET_PERMISSIONS_DATA: 'permissions/RESET_FORM_DATA',
            }),

            buildFormData(roleTemplate) {
                this.RESET_PERMISSIONS_DATA();

                let data = Object.create(null),
                    pattern = roleTemplate?.permissions || this.pattern;

                this.list.forEach((group) => {
                    let modules = Object.create(null);

                    group.modules.forEach((module) => {
                        let actions = Object.create(null);

                        module.actions.forEach((action) =>
                            this.$set(actions, [action.key], +!!pattern?.[module.key]?.includes(action.key) || module.is_static || 0),
                        );

                        this.$set(modules, [module.key], actions);
                    });

                    this.$set(data, [group.key], modules);
                });

                this.SET_PERMISSIONS_DATA(data);

                this.hasLoadedData = true;
            },
        },
    };
</script>

<style lang="scss" scoped></style>
