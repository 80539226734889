export default {
    namespaced: true,

    state: {
        SHAPES_ICONS: {
            circle: 'fa-light fa-circle',
            rectangle: 'fa-light fa-sharp fa-square',
            rectangle_rounded: 'fa-light fa-square',
            free_shape: 'fa-light fa-star',
        },

        SHAPE_CIRCLE: 'circle',
        SHAPE_RECTANGLE: 'rectangle',
        SHAPE_RECTANGLE_ROUNDED: 'rectangle_rounded',
        SHAPE_FREE_SHAPE: 'free_shape',

        INDEX_CIRCLE: 0,
        INDEX_RECTANGLE: 1,
        INDEX_RECTANGLE_ROUNDED: 2,
        INDEX_FREE_SHAPE: 3,

        list: [],
        item: {},

        loadingGetList: false,
    },

    getters: {
        advancedList(state) {
            return state.list.map((item, index) => Object.assign({ ...item }, { index, icon: state.SHAPES_ICONS[item.alias] }));
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },
        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },
    },

    actions: {
        GET_LIST: async ({ rootState, rootGetters, state, getters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let path = `/api/configs/shapes`;

                let resp = await $axios.get(path);

                commit('SET_LIST', resp.shapes);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },
    },
};
