<template>
    <div class="header druk-l-surface-tint-mild" :class="{ 'has-hide': !hasActiveDrawer, 'druk-is-inverse': hasInverseDrawer }">
        <div class="header__head">
            <div class="header__logoWrap" :style="{ '--user-color': userColor, '--user-background-color': userBackgroundColor }">
                <a v-if="hasTypographyAccess" :href="`https://${currentTypography.domain}`" target="_blank" class="header__logo">
                    <span class="header__title">{{ currentTypography.title }}</span>

                    <druk-icon class="header__icon" :name="'arrow-up-right-from-square'" :color="userColor" />

                    <div class="header__letter">
                        {{ getFirstLetter(currentTypography.title) }}
                    </div>
                </a>

                <router-link v-else :to="{ name: 'dashboard' }" class="header__logo">
                    <div class="header__title">MIS DRUK</div>

                    <div class="header__letter">D</div>
                </router-link>
            </div>
        </div>

        <div class="header__main">
            <header-menu v-if="hasLoadedPermission"></header-menu>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    import HeaderMenu from './HeaderMenu';

    export default {
        name: 'header-index',

        components: {
            HeaderMenu,
        },

        data() {
            return {
                window: {
                    width: 0,
                },
            };
        },

        created() {
            this.handleResize();
        },

        computed: {
            ...mapState({
                DEFAULT_LANG: (state) => state.DEFAULT_LANG,
                MEDIUM_ADNIMATION_DURATION: (state) => state.constant.MEDIUM_ADNIMATION_DURATION,

                user: (state) => state.auth.user,

                hasInverseDrawer: (state) => state.hasInverseDrawer,
                hasAlternativeHeaderLogo: (state) => state.hasAlternativeHeaderLogo,
            }),

            ...mapGetters({
                currentTypography: 'currentTypography',

                hasERPAccess: 'hasERPAccess',
                hasTypographyAccess: 'hasTypographyAccess',
                hasLoadedPermission: 'hasLoadedPermission',
            }),

            hasActiveDrawer: {
                get() {
                    return this.$store.state.hasActiveDrawer;
                },
                set(status) {
                    this.SET_DRAWER_STATUS(status);

                    this.$bus.$emit('set-table-scroll', this.MEDIUM_ADNIMATION_DURATION);
                    this.$bus.$emit('set-fixed-drop-position', this.MEDIUM_ADNIMATION_DURATION);
                },
            },

            userColor() {
                if (this.hasAlternativeHeaderLogo)
                    return this.hasTypographyAccess ? this.$material.white : this.$material['on-primary'];

                return this.hasInverseDrawer ? this.$material['inverse-on-surface-variant'] : this.$material['on-surface-variant'];
            },

            userBackgroundColor() {
                if (this.hasAlternativeHeaderLogo)
                    return this.hasTypographyAccess ? this.currentTypography.color : this.$material.primary;

                return 'inherit';
            },
        },

        methods: {
            ...mapMutations({
                SET_DRAWER_STATUS: 'SET_DRAWER_STATUS',
            }),

            getFirstLetter(str) {
                return str.substr(0, 1);
            },

            handleResize() {
                this.window.width = window.innerWidth;
                this.window.width <= 768 ? (this.hasActiveDrawer = !this.hasActiveDrawer) : null;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .header {
        position: fixed;
        z-index: 10;
        left: 0px;
        top: 0px;
        width: 280px;
        padding: 0 8px;
        height: 100%;
        transition: all 0.3s;
        &.has-hide {
            width: 80px;
            .header__main {
                padding: 0;
            }
            .header__logo {
                width: 100%;
                padding: 0;
            }
            .header__title {
                pointer-events: none;
                opacity: 0;
                width: 0;
                max-height: 0;
                visibility: hidden;
                transition:
                    opacity 0s var(--druk-easing-emphasized-accelerate) var(--druk-duration-medium-2),
                    width 0s,
                    max-height 0s 0s;
            }
            .header__icon {
                display: none;
                pointer-events: none;
                opacity: 0;
                visibility: hidden;
            }
            .header__letter {
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 1;
                visibility: visible;
                @media (max-width: $druk-breakpoints-md) {
                    opacity: 0;
                }
            }
            .header__logoWrap {
                display: block;
            }
            .user__show {
                pointer-events: none;
                opacity: 0;
                visibility: hidden;
            }
            .user__name {
                pointer-events: none;
                opacity: 0;
                visibility: hidden;
            }
            .user__logo {
                width: 100%;
                img {
                    max-width: 100%;
                }
            }
            @media (max-width: $druk-breakpoints-md) {
                width: 0;
                padding: 0;
                .header__head {
                    padding: 0;
                    margin: 0;
                    width: 100%;
                }
                .header__main {
                    padding: 0;
                }
            }
        }
        &__head {
            width: calc(100% + 16px);
            margin: 0 -8px;
            transition: background-color 0.3s;
        }
        &__main {
            margin-top: 20px;
            position: relative;
            overflow: scroll;
            height: calc(100% - 80px);
            scrollbar-width: none;
            transition: background-color 0.3s;
            &::-webkit-scrollbar {
                width: 0px;
                background-color: transparent;
            }
        }
        &__logo {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            min-height: 60px;
            padding: 16px 24px;
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 1px;
            color: var(--user-color);
            line-height: 1.2em;
            svg {
                margin: 0px 0px 0px 8px;
            }
        }
        &__logoWrap {
            word-wrap: break-word;
            white-space: normal;
            font-size: 18px;
            letter-spacing: 1px;
            line-height: 1.2em;
            text-align: center;
            text-transform: uppercase;
            background-color: var(--user-background-color);
        }
        &__title {
            display: block;
            opacity: 1;
            width: auto;
            max-height: 100px;
            transition:
                opacity var(--druk-duration-short-4) var(--druk-easing-emphasized-decelerate) var(--druk-delay-extra-long),
                width 0s var(--druk-delay-long),
                max-height 0s var(--druk-delay-long);
        }
        &__letter {
            display: none;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            color: var(--user-color);
            opacity: 0;
            visibility: hidden;
            transition: opacity var(--druk-easing-emphasized-accelerate) ease-in-out;
        }
    }
</style>
