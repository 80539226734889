<template>
    <div class="selector selector--file" :class="{ 'has-full': hasFull, 'has-error': errors.has(name) }">
        <div class="selector__label" :class="{ [`druk-l-surface-${surface}`]: surface }" @click="onToggle">
            <span>
                <label>{{ label }}</label>
                <i v-if="hasRequired">*</i>
            </span>

            <druk-hint
                v-if="hint"
                :tooltip="{ text: hint.text || hint, from: 'top', maxWidth: hint.maxWidth, isNotCollapse: hint.isNotCollapse }"
                :icon="{ name: 'circle-info', size: 'xs', color: hasActive ? 'primary' : 'outline' }" />
        </div>

        <div class="selector__main" :class="{ [`druk-l-surface-${surface}`]: surface }">
            <input
                class="selector__area selector__area--file-picker"
                :class="{ 'druk-is-disabled': disabled, 'has-loading': loader }"
                type="file"
                ref="file"
                :id="name"
                :data-vv-name="name"
                :data-vv-as="label"
                v-validate="rules || ''"
                @change="processFile"
                :placeholder="placeholder" />

            <div class="selector__mask" :class="{ 'druk-is-placeholder': !file && !value }">
                <span>{{ file ? file.name : value || $t('file.choose') }}</span>
            </div>
        </div>

        <div v-if="errors.has(name)" class="selector__support">
            <druk-support :isError="true" :isTextField="true">{{ errorText || errors.first(name) }}</druk-support>
        </div>

        <div v-else-if="support" class="selector__support">
            <druk-support :isTextField="true">{{ support }}</druk-support>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'form-file',

        inject: ['$validator'],

        props: {
            value: {
                required: true,
            },

            name: {
                type: String,
                required: true,
            },

            hint: [String, Object],
            mask: String,
            placeholder: [String, Object],
            type: String,
            label: String,
            validatorLabel: String,
            rules: [String, Object],
            autocomplete: String,
            errorText: String,
            buttonIcon: String,
            defaultCount: [Number, String],
            maxLength: [Number, String],
            support: String,

            loader: Boolean,
            canSaveEmpty: Boolean,
            staticButton: Boolean,
            isFilterType: Boolean,
            hasShowLength: Boolean,
            disabled: Boolean,

            //
            surface: {
                type: String,
                default: 'tint-pale',
            },
        },

        data() {
            return {
                file: null,
            };
        },

        computed: {
            hasFull() {
                return this.file;
            },

            hasRequired() {
                if (this.rules && (typeof this.rules === 'string' ? this.rules.indexOf('required') !== -1 : this.rules.required))
                    return true;

                return false;
            },
        },

        methods: {
            onToggle() {
                this.$refs.file.focus();
            },

            processFile(event) {
                let files = event.target.files || event.dataTransfer.files;

                this.file = files.length ? files[0] : null;
                this.$emit('upload', this.file);
            },
        },
    };
</script>

<style lang="scss" scoped></style>
