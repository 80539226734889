import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        DEFAULT_EDITOR_TYPE: 'full',
        MATERIAL_EDITOR_TYPE: 'material',
        EQUIPMENT_EDITOR_TYPE: 'equipment',

        ADDITIONAL_VARIABLES: [
            {
                key: 'number',
                translation_alias: 'common.number_sm',
                input: null,
                is_number: true,
            },
            {
                key: '+',
                alias: 'plus',
                icon: 'fa-solid fa-plus',
            },
            {
                key: '-',
                alias: 'minus',
                icon: 'fa-solid fa-minus',
            },
            {
                key: '*',
                alias: 'multiplication',
                icon: 'fa-solid fa-xmark',
            },
            {
                key: '/',
                alias: 'division',
                icon: 'fa-solid fa-divide',
            },
            {
                key: '(',
                alias: 'opening-bracket',
                icon: 'fa-solid fa-bracket-round',
                is_opening_bracket: true,
            },
            {
                key: ')',
                alias: 'closing-bracket',
                icon: 'fa-solid fa-bracket-round-right',
                is_closing_bracket: true,
            },
        ],

        COMMON_AREA_ITEM: {
            index: null,
            value: null,
        },

        COMMON_CURSOR: {
            index: null,
            is_right: false,
        },

        DEFAULT_TIME_FORMULA: ['[count]', '*', '[manufacturing_time]', '+', '[makeready_time]'],
        DEFAULT_WEIGHT_FORMULA: ['[material_weight]', '*', '[material_amount]'],
        DEFAULT_SHEETS_MATERIAL_FORMULA: ['ceil', '(', '[count]', '*', '[elements_per_detail]', '/', '[details_per_sheet]', ')'],
        DEFAULT_ROLL_MATERIAL_FORMULA: [
            'ceil',
            '(',
            '[count]',
            '*',
            '(',
            '[detail_height]',
            '+',
            '[padding]',
            ')',
            '/',
            '(',
            'floor',
            '(',
            '[max_print_width]',
            '/',
            '(',
            '[detail_width]',
            '+',
            '[indent_left]',
            '+',
            '[indent_right]',
            ')',
            ')',
            ')',
            '/',
            '1000',
            ')',
        ],
        DEFAULT_SETUP_MATERIAL_FORMULA: ['0'],

        SYSTEM_VARIABLES_KEY: 'system',
        FUNCTIONS_VARIABLES_KEY: 'functions',
        OTHER_VARIABLES_KEY: 'other',

        FORMULA_VARIABLE_CLICK_PRICE: 'click_price',
        FORMULA_VARIABLE_MATERIAL_PRICE: 'material_price',

        BRACKETS_COLOR_MAP: ['azul', 'topaz', 'dusk', 'iris', 'dark-aqua', 'atlantis', 'candlelight'],

        HISTORY_LIMIT: 20,

        variables: [],

        area: [],
        areaStash: [],

        cursor: {},
        cursorStash: {},

        history: [],
        historyStash: [],

        version: -1,
        versionStash: -1,

        buffer: {
            full: [],
            material: [],
            equipment: [],
        },

        loadingGetVariables: false,
    },

    getters: {
        standardFormulas(state) {
            return [
                {
                    translation_alias: 'library.works.material_formulas.sheets_formula',
                    formula: state.DEFAULT_SHEETS_MATERIAL_FORMULA,
                },
                {
                    translation_alias: 'library.works.material_formulas.roll_formula',
                    formula: state.DEFAULT_ROLL_MATERIAL_FORMULA,
                },
            ];
        },

        formattedVariables(state) {
            let list = Object.create(null);
            Object.entries(state.variables).forEach(([groupKey, group]) =>
                Vue.set(
                    list,
                    groupKey,
                    Object.entries({ ...group })
                        .filter(
                            ([varKey, varValue]) =>
                                groupKey !== state.SYSTEM_VARIABLES_KEY ||
                                (groupKey === state.SYSTEM_VARIABLES_KEY &&
                                    !state.variables[state.OTHER_VARIABLES_KEY].find((item) => item.key === varValue.key)),
                        )
                        .map(([varKey, varValue]) => ({ ...varValue, groupKey })),
                ),
            );

            return list;
        },

        allVariables(state, getters) {
            return Object.values({ ...getters.formattedVariables })
                .flat()
                .concat(state.ADDITIONAL_VARIABLES);
        },

        materialVariables(state, getters) {
            return getters.formattedVariables.material?.filter((variable) => variable.key !== state.FORMULA_VARIABLE_MATERIAL_PRICE);
        },

        equipmentVariables(state, getters) {
            return getters.formattedVariables.equipment?.filter((variable) => variable.key !== state.FORMULA_VARIABLE_CLICK_PRICE);
        },

        parsedVariables(state, getters) {
            let list = Object.create(null);
            getters.allVariables
                .filter((variable) => !!variable.groupKey)
                .forEach(
                    (variable) =>
                        (list[variable.key] =
                            variable.groupKey === state.FUNCTIONS_VARIABLES_KEY ? variable.title : `[${variable.title}]`),
                );

            return list;
        },

        parsedArea(state) {
            return state.area
                .filter((item) => !!item.value)
                .map((item) =>
                    item.value.is_number
                        ? item.value.input.replace(',', '.')
                        : item.value.groupKey && item.value.groupKey !== state.FUNCTIONS_VARIABLES_KEY
                          ? `[${item.value.key}]`
                          : item.value.key,
                );
        },

        currentArea(state) {
            if (!state.history.length) return [];
            return JSON.parse(state.history[state.version]);
        },

        bracketsPairs(state) {
            let stack = [],
                brackets = state.area.filter((item) => item.value?.is_opening_bracket || item.value?.is_closing_bracket);

            brackets.forEach((bracket) => {
                if (bracket.value.is_opening_bracket) {
                    stack.push([bracket.index, null]);
                } else if (bracket.value.is_closing_bracket) {
                    let empty = [...stack.filter((item) => !item[1])].pop(),
                        index = stack.indexOf(empty);

                    index !== -1 ? (stack[index][1] = bracket.index) : stack.push([null, bracket.index]);
                }
            });

            return stack;
        },

        emptyIndex(state) {
            return state.area.find((item) => !item.value)?.index;
        },

        hasCursorInArea(state) {
            let last = [...state.area].pop();
            return !!(last && !last.value);
        },

        hasActiveCursor(state) {
            return state.cursor.index !== null;
        },

        hasValidBracketPairs(state, getters) {
            return !getters.bracketsPairs.filter((pair) => !!(pair[0] === null || pair[1] === null)).length;
        },

        isCloneAvailable(state) {
            return !!state.area.length;
        },
    },

    actions: {
        GET_VARIABLES: async ({ state, getters, rootGetters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    key: 'loadingGetVariables',
                    value: true,
                });

                let path = `/api/typographies/${rootGetters.currentTypography.id}/operations/variables`;

                let resp = await $axios.get(path);

                commit('SET_VARIABLES', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    key: 'loadingGetVariables',
                    value: false,
                });
            }
        },

        SET_AREA: ({ state, getters, commit, dispatch }, map) => {
            map.forEach((item, index) => {
                let value = !Number.isNaN(+item)
                    ? {
                          ...getters.allVariables.find((variable) => variable.is_number),
                          input: item,
                      }
                    : {
                          ...getters.allVariables.find((variable) => variable.key === item.replace(/\[|\]/g, '')),
                      };

                commit('SET_AREA_ITEM', { ...state.COMMON_AREA_ITEM, index, value });
            });

            commit('SET_AREA_ITEM', {
                ...state.COMMON_AREA_ITEM,
                index: map.length + 1,
            });

            dispatch('UPDATE_AREA');
        },

        VALIDATE_AREA: async ({ state, getters, rootGetters, commit, dispatch }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    key: 'loadingValidateArea',
                    value: true,
                });

                let path = `/api/typographies/${rootGetters.currentTypography.id}/formula/validate`;

                let resp = await $axios.post(path, {
                    formula: getters.parsedArea,
                });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    key: 'loadingValidateArea',
                    value: false,
                });
            }
        },

        PASTE_AREA: ({ state, getters, commit, dispatch }, key) => {
            state.area = [...state.buffer[key]];
            dispatch('UPDATE_AREA');
        },

        ADD_AREA_ITEM: ({ state, getters, commit, dispatch }, { index, value }) => {
            commit('SET_AREA_ITEM', { ...state.COMMON_AREA_ITEM, index, value });
            dispatch('UPDATE_AREA');
        },

        ADD_AREA_ITEMS: ({ state, getters, commit, dispatch }, { index, values }) => {
            values.forEach((value, valueIndex) =>
                commit('SET_AREA_ITEM', {
                    ...state.COMMON_AREA_ITEM,
                    index: index + valueIndex + +!!(valueIndex === values.length - 1),
                    value,
                }),
            );

            dispatch('UPDATE_AREA');
        },

        CLONE_AREA_ITEM: ({ state, getters, commit, dispatch }, index) => {
            commit('SET_AREA_ITEM', { ...state.area[index] });
            dispatch('UPDATE_AREA');
        },

        REMOVE_AREA_ITEM: ({ state, getters, commit, dispatch }, index) => {
            commit('DELETE_AREA_ITEM', index);
            dispatch('UPDATE_AREA');
        },

        CHANGE_EMPTY_CURSOR_POSITION: ({ state, getters, commit, dispatch }, { index, without_update }) => {
            let clearArea = [...state.area].filter((item) => !!item.value);

            state.area = clearArea
                .slice(0, index)
                .concat({ ...state.COMMON_AREA_ITEM, index })
                .concat(clearArea.slice(index));

            if (!without_update) dispatch('UPDATE_AREA');
        },

        UPDATE_AREA: ({ state, getters, commit }) => {
            commit('INDEX_AREA');
            commit('SET_HISTORY');
            commit('STASH_AREA');
        },

        DELETE_AREA: ({ state, getters, commit }) => {
            commit('RESET_AREA');
            commit('STASH_AREA');
        },
    },

    mutations: {
        SET_VARIABLES(state, list) {
            state.variables = list;
        },

        SET_AREA_ITEM(state, value) {
            state.area = state.area.slice(0, value.index).concat(value).concat(state.area.slice(value.index, state.area.length));
        },

        SET_AREA_CURSOR(state) {
            state.area.push({ ...state.COMMON_AREA_ITEM, index: state.area.length });
        },

        SET_HISTORY(state) {
            state.history.push(JSON.stringify([...state.area]));

            if (state.history.length > state.HISTORY_LIMIT) {
                state.history.shift();
                return;
            }

            state.version += 1;
            while (state.version !== state.history.length - 1) {
                state.history = state.history.slice(0, state.version).concat(state.history.slice(state.version + 1));
            }
        },

        SET_CURSOR(state, formData) {
            Vue.set(state, 'cursor', formData);
        },

        SET_VERSION(state, { value, is_update }) {
            if (is_update) state.history.push(JSON.stringify([...state.area]));

            Vue.set(state, 'version', value);
            if (!is_update) Vue.set(state, 'area', JSON.parse([...state.history][value]));
        },

        SET_LOADING_STATUS(state, { key, value }) {
            Vue.set(state, key, value);
        },

        CLONE_AREA(state, key) {
            Vue.set(state.buffer, key, [...state.area]);
        },

        INDEX_AREA(state) {
            state.area = state.area.map((item, index) => ({ ...item, index }));
        },

        DELETE_AREA_ITEM(state, index) {
            Vue.delete(state.area, index);
        },

        STASH_AREA(state) {
            state.areaStash = [...state.area];
            state.cursorStash = { ...state.cursor };
            state.historyStash = [...state.history];
            state.versionStash = state.version;
        },

        RESTORE_AREA(state) {
            state.area = [...state.areaStash];
            state.cursor = { ...state.cursorStash };
            state.history = [...state.historyStash];
            state.version = state.versionStash;
        },

        RESET_AREA(state) {
            if (state.area.length) state.areaStash = [...state.area];
            if (Object.keys(state.cursorStash).length) state.cursorStash = { ...state.cursor };
            if (state.history.length > 1) state.historyStash = [...state.history];
            if (state.version !== -1) state.versionStash = state.version;

            Vue.set(state, 'area', []);
            Vue.set(state, 'cursor', {});
            Vue.set(state, 'history', []);
            Vue.set(state, 'version', -1);
        },

        RESET_CURSOR(state) {
            Vue.set(state, 'cursor', { ...state.COMMON_CURSOR });
        },
    },
};
