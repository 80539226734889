export default {
    DoughutLabel: {
        id: 'doughnutlabel',

        defaults: {
            font: {
                family: 'Montserrat',
                size: 24,
                weight: 400,
            },
            color: '#3F3F3F',
            textAlign: 'center',
            textBaseline: 'middle',
            display: true,
        },

        afterDraw(chart, args, options) {
            if (!options.display) return;

            const { ctx, data } = chart;

            ctx.save();

            const value = [...data.datasets].shift().data.join('/'),
                xCoord = chart.getDatasetMeta(0).data[0].x,
                yCoord = chart.getDatasetMeta(0).data[0].y;

            ctx.font = `${options.font.weight} ${options.font.size}px ${options.font.family}`;
            ctx.fillStyle = options.color;
            ctx.textAlign = options.textAlign;
            ctx.textBaseline = options.textBaseline;
            ctx.fillText(value, xCoord, yCoord);
        },
    },
};
