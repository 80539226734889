import Vue from 'vue';

import doppler from './doppler';
import owners from './owners';

export default {
    namespaced: true,

    modules: {
        doppler,
        owners,
    },

    state: {
        COMMON_FORM_DATA: {
            name: null,
            last_name: null,
            birthday: { from: null, to: null },
            email: null,
            phone: null,
            status: null,
            password: null,
            typography: null,
            role: null,
            avatar: null,
            is_auto_assigned: false,
        },

        list: [],
        listPagination: {},

        statuses: [],

        item: {},

        formData: {},

        listFilter: {
            page: null,
            limit: 30,
            email: null,
            typography_id: null,
            role_id: null,
            status_id: null,
            permission: null,
            with_typography_employees: false,
            with_trashed: false,
            sort: 'new',
            direction: 'desc',
        },

        loadingGetList: false,
        loadingGetItem: false,
        loadingGetStatuses: false,
        loadingSaveItem: false,
        loadingRestoreItem: false,
        loadingDeleteItem: false,
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },

        isFilterNotEmpty(state, getters, rootState) {
            return !!Object.keys(getters.listActiveFilter).filter(
                (key) => !rootState.constant.INACTIVE_FILTERING_KEYS.concat(state.INACTIVE_FILTERING_KEYS).includes(key),
            ).length;
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },

        SET_ITEM(state, item) {
            state.item = item;
        },

        SET_STATUSES(state, list) {
            state.statuses = list;
        },

        SET_FORM_DATA(state, formData) {
            Vue.set(state, 'formData', Object.assign(state.formData, formData));
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        UPDATE_LIST_FILTER(state, newFilterlist) {
            Object.entries(newFilterlist).forEach(([key, value]) => Vue.set(state.listFilter, key, value));
            Vue.set(state.listFilter, 'page', newFilterlist.page || 1);
        },

        RESET_ITEM(state) {
            state.item = {};
        },

        RESET_FORM_DATA(state) {
            state.formData = { ...state.COMMON_FORM_DATA };
        },

        RESET_STORE(state) {
            state.listFilter = {
                page: null,
                limit: null,
                email: null,
                role_id: null,
                typography_id: null,
                status_id: null,
                permission: null,
                with_typography_employees: false,
                with_trashed: false,
                sort: 'new',
                direction: 'desc',
            };
        },
    },

    actions: {
        GET_LIST: async ({ state, getters, commit, rootGetters }, filters = {}) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.get('/api/employees', {
                    params: {
                        ...(Object.keys(filters).length ? filters : getters.listActiveFilter),
                        with_typography_employees: !!rootGetters.hasTypographyAccess,
                    },
                });

                commit('SET_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_ITEM: async ({ state, getters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                let resp = await $axios.get(`/api/employees/${id}`);

                commit('SET_ITEM', resp.single);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        GET_STATUSES: async ({ state, getters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetStatuses', status: true });

                let resp = await $axios.get(`/api/employees/statuses`);

                commit('SET_STATUSES', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetStatuses', status: false });
            }
        },

        SAVE_ITEM: async ({ state, commit, dispatch }, { formData, filters = {}, without_fetch }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let resp = await $axios.post('/api/employees', formData);

                if (without_fetch) return resp;

                formData.id && Object.keys(state.item).length ? dispatch('GET_ITEM', formData.id) : dispatch('GET_LIST', filters);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        RESTORE_ITEM: async ({ state, commit, dispatch }, { id, filters = {}, is_single, is_owner, without_fetch }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingRestoreItem', status: true });

                let path = `/api/employees/${id}/restore`;

                let resp = await $axios.post(path);

                if (without_fetch) return resp;

                if (!is_single) {
                    !is_owner ? dispatch('GET_LIST', filters) : dispatch('employees/owners/GET_LIST', filters, { root: true });
                } else dispatch('GET_ITEM', id);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingRestoreItem', status: false });
            }
        },

        DELETE_ITEM: async ({ commit, dispatch, getters, rootGetters }, { id, filters = {}, is_single, is_owner, force }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let resp = await $axios.delete(`/api/employees/${id}?force=${force}`);

                if (!resp.success) return resp;

                if (!is_single) {
                    !is_owner ? dispatch('GET_LIST', filters) : dispatch('employees/owners/GET_LIST', filters, { root: true });
                } else dispatch('GET_ITEM', id);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },
    },
};
