import Vue from 'vue';

import VeeValidate, { Validator } from 'vee-validate';

import PhoneNumber from 'awesome-phonenumber';

import attributesUk from 'vee-validate/dist/locale/uk';
import attributesEn from 'vee-validate/dist/locale/en';
import attributesPl from 'vee-validate/dist/locale/pl';
import attributesRu from 'vee-validate/dist/locale/ru';

import { parseRules, decimal, min_value, max_value, cyrillicA, latinA, georgianA, getMessage, parseMessage } from './methods';

import i18n from '../i18n';

Vue.use(VeeValidate);

Validator.extend('alias', {
    validate: (value) => {
        const validChars = /[0-9/A-Z/a-z/./_]/,
            invalidChars = /[^0-9/^A-Z/^a-z/^./^_]/;

        let containsValidChars = invalidChars.test(value);
        let containsInvalidChars = validChars.test(value);

        return !containsValidChars && containsInvalidChars ? true : false;
    },
});

Validator.extend('alphabets', {
    validate(value, args) {
        let rules = parseRules(args);

        let allowed = [cyrillicA(rules.cyrillic), latinA(rules.latin), georgianA(rules.georgian)].join(''),
            dashes = '–—-',
            apostrophes = "'ʼ‘`";

        let valid = new RegExp(String.raw`[${allowed}${apostrophes}${dashes}]`, 'g'),
            invalid = new RegExp(String.raw`[^${allowed}${apostrophes}${dashes}]`, 'g'),
            validDashes = /^[^-\–\—]*[-\–\—]?[^-\–\—]*$/,
            validApostrophes = /^[^'\ʼ\‘\`]*['\ʼ\‘\`]?[^'\ʼ\‘\`]*$/;

        let hasAnEdgeDash = dashes.split('').includes(value.charAt(0)) || dashes.split('').includes(value.charAt(value.length - 1)),
            hasAnEdgeApostroph =
                apostrophes.split('').includes(value.charAt(0)) || apostrophes.split('').includes(value.charAt(value.length - 1));

        let hasValidSymbols = validDashes.test(value) && validApostrophes.test(value) && !hasAnEdgeDash && !hasAnEdgeApostroph;

        return valid.test(value) && !invalid.test(value) && value.length > 1 && hasValidSymbols;
    },
});

Validator.extend('phone_number', {
    validate(value) {
        return new Promise((resolve) => {
            let phone = new PhoneNumber(value);
            resolve({ valid: phone.isValid() });
        });
    },
});

Validator.extend('int', {
    validate(value, args) {
        let rules = parseRules(args);

        let validRegex = /[0-9]/,
            invalidRegex = /[^0-9]/;

        return new Promise((resolve) => {
            let valid = validRegex.test(value) && !invalidRegex.test(value);

            let errors = {
                min_value: !min_value(value, rules.min_value),
                max_value: !max_value(value, rules.max_value),
            };

            let message = getMessage(errors, rules);

            return resolve({
                valid: valid && !Object.values(errors).filter((value) => value).length,
                data: valid ? message : {},
            });
        });
    },
});

Validator.extend('not_in_array', {
    validate(value, args) {
        return !args.includes(+value);
    },
});

Validator.extend('even_numbers', {
    validate(value) {
        return value % 2 === 0;
    },
});

Validator.extend('float', {
    validate(value, args) {
        let string = value.toString(),
            fractional = string.includes('.') ? string.split('.')[1] || '' : string.split(',')[1] || '',
            rules = parseRules(args);

        let regex = /^[+-]?\d*(\.|\,)?\d+$/;

        return new Promise((resolve) => {
            let valid = regex.test(value);

            let errors = {
                decimal: !decimal(fractional, rules.decimal),
                min_value: !min_value(value, rules.min_value),
                max_value: !max_value(value, rules.max_value),
            };

            let message = getMessage(errors, rules);

            return resolve({
                valid: valid && !Object.values(errors).filter((value) => value).length,
                data: valid ? message : {},
            });
        });
    },
});

Validator.extend('positive_float', {
    validate(value, args) {
        let string = value.toString(),
            fractional = string.includes('.') ? string.split('.')[1] || '' : string.split(',')[1] || '',
            rules = parseRules(args);

        let regex = /^\d*(\.|\,)?\d+?$/;

        return new Promise((resolve) => {
            let valid = regex.test(value);

            let errors = {
                decimal: !decimal(fractional, rules.decimal),
                min_value: !min_value(value, rules.min_value),
                max_value: !max_value(value, rules.max_value),
            };

            let message = getMessage(errors, rules);

            return resolve({
                valid: valid && !Object.values(errors).filter((value) => value).length,
                data: valid ? message : {},
            });
        });
    },
});

const messages = {
    alias: (field, args, data) => parseMessage(field, args, data, 'alias'),

    alphabets: (field, args, data) =>
        parseMessage(
            field,
            args.map((item) => i18n.t(`typographies.validator_alpabets.${item}_sm_genitive_fem`)).join(', '),
            data,
            'alphabets',
        ),

    decimal: (field, args, data) => parseMessage(field, args, data, 'decimal'),
    float: (field, args, data) => parseMessage(field, args, data, 'float'),
    even_numbers: (field, args, data) => parseMessage(field, args, data, 'even_numbers'),
    int: (field, args, data) => parseMessage(field, args, data, 'int'),
    max_value: (field, args, data) => parseMessage(field, args, data, 'max_value'),
    min_value: (field, args, data) => parseMessage(field, args, data, 'min_value'),
    not_in_array: (field, args, data) => parseMessage(field, args, data, 'not_in_array'),
    phone_number: (field, args, data) => parseMessage(field, args, data, 'phone_number'),
    positive_float: (field, args, data) => parseMessage(field, args, data, 'positive_float'),
};

const dictionary = {
    uk: { messages },
    en: { messages },
    pl: { messages },
    ru: { messages },
};

Validator.localize(dictionary);

Vue.mixin({
    methods: {
        setValidateLocalization(locale) {
            switch (locale) {
                case 'uk':
                    Validator.localize('uk', attributesUk);
                    break;
                case 'pl':
                    Validator.localize('pl', attributesPl);
                    break;
                case 'en':
                    Validator.localize('en', attributesEn);
                    break;
                case 'ru':
                    Validator.localize('ru', attributesRu);
                    break;
            }
        },
    },
});
