<template>
    <div class="druk-c-brd-crmbs">
        <ul class="druk-c-brd-crmbs__list">
            <li v-for="(item, index) in breadCrumbs" :key="index" class="druk-c-brd-crmbs__item druk-u-text-label-lg">
                <span v-if="index > 0" class="druk-c-brd-crmbs__divider">/</span>

                <druk-link v-if="item.path" :path="item.path" :label="item.title" />

                <template v-else>
                    <druk-loader v-if="item.hasLoader" :color="'on-surface-variant'" :size="'xs'" :isInline="true" />
                    <span v-else>{{ item.title || '...' }}</span>
                </template>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'bread-crumbs',

        props: {
            breadCrumbs: Array,
        },
    };
</script>

<style lang="scss" scoped>
    .druk-c-brd-crmbs {
        line-height: 1;
        color: var(--druk-on-surface-variant);
        &__list {
        }
        &__item {
            display: inline;
            word-break: break-all;
        }
        &__divider {
            margin: 0px 4px;
        }
    }
</style>
