<template>
    <component
        :is="!!path ? 'router-link' : 'button'"
        :to="path"
        class="druk-c-fab"
        :class="{ 'druk-is-full': isFull }"
        @click="$emit('click')">
        <div
            class="druk-c-fab__wrapper"
            :class="{
                [`druk-u-${base}`]: !!base,
                [`druk-l-${size}`]: !!size,
                'druk-is-expanded': !!label,
                'druk-is-inside-drawer': isInsideDrawer,
                'druk-is-inverse': isInverse,
            }">
            <div class="druk-c-fab__icon" :class="{ [`druk-l-${size}`]: !!size, 'druk-is-expanded': !!label }">
                <druk-icon :color="iconColorsMap[base]" :name="icon" :size="iconSizesMap[size]" />
            </div>

            <div
                class="druk-c-fab__label"
                :class="{ 'druk-u-text-label-lg': !isLarge, 'druk-u-text-title-m': isLarge, [`druk-u-${base}`]: !!base }">
                {{ label }}
            </div>

            <div
                class="druk-c-fab__state"
                :class="{
                    [`druk-u-${base}`]: !!base,
                    [`druk-l-${size}`]: !!size,
                    'druk-is-inside-drawer': isInsideDrawer,
                    'druk-is-inverse': isInverse,
                }"></div>
        </div>
    </component>
</template>

<script>
    export default {
        name: 'druk-fab',

        props: {
            base: {
                type: String,
                default: 'primary', // @Explanation: Possible values - surface, primary, secondary, tertiary
            },
            icon: {
                type: String,
                default: 'd',
            },
            size: {
                type: String,
                default: 'm', // @Explanation: Possible values - s, m, lg
            },

            path: Object,
            label: String,

            isFull: Boolean,
            isInsideDrawer: Boolean,
            isInverse: [Boolean, Number],
        },

        data() {
            return {
                SIZE_LARGE: 'lg',
            };
        },

        computed: {
            iconColorsMap() {
                return {
                    surface: this.isInverse ? 'inverse-primary' : 'primary',
                    primary: this.isInverse ? 'inverse-on-primary-container' : 'on-primary-container',
                    secondary: this.isInverse ? 'inverse-on-secondary-container' : 'on-secondary-container',
                    tertiary: this.isInverse ? 'inverse-on-tertiary-container' : 'on-tertiary-container',
                };
            },

            iconSizesMap() {
                return {
                    s: !!this.label ? 's' : 'm',
                    m: !!this.label ? 's' : 'm',
                    lg: 'lg',
                };
            },

            isLarge() {
                return this.size === this.SIZE_LARGE;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .druk-c-fab {
        cursor: pointer;
        outline: initial;
        border: initial;
        background-color: transparent;
        &.druk-is-full {
            width: 100%;
        }
        &__wrapper {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100px;
            box-shadow: var(--druk-elevation-3);
            transition-property: box-shadow;
            transition-duration: var(--druk-duration-short-4);
            transition-timing-function: var(--druk-easing-emphasized-accelerate);
            &:hover,
            &:active {
                transition-duration: var(--druk-duration-medium-2);
                transition-timing-function: var(--druk-easing-emphasized-decelerate);
            }
            &:hover {
                box-shadow: var(--druk-elevation-4);
            }
            &:active {
                box-shadow: var(--druk-elevation-3);
            }
            &.druk-u-surface {
                color: var(--druk-primary);
                background-color: var(--druk-tokens-fab-surface-background);
            }
            &.druk-u-primary {
                color: var(--druk-on-primary-container);
                background-color: var(--druk-primary-container);
            }
            &.druk-u-secondary {
                color: var(--druk-on-secondary-container);
                background-color: var(--druk-secondary-container);
            }
            &.druk-u-tertiary {
                color: var(--druk-on-tertiary-container);
                background-color: var(--druk-tertiary-container);
            }
            &.druk-u-surface.druk-is-inverse {
                color: var(--druk-inverse-primary);
                background-color: var(--druk-tokens-inverse-fab-surface-background);
            }
            &.druk-u-primary.druk-is-inverse {
                color: var(--druk-inverse-on-primary-container);
                background-color: var(--druk-inverse-primary-container);
            }
            &.druk-u-secondary.druk-is-inverse {
                color: var(--druk-inverse-on-secondary-container);
                background-color: var(--druk-inverse-secondary-container);
            }
            &.druk-u-tertiary.druk-is-inverse {
                color: var(--druk-inverse-on-tertiary-container);
                background-color: var(--druk-inverse-tertiary-container);
            }
            &.druk-l-s {
                padding: 8px;
            }
            &.druk-l-m {
                padding: 16px;
            }
            &.druk-l-lg {
                padding: 28px;
            }
            &.druk-l-s.druk-is-expanded {
                padding: 8px 12px 8px 8px;
            }
            &.druk-l-m.druk-is-expanded {
                padding: 16px 20px 16px 16px;
            }
            &.druk-l-lg.druk-is-expanded {
                padding: 28px 36px 28px 28px;
            }
            &.druk-is-inside-drawer {
                border-radius: 100px;
                box-shadow: initial;
            }
        }
        &__icon {
            pointer-events: none;
            position: relative;
            z-index: 1;
            &.druk-l-s.druk-is-expanded {
                margin-right: 8px;
            }
            &.druk-l-m.druk-is-expanded {
                margin-right: 12px;
            }
            &.druk-l-lg.druk-is-expanded {
                margin-right: 12px;
            }
        }
        &__label {
            pointer-events: none;
            position: relative;
            line-height: 24px;
            z-index: 1;
        }
        &__state {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 100px;
            background-color: transparent;
            transition-property: background-color;
            transition-duration: var(--druk-duration-short-4);
            transition-timing-function: var(--druk-easing-emphasized-accelerate);
            &:hover,
            &:active {
                transition-duration: var(--druk-duration-medium-2);
                transition-timing-function: var(--druk-easing-emphasized-decelerate);
            }
            &.druk-u-surface:hover {
                background-color: var(--druk-state-layers-primary-0-08);
            }
            &.druk-u-surface:active {
                background-color: var(--druk-state-layers-primary-0-12);
            }
            &.druk-u-primary:hover {
                background-color: var(--druk-state-layers-on-primary-container-0-08);
            }
            &.druk-u-primary:active {
                background-color: var(--druk-state-layers-on-primary-container-0-12);
            }
            &.druk-u-secondary:hover {
                background-color: var(--druk-state-layers-on-secondary-container-0-08);
            }
            &.druk-u-secondary:active {
                background-color: var(--druk-state-layers-on-secondary-container-0-12);
            }
            &.druk-u-tertiary:hover {
                background-color: var(--druk-state-layers-on-tertiary-container-0-08);
            }
            &.druk-u-tertiary:active {
                background-color: var(--druk-state-layers-on-tertiary-container-0-12);
            }
            &.druk-u-surface.druk-is-inverse:hover {
                background-color: var(--druk-state-layers-inverse-primary-0-08);
            }
            &.druk-u-surface.druk-is-inverse:active {
                background-color: var(--druk-state-layers-inverse-primary-0-12);
            }
            &.druk-u-primary.druk-is-inverse:hover {
                background-color: var(--druk-state-layers-inverse-on-primary-container-0-08);
            }
            &.druk-u-primary.druk-is-inverse:active {
                background-color: var(--druk-state-layers-inverse-on-primary-container-0-12);
            }
            &.druk-u-secondary.druk-is-inverse:hover {
                background-color: var(--druk-state-layers-inverse-on-secondary-container-0-08);
            }
            &.druk-u-secondary.druk-is-inverse:active {
                background-color: var(--druk-state-layers-inverse-on-secondary-container-0-12);
            }
            &.druk-u-tertiary.druk-is-inverse:hover {
                background-color: var(--druk-state-layers-inverse-on-tertiary-container-0-08);
            }
            &.druk-u-tertiary.druk-is-inverse:active {
                background-color: var(--druk-state-layers-inverse-on-tertiary-container-0-12);
            }
            &.druk-is-inside-drawer {
                border-radius: 100px;
            }
        }
    }
</style>
