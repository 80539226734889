<!-- @Explanation: Super secret functionality -->
<template>
    <form-switch v-model="isAlternative" :label="$t('common.color_theme.alternative_logo')" name="isAlternative" />
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    export default {
        name: 'typographies-alternative-header-logo',

        computed: {
            ...mapState({
                hasAlternativeHeaderLogo: (state) => state.hasAlternativeHeaderLogo,
            }),

            isAlternative: {
                get() {
                    return this.hasAlternativeHeaderLogo;
                },
                set(value) {
                    this.SET_PROJECT_ALTERNATIVE_LOGO(value);
                },
            },
        },

        methods: {
            ...mapMutations({
                SET_PROJECT_ALTERNATIVE_LOGO: 'SET_PROJECT_ALTERNATIVE_LOGO',
            }),
        },
    };
</script>

<style lang="scss" scoped></style>
