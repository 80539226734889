<template>
    <div class="form__footer form__footer--alt has-card druk-l-surface-tint-pale">
        <div class="form__options">
            <druk-dialog-option
                v-if="$fn.hasAccess(deletePermissionModule, deletePermissionAction) && isEditMode"
                class="druk-u-margin-right-8"
                :isUnavailable="hasButtonLoader"
                @success="$emit('onDelete', $event)">
                <druk-button
                    :type="'outlined'"
                    :icon="'trash'"
                    :label="$t('common.delete')"
                    :isHighlighted="true"
                    :isWaiting="hasButtonLoader" />
            </druk-dialog-option>

            <druk-button
                v-if="hasBackwardButton"
                :type="'outlined'"
                :icon="'backward'"
                :label="backWardLabel"
                :isWaiting="hasButtonLoader"
                @click="$emit('onCancel', $event)" />

            <druk-dialog-option v-else @success="$emit('onCancel', $event)">
                <druk-button :type="'outlined'" :icon="'xmark'" :label="$t('admin.btn.cancel')" />
            </druk-dialog-option>
        </div>

        <div class="form__options">
            <druk-button
                :class="{ '_order-1': hasLastStage, 'druk-u-margin-right-8': !hasLastStage }"
                :type="!hasLastStage ? 'tonal' : 'filled'"
                :icon="'floppy-disk'"
                :label="isEditMode ? $t('btn.save') : $t('btn.create')"
                :isWaiting="hasButtonLoader"
                @click="$emit('onSave', $event)" />

            <druk-button
                v-if="!hasFirstStage"
                class="druk-u-margin-right-8"
                :type="'tonal'"
                :icon="'backward'"
                :label="$t('common.go.back')"
                :isWaiting="hasButtonLoader"
                :isDisabled="hasBrokenWizard"
                @click="$emit('onBack', $event)" />

            <druk-button
                v-if="!hasLastStage"
                :icon="'forward'"
                :label="$t('admin.btn.continue')"
                :isWaiting="hasButtonLoader"
                :isDisabled="hasBrokenWizard"
                @click="$emit('onNext', $event)" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'wizard-footer',

        props: {
            deletePermissionModule: String,
            deletePermissionAction: String,
            backWardLabel: String,
            isEditMode: Boolean,
            hasFirstStage: Boolean,
            hasLastStage: Boolean,
            hasBrokenWizard: Boolean,
            hasBackwardButton: Boolean,
            loadingSaveItem: Boolean,
            loadingDeleteItem: Boolean,
        },

        computed: {
            hasButtonLoader() {
                return this.loadingSaveItem || this.loadingDeleteItem;
            },
        },
    };
</script>

<style lang="scss" scoped></style>
