<template>
    <div class="curve__canvas">
        <div class="curve__canvasTitle">
            {{ $t(`${translatesBase}.curve_title`) }}
        </div>

        <div class="canvas" id="canvasWrapper" :class="{ 'has-inaccessible': !isEditMode }">
            <figure
                id="canvas"
                @mousemove="onMove"
                @mouseover="hasShownPrompt = true"
                @mouseleave="hasShownPrompt = false"
                @mousedown="hasShownPrompt = false"
                @mouseup="hasShownPrompt = true"></figure>

            <div
                class="canvas__tooltip"
                v-if="hasShownPrompt && hasCursorInOverflow"
                :style="{ left: `${mouseCoords.x + curveGaps.left}px`, top: `${mouseCoords.y}px` }">
                <div class="canvas__tooltip-item">
                    <druk-support :hasNoIcon="true" :isInverse="true"
                        >{{ $t(`${translatesBase}.curve.for.click`) }}: <b>{{ clicks }}</b></druk-support
                    >
                </div>

                <div class="canvas__tooltip-item">
                    <druk-support :hasNoIcon="true" :isInverse="true"
                        >{{ $t('equep.price') }}: <b>{{ parseFloat(price).toFixed(4) }} {{ selectedCurrencyCode }}</b></druk-support
                    >
                </div>
            </div>

            <div class="canvas__warning" v-if="!isEditMode">
                <font-awesome-icon :icon="`fa-regular ${hasPreviewMode ? 'fa-triangle-exclamation' : 'fa-circle-info'}`" />
                <span>{{ hasPreviewMode ? $t('common.has_no_data') : $t('equep.curve.set') }}</span>
            </div>
        </div>

        <div v-if="hasBezierLegend" class="curve__canvasLegend" :style="{ 'max-width': `${canvas.cvs.width}px` }">
            <druk-support v-for="(range, index) in bezierLegend" :key="index" class="druk-u-margin-right-4" :hasNoIcon="true">
                <span
                    class="druk-l-shape-figure--rect druk-u-margin-right-4"
                    :style="{
                        border: `1px solid ${getRectBorder(bezierShades[index])}`,
                        'background-color': getRectBGColor(bezierShades[index]),
                    }"></span>
                <span>{{ range.start }}{{ index !== bezierLegend.length - 1 ? ` - ${range.end}` : '+' }}: </span>
                <b>{{ range.price }} {{ selectedCurrencyCode }}</b>
            </druk-support>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    import { Bezier } from 'bezier-js';

    import CanvasLayout from './canvas/CanvasLayout';
    import CanvasInteraction from './canvas/CanvasInteraction';

    export default {
        name: 'calc-modes-curve-canvas',

        props: {
            translatesBase: String,

            surface: {
                type: String,
                default: 'tint-pale',
            },

            hasPreviewMode: Boolean,
        },

        data() {
            return {
                // BASE_COLOR: this.$store.getters.currentTypography?.color || '#1EABF1',

                BORDER_GAP: {
                    bottom: 50,
                    left: 110,
                },

                CURVE_EXTREMES: {
                    x1: 280,
                    y1: 0,
                    x2: 780,
                    y2: 250,
                },

                CURVE_COORDS: {
                    x1: 0,
                    y1: 0,
                    x2: 500,
                    y2: 250,
                },

                curveGaps: {},

                clicks: null,
                mouseCoords: { x: null, y: null },

                curveConstructor: null,
                canvas: null,

                hasShownPrompt: false,
            };
        },

        created() {
            this.$bus.$on('on-curve-reset', () => this.onResetCurveData());

            this.$bus.$on('on-curve-update', () => {
                this.onCanvasReset();
            });

            this.$bus.$on('on-change-clicks', (clicks) => {
                this.clicks = clicks;
                this.onUpdate(clicks);
            });
        },

        mounted() {
            this.buildCanvas();
        },

        watch: {
            isDarkTheme() {
                this.drawCanvas();
            },
        },

        computed: {
            ...mapState({
                curve: (state) => state.calcModes.curve.item,
                curveStash: (state) => state.calcModes.curve.itemStash,
                curveResetData: (state) => state.calcModes.curve.resetData,
                curveRanges: (state) => state.calcModes.ranges.listStash,

                selectedCurrencyCode: (state) => state.selectedCurrencyCode,
            }),

            ...mapGetters({
                isDarkTheme: 'isDarkTheme',
            }),

            CURVE_COLORS_MAP() {
                return {
                    base: this.$material[`primary__${this.isDarkTheme ? 'blue-dark' : 'blue-light'}`],
                    border: this.$material[`outline__${this.isDarkTheme ? 'blue-dark' : 'blue-light'}`],
                    circle: this.$material[`on-primary__${this.isDarkTheme ? 'blue-dark' : 'blue-light'}`],
                    concealer: this.$material[`surface-${this.surface}__${this.isDarkTheme ? 'blue-dark' : 'blue-light'}`],
                    targents: this.$material[`outline-variant__${this.isDarkTheme ? 'blue-dark' : 'blue-light'}`],
                    text: this.$material[`on-surface__${this.isDarkTheme ? 'blue-dark' : 'blue-light'}`],
                };
            },

            bezierCoords() {
                return [
                    [this.CURVE_EXTREMES.x1, this.CURVE_EXTREMES.y1],
                    [this.curve.x1 + this.bezierHorizontalGap, this.curve.y1 + this.bezierVerticalGap],
                    [this.curve.x2 + this.bezierHorizontalGap, this.curve.y2 + this.bezierVerticalGap],
                    [this.CURVE_EXTREMES.x2, this.CURVE_EXTREMES.y2],
                ];
            },

            bezierHorizontalGap() {
                return this.CURVE_EXTREMES.x1 - this.CURVE_COORDS.x1;
            },

            bezierVerticalGap() {
                return this.CURVE_EXTREMES.y1 - this.CURVE_COORDS.y1;
            },

            bezierLabels() {
                return {
                    price: {
                        start: `${parseFloat(this.curve?.max_price || this.curve?.min_price || 0).toFixed(4)} ${this.selectedCurrencyCode}`,
                        end: this.curve?.min_price
                            ? `${parseFloat(this.curve.min_price).toFixed(4)} ${this.selectedCurrencyCode}`
                            : '...',
                    },

                    clicks: {
                        start: `1 ${this.$t(`${this.translatesBase}.curve.clicks_sm`)}`,
                        end: this.curve?.max_clicks
                            ? `${this.curve.max_clicks} ${this.$t(`${this.translatesBase}.curve.clicks_sm`)}`
                            : '...',
                    },
                };
            },

            bezierRanges() {
                return {
                    list: this.curveRanges.map((range, index) => ({
                        start: range.count,
                        end: this.curveRanges[index + 1]
                            ? +this.curveRanges[index + 1].count - 1
                            : this.curve.max_clicks > +range.count
                              ? this.curve.max_clicks
                              : +range.count + 1,
                        price: range.price,
                    })),
                    clicks: this.curve.max_clicks,
                    max_price: $fn.toFloat(this.curve.max_price),
                    min_price: $fn.toFloat(this.curve.min_price),
                };
            },

            price() {
                if (!this.bezierFixedCoords) return 0;

                let t = !this.hasExtremeClicks ? this.bezierT : +!!(parseInt(this.clicks) !== 1);

                let point = this.getPointByT(t);
                return this.normalizeY(point);
            },

            bezierT() {
                let clicks = parseInt(this.clicks) / (this.curve.max_clicks / this.bezierFixedCoords[3].x);

                let startT = 0,
                    endT = 1,
                    t = null,
                    delta = 1;

                for (let i = 0; i < 10 && delta > 0.01; i++) {
                    t = (startT + endT) / 2;

                    let point = this.getPointByT(t);
                    delta = Math.abs(clicks - point[0]);

                    if (point[0] <= clicks) startT = t;
                    if (point[0] > clicks) endT = t;
                }

                return t;
            },

            bezierFixedCoords() {
                return this.curveConstructor?.points.map((point) => ({
                    x: point.x - this.CURVE_EXTREMES.x1,
                    y: point.y - this.CURVE_EXTREMES.y1,
                }));
            },

            bezierLegend() {
                return [...this.bezierRanges.list];
            },

            bezierShades() {
                return this.canvas?.colorShades;
            },

            isEditMode() {
                return !!(this.curve.max_clicks && this.curve.max_price && this.curve.min_price);
            },

            hasExtremeClicks() {
                return (
                    parseInt(this.clicks) === this.curve.max_clicks ||
                    parseInt(this.clicks) === 1 ||
                    parseInt(this.clicks) >= this.curve.max_clicks
                );
            },

            hasRangesData() {
                return this.curveRanges.length > 1;
            },

            hasBezierLegend() {
                return !!(this.hasRangesData && this.bezierLegend.length > 1 && this.bezierShades);
            },

            hasCursorInOverflow() {
                return !(
                    this.mouseCoords.x < this.CURVE_EXTREMES.x1 ||
                    this.mouseCoords.x > this.CURVE_EXTREMES.x2 ||
                    this.mouseCoords.y < this.CURVE_EXTREMES.y1 ||
                    this.mouseCoords.y > this.CURVE_EXTREMES.y2
                );
            },
        },

        methods: {
            ...mapMutations({
                SET_CURVE_PROP: 'calcModes/curve/SET_ITEM_PROP',
                UPDATE_CURVE: 'calcModes/curve/UPDATE_ITEM',
                RESTORE_CURVE: 'calcModes/curve/RESTORE_ITEM',
            }),

            onResetCurveData() {
                this.RESTORE_CURVE();
                this.onUpdate(this.curve.max_clicks || 1);

                this.curveConstructor = new Bezier(this.bezierCoords.flat());
                this.setCanvasInteractions();

                this.onCanvasReset();
            },

            onCanvasReset() {
                this.canvas.reset();

                this.drawCanvas();
                this.updateCurveCtrls();
            },

            buildCanvas() {
                this.curveConstructor = new Bezier(this.bezierCoords.flat());

                this.canvas = new CanvasLayout({
                    id: 'canvas',
                    dimensions: { height: 300, width: 840 },
                    baseColor: this.CURVE_COLORS_MAP.base,
                    borderGap: this.BORDER_GAP,
                });

                this.setCanvasInteractions();
                this.setCurveGaps();
                this.drawCanvas();
            },

            setCanvasInteractions() {
                CanvasInteraction({
                    canvas: this.canvas.getCanvas(),
                    curve: this.curveConstructor,
                    borderGap: this.BORDER_GAP,
                }).onUpdate = (e) => this.onCanvasReset(e);
            },

            setCurveGaps() {
                let wrapperPosition = document.getElementById('canvasWrapper').getBoundingClientRect();

                this.curveGaps = {
                    right: (wrapperPosition.width - this.canvas.cvs.width) / 2,
                    left: (wrapperPosition.width - this.canvas.cvs.width) / 2,
                };
            },

            drawCanvas() {
                this.canvas.generateColorShades(this.CURVE_COLORS_MAP.base, this.curveRanges.length, this.isDarkTheme);
                this.canvas.drawSkeleton(
                    this.curveConstructor,
                    this.bezierLabels,
                    this.hasRangesData ? this.bezierRanges : null,
                    this.CURVE_COLORS_MAP,
                    this.hasPreviewMode,
                );
                this.canvas.drawCurve(this.curveConstructor, this.CURVE_COLORS_MAP.base);
            },

            updateCurveCtrls() {
                this.UPDATE_CURVE({
                    x1: this.curveConstructor?.points[1].x - this.bezierHorizontalGap || 0,
                    y1: this.curveConstructor?.points[1].y - this.bezierVerticalGap || 0,
                    x2: this.curveConstructor?.points[2].x - this.bezierHorizontalGap || 0,
                    y2: this.curveConstructor?.points[2].y - this.bezierVerticalGap || 0,
                });
            },

            onMove(event) {
                this.mouseCoords = this.getMouserCordByElement(event);
                if (!this.hasCursorInOverflow) return;

                this.clicks =
                    Math.round(((this.mouseCoords.x - this.CURVE_EXTREMES.x1) * this.curve.max_clicks) / this.CURVE_COORDS.x2) || 1;
                this.onUpdate(this.clicks);
            },

            getMouserCordByElement(event) {
                let canvasPosition = document.getElementById('canvas').getBoundingClientRect();

                return {
                    x: event.clientX - canvasPosition.left,
                    y: event.clientY - canvasPosition.top,
                };
            },

            onUpdate(clicks) {
                this.$emit('onUpdate', { clicks: clicks, price: this.price });
            },

            getPointByT(t) {
                let u = 1 - t;

                let fn = (cord0, cord1, cord2, cord3) => {
                    return (
                        Math.pow(u, 3) * cord0 +
                        3 * Math.pow(u, 2) * t * cord1 +
                        3 * u * Math.pow(t, 2) * cord2 +
                        Math.pow(t, 3) * cord3
                    );
                };

                return [
                    fn(
                        this.bezierFixedCoords[0].x,
                        this.bezierFixedCoords[1].x,
                        this.bezierFixedCoords[2].x,
                        this.bezierFixedCoords[3].x,
                    ),
                    fn(
                        this.bezierFixedCoords[0].y,
                        this.bezierFixedCoords[1].y,
                        this.bezierFixedCoords[2].y,
                        this.bezierFixedCoords[3].y,
                    ),
                ];
            },

            normalizeY(point) {
                return (
                    $fn.toFloat(this.curve.max_price) +
                    ($fn.toFloat(this.curve.min_price) - $fn.toFloat(this.curve.max_price)) * (+point[1] / +this.CURVE_COORDS.y2)
                );
            },

            getRectBorder(color) {
                return `rgba(${color.r}, ${color.g}, ${color.b}, 1)`;
            },

            getRectBGColor(color) {
                return `rgba(${color.r}, ${color.g}, ${color.b}, 0.6)`;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .curve {
        &__canvas {
            display: flex;
            flex-direction: column;
        }
        &__canvasTitle {
            text-align: center;
            margin-bottom: 16px;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.1px;
            font-weight: 500;
        }
        &__canvasLegend {
            margin: 0px auto;
        }
    }

    .canvas {
        position: relative;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        &__legend {
            margin-bottom: 20px;
        }
        &__tooltip {
            pointer-events: none;
            position: absolute;
            padding: 5px 12px;
            border-radius: 5px;
            color: var(--druk-inverse-on-surface);
            background-color: var(--druk-surface-inverse-surface);
            transform: translate(calc(-50%), calc(-100% - 6px));
            &::before {
                content: '';
                position: absolute;
                top: 100%;
                left: 50%;
                width: 0px;
                height: 0px;
                border-style: solid;
                border-width: 9px 6px 0px 6px;
                border-color: var(--druk-surface-inverse-surface) transparent transparent transparent;
                transform: translateX(-50%);
            }
        }
        &__warning {
            position: absolute;
            top: calc(50% - 25px);
            left: calc(50% + 50px);
            padding: 4px 8px;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.4px;
            font-weight: 400;
            border-radius: 4px;
            color: var(--druk-inverse-on-surface);
            background-color: var(--druk-surface-inverse-surface);
            transform: translate(-50%, -50%);
            svg {
                margin-right: 2px;
                width: 14px;
                height: 14px;
            }
        }
    }
</style>
